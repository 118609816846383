import { useMutation } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import PaySiteResponse from "../../interfaces/payment/PaySiteResponse";
import PaySiteRequest from "../../interfaces/payment/PaySiteRequest";

const unexpectedErrorText = "Erro inesperado, tente novamente";

const sitePayment = async (data: PaySiteRequest) => {
    try{
        const response = await AxiosConfig
            .getAxiosInstance()
            .post(
                '/api/v1/payments/site',
                data
            );

        if (response.status === 200)
            return response.data;
        throw new Error(unexpectedErrorText).message
    }catch(err){
        throw new Error(unexpectedErrorText).message;
    }
}
        
export const useSitePayment = (onSuccessCallback: (response: PaySiteResponse) => void) =>
    useMutation<PaySiteResponse, Error, PaySiteRequest>({
        mutationFn: sitePayment,
        onSuccess: (response) => {
            onSuccessCallback(response);
        }
    });
