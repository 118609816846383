import { PublishmentStep } from "../../../publishmentModal/publishment";
import { 
    ContainerTitle, 
    InnerContainerTitle,
    ContainerCircle, 
    StepNumber, 
    GoBackArrow,
    ContainerFlex, 
    Select, 
    Div, 
    SubStepTitle,
    BiggerLabel,
    MainContainer,
    Button,
    Input,
    CepInput
} from "./cardAddressStep.styles";
import { SubStep } from "../../paymentStep";
import { useFormik } from "formik";
import * as yup from 'yup';
import { PaymentStepData } from "../../../../../../interfaces/payment/PaymentStepData";
import { useGetZipCodeAddress } from "../../../../../../hooks/paymentService/useGetZipCodeAddress";
import { useEffect } from "react";
import { StatesList } from "../../../../../../resources/AddressesResource";
import { DropdownOption } from "../../../../../../components/Dropdown/Dropdown";
import FireBaseService, { analyticsEvents } from "../../../../../../infrastructure/firebaseService";

interface CardAddressStepProps{
    paymentStepData: PaymentStepData;
    step: SubStep;
    goToStep: (step: PublishmentStep) => void;
    goToSubStep: (step: SubStep) => void;
    updatePaymentStepData: (paymentStepData: PaymentStepData) => void;
}

export default function CardAddressStep({
    paymentStepData,
    step,
    goToStep,
    goToSubStep,
    updatePaymentStepData
}: CardAddressStepProps){
    const validationSchema = yup.object().shape({
        zipCode: yup
            .string()
            .required('CEP é obrigatório')
            .matches(/^\d{5}-\d{3}$/, 'CEP deve conter 8 dígitos'),
        street: yup
            .string()
            .required('Rua é obrigatória'),
        city: yup
            .string()
            .required('Cidade é obrigatória'),
        state: yup
            .string()
            .required('Estado é obrigatório')
    });

    const formik = useFormik({
        initialValues: {
            zipCode: paymentStepData.paymentData.billingAddress.zipCode,
            street: paymentStepData.paymentData.billingAddress.street,
            neighborhood: paymentStepData.paymentData.billingAddress.neighborhood,
            number: paymentStepData.paymentData.billingAddress.number,
            city: paymentStepData.paymentData.billingAddress.city,
            state: paymentStepData.paymentData.billingAddress.state,
            complement: paymentStepData.paymentData.billingAddress.complement
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            FireBaseService.logButtonClick(analyticsEvents.editorBillingAddress);

            updatePaymentStepData({
                ...paymentStepData,
                paymentData: {
                    ...paymentStepData.paymentData,
                    billingAddress: {
                        ...paymentStepData.paymentData.billingAddress,
                        street: values.street,
                        neighborhood: values.neighborhood,
                        zipCode: values.zipCode,
                        complement: values.complement,
                        number: values.number,
                        city: values.city,
                        state: values.state
                    }
                }
            });

            goToSubStep(SubStep.PROCESSING_PAYMENT);
        },
    });
    
    const { data: zipCodeInfo, isLoading: loadingZipCodeInfo, refetch: getZipCodeInfo, isFetched: isZipCodeFetched } = useGetZipCodeAddress(formik.values.zipCode);

    useEffect(() => {
        if (!!zipCodeInfo) {
            if (zipCodeInfo.bairro.length > 0)
                formik.setFieldValue("neighborhood", zipCodeInfo.bairro);
            if (zipCodeInfo.localidade.length > 0)
                formik.setFieldValue("city", zipCodeInfo.localidade);
            if (zipCodeInfo.logradouro.length > 0)
                formik.setFieldValue("street", zipCodeInfo.logradouro);
            if (zipCodeInfo.uf.length > 0)
                formik.setFieldValue("state", zipCodeInfo.uf);
        }
    }, [zipCodeInfo]);

    const handleStoppedTypingAddress = (value: string) => {
        if (value.length == 9)
            getZipCodeInfo();
    }

    const statesList: DropdownOption[] = StatesList.map((value) => {
        return {
            value: value,
            text: value
        }
    });

    return(
        <MainContainer>
            <ContainerTitle>
                <GoBackArrow to={() => goToSubStep(SubStep.CARD_DETAILS)}/>
                    <InnerContainerTitle>
                        <ContainerCircle>
                            <StepNumber>2</StepNumber>
                        </ContainerCircle>
                        <SubStepTitle>Endereço do cartão</SubStepTitle>
                    </InnerContainerTitle>
            </ContainerTitle>

            <BiggerLabel>Digite o seu CEP</BiggerLabel>
            <CepInput
                type='text'
                placeholder='CEP'
                label='CEP'
                mask="99999-999"
                maskInput={true}
                inputId='zipCode'
                value={formik.values.zipCode}
                loading={loadingZipCodeInfo}
                onChange={formik.handleChange}
                onStopTyping={handleStoppedTypingAddress}
                error={!!formik.errors.zipCode && formik.touched.zipCode}
                errorText={formik.errors.zipCode}
            />

            {!!isZipCodeFetched && (
                <>
                    <BiggerLabel>Endereço</BiggerLabel>
                    <Div onSubmit={formik.handleSubmit}>
                        <ContainerFlex>
                            <Input
                                type="text"
                                placeholder="Logradouro"
                                label="Logradouro (Rua, Avenida)"
                                inputId="street"
                                value={formik.values.street}
                                onChange={formik.handleChange}
                                error={!!formik.errors.street && formik.touched.street}
                                errorText={formik.errors.street}
                            />
                            <Input
                                type="text"
                                placeholder="Bairro"
                                label="Bairro"
                                inputId="neighborhood"
                                value={formik.values.neighborhood}
                                onChange={formik.handleChange}
                                error={!!formik.errors.neighborhood && formik.touched.neighborhood}
                                errorText={formik.errors.neighborhood}
                            />
                        </ContainerFlex>
                        <ContainerFlex>
                            <Input
                                type="text"
                                placeholder="Cidade"
                                label="Cidade"
                                inputId="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={!!formik.errors.city && formik.touched.city}
                                errorText={formik.errors.city}
                            />
                            <Select
                                id="state"
                                value={formik.values.state}
                                onChangeEvent={formik.handleChange}
                                options={statesList}
                                label="Estado"
                            />
                        </ContainerFlex>
                        <ContainerFlex>
                            <Input
                                type="text"
                                placeholder="Número"
                                label="Número (Opcional)"
                                inputId="number"
                                value={formik.values.number}
                                onChange={formik.handleChange}
                            />
                            <Input
                                type="text"
                                placeholder="Complemento"
                                label="Complemento (Opcional)"
                                inputId="complement"
                                value={formik.values.complement}
                                onChange={formik.handleChange}
                            />         
                        </ContainerFlex>
                    </Div> 
                    <Button
                        text='Próximo'
                        type='submit'
                        loading={step == SubStep.PROCESSING_PAYMENT}
                        onClick={formik.handleSubmit}
                    />
                </>
            )}
    </MainContainer>
    )
}