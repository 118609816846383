import styled from "styled-components";
import { themes } from "../../themes/themes";

const { placeholderSearchInputColor, dashBoardFont, white } = themes;

export const SearchInputComponent = styled.input`
    width: 100%;
    position: relative;
    border: none;
    border-radius: 15px;
    padding-left: 1rem;
    height: 56px;
    
    &::-webkit-input-placeholder{
        color: ${placeholderSearchInputColor};
        font-size: 0.8rem;
        font-family: ${dashBoardFont};
        line-height: 1rem;
    }
    &::-moz-placeholder{
        color: ${placeholderSearchInputColor};
        font-size: 0.8rem;
        font-family: ${dashBoardFont};
        line-height: 1rem;
    }
    &::-ms-input-placeholder{
        color: ${placeholderSearchInputColor};
        font-size: 0.8rem;
        font-family: ${dashBoardFont};
        line-height: 1rem;
    }
    &:focus{
        box-shadow: 0 0 0 0;
        border: none;
        outline: 0;
    }
`;

export const SearchIco = styled.img`
    margin-left: 16px;
`;

export const DivSearch = styled.div`
    border-radius: 15px;
    position: relative;
    background-color: ${white};
    display: flex;
    border: 1px solid ${placeholderSearchInputColor};

    @media (max-width: ${themes.tabletDimension}){
        margin: 0 12px;
    }
`;