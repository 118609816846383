import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import TextInput from '../../components/TextInput/TextInput';
import StandardButton from '../../components/Button/Button';
import ReturnArrow from '../../components/ReturnArrow/ReturnArrow';
import { useForgotPassword } from '../../hooks/userService/useForgotPassword';
import { 
    DescriptionForgotPassword, 
    DivButton, 
    DivInnerCard, 
    MainContainer, 
    TitleForgotPassword, 
    AlertInstructions, 
    Card
} from './ForgotPasswordPage.styles';
import FireBaseService, { analyticsEvents } from '../../infrastructure/firebaseService';

function ForgotPasswordPage() {
    const navigate = useNavigate();
    const { mutate: forgotPassword, isLoading, error: forgotPasswordError, isSuccess } = useForgotPassword();

    const validationSchema = yup.object({
        email: yup
          .string()
          .email('Digite um email válido')
          .required('Digite seu email')
    });

    const formik = useFormik({
        initialValues: {
          email: ''
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            const submitData = {
                email: formik.values.email
            }
            FireBaseService.logButtonClick(analyticsEvents.confirmForgotPasswordButton);
            forgotPassword(submitData);
        },
    });

    const handleArrowClick = () => {
        FireBaseService.logButtonClick(analyticsEvents.forgotPasswordBackButton);
        navigate('/auth');
    }

    return (
      <MainContainer>
            <Card>
                <DivInnerCard>
                    <ReturnArrow to={handleArrowClick}/>
                    <TitleForgotPassword>
                        Esqueci a senha
                    </TitleForgotPassword>
                    <DescriptionForgotPassword>
                        Insira seu endereço de e-mail. Iremos enviar instruções sobre como criar uma nova senha.
                    </DescriptionForgotPassword>
                    <form onSubmit={formik.handleSubmit}>
                        {!isLoading && !!forgotPasswordError &&(
                            <AlertInstructions severity="error">{forgotPasswordError}</AlertInstructions>
                        )}
                        {!isLoading && isSuccess && (
                            <AlertInstructions severity="success">Sucesso! Verifique sua caixa de entrada</AlertInstructions>
                        )}
                        <TextInput
                            inputId='email'
                            placeholder='E-mail'
                            label='E-mail'
                            type='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={!!formik.errors.email && formik.touched.email}
                            errorText={formik.errors.email}
                        />
                        <DivButton>
                            <StandardButton
                                text='Recuperar'
                                type='submit'
                                loading={isLoading}
                                disabled={formik.values.email === "" || !!formik.errors.email}
                                onClick={formik.handleSubmit}
                            />
                        </DivButton>
                    </form>
                </DivInnerCard>
            </Card>
      </MainContainer>
    );
}
  
export default ForgotPasswordPage;

