import styled from 'styled-components';
import { themes } from '../../themes/themes';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";

type Size = 'small' | 'medium';

interface IconProps {
    size: Size;
}

interface ChevronIconProps {
    iconsize: Size;
}

export const maxHeight = 170;

export const Container = styled.div`
    position: relative;
`

export const MainContainer = styled.div<{nopadding?: boolean}>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }

    ${props => {
        if (props.nopadding) {
            return 'padding: 0 !important;'
        }
    }}
`

export const DropdownValueContainer = styled.div<IconProps>`
    color: ${themes.darkPurple};
    font-size: ${props => props.size === 'medium' ? '20' : '16'}px;
    display: flex;
    align-items: center;
`

export const ChevronDown = styled(FaChevronDown)<ChevronIconProps>`
    color: ${themes.darkPurple};
    font-size: 1.063rem;
    user-select: none;
    font-size: ${props => props.iconsize === 'medium' ? '12' : '10'}px;
`

export const ChevronUp = styled(FaChevronUp)<ChevronIconProps>`
    color: ${themes.darkPurple};
    font-size: 1.063rem;
    user-select: none;
    font-size: ${props => props.iconsize === 'medium' ? '12' : '10'}px;
`

export const DropdownItemsContainer = styled.div<{size: Size, noPadding?: boolean}>`
    position: relative;
    margin: ${props => props.size === 'medium' ? '12px 0': '6px 0'};
    position: absolute;
    width: max-content;
    background-color: ${themes.white};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: ${props => !!props.noPadding ? '0' : '10px 0px'};
    max-height: ${maxHeight}px;
    overflow-y: auto;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        border: 2px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }
`

export const DropdownWrapper = styled.div<{opened: boolean, dropdownUp: boolean, size: Size}>`
    position: relative;

    ${props => props.opened && `
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            margin: 6px 0;
            margin: ${props.size === 'medium' ? '6px 0': '0'};
            ${props.dropdownUp ? `
                bottom: 100%;
                border-top: 10px solid ${themes.white};
            ` : `
                top: 100%;
                border-bottom: 10px solid ${themes.white};
            `}
        }
    `};
`

export const DropdownItemContainer = styled.div`
    padding: 15px 24px;

    &:hover {
        background-color: ${themes.pinkLace};
        cursor: pointer;
    }
`

export const DropdownItemValue = styled.p`
    font-family: ${themes.primaryFont};
    font-size: 0.875rem;
    user-select: none;
    color: ${themes.darkPurple};
`