import { SocialNetwork } from "@hivefuse/page-components";
import CreationOptions from "../pages/siteEditor/interface/creationOptions";

export const GetPreferredSocialNetwork = (socialNetworks: SocialNetwork[]): SocialNetwork | undefined=> {
    const whastapp = socialNetworks.find(s => s.type == 'Whatsapp');
    if (!!whastapp)
        return whastapp;

    const instagram = socialNetworks.find(s => s.type == 'Instagram');
    if (!!instagram)
        return instagram;

    const facebook = socialNetworks.find(s => s.type == 'Facebook');
    if (!!facebook)
        return facebook;

    const twitter = socialNetworks.find(s => s.type == 'Twitter');
    if (!!twitter)
        return twitter;

    return undefined;
}

export const GetSocialMediaUrl = (socialNetwork: SocialNetwork) => {
    switch(socialNetwork.type) {
        case 'Facebook':
            return `https://facebook.com/${socialNetwork.address}`
        case 'Instagram':
            return `https://instagram.com/${socialNetwork.address}`
        case 'Whatsapp':
            return `https://wa.me/${socialNetwork.address}`
        case 'Twitter':
            return `https://x.com/${socialNetwork.address}`
    }
}

export const getSocialNetworks = (options?: CreationOptions): SocialNetwork[] => {
    const socialNetworks: SocialNetwork[] = [];
    const { facebook, twitter, instagram, whatsapp } = {...options};

    if (!!facebook && facebook.length > 0)
        socialNetworks.push({ type: 'Facebook', address: facebook });
    if (!!twitter && twitter.length > 0)
        socialNetworks.push({ type: 'Twitter', address: twitter });
    if (!!instagram && instagram.length > 0)
        socialNetworks.push({ type: 'Instagram', address: instagram });
    if (!!whatsapp && whatsapp.length > 0)
        socialNetworks.push({ type: 'Whatsapp', address: whatsapp });
    return socialNetworks;
}