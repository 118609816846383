import StandardButton from "../../../../../components/Button/Button";
import ReturnArrow from "../../../../../components/ReturnArrow/ReturnArrow";
import LocationInput from "../../../../../components/LocationInput/LocationInput";
import { SiteCreationStep } from "../../../SiteCreationPage";
import { AdvanceArrow, BusinessWhere, DivArrows, DivButton, OptionalText } from "./LocationStep.styles";
import FireBaseService, { analyticsEvents } from "../../../../../infrastructure/firebaseService";
import { useState } from "react";

interface Props{
    initialValue?: string;
    goToStep: (step: SiteCreationStep) => void;
    setLocation: (value?: string) => void;
}

export default function LocationStep({initialValue, goToStep, setLocation }: Props){
    const [location, setLocationState] = useState(initialValue ?? '');

    const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleGoToNextStep();
        }
    }

    const handleSkipStepClicked = () => {
        FireBaseService.logButtonClick(analyticsEvents.siteLocationForm);
        setLocation(location);
        goToStep(SiteCreationStep.SOCIAL_MEDIA);
    }

    const handleGoToNextStep = () => {
        if (location.length > 0)
            setLocation(location);
        else
            setLocation(undefined);

        FireBaseService.logButtonClick(analyticsEvents.siteLocationForm);
        goToStep(SiteCreationStep.SOCIAL_MEDIA);
    }
    
    return(
        <>
            <DivArrows>
                <ReturnArrow to={() => goToStep(SiteCreationStep.NAME)}/>
                <AdvanceArrow to={handleSkipStepClicked} positionArrow="right">
                    Pular
                </AdvanceArrow>
            </DivArrows>
            <BusinessWhere>
                Onde fica o seu negócio?
                <OptionalText>(Opcional)</OptionalText>
            </BusinessWhere>
            <LocationInput
                value={location}
                setValue={(value) => setLocationState(value)}
                onKeyUp={handleEnterKeyPress}
            />
            <DivButton>
                <StandardButton
                    type="button"
                    text="Próximo"
                    onClick={handleGoToNextStep}
                />
            </DivButton>
        </>
    )
}