import { getFontColor } from '../../../utils/colorUtils';
import { BackgroundPreview, EditButton, EditContainer, EditInnerContainer, InputLabel, InputRow, LogoInnerContainer, RowInputLabel, Select, TabsList } from './editHeader.style';
import { headerLogoStyles, HeaderProps, headerTextStyles, Position, Size } from '@hivefuse/page-components';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import ColorPicker from '../../../../../components/ColorPicker/ColorPicker';
import { HeaderEditScreen } from '../headerCreation';
import { Tab } from '@mui/material';
import { useState } from 'react';
import InputText from '../../../../../components/TextInput/TextInput';
import ImageSelectionModal from '../../imageSelectionModal/imageSelectionModal';
import { useRecoilValue } from 'recoil';
import { siteStockImagesState } from '../../../../../state/siteEditorState';
import Checkbox from '../../../../../components/Checkbox/Checkbox';

interface Props {
    currentProps: HeaderProps;
    onEdit: (newProps: HeaderProps) => void;
    headerEditScreen: HeaderEditScreen;
    goToScreen: (screen: HeaderEditScreen) => void;
}

enum LogoEditScreen {
    TITLE = 'TITLE',
    IMAGE = 'IMAGE'
}

export default function EditHeader(props: Props) {
    const { currentProps, headerEditScreen, onEdit, goToScreen } = props;
    const images = useRecoilValue(siteStockImagesState);
    const [imageSelectionModalOpened, setImageSelectionModalOpened] = useState(false);
    const getInitialLogoEditScreen = (): LogoEditScreen => {
        if (!!currentProps.title)
            return LogoEditScreen.TITLE
        return LogoEditScreen.IMAGE
    }
    const [logoEditScreen, setLogoEdit] = useState(getInitialLogoEditScreen());

    const handleTitleChange = (e: any) => {
        onEdit({
            ...currentProps,
            title: e.target.value
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            backgroundColor: e,
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            padding: e
        });
    }

    const handleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            alignment: e
        });
    }

    const handleLogoImageSelected = (imageUrl: string) => {
        onEdit({
            ...currentProps,
            title: undefined,
            logo: {
                imageUrl: imageUrl
            }
        });
    }

    const handleLogoSizeChanged = (size: Size) => {
        onEdit({
            ...currentProps,
            logo: {
                imageUrl: currentProps?.logo?.imageUrl ?? '',
                mobileSize: size,
                desktopSize: size
            }
        });
    }

    const handleChangedFixedHeader = (value: boolean) => {
        onEdit({
            ...currentProps,
            fixed: value
        });
    }

    const getLogoPreview = () => {
        if (!!currentProps.title) {
            return (
                <BackgroundPreview
                    background={{backgroundColor: currentProps.backgroundColor}}
                >
                    <LogoInnerContainer>
                        <h1 style={{...headerTextStyles({$font: currentProps.fontRef?.font, $font_color: currentProps.fontColor }), ...{padding: '1rem 0'}}}>{currentProps.title}</h1>
                    </LogoInnerContainer>
                </BackgroundPreview>
            )
        }

        return (
            <BackgroundPreview
                background={{backgroundColor: currentProps.backgroundColor}}
            >
                {!!currentProps.logo?.imageUrl && (
                    <LogoInnerContainer>
                        <img style={{...headerLogoStyles({$desktop_size: Size.MEDIUM, $mobile_size: Size.MEDIUM}), ...{padding: '1rem 0'}}} src={currentProps.logo.imageUrl}/>
                    </LogoInnerContainer>
                )}
            </BackgroundPreview>
        )
    }

    if (headerEditScreen == HeaderEditScreen.LOGO)
        return (
            <EditContainer>
                <TabsList value={logoEditScreen} variant="scrollable" scrollButtons={false} onChange={(_, value) => setLogoEdit(value)}>
                    <Tab label="Texto" value={LogoEditScreen.TITLE}/>
                    <Tab label="Imagem" value={LogoEditScreen.IMAGE}/>
                </TabsList>

                {logoEditScreen == LogoEditScreen.TITLE && (
                    <EditInnerContainer>
                        <InputLabel noMarginTop={true}>Título</InputLabel>
                        <InputText
                            type='text'
                            value={currentProps?.title}
                            onChange={handleTitleChange}
                        />
                    </EditInnerContainer>
                )}

                {logoEditScreen == LogoEditScreen.IMAGE && (
                    <>
                        <InputLabel>Tamanho da Imagem</InputLabel>
                        <Dropdown
                            value={currentProps?.logo?.desktopSize ?? Size.SMALL}
                            options={[
                                {
                                    text: 'Pequena',
                                    value: Size.SMALL
                                },
                                {
                                    text: 'Média',
                                    value: Size.MEDIUM 
                                },
                                {
                                    text: 'Grande',
                                    value: Size.LARGE
                                }
                            ]}
                            onValueChange={handleLogoSizeChanged}
                        />
                        <EditInnerContainer alignLeft={true}>
                            <EditButton onClick={() => setImageSelectionModalOpened(true)}>Adicionar Imagem</EditButton>
                        </EditInnerContainer>
                    </>
                )}

                {getLogoPreview()}

                {imageSelectionModalOpened && (
                    <ImageSelectionModal
                        onClose={() => setImageSelectionModalOpened(false)}
                        selectedImage={currentProps?.logo?.imageUrl}
                        onImageSelected={(image) => handleLogoImageSelected(image)}
                        images={images}
                    />
                )}
            </EditContainer>
        );

    return (
        <EditContainer>
            <InputRow>
                <RowInputLabel>Logo</RowInputLabel>
                <EditButton onClick={() => goToScreen(HeaderEditScreen.LOGO)}>Alterar</EditButton>
            </InputRow>
            {getLogoPreview()}
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
            <Checkbox
                text='Fixado no topo'
                value={currentProps?.fixed}
                onChange={handleChangedFixedHeader}
            />
            <InputLabel>Alinhamento</InputLabel>
            <Dropdown
                value={currentProps?.alignment}
                options={[
                    {
                        text: 'Esquerda',
                        value: Position.LEFT 
                    },
                    {
                        text: 'Centro',
                        value: Position.CENTER 
                    },
                    {
                        text: 'Direita',
                        value: Position.RIGHT 
                    }
                ]}
                onValueChange={handleAlignmentChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.padding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL
                    },
                    {
                        text: 'Medio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
        </EditContainer>
    )
}