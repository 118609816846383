import { useMutation } from "react-query";
import PublishSiteRequest from "../../interfaces/site/PublishSiteRequest";
import AxiosConfig from "../../configs/AxiosConfig";

const publishTrialSite = async (data: PublishSiteRequest) => {
    try{
        const response = await AxiosConfig
            .getAxiosInstance()
            .post(
                `api/v1/sites/trial/publish/${data.siteId}`,
                data
            );

        if (response.status === 200)
            return response.data;
        throw new Error();
    } catch(err) {
        throw new Error();
    }
}
        
export const usePublishTrialSite = (onSuccessCallback: () => void) =>
    useMutation<void, Error, PublishSiteRequest>({
        mutationFn: publishTrialSite,
        onSuccess: (response) => {
            onSuccessCallback();
        }
    });
