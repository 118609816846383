import LogoHiveBImg from "../../assets/hiveb-logo.svg";
import { Header, LogoHiveB, MenuHamComponent } from "./HeaderMobile.styles";

interface Props{
    handleClickOnOpenMenuButton: () => void;
}

export default function HeaderMobile({handleClickOnOpenMenuButton}: Props){
    return(
        <Header>
            <LogoHiveB src={LogoHiveBImg}/>
            <MenuHamComponent onClick={handleClickOnOpenMenuButton}/>
        </Header>
    )
}