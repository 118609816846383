import { useEffect, useImperativeHandle, useState } from 'react';

import { AspectRatio, BackgroundProps, Gallery, GalleryProps, Size } from '@hivefuse/page-components';
import { ImageHelper } from '../../utils/imageUtils';
import { getFontColor } from '../../utils/colorUtils';
import { ThemeUtils } from '../../utils/themeUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { siteComponentIndexState, siteEditedState, siteStockImagesState, siteThemeState } from '../../../../state/siteEditorState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useRegisterRef } from '../../utils/siteRefManager';
import EditGallery from './editGallery/editGallery';

interface Props {
    componentId: string;
    existingProps?: GalleryProps;
}

export enum GalleryEditScreen {
    GENERAL = 'GENERAL',
    LIST_ITEM = 'LIST_ITEM',
    NEW_ITEM = 'NEW_ITEM'
}

const GalleryCreation  = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const [galleryProps, setGalleryProps] = useState<GalleryProps>(existingProps ?? {});
    const [currentEditScreen, setCurrentEditScreen] = useState(GalleryEditScreen.GENERAL);
    const theme = useRecoilValue(siteThemeState)!;
    const images = useRecoilValue(siteStockImagesState);
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return galleryProps;
        },
        regenerateStyle() {
            handleRegenerateEntireStyle()
        },
        regenerateContent() {

        },
        regenerateEntireSection() {
            regenerateEntireSection()
        }
    })); 

    useEffect(() => {
        if (!existingProps)
            getInitialProps();

        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!theme) {
            setGalleryProps(prevState => { return {
                ...prevState,
                fonts: theme?.themeFont
            } });
        }
    }, [theme]);

    const getInitialProps = () => {
        const newBannerStyleProps = GetNewStyle();
        const selectedImages = ImageHelper.getRandomImages(images, 6);
        setGalleryProps(prevState => { return { ...prevState, ...newBannerStyleProps, title: 'Galeria', imageUrls: selectedImages }});
    }

    const handleRegenerateEntireStyle = () => {
        setSiteEditedState(true);
        const newStyle = GetNewStyle();
        setGalleryProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleRegenerateStyle = () => {
        setSiteEditedState(true);
        const newStyle = GetRegeneratedStyle();
        setGalleryProps(prevState => { return {...prevState, ...newStyle} });
    }

    const GetRegeneratedStyle = (): GalleryProps => {
        return {
            aspectRatio: GenerateAspectRatio(),
            verticalPadding: GeneratePadding(),
            borderEdge: theme.borderEdges,
            fonts: theme?.themeFont,
            imageShadows: GenerateShadow()
        };
    }

    const GetNewStyle = (): GalleryProps => {
        return {
            aspectRatio: GenerateAspectRatio(),
            verticalPadding: GeneratePadding(),
            background: GenerateBackground(),
            borderEdge: theme.borderEdges,
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont,
            imageShadows: GenerateShadow()
        };
    }

    const GenerateShadow = (): string | undefined => {
        if (!theme.useShadows)
            return undefined;

        return "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px";
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const GenerateAspectRatio = (): AspectRatio => {
        const allowedAspectRatios = [AspectRatio.SQUARE, AspectRatio.WIDESCREEN, AspectRatio.LANDSCAPE, AspectRatio.PORTRAIT];
        return allowedAspectRatios[Math.floor(Math.random() * allowedAspectRatios.length)];
    }

    const GeneratePadding = (): Size => {
        return Object.values(Size)[Math.floor(Math.random() * Object.values(Size).length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const regenerateEntireSection = () => {
        setSiteEditedState(true);
        const newStyle = GetNewStyle();
        const selectedImages = ImageHelper.getRandomImages(images, 6);
        setGalleryProps(prevState => { return {...prevState, ...newStyle, imageUrls: selectedImages} });
    }

    const goToScreen = (screen: GalleryEditScreen) => {
        setCurrentEditScreen(screen);
    }

    const onEditing = (newProps: GalleryProps) => {
        setSiteEditedState(true);
        setGalleryProps(prevState => { return {...prevState, ...newProps} });
    }

    const handleOnEdit = () => {
        setCurrentEditScreen(GalleryEditScreen.GENERAL);
    }

    const getEditComponent = () => {
        return (
            <EditGallery
                onEdit={onEditing}
                currentProps={galleryProps}
                editScreen={currentEditScreen}
                images={images}
                goToScreen={goToScreen}
            />
        )
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            editComponent={getEditComponent()}
            editTitleHeader='Galeria'
            onEdit={handleOnEdit}
            onInnerNavigation={currentEditScreen != GalleryEditScreen.GENERAL}
            onInnerNavigationGoBack={() => setCurrentEditScreen(GalleryEditScreen.GENERAL)}
            onRegenerateStyle={handleRegenerateStyle}
            onRegenerateEntireSection={regenerateEntireSection}
        >
            <Gallery
                data={{
                    ...galleryProps
                }}
            />
        </BackdropEditor>
    )
});

export default GalleryCreation;