import PlacesAutocomplete, { AutocompletePrediction } from 'react-places-autocomplete';
import { DivDropDown, BackGroundSuggestion, Suggestion, Container, LoadingSpinner } from './LocationInput.styles';
import InputText from '../TextInput/TextInput';
import { useEffect, useRef, useState } from 'react';

interface Props{
    value: string;
    setValue: (address: string) => void;
    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}

interface Suggestion {
    id: string;
    active: boolean;
    index: number;
    description: AutocompletePrediction["description"];
    placeId: AutocompletePrediction["place_id"];
    formattedSuggestion: {
        mainText: AutocompletePrediction["structured_formatting"]["main_text"];
        secondaryText: AutocompletePrediction["structured_formatting"]["secondary_text"];
    };
    matchedSubstrings: AutocompletePrediction["matched_substrings"];
    terms: AutocompletePrediction["terms"];
    types: AutocompletePrediction["types"];
}

export default function LocationInput({ onFocus, value: addressValue, setValue, onKeyUp }: Props){
    const [focusedSuggestion, setFocusedSuggestion] = useState(0);
    const suggestionRef = useRef<(HTMLDivElement | null)[]>(Array(3).fill(null));
    const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, currentSuggestions: Suggestion[]) => {
        if (dropDownIsOpen) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setFocusedSuggestion((prev) => (prev + 1) % 3);
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setFocusedSuggestion((prev) => (prev - 1 + 3) % 3);
            } else if (e.key === 'Tab') {
                e.preventDefault();
                setFocusedSuggestion((prev) => (prev + 1) % 3);
            } else if (e.key === 'Enter') {
                e.preventDefault();
                if (focusedSuggestion !== null) {
                    const index = suggestionRef.current.findIndex((_, i) => i === focusedSuggestion);
                    if (index !== -1) {
                        const selectedSuggestion = currentSuggestions[focusedSuggestion];
                        if (selectedSuggestion) {
                            handleSelect(selectedSuggestion.description);
                        }
                    }
                }
            }
        } else if (e.key === 'Enter' && onKeyUp !== undefined){
            onKeyUp(e)
        }
    };

    const getSuggestionStyle = (index: number): React.CSSProperties => {
        if (focusedSuggestion !== null && index === focusedSuggestion) {
            return {
                backgroundColor: '#F9DCF1',
            };
        }
        return {};
    };

    const handleSelect = async (addressValue: string) => {
        setValue(addressValue);
        setDropDownIsOpen(false);
    }

    useEffect(() => {
        if(addressValue === ''){
            setDropDownIsOpen(false)
            setFocusedSuggestion(0)
        }
    }, [addressValue])
    
    const searchOptions = {
        componentRestrictions: { country: ['BR'] }
    }

    return(
        <PlacesAutocomplete
            value={addressValue}
            searchOptions={searchOptions}
            onChange={(newValue) => {
                setValue(newValue);

                if(newValue !== ''){
                    setDropDownIsOpen(true);
                }
            }}
            onSelect={handleSelect}
        >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
            const {value, onKeyDown, ...restInputProps} = getInputProps({
                placeholder: 'Localização',
                className: 'location-search-input',
                type: 'text',
                onFocus: onFocus
            })

            return (
                <Container>
                    <InputText
                        value={addressValue}
                        onKeyDown={(e) => handleKeyDown(e, suggestions as Suggestion[])}
                        loading={loading}
                        {...restInputProps}
                    />
                    {!loading && dropDownIsOpen && suggestions.length !== 0 &&
                        <DivDropDown>
                            {suggestions.slice(0, 3).map((suggestion, index) => {
                                const {...suggestionItemProps} = getSuggestionItemProps(suggestion);
                                return (
                                    <BackGroundSuggestion
                                        OnMouseOver={suggestion.active}
                                        {...suggestionItemProps}
                                        key={index}
                                        onKeyDown={(e) => handleKeyDown(e as any, suggestions as Suggestion[])}
                                        tabIndex={0}
                                        style={getSuggestionStyle(index)}
                                    >   
                                        <Suggestion ref={(el) => (suggestionRef.current[index] = el || null)}>{String(suggestion.description)}</Suggestion>
                                    </BackGroundSuggestion>
                                );
                            })}
                        </DivDropDown>
                    }
                </Container>
            )
        }}
      </PlacesAutocomplete>
    );
}