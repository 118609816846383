import styled, { keyframes } from "styled-components";
import Dropdown from "../../../../../../components/Dropdown/Dropdown";
import InputText from "../../../../../../components/TextInput/TextInput";
import ReturnArrow from "../../../../../../components/ReturnArrow/ReturnArrow";
import { themes } from "../../../../../../themes/themes";
import { ImSpinner6 } from "react-icons/im";
import { CiCircleCheck } from "react-icons/ci";
import StandardButton from "../../../../../../components/Button/Button";
import TextInput from "../../../../../../components/TextInput/TextInput";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const DiscountCouponContainer = styled.div`
    width: 100%;
    max-width: 220px;
    border-right: ${themes.lightBrown} solid 2px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    @media (max-width: ${themes.tabletDimension}){
        max-width: 100%;
        justify-content: center;
        align-items: center;
    }
`;

export const InnerContainerCoupon = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    
    @media (max-width: ${themes.tabletDimension}){
        justify-content: center;
        align-items: center;
    }
`;

export const Title = styled.p`
    color: ${themes.darkPurple};
    font-size: 1.563rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    line-height: 1.25rem;
    word-wrap: break-word;
    margin-top: 25px;

    @media (max-width: ${themes.tabletDimension}){
        text-align: center;
    }
`;

export const SubStepTitle = styled.p`
    color: ${themes.darkGray};
    font-size: 1rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    word-wrap: break-word;

    @media (max-width: ${themes.tabletDimension}){
        margin-top: 10px;
        max-height: 45px;
        height: 100%;
    }
`;

export const DivWithFlex = styled.div`
    display: flex;
    column-gap: 18px;
`

export const InstallmentsQuantity = styled.p`
    color: ${themes.darkGray};
    font-size: 1.125rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    word-wrap: break-word;
    position: relative;
    margin-top: 12px;

    @media (max-width: ${themes.tabletDimension}){
        text-align: center;
    }
`;

export const LoadingSpinner = styled(ImSpinner6)`
    position: absolute;
    margin-top: 5px;
    right: 14px;
    width: 22px;
    height: 22px;
    animation: ${rotate360} 1s linear infinite;
`;

export const CircleCheck = styled(CiCircleCheck)`
    position: absolute;
    margin-top: 5px;
    right: 20px;
    width: 22px;
    height: 22px;
    color: ${themes.greenActive};
`;

export const LogoHiveB = styled.img`
    width: 158px;
    height: 67px;
    @media (max-width: ${themes.tabletDimension}){
        display: none;
    }
`;

export const LogoHiveBMobile = styled.img`
    height: 45px;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    @media (max-width: ${themes.tabletDimension}){
        display: block;
    }
`;

export const DivInput = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 25px;
    
    @media (max-width: ${themes.tabletDimension}){
        flex: 1;
        margin-top: 0px;
    }
`;

export const ContainerCardDetails = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ContainerTitle = styled.div`
    display: flex;

    @media (max-width: ${themes.tabletDimension}){
        margin-top: 35px;
    }
`;

export const ContainerCircle = styled.div`
    position: relative;
    margin-left: 12px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    background-color: ${themes.darkPurple};
    border-radius: 50%;
`;

export const StepNumber = styled.p`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${themes.white};
    font-size: 1.2rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    word-wrap: break-word;
`;

export const InnerContainerExpAndCvv = styled.div`
    display: flex;
    column-gap: 25px;
    margin-top: 7px;

    @media (max-width: ${themes.mobileDimension}){
        flex-direction: column;
        column-gap: 0px;
        row-gap: 5px;
    }
`;

export const Input = styled(InputText)`
    flex: 1;
    width: auto;

    @media (max-width: ${themes.tabletDimension}){
        height: 40px;
    }
`;
export const InputCouponDiscount = styled(InputText)<{isDiscountTrue?: boolean}>`
    flex: 1;
    width: auto;
    border: ${(props) => props.isDiscountTrue ? `1px ${themes.greenActive} solid` : `1px ${themes.darkGray} solid` };

    @media (max-width: ${themes.tabletDimension}){
        height: 40px;
    }
`;

export const DivFlexless = styled.div`
    flex: none;
`;

export const InnerContainerInstallments = styled.div`
    margin-top: 5px;
    flex: 1;

    @media (max-width: ${themes.tabletDimension}){
        margin-top: 12px;
    }
`;

export const ContainerCPFAndInstallments = styled.div`
    display: flex;
    column-gap: 18px;
    align-items: center;
    margin-top: 7px;
`;

export const Select = styled(Dropdown)`
    flex: 1;
`;

export const InnerContainerTitle = styled.div`
    display: flex;
    column-gap: 16px;
    align-items: center;
`;

export const GoBackArrow = styled(ReturnArrow)`
    padding: 0;
`;

export const DivButton = styled.div`
    @media(max-width: ${themes.tabletDimension}){
        margin-top: 25px;
    }
`;

export const DivWithMarginTop = styled.div`
    margin-top: 7px;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;

    @media (max-width: ${themes.tabletDimension}){
        flex-direction: column
    }
`

export const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Row = styled.div`
    display: flex;
    gap: 24px;

    @media(max-width: ${themes.mobileDimension}){
        flex-direction: column;
        gap: 12px;
    }
`;

export const Button = styled(StandardButton)`
    margin-top: 24px;
`;

export const DomainText = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    font-weight: 700;
    color: ${themes.darkGray};
    margin: 12px 0;

    @media(max-width: ${themes.tabletDimension}){
        text-align: center;
    }
`;

export const CouponExistText = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 0.85rem;
    font-weight: 700;
    color: ${themes.darkGray};
    margin-top: 12px;
`;

export const CouponContainer = styled.div`
    margin-top: auto;
`;