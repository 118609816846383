import ReactDOM from "react-dom";
import { AlertBg, AlertBorder, AlertIconContainer, AlertInner, Button, ButtonContainers, Container, Description, DivDescription, InnerContainer, IoCloseX, Title } from "./Alert.style";
import { ButtonType } from "../Button/Button";
import { useEffect } from "react";


export interface AlertButtons {
    text: string,
    onPress?: () => void;
    buttonType?: ButtonType;
}

const AlertComponent = (props: { title: string, description: string, buttons: AlertButtons[], onClose: () => void, showAlertIcon?: boolean}) => {
    const { title, description, buttons, onClose, showAlertIcon } = props;

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
          document.body.style.overflow = 'visible';
        };
    }, []);

    const modalRoot = document.getElementById('modal-root')!;
    return ReactDOM.createPortal(
        <Container>
            <InnerContainer>
                <IoCloseX onClick={() => onClose()}/>
                {!!showAlertIcon &&
                    <AlertIconContainer>
                        <AlertBorder/>
                        <AlertBg></AlertBg>
                        <AlertInner>!</AlertInner>
                    </AlertIconContainer>
                }
                <Title>{title}</Title>
                <DivDescription>
                    <Description>{description}</Description>
                </DivDescription>
                <ButtonContainers>
                    {buttons.map((button, index) => (
                        <Button
                            key={index}
                            text={button.text}
                            buttonType={button.buttonType}
                            onClick={() => {
                                if (!!button.onPress)
                                    button.onPress();
                                onClose();
                            }}
                        />
                    ))}
                </ButtonContainers>
            </InnerContainer>
        </Container>,
        modalRoot
    );
}


export class Alert {
    static alert(title: string, description: string, buttons: AlertButtons[], showAlertIcon?: boolean) {
        const modalRoot = document.getElementById('modal-root')!;
        const onClose = () => {
            ReactDOM.unmountComponentAtNode(modalRoot);
        };

        ReactDOM.render(
            <AlertComponent
                showAlertIcon={showAlertIcon}
                title={title}
                description={description}
                buttons={buttons}
                onClose={onClose}
            />,
            modalRoot
        );
    }
}