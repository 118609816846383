import React from "react";
import { CompanySiteComponentType, CompanySiteComponenteProps } from "../interface/CompanySiteComponent";
import { nanoid } from 'nanoid';
import BannerCreation from "../components/bannerCreation/bannerCreation";
import TextCreation from "../components/textCreation/textCreation";
import TextAndImageCreation from "../components/textAndImageCreation/textAndImage";
import HeaderCreation from "../components/headerCreation/headerCreation";
import GalleryCreation from "../components/galleryCreation/galleryCreation";
import TestimonialsCreation from "../components/testimonialsCreation/testimonialsCreation";
import ListCreation from "../components/listCreation/listCreation";
import FooterCreation from "../components/footerCreation/footerCreation";
import LocationCreation from "../components/locationCreation/locationCreation";
import { SiteComponentState } from "../../../state/siteEditorState";
import Domain from "../../../interfaces/Domain";
import { DomainType } from "../../../interfaces/DomainType";
import VideoCreation from "../components/videoCreation/videoCreation";
import InstagramCreation from "../components/instagramCreation/instagramCreation";

export const createComponent = (
    type: CompanySiteComponentType,
    registerRef: (id: string, ref?: React.MutableRefObject<any> | undefined) => void,
    existingProps?: CompanySiteComponenteProps
): SiteComponentState => {
    const ref = React.createRef();
    const componentId = nanoid();
    registerRef(componentId, ref);

    switch(type) {
        case 'Banner':
            return {
                component: (
                    <BannerCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Banner'
            };
        case 'Text':
            return {
                component: (
                    <TextCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Text'
            };
        case 'TextAndImage':
            return {
                component: (
                    <TextAndImageCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'TextAndImage'
            };
        case 'Header':
            return {
                component: (
                    <HeaderCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Header'
            };
        case 'Gallery':
            return {
                component: (
                    <GalleryCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Gallery'
            };
        case 'Location':
            return {
                component: (
                    <LocationCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Location'
            };
        case 'Testimonials':
            return {
                component: (
                    <TestimonialsCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Testimonials'
            };
        case 'List':
            return {
                component: (
                    <ListCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'List'
            };
        case 'Footer':
            return {
                component: (
                    <FooterCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Footer'
            };
        case 'Video':
            return {
                component: (
                    <VideoCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Video'
            };
        case 'Instagram':
            return {
                component: (
                    <InstagramCreation
                        key={componentId}
                        componentId={componentId}
                        existingProps={existingProps}
                        ref={ref}
                    />
                ),
                componentId: componentId,
                type: 'Instagram'
            };
    }
}

export const moveComponentUp = (originalArray: SiteComponentState[], index: number): SiteComponentState[] => {
    const array = [...originalArray];
    if (index > 0 && index < array.length) {
        let temp = array[index];
        array[index] = array[index - 1];
        array[index - 1] = temp;
    }
    return array;
}

export const moveComponentDown = (originalArray: SiteComponentState[], index: number): SiteComponentState[] => {
    const array = [...originalArray];
    if (index >= 0 && index < array.length - 1) {
        let temp = array[index];
        array[index] = array[index + 1];
        array[index + 1] = temp;
    }
    return array;
}

export const isDifferentStyle = (currentProps: any, newStyle: any) => {
    for (let key in newStyle) {
        if (currentProps.hasOwnProperty(key) && newStyle[key] !== currentProps[key]) {
            return true;
        }
    }

    return false;
}

const GetDomainSuffix = (domain: Domain): string => {
    switch (domain.type) {
        case DomainType.COM_BR:
            return 'com.br';
        case DomainType.HIVEB:
            return 'hiveb.com.br'
        default:
            return ''
    }
}

export const GetDomainString = (domain?: Domain): string => {
    if (domain == undefined)
        return '';

    return `${domain.baseUrl}.${GetDomainSuffix(domain)}`;
}

export const GetDomainUrl = (domain?: Domain): string => {
    if (domain == undefined)
        return '';
    
    return `https://${GetDomainString(domain)}`;
}