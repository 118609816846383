export class BaseApi {
    static onButtonClickQueryOptions = {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0
    };

    static noRefetchWithRetry = {
        refetchOnWindowFocus: false
    };

    static queryWhenExists(param: any) {
        return {
            refetchOnWindowFocus: false,
            enabled: !!param,
            retry: 0
        }
    }

    static fetchUnauthenticatedPostMethod<T>(
        serverAddress: string,
        path: string,
        body?: T
    ): Promise<Response> {
        return fetch(serverAddress + path, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: body !== undefined ? JSON.stringify(body) : undefined,
        });
    }

    static fetchGetMethod(
        serverAddress: string,
        path: string,
        token: string
    ): Promise<Response> {
        return fetch(serverAddress + path, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    }

    static fetchPutMethod<T>(
        serverAddress: string,
        path: string,
        token: string,
        body?: T
    ): Promise<Response> {
        return fetch(serverAddress + path, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: body !== undefined ? JSON.stringify(body) : undefined,
        });
    }

    static fetchPostMethod<T>(
        serverAddress: string,
        path: string,
        token: string,
        body?: T
    ): Promise<Response> {
        return fetch(serverAddress + path, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: body !== undefined ? JSON.stringify(body) : undefined,
        });
    }

    static fetchDeleteMethod(
        serverAddress: string,
        path: string,
        token: string
    ): Promise<Response> {
        return fetch(serverAddress + path, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    }
}