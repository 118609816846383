import { useMutation } from "react-query";
import AxiosConfig from "../../../configs/AxiosConfig";
import FileUploaded from "../../../interfaces/FileUploaded";
import ImageUploadResponse from "./interfaces/imageUploadResponse";
import { compressImageFromBase64 } from "../../../helpers/FileHelper";
import axios from "axios";

const unexpectedErrorText = 'Um erro inesperado ocorreu';
const uploadQuotaReached = "O limite de uploads é de 20 imagens, se certifique que não esta ultrapassando este limite";
const getUploadSiteImage = async (files: FileUploaded[], siteId?: string) => {
    try {
        const formData = new FormData();

        for (const file of files) {
            const image = await compressImageFromBase64(file.data_url, file.file.type);
            formData.append('files', image, file.file.name);
        }
        
        const response = await AxiosConfig.getAxiosInstance()
            .post(
                `api/v1/site/${siteId}/images`,
                formData,
                { headers: { 'Content-Type' : 'multipart/form-data' } }
            );

        if (response.status === 201)
            return response.data;
        throw new Error(unexpectedErrorText);
    } catch(err) {
        if(!!axios.isAxiosError(err) && err.response?.status === 400)
            throw new Error(uploadQuotaReached);
        throw new Error(unexpectedErrorText);
    }
}

export const useUploadSiteImage = (siteId?: string) => {
    return useMutation<ImageUploadResponse, Error, FileUploaded[]>({
        retry: false,
        mutationKey: ['UploadSiteImage'],
        mutationFn: async (files: FileUploaded[]) => getUploadSiteImage(files, siteId),
    })
}