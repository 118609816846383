import { useMutation } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import CreateSiteRequest from "../../interfaces/site/CreateSiteRequest";
import CreateSiteResponse from "../../interfaces/site/CreateSiteResponse";

const unexpectedErrorText = "Erro inesperado, tente novamente";

const createSite = async (data: CreateSiteRequest): Promise<CreateSiteResponse> => {
    try{
        const response = await AxiosConfig
            .getAxiosInstance()
            .post('/api/v1/site', data);

        if (response.status === 201)
            return response.data;
        throw new Error(unexpectedErrorText).message
    }catch(err){
        throw new Error(unexpectedErrorText).message;
    }
}
        
export const useCreateSite = (onSuccessCallback: (response: CreateSiteResponse) => void) =>
    useMutation<CreateSiteResponse, Error, CreateSiteRequest>({
        mutationFn: createSite,
        onSuccess: (response) => {
            onSuccessCallback(response);
        }
    });
