import styled from "styled-components";
import { themes } from "../../themes/themes";
import StandardButton from "../Button/Button";
import { IoClose } from "react-icons/io5";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${themes.white};
    min-height: 200px;
    max-width: 500px;
    width: calc(100% - 48px);
    margin: 24px;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 24px;
    position: relative;
`

export const Title = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1.563rem;
    line-height: 1.563rem;
    color: ${themes.darkPurple};
    font-weight: bold;
    margin-bottom: 36px;
`

export const Description = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.alertFontColor};
    font-weight: 500;
    text-align: center;
`

export const ButtonContainers = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 36px;
`

export const Button = styled(StandardButton)`
`

export const AlertIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
`

export const AlertBorder = styled.div`
    width: 106px;
    height: 106px;
    border-radius: 50%;
    background-color: ${themes.alertPurpleBorderBg};
`

export const AlertBg = styled.div`
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: ${themes.alertPurpleBg};
    position: absolute;
`

export const AlertInner = styled.p`
    color: white;
    font-size: 2.813rem;
    font-family: ${themes.alertFont};
    font-weight: 500;
    line-height: 1.875rem;
    word-wrap: break-word;
    position: absolute;
`

export const DivDescription = styled.div`
    justify-content: center;
    display: flex;
`

export const IoCloseX = styled(IoClose)`
    position: absolute;
    top: 28px;
    right: 28px;
    width: 22px;
    height: 22px;
    z-index: 2;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`