import { useEffect, useImperativeHandle, useState } from 'react';

import EditLocation from './editLocation/editLocation';
import { BackgroundProps, Location ,LocationProps, Size } from '@hivefuse/page-components';
import { getFontColor } from '../../utils/colorUtils';
import { ThemeUtils } from '../../utils/themeUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';
import BaseConfig from '../../../../configs/BaseConfig';
import { isDifferentStyle } from '../../utils/siteUtils';

interface Props {
    componentId: string;
    existingProps?: LocationProps;
}

const LocationCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const theme = useRecoilValue(siteThemeState)!;
    const [locationProps, setLocationProps] = useState<LocationProps>(existingProps ?? { googleMapsApiKey: BaseConfig.config.embededGoogleMapsApiKey });
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return locationProps;
        },
        regenerateStyle() {
            handleGenerateStyle()
        },
        regenerateContent() {

        },
        regenerateEntireSection() {
            handleGenerateStyle()
        }
    })); 

    useEffect(() => {
        if (!existingProps)
            getInitialProps();

        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!theme) {
            setLocationProps(prevState => { return {
                ...prevState,
                fonts: theme?.themeFont
            } });
        }
    }, [theme]);

    const getInitialProps = () => {
        const newBannerStyleProps = GetNewStyle();
        setLocationProps(prevState => { return {
            ...prevState,
            ...newBannerStyleProps,
            address: creationOptions.location,
            textFields: [
                {
                    title: 'Localização',
                    description: creationOptions.location
                }
            ]
        }});
    }

    const handleGenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = GetNewStyle();
        while (!isDifferentStyle(locationProps, newStyle)) {
            newStyle = GetNewStyle();
        }
        
        setLocationProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleRegenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = GetRegeneratedStyle();
        while (!isDifferentStyle(locationProps, newStyle)) {
            newStyle = GetRegeneratedStyle();
        }
        
        setLocationProps(prevState => { return {...prevState, ...newStyle} });
    }

    const GetNewStyle = (): LocationProps => {
        return {
            verticalPadding: GeneratePadding(),
            background: GenerateBackground(),
            borderEdge: theme.borderEdges,
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont,
            mapShadow: GenerateShadow()
        };
    }

    const GetRegeneratedStyle = (): LocationProps => {
        return {
            verticalPadding: GeneratePadding(),
            borderEdge: theme.borderEdges,
            fonts: theme?.themeFont,
            mapShadow: GenerateShadow()
        };
    }

    const GenerateShadow = (): string | undefined => {
        if (!theme.useShadows)
            return undefined;

        return "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px";
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const GeneratePadding = (): Size => {
        return Object.values(Size)[Math.floor(Math.random() * Object.values(Size).length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const onEditing = (newProps: LocationProps) => {
        setSiteEditedState(true);
        setLocationProps(prevState => { return {...prevState, ...newProps} });
    }

    const getEditComponent = () => {
        return (
            <EditLocation
                onEdit={onEditing}
                currentProps={locationProps}
            />
        )
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            editTitleHeader='Localização'
            onRegenerateStyle={handleRegenerateStyle}
            editComponent={getEditComponent()}
        >
            <Location
                data={{
                    ...locationProps,
                    googleMapsApiKey: BaseConfig.config.embededGoogleMapsApiKey
                }}
            />
        </BackdropEditor>
    )
});

export default LocationCreation;