import styled, { keyframes } from 'styled-components';
import { themes } from '../../../../themes/themes';
import Lottie from 'lottie-react';

export const MainContainer = styled.div`
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${themes.yankeesBlue};
    z-index: 9999999;
    justify-content: center;
    align-items: center;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);,
    height: 100%;
    width: 100%;
`;

export const LoadingDiv = styled.div`
    height: 200px;
    width: 100%;
    position: relatice;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LottieLoading = styled(Lottie)`
    height: 200px;
    width: 100%;
    position: absolute;
`;

export const SliderContainer = styled.div`
    height: 50px;
    width: 100%;
    position: relative;
`;

export const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

export const AnimatedText = styled.div`
  animation: ${fadeInOut} 5s infinite;
  text-align: center;
  padding: 20px;
`;

export const Image = styled.img`
    z-index: 99999999;
`;

export const Text = styled.p`
    color: ${themes.white};
    font-family: ${themes.secundaryFont};
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
`;