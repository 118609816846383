import { Logo, MainContainer, Text } from "./GoogleButton.style";

interface Props {
    onClick: () => void;
}

export default function GoogleButton(props: Props){
    return(
        <MainContainer onClick={() => props.onClick()}>
            <Logo src='https://cdn.hiveb.com.br/assets/google_auth_logo.svg'/>
            <Text>Continuar com o Google</Text>
        </MainContainer>
    )
}