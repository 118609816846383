import { useMutation } from "react-query";
import AuthToken from "../../interfaces/authentication/AuthToken";
import AxiosConfig from "../../configs/AxiosConfig";
import RegisterRequest from "../../interfaces/authentication/RegisterRequest";
import { AxiosError } from "axios";
import { Toast } from "../../components/Toast/Toast";

const register = async (data: RegisterRequest) => {
    const response = await AxiosConfig
        .getAxiosInstance()
        .post<AuthToken>('/api/v1/users/business/register', data);

    return response.data;
}
        
export const useRegister = (onSuccessCallback: () => void) => {
    const mutation = useMutation<AuthToken, Error, RegisterRequest>({
        mutationFn: register,
        onSuccess: (response) => {
            try {
                AxiosConfig.setAuthToken(response!);
                onSuccessCallback();
            } catch(err) {
                throw new Error();
            }
        },
        onError: (error) => {
            if (error instanceof AxiosError && (error.response?.status == 409)) 
                Toast.showToast(
                    'Email já cadastrado, por favor escolha outra Email',
                    'error'
                );
            else
                Toast.showToast(
                    'Ocorreu um erro inesperado, por favor tente novamente',
                    'error'
                );
        }
    });
    return mutation;
}
