import styled from "styled-components";
import { themes } from "../../../../../themes/themes";
import ReturnArrow from "../../../../../components/ReturnArrow/ReturnArrow";
import TextInput from "../../../../../components/TextInput/TextInput";

const { black, primaryFont } = themes;

export const TitleSocialMedia = styled.h2`
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.563rem;
    word-wrap: break-word;
`;

export const DivTitle = styled.div`
    display: flex;
    place-items: baseline;
    margin-top: 15px;
    margin-bottom: 15px;
    column-gap: 10px;
`;

export const OptionalText = styled.p`
    color: rgba(97.75, 85.94, 85.94, 0.60);
    font-size: 0.875rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
`;

export const DivArrows = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AdvanceArrow = styled(ReturnArrow)`
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 28px;
`;