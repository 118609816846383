import { useState } from 'react';
import { Alert } from '../../../../../components/Alert/Alert';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import InputText from '../../../../../components/TextInput/TextInput';
import { getFontColor } from '../../../utils/colorUtils';
import { ListEditScreen } from '../listCreation';
import { EditContainer, InputLabel, ListBlock, ListItemsListContainer, Image, ListItemTitle, ListItemDescription, ListItemDescriptionContainer, EditIcon, DeleteIcon, AddListItemText, InputRow, EditButton, ImagePreview, SaveNewBlockButton, ControlsRow, RemoveButton } from './editList.style';
import { AspectRatio, BorderEdge, ListBlock as ListBlockItem, ListProps, Position, Size } from '@hivefuse/page-components';
import ImageSelectionModal from '../../imageSelectionModal/imageSelectionModal';
import ColorPicker from '../../../../../components/ColorPicker/ColorPicker';

interface Props {
    currentProps: ListProps;
    onEdit: (newProps: ListProps) => void;
    images: string[];
    bannerEditScreen: ListEditScreen;
    goToScreen: (screen: ListEditScreen) => void;
}

export default function EditList(props: Props) {
    const { currentProps, images, bannerEditScreen, onEdit, goToScreen } = props;
    const [editingItemIndex, setEditingItemIndex] = useState<number | undefined>(undefined);
    const [imageSelectionModalOpened, setImageSelectionModalOpened] = useState(false);
    const [newBlock, setNewBlock] = useState<ListBlockItem>({});

    const handleTitleChange = (e: any) => {
        onEdit({
            ...currentProps,
            title: e.target.value
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                backgroundColor: e
            },
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e
        });
    }

    const handleImagesAspectRatioChange = (e: any) => {
        onEdit({
            ...currentProps,
            imagesAspectRatio: e
        });
    }

    const handleTitleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            titlePosition: e
        });
    }

    const handleImagesBorderEdgeChange = (e: any) => {
        onEdit({
            ...currentProps,
            imagesBorderEdge: e
        });
    }

    const handleServiceTitleChange = (e: any, index: number) => {
        if (!currentProps.listBlocks) 
            return;

        const newServices = [...currentProps.listBlocks];
        newServices[index].title = e.target.value;
        onEdit({
            ...currentProps,
            listBlocks: newServices
        });
    }

    const handleServiceDescriptionChange = (e: any, index: number) => {
        if (!currentProps.listBlocks) 
            return;

        const newServices = [...currentProps.listBlocks];
        newServices[index].description = e.target.value;
        onEdit({
            ...currentProps,
            listBlocks: newServices
        });
    }

    const handleServiceImageChange = (e: any, index: number) => {
        if (!currentProps.listBlocks) 
            return;

        const newServices = [...currentProps.listBlocks];
        newServices[index].imageUrl = e;
        onEdit({
            ...currentProps,
            listBlocks: newServices
        });
    }

    const handleRemoveListImage = (index: number) => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover esta imagem?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => handleRemoveListImageAtIndex(index)
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                }
            ],
            true
        );
    }

    const handleRemoveListImageAtIndex = (index: number) => {
        if (!currentProps.listBlocks) 
            return;

        const newServices = [...currentProps.listBlocks];
        newServices[index].imageUrl = undefined;
        onEdit({
            ...currentProps,
            listBlocks: newServices
        });
    }

    const handleRemoveListItem = (index: number) => {
        if (!currentProps.listBlocks) 
            return;

        const newListBlocks = [...currentProps.listBlocks];
        newListBlocks.splice(index, 1);
        onEdit({
            ...currentProps,
            listBlocks: newListBlocks
        });
    }

    const handleOnListItemRemoveClick = (index: number) => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover este item?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => handleRemoveListItem(index)
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                }
            ],
            true
        );
    }

    const handleEditListClick = (index: number) => {
        setEditingItemIndex(index);
        goToScreen(ListEditScreen.LIST_ITEM);
    }

    const handleNewBlockClick = () => {
        setNewBlock({});
        goToScreen(ListEditScreen.NEW_ITEM);
    }

    const handleAddNewBlock = () => {
        if (!currentProps.listBlocks) 
            return;

        const newServices = [...currentProps.listBlocks, {...newBlock}];
        onEdit({
            ...currentProps,
            listBlocks: newServices
        });
        setNewBlock({});
        goToScreen(ListEditScreen.GENERAL);
    }

    const handleEditNewBlockImage = (value: string | undefined) => {
        setNewBlock(prev => ({...prev, imageUrl: value}));
    }

    const handleRemoveNewBlockImage = () => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover este item?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => handleEditNewBlockImage(undefined)
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                }
            ],
            true
        );
    }

    if (bannerEditScreen == ListEditScreen.LIST_ITEM) {
        const itemIndex = editingItemIndex ?? 0;
        return (
            <EditContainer>
                <InputLabel noMarginTop={true}>Título</InputLabel>
                <InputText
                    type='text'
                    value={currentProps!.listBlocks?.at(itemIndex)?.title}
                    onChange={(e) => handleServiceTitleChange(e, itemIndex)}
                />
                <InputLabel>Descrição</InputLabel>
                <InputText
                    type='text'
                    textareaInput={true}
                    rows={5}
                    value={currentProps!.listBlocks?.at(itemIndex)?.description}
                    onChange={(e) => handleServiceDescriptionChange(e, itemIndex)}
                />
                <InputRow>
                    <InputLabel>Imagem</InputLabel>
                    <ControlsRow>
                        {currentProps!.listBlocks?.at(itemIndex)?.imageUrl != undefined && (
                            <RemoveButton onClick={() => handleRemoveListImage(itemIndex)}>
                                Remover
                            </RemoveButton>
                        )}
                        <EditButton onClick={() => setImageSelectionModalOpened(true)}>
                            {currentProps!.listBlocks?.at(itemIndex)?.imageUrl != undefined
                                ? 'Alterar'
                                : 'Adicionar'
                            }
                        </EditButton>
                    </ControlsRow>
                </InputRow>
                {currentProps!.listBlocks?.at(itemIndex)?.imageUrl != undefined && (
                    <ImagePreview src={currentProps!.listBlocks?.at(itemIndex)?.imageUrl ?? ''}/>
                )}
                
                {imageSelectionModalOpened && (
                    <ImageSelectionModal
                        onClose={() => setImageSelectionModalOpened(false)}
                        selectedImage={currentProps!.listBlocks?.at(itemIndex)?.imageUrl}
                        onImageSelected={(image) => handleServiceImageChange(image, itemIndex)}
                        images={images}
                    />
                )}
            </EditContainer>
        );
    }

    if (bannerEditScreen == ListEditScreen.NEW_ITEM) {
        return (
            <EditContainer>
                <InputLabel noMarginTop={true}>Título</InputLabel>
                <InputText
                    type='text'
                    value={newBlock.title ?? ''}
                    onChange={(e) => setNewBlock(prev => ({...prev, title: e.target.value}))}
                />
                <InputLabel>Descrição</InputLabel>
                <InputText
                    type='text'
                    textareaInput={true}
                    rows={5}
                    value={newBlock.description ?? ''}
                    onChange={(e) => setNewBlock(prev => ({...prev, description: e.target.value}))}
                />
                <InputRow>
                    <InputLabel>Imagem</InputLabel>
                    <ControlsRow>
                        {newBlock.imageUrl != undefined && (
                            <RemoveButton onClick={handleRemoveNewBlockImage}>
                                Remover
                            </RemoveButton>
                        )}
                        <EditButton onClick={() => setImageSelectionModalOpened(true)}>
                            {newBlock.imageUrl != undefined
                                ? 'Alterar'
                                : 'Adicionar'
                            }
                        </EditButton>
                    </ControlsRow>
                </InputRow>
                {newBlock.imageUrl != undefined && (
                    <ImagePreview src={newBlock.imageUrl ?? ''}/>
                )}

                <SaveNewBlockButton
                    text='Salvar'
                    onClick={handleAddNewBlock}
                />

                {imageSelectionModalOpened && (
                    <ImageSelectionModal
                        onClose={() => setImageSelectionModalOpened(false)}
                        selectedImage={newBlock.imageUrl}
                        onImageSelected={(image) => handleEditNewBlockImage(image)}
                        images={images}
                    />
                )}
            </EditContainer>
        );
    }

    return (
        <EditContainer>
            <InputLabel noMarginTop={true}>Título</InputLabel>
            <InputText
                type='text'
                value={currentProps?.title}
                onChange={handleTitleChange}
            />
            <InputLabel>Tamanho das imagens</InputLabel>
            <Dropdown
                value={currentProps?.imagesAspectRatio ?? AspectRatio.WIDESCREEN}
                options={[
                    {
                        text: 'Paisagem',
                        value: AspectRatio.LANDSCAPE
                    },
                    {
                        text: 'Retrato',
                        value: AspectRatio.PORTRAIT
                    },
                    {
                        text: 'Quadrada',
                        value: AspectRatio.SQUARE
                    },
                    {
                        text: 'Widescreen',
                        value: AspectRatio.WIDESCREEN
                    }
                ]}
                onValueChange={handleImagesAspectRatioChange}
            />
            <InputLabel>Alinhamento do Titulo</InputLabel>
            <Dropdown
                value={currentProps?.titlePosition}
                options={[
                    {
                        text: 'Esquerda',
                        value: Position.LEFT 
                    },
                    {
                        text: 'Centro',
                        value: Position.CENTER 
                    },
                    {
                        text: 'Direita',
                        value: Position.RIGHT 
                    }
                ]}
                onValueChange={handleTitleAlignmentChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.verticalPadding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL 
                    },
                    {
                        text: 'Médio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
            <InputLabel>Tamanho da borda das Imagens</InputLabel>
            <Dropdown
                value={currentProps?.imagesBorderEdge}
                options={[
                    {
                        text: 'Sem borda',
                        value: BorderEdge.SHARP
                    },
                    {
                        text: 'Pequena',
                        value: BorderEdge.SMALL
                    },
                    {
                        text: 'Média',
                        value: BorderEdge.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: BorderEdge.LARGE
                    }
                ]}
                onValueChange={handleImagesBorderEdgeChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
            <InputLabel>Lista</InputLabel>
            <ListItemsListContainer>
                {currentProps?.listBlocks?.map((block, i) => (
                    <ListBlock key={i} firstItem={i == 0}>
                        <Image src={block.imageUrl ?? ''}/>
                        <ListItemDescriptionContainer>
                            <ListItemTitle>{block.title}</ListItemTitle>
                            <ListItemDescription>{block.description}</ListItemDescription>
                        </ListItemDescriptionContainer>
                        <EditIcon onClick={() => handleEditListClick(i)}/>
                        <DeleteIcon onClick={() => handleOnListItemRemoveClick(i)}/>
                    </ListBlock>
                ))}
                <AddListItemText onClick={handleNewBlockClick}>Adicionar novo item</AddListItemText>
            </ListItemsListContainer>
        </EditContainer>
    )
}