import styled from 'styled-components';
import { themes } from '../../../../themes/themes';
import StandardButton from '../../../../components/Button/Button';
import Lottie from 'lottie-react';

export const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 48px 0;
`;

export const SuccessContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center; 
     margin: 48px 0;   
`;

export const FailedContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
     margin: 48px 0;
`;

export const DivImg = styled.div`
    height: 82px;
    position: relative;
    display: flex;
    justify-content: center;
`;

export const InnerDiv = styled.div`
`;

export const DivError = styled.div`
    margin: 125px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${themes.tabletDimension}){
        margin: 0;
        flex: 1;
    }
`;

export const ErrorTitle = styled.h1`
    display: flex;
    text-align: center;
    justify-content: center;
    color: ${themes.darkPurple};
    font-size: 1.563rem;
    font-family: ${themes.primaryFont};
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
`;

export const ErrorMessage = styled.p`
    display: flex;
    justify-content: center;
    margin-top: 42px;
    text-align: center;
    max-width: 337px;
    color: ${themes.darkGray};
    font-size: 1rem;
    font-family: ${themes.primaryFont};
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word
`;

export const DivButton = styled.div`
    display: flex; 
    margin-top: auto;
`;

export const DivSuccess = styled.div`
    margin: 80px 0px;
    display: flex; 
    justify-content: space-evenly; 
    flex: 1; 
    height: 100%;
    gap: 42px;
    flex-direction: column;

    @media (max-width: ${themes.tabletDimension}){
        justify-content: center;
    }
`;

export const SuccessTitle = styled.h1`
    display: flex;
    text-align: center;
    justify-content: center;
    color: ${themes.darkPurple};
    font-size: 1.563rem;
    font-family: ${themes.primaryFont};
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
`;

export const AccessUrl = styled.p`
    color: ${themes.darkGray};
    font-size: 1rem;
    font-family: ${themes.primaryFont};
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
    overflow: hidden;
`;

export const DivSuccessModalButton = styled.div`
    display: flex; 
    margin-top: auto;
`;

export const DivAccess = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: center;

    @media (max-width: ${themes.mobileDimension}){
        flex-direction: column;
        gap: 10px;
    }
`;

export const CopyButton = styled(StandardButton)`
    width: 100px;
    height: 54px;
`;

export const DivUrl = styled.div`
    max-width: 350px;
`;

export const LottieFail = styled(Lottie)`
    height: 150px; 
    width: 150px; 
    height: 100%;
    align-items: center;
`;

export const LottieSuccess = styled(Lottie)`
    height: 150px; 
    width: 150px; 
    height: 100%;
    align-items: center;
`;

export const LottieLoading = styled(Lottie)`
    height: 150px;
    width: 150px;
    height: 100%;
    align-items: center;
`;