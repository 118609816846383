import { ArrowLeft, ArrowRight, ReturnArrowComponent } from './ReturnArrow.styles';

interface Props{
    to?: () => void;
    className?: string;
    children?: React.ReactNode;
    positionArrow?: 'left' | 'right';
    disable?: boolean;
}

export default function ReturnArrow({ to, className, children, positionArrow = 'left', disable }: Props){
 
    return(
        <ReturnArrowComponent onClick={to} className={className} disable={disable}>
            {positionArrow === 'left' && (<ArrowLeft disable={disable} />)}
            {children === undefined ? "Voltar" : children}
            {positionArrow === 'right' && (<ArrowRight disable={disable} />)}
        </ReturnArrowComponent>
    )
}