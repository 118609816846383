import { useRecoilValue } from "recoil";
import { useGetImages } from "../../../../../../hooks/imageService/useGetImages";
import SquaredImage from "../components/squaredImage/SquaredImage";
import {
    ErrorMessage,
    ErrorMessageContainer,
    InnerContainer,
    PartnerLink,
    PartnerText,
    RetryButton
} from "./stockImages.style";
import { creationOptionsState } from "../../../../../../state/siteEditorState";

interface Props {
    images: string[];
    selectedImage?: string;
    onImageSelected: (imageUrl: string) => void;
}

const StockImages = (props: Props) => {
    const { images, selectedImage, onImageSelected } = props;
    const creationOptions = useRecoilValue(creationOptionsState);
    const { mutate: getImages, isLoading } = useGetImages();
    
    return (
        <>
            {images.length > 0 ? (
                <InnerContainer>
                    {images.map((image, index) => (
                        <SquaredImage
                            key={index}
                            selected={image === selectedImage}
                            src={image}
                            onImageSelected={onImageSelected}
                        />
                    ))}
                </InnerContainer>
            ) : (
                <ErrorMessageContainer>
                    <ErrorMessage>
                        Erro inesperado ao carregar as imagens, por favor tente novamente
                    </ErrorMessage>
                    <RetryButton
                        text='Tentar Novamente'
                        loading={isLoading}
                        onClick={() => getImages(creationOptions!.category)}
                    />
                </ErrorMessageContainer>
            )}
            <PartnerText>Imagens fornecidas pelo <PartnerLink href='https://www.pexels.com/'>Pexels</PartnerLink></PartnerText>
        </>
    )
};

export default StockImages;