import { useEffect, useImperativeHandle, useState } from 'react';

import EditHeader from './editHeader/editHeader';
import { Header, HeaderProps, Position, Size } from '@hivefuse/page-components';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';
import { isDifferentStyle } from '../../utils/siteUtils';

interface Props {
    componentId: string;
    existingProps?: HeaderProps;
}

export enum HeaderEditScreen {
    GENERAL = 'GENERAL',
    LOGO = 'LOGO'
}

const HeaderCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const [currentEditScreen, setCurrentEditScreen] = useState(HeaderEditScreen.GENERAL);
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const theme = useRecoilValue(siteThemeState)!;
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useEffect(() => {
        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!theme)
            setHeaderProps(prevState => { return {
                ...prevState,
                fontRef: theme?.themeFont?.title,
            } });
    }, [theme]);

    useImperativeHandle(ref, () => ({
        getProps() {
          return headerProps;
        },
        regenerateStyle() {
            handleGenerateStyle();
        },
        regenerateContent() {

        },
        regenerateEntireSection() {
            handleGenerateStyle();
        }
    })); 

    const getInitialProps = (): HeaderProps => {
        const newHeaderProps = getNewStyle();
        newHeaderProps.title = creationOptions.name;
        return newHeaderProps;
    }

    const handleRegenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = getRegeneratedStyle();
        while (!isDifferentStyle(headerProps, newStyle)) {
            newStyle = getRegeneratedStyle();
        }

        setHeaderProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleGenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = getNewStyle();
        while (!isDifferentStyle(headerProps, newStyle)) {
            newStyle = getNewStyle();
        }

        setHeaderProps(prevState => { return {...prevState, ...newStyle} });
    }

    const getNewStyle = (): HeaderProps => {
        return {
            alignment: generateAlignment(),
            padding: generatePadding(),
            backgroundColor: generateBackgroundColor(),
            fontColor: generateFontColor(),
            fontRef: theme?.themeFont?.title,
            fixed:theme?.headerSticked,
            borderColor: getBorderColor(),
            shadowBorder: getShadow()
        };
    }

    const getRegeneratedStyle = (): HeaderProps => {
        return {
            alignment: generateAlignment(),
            padding: generatePadding(),
            fixed: theme?.headerSticked,
            borderColor: getBorderColor(),
            shadowBorder: getShadow()
        };
    }

    const getBorderColor = () => {
        if (!!theme.useShadows || !theme.headerSticked)
            return undefined;

        return "#efefef";
    }

    const getShadow = () => {
        if (!theme.useShadows || !theme.headerSticked)
            return undefined;

        return "0 3px 5px rgba(57, 63, 72, 0.3)";
    }

    const generateAlignment = (): Position => {
        const allowedPositions = [Position.LEFT, Position.CENTER];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const generatePadding = (): Size => {
        return Object.values(Size)[Math.floor(Math.random() * Object.values(Size).length)];
    }

    const generateBackgroundColor = () => {
        return ThemeUtils.getComponentBackgroundColor(index, theme);
    }

    const generateFontColor = () => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const onEditing = (newProps: HeaderProps) => {
        setSiteEditedState(true);
        setHeaderProps(prevState => { return {...prevState, ...newProps} });
    }

    const handleOnEdit = () => {
        setCurrentEditScreen(HeaderEditScreen.GENERAL);
    }

    const getEditComponent = () => {
        return (
            <EditHeader
                onEdit={onEditing}
                currentProps={headerProps}
                headerEditScreen={currentEditScreen}
                goToScreen={(screen) => setCurrentEditScreen(screen)}
            />
        )
    }

    const [headerProps, setHeaderProps] = useState<HeaderProps>(existingProps ?? getInitialProps());
    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            onRegenerateStyle={handleRegenerateStyle}
            editTitleHeader='Topo'
            sticky={headerProps.fixed}
            editComponent={getEditComponent()}
            onEdit={handleOnEdit}
            toolbarOverTop={true}
            onInnerNavigation={currentEditScreen != HeaderEditScreen.GENERAL}
            onInnerNavigationGoBack={() => setCurrentEditScreen(HeaderEditScreen.GENERAL)}
        >
            <Header
                data={headerProps}
            />
        </BackdropEditor>
    )
});

export default HeaderCreation;