import styled from 'styled-components';
import { themes } from '../../themes/themes';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";

export const maxHeight = 150;

export const Container = styled.div`
    position: relative;
`

export const MainContainer = styled.div<{minHeight?: string}>`
    box-sizing: border-box;
    min-height: ${(props) => props.minHeight ? props.minHeight : '54px'};
    border: 1px solid ${themes.darkGray};
    box-shadow: ${themes.inputShadow};
    background-color: ${themes.white};
    border-radius: 10px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`

export const DropdownValueContainer = styled.div`
    padding-right: 24px;
`
export const DropdownLabel = styled.p`
    font-family: ${themes.primaryFont};
    font-size: 0.563rem;
    line-height: 0.563rem;
    color: ${themes.onyx};
    user-select: none;
`

export const DropdownValue = styled.p`
    font-family: ${themes.primaryFont};
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-top: 4px;
    color: ${themes.onyx};
    user-select: none;
`

export const ChevronDown = styled(FaChevronDown)`
    color: ${themes.darkPurple};
    font-size: 1.063rem;
    user-select: none;
`

export const ChevronUp = styled(FaChevronUp)`
    color: ${themes.darkPurple};
    font-size: 1.063rem;
    user-select: none;
`

export const DropdownItemsContainer = styled.div`
    margin: 6px 0;
    position: absolute;
    width: 100%;
    background-color: ${themes.white};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px 0px;
    max-height: ${maxHeight}px;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 10;

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        border: 2px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }
`

export const DropdownItemContainer = styled.div`
    padding: 15px 24px;

    &:hover {
        background-color: ${themes.pinkLace};
        cursor: pointer;
    }
`

export const DropdownItemValue = styled.p`
    font-family: ${themes.primaryFont};
    font-size: 0.875rem;
    user-select: none;
`