import styled from "styled-components"
import StandardButton from "../Button/Button"
import { ModalButton } from "../../interfaces/ModalButton"

const Container = styled.div<PopUpActionsProps>`
    display: flex;
    column-gap: 12px;
    width: 100%;
`;

const Button = styled(StandardButton)<{ buttonWidth: string, buttonMaxWidth: string }>`
    max-width: ${(props) => props.buttonMaxWidth ? props.buttonMaxWidth : '100%'};
    width: ${(props) => props.buttonWidth ? props.buttonWidth : '100%'};
`
interface PopUpActionsProps {
    buttons?: ModalButton[];
    isSpecificStyle?: boolean;
}

export default function ConfirmationModalActions({ buttons }: PopUpActionsProps){
    return(
        <Container>
            {buttons?.map((button, index) => 
                <Button
                    key={index}
                    onClick={button.handlePressButton}
                    buttonType={button.buttonType}
                    type={button.type}
                    text={button.buttonText}
                    buttonWidth={button.buttonWidth!}
                    buttonMaxWidth={button.buttonMaxWidth!}
                    disabled={button.disabled}
                    loading={button.loading}
                />
                )
            }
        </Container>
    )
}