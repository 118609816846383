import React, { useEffect, useState } from "react";
import { Border, Container, InnerContainer, LoadingContainer } from "./BackdropEditor.style";
import ToolBar from "./ToolBar/ToolBar";
import { Spinner } from "../spinner/spinner";
import ComponentEditor from "../componentEditor/ComponentEditor";
import { useRecoilValue } from "recoil";
import { editingSiteState, siteComponentsSizeState } from "../../../../state/siteEditorState";
import AddSectionButton from "./AddSectionButton/AddSectionButton";
import { AddSectionDirection } from "../../interface/addSectionDirectionEnum";
import AddSectionModal from "./AddSectionModal/AddSectionModal";

interface Props {
    index: number;
    componentId: string;
    disabled?: boolean;
    children: React.ReactNode;
    immobile?: boolean;
    removable?: boolean;
    loading?: boolean;
    editComponent?: React.ReactNode;
    editTitleHeader?: string;
    onInnerNavigation?: boolean;
    sticky?: boolean;
    navigationRoot?: any;
    toolbarOverTop?: boolean;
    onEdit?: () => void;
    onInnerNavigationGoBack?: () => void;
    onRegenerateStyle?: () => void;
    onRegenerateContent?: () => void;
    onRegenerateEntireSection?: () => void;
}

export default function BackdropEditor(props: Props) {
    const {
        disabled,
        children,
        componentId,
        index,
        immobile,
        removable,
        sticky,
        loading,
        editComponent,
        editTitleHeader,
        onInnerNavigation,
        toolbarOverTop,
        onEdit,
        onInnerNavigationGoBack,
        onRegenerateStyle,
        onRegenerateContent,
        onRegenerateEntireSection
    } = props;
    const [editing, setEditing] = useState(false);
    const siteComponentsSize = useRecoilValue(siteComponentsSizeState);
    const [addComponentModal, setAddComponentModal] = useState(false);
    const [addComponentIndex, setAddComponentIndex] = useState(1);
    const editingSite = useRecoilValue(editingSiteState);
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setScrolled(scrollTop > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleEditComponent = () => {
        setEditing(true);
        if (onEdit)
            onEdit();
    }

    const handleEditClose = () => {
        setEditing(false);
    }

    const onAddSection = (direction: AddSectionDirection) => {
        setAddComponentModal(true);
        setAddComponentIndex(
            direction == AddSectionDirection.ABOVE
                ? index
                : index + 1
        );
    }

    if (loading) {
        return (
            <LoadingContainer>
                <Spinner/>
            </LoadingContainer>
        );
    }

    const isFirstItem = index == 0;
    const lastComponent = index == (siteComponentsSize - 1);
    return (
        <>
            <Container
                sticky={sticky}
            >
                {editingSite && (
                    <Border sticky={sticky}/>
                )}
                {editingSite && !isFirstItem &&  !lastComponent && (
                    <AddSectionButton
                        higherOverflow={index == 1 && !scrolled}
                        direction={AddSectionDirection.ABOVE}
                        onAddSectionButtonClicked={() => onAddSection(AddSectionDirection.ABOVE)}
                    />
                )}
                <InnerContainer isHovering={editingSite}>
                    {children}
                </InnerContainer>
                {editingSite && (
                    <ToolBar
                        index={index}
                        toolbarOverTop={toolbarOverTop}
                        componentId={componentId}
                        siteComponentsSize={siteComponentsSize}
                        sticky={sticky}
                        isLastComponent={lastComponent}
                        onRegenerateStyle={onRegenerateStyle}
                        onRegenerateEntireSection={onRegenerateEntireSection}
                        onRegenerateContent={onRegenerateContent}
                        onEditComponent={!!editComponent ? handleEditComponent : undefined}
                    />
                )}
                {editingSite && !lastComponent && !isFirstItem && (
                    <AddSectionButton
                        higherOverflow={sticky}
                        direction={AddSectionDirection.BELLOW}
                        onAddSectionButtonClicked={() => onAddSection(AddSectionDirection.BELLOW)}
                    />
                )}
            </Container>
            {!!addComponentModal && (
                <AddSectionModal
                    insertIndex={addComponentIndex}
                    onModalClose={() => setAddComponentModal(false)}
                />
            )}
            {!!editComponent && !!editing && (
                <ComponentEditor
                    onInnerNavigation={onInnerNavigation}
                    onInnerNavigationGoBack={onInnerNavigationGoBack}
                    title={editTitleHeader}
                    onClose={handleEditClose}
                >
                    {editComponent}
                </ComponentEditor>
            )}
        </>
    )
}