import { ColorCategories } from "../../interface/colorCategories";
import { ColorCategory } from "../../interface/colorCategory";

export const vintageColorCategories: ColorCategories = {
    category: ColorCategory.Vintage,
    colorThemes: [
        {
            ctaColor: '#212E21',
            neutralBackgroundColor: '#F4F3E8',
            colorfulBackgroundColor: '#E6DCC8',
            gradientColors: []
        },
        {
            ctaColor: '#655A3F',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E5E9FD',
            gradientColors: []
        },
        {
            ctaColor: '#C79288',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E6E4E8',
            gradientColors: []
        },
        {
            ctaColor: '#A3826C',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#EFEFEE',
            gradientColors: []
        },
        {
            ctaColor: '#253551',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E0E0DB',
            gradientColors: []
        },
        {
            ctaColor: '#FF5E1A',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#DAD9D9',
            gradientColors: []
        },
    ]
};