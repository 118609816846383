import styled from "styled-components";

export const WrapperCard = styled.div`
    background:  #FFFFFF; 
    box-shadow: 0px 0px 100px 30px rgba(0, 0, 0, 0.25); 
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    padding: 32px 25px;
    align-items: center;
`;

export const ImgLogoHiveB = styled.img`
    width: 126px;
    height: 54px;   
`;