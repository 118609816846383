import { useState } from "react";
import { Checked, Circle, Container, Text } from "./Checkbox.style";

interface Props {
    className?: string;
    text?: string;
    value?: boolean;
    onChange: (value: boolean) => void;
}

const Checkbox = (props: Props) => {
    const { className, text, value, onChange } = props;
    const [checked, setCheck] = useState(value ?? false);

    const handleChange = () => {
        onChange(!checked);
        setCheck(!checked);
    }

    return(
        <Container className={className} onClick={handleChange}>
            {!!text && (
                <Text>{text}</Text>
            )}
            <Circle checked={checked}>
                {checked && (
                    <Checked name='FaCheck'/>
                )}
            </Circle>
        </Container>
    )
}

export default Checkbox;