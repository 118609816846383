import styled from 'styled-components';
import { themes } from '../../../../../themes/themes';

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
`;

export const Backdrop = styled.div`
    background-color: ${themes.blackShadow};
    height: 100vh;
    width: 100vw;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    margin: 12px;
    background-color: ${themes.white};
    box-sizing: border-box;
    border-radius: 24px;
    max-width: 500px;

    @media (max-width: ${themes.tabletDimension}){
        width: 100%;
        height: 100%;
        max-height: none;
        max-width: none;
        border-radius: 0px;
        top: 0;
        right: 0;
        margin: 0;
    }
`;

export const ModalInner = styled.div`
    padding: 24px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 16px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        border: 4px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }
`;

export const ModalTopContainer = styled.div`
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${themes.gray};
`;

export const BackButton = styled.p`
    display: inline-block;
    font-family: ${themes.dashBoardFont};
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: ${themes.darkPurple};
    user-select: none;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export const ModalTitle = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.25rem;
    color: ${themes.darkPurple};
`;

export const ComponentContainer = styled.div<{ firstItem?: boolean }>`
    padding-bottom: 24px;
    padding-top: 24px;

    ${props => !props.firstItem ? `
        border-top: 1px solid ${themes.onyx};
    `
    : `
        padding-top: 0px;
    `}

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export const ComponentTile = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.black};
    margin-bottom: 12px;
`;

export const ComponentDescription = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: ${themes.darkGray};
`;