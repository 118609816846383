import './index.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import './infrastructure/firebaseService';

import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { ptBR as coreBR } from '@mui/material/locale';
import {
    createTheme, StyledEngineProvider, ThemeProvider
} from '@mui/material/styles';
import { ptBR as dataGridBR } from '@mui/x-data-grid';
import { ptBR as pickersBR } from '@mui/x-date-pickers';

import App from './App';
import BaseConfig from './configs/BaseConfig';
import FireBaseService from './infrastructure/firebaseService';
import { themes } from './themes/themes';

BaseConfig.init();
FireBaseService.init();

const container = document.getElementById('root');

const theme = createTheme({
  palette: {
    primary: { main: themes.darkPurple },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  }
}, dataGridBR, pickersBR, coreBR);

render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <div id="modal-root"/>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  </StyledEngineProvider>
, container);