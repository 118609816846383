import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router";
import { useEffect, useState } from "react";

import LogoHiveB from '../../assets/LogoHiveB.svg';
import StarYourSiteNavBar from "../../assets/StarYourSiteIcoNavBar.svg";
import CreateNewSiteIcoNavBar from "../../assets/CreateNewSiteIcoNavBar.svg";
import Top_Left_EllipseNavBar from "../../assets/Top_Left_EllipseNavBar.svg";
import Top_Left_EllipseVoidNavBar from "../../assets/Top_Left_EllipseVoidNavBar.svg";
import Lower_right_EllipseNavBar from "../../assets/Lower_right_EllipseNavBar.svg";
import Lower_right_EllipseVoidNavBar from "../../assets/Lower_right_EllipseVoidNavBar.svg";
import SettingsIco from "../../assets/SettingIco.svg";
import LogoutIco from "../../assets/LogoutIco.svg";
import { OptionEnum } from "./OptionEnum";
import { 
    LogoHiveBImg, 
    OptionsList,
    Option, 
    OptionImg, 
    NavBar, 
    Text, 
    TopEllipse, 
    TopEllipseVoid, 
    LowerEllipse, 
    LowerEllipseVoid, 
    NavBarOption, 
    MainContainer, 
    BgBlur,
    CloseX,
    Div,
    ContainerOutlet
} from "./DashboardBase.styles";
import { useRecoilState } from "recoil";
import { authState } from "../../state/authState";
import AxiosConfig from "../../configs/AxiosConfig";
import HeaderMobile from "../../components/HeaderMobile/HeaderMobile";
import FireBaseService, { analyticsEvents } from "../../infrastructure/firebaseService";
import { Alert } from "../../components/Alert/Alert";

export default function DashboardBaseV2(){
    const navigate = useNavigate();
    const [_auth, setAuth] = useRecoilState(authState);
    const [optionSelected, setOptionSelected] = useState(OptionEnum.YOUR_SITES);
    const [showNavBarMobile, setShowNavBarMobile] = useState(false);

    const isOptionSelected = (option: OptionEnum) => {
        return option === optionSelected;
    }

    const handleClick = (option: OptionEnum) => {
        document.body.style.overflow = 'visible';
        setOptionSelected(option);
        navigate(String(option));
        handleClickOnCloseButton();
    }

    useEffect(() => {
        verifyPathNameAndSetOptionSelected();
    }, []);

    const verifyPathNameAndSetOptionSelected = () => {
        const path = window.location.pathname;
        if(path === '/settings'){
            setOptionSelected(OptionEnum.SETTINGS);
        }else if(path === '/sites'){
            setOptionSelected(OptionEnum.YOUR_SITES);
        }
    }

    const handleClickOnOpenMenuButton = () => {
        setShowNavBarMobile(true);
        document.body.style.overflow = 'hidden';
    }

    const handleClickOnCloseButton = () => {
        setShowNavBarMobile(false);
        document.body.style.overflow = 'visible';
    }

    const handleCreateNewSiteButton = () =>{
        FireBaseService.logButtonClick(analyticsEvents.dashboardCreateSiteButton);
        document.body.style.overflow = 'visible';
        handleClick(OptionEnum.CREATE_NEW_SITE);
    }

    const handleOnExitClicked = () => {
        Alert.alert(
            '',
            'Tem certeza que deseja sair?',
            [
                {
                    text: 'Sim',
                    buttonType: 'secondary',
                    onPress: () => AxiosConfig.logout()
                },
                {
                    text: 'Não'
                }
            ],
            true
        );
    }

    return(
        <Div>
        <MainContainer>
            <HeaderMobile handleClickOnOpenMenuButton={handleClickOnOpenMenuButton}/>
            <NavBar showNavBarMobile={showNavBarMobile}>
                {showNavBarMobile &&
                    <CloseX onClick={handleClickOnCloseButton}/>
                }
                <LogoHiveBImg src={LogoHiveB}/>
                <OptionsList>
                    <Option isSelected={isOptionSelected(OptionEnum.YOUR_SITES)} onClick={() => handleClick(OptionEnum.YOUR_SITES)}>
                        <OptionImg src={StarYourSiteNavBar}/>
                        <Text>
                            Seus Sites
                        </Text>
                    </Option>
                    <Option isSelected={isOptionSelected(OptionEnum.CREATE_NEW_SITE)} onClick={handleCreateNewSiteButton}>
                        <OptionImg src={CreateNewSiteIcoNavBar}/>
                        <Text>
                            Criar novo site
                        </Text>
                    </Option>
                </OptionsList>
                <TopEllipse src={Top_Left_EllipseNavBar}/>
                <TopEllipseVoid src={Top_Left_EllipseVoidNavBar}/>
                <LowerEllipse src={Lower_right_EllipseNavBar}/>
                <LowerEllipseVoid src={Lower_right_EllipseVoidNavBar}/>
                <NavBarOption isSelected={isOptionSelected(OptionEnum.SETTINGS)} onClick={() => handleClick(OptionEnum.SETTINGS)}>
                    <OptionImg src={SettingsIco}/>
                    <Text>
                        Configurações
                    </Text>
                </NavBarOption>
                <NavBarOption onClick={handleOnExitClicked}>
                    <OptionImg src={LogoutIco}/>
                    <Text>
                        Sair
                    </Text>
                </NavBarOption>
            </NavBar>
            <ContainerOutlet>
                <Outlet/>
            </ContainerOutlet>
            {showNavBarMobile && 
                <BgBlur onClick={handleClickOnCloseButton}/>
            }
        </MainContainer>
        </Div>
    )
}