import { LocationProps } from '@hivefuse/page-components';
import { EditContainer, InputLabel } from './editLocation.style';
import LocationInput from '../../../../../components/LocationInput/LocationInput';
import ColorPicker from '../../../../../components/ColorPicker/ColorPicker';
import { getFontColor } from '../../../utils/colorUtils';

interface Props {
    currentProps: LocationProps;
    onEdit: (newProps: LocationProps) => void;
}

export default function EditLocation(props: Props) {
    const { currentProps, onEdit } = props;

    const handleLocationChange = (e: any) => {
        if (!currentProps.textFields)
            return;

        const newTextFields = [...currentProps.textFields];
        newTextFields[0].description = e;
        onEdit({
            ...currentProps,
            address: e,
            textFields: newTextFields
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                backgroundColor: e
            },
            fontColor: fontColor
        });
    }

    return (
        <EditContainer>
            <InputLabel noMarginTop={true}>Endereço</InputLabel>
            <LocationInput
                value={currentProps?.address ?? ''}
                setValue={handleLocationChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
        </EditContainer>
    )
}