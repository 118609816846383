import styled from 'styled-components';
import { themes } from '../../../../../themes/themes';
import SquaredImage from '../../imageSelectionModal/screens/components/squaredImage/SquaredImage';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { BaseContainer } from '@hivefuse/page-components';
import StandardButton from '../../../../../components/Button/Button';

export const EditContainer = styled.div`
`;

export const Input = styled.input`
    margin-bottom: 1rem;
`;

export const Select = styled.select`
    margin-bottom: 1rem;
`;

export const AddButton = styled.p`
    display: inline-block;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: #c3c3c3;
    
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const ListBlock = styled.div<{ firstItem: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    align-items: center;

    ${props => !props.firstItem ? `
        border-top: 1px solid ${themes.onyx};
    `
    : `
        padding-top: 0px;
    `}
`;

export const InputLabel = styled.p<{ noMarginTop?: boolean }>`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
    margin-top: ${props => !!props.noMarginTop ? '0' : '12px'};
    margin-bottom: 12px;
`;

export const ListItemsListContainer = styled.div`

`;

export const Image = styled(SquaredImage)`
    width: 50px;
    height: 50px;
    flex-shrink: 0;
`;

export const ListItemTitle = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: ${themes.black};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 6px;
`;

export const ListItemDescription = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: ${themes.darkGray};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ListItemDescriptionContainer = styled.div`
    flex-shrink: 1;
    min-width: 0;
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const EditIcon = styled(FaEdit)`
    flex-shrink: 0;
    padding: 6px;
    font-size: 1rem;
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const DeleteIcon = styled(MdDeleteForever)`
    flex-shrink: 0;
    padding: 6px;
    font-size: 1rem;
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const AddListItemText = styled.p`
    margin-top: 12px;
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkPurple};
    display: inline-block;
    float: right;
    user-select: none;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
`;

export const BackgroundPreview = styled(BaseContainer)`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
`;

export const ImagePreview = styled.div<{ src: string}>`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const EditButton = styled.p`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const RemoveButton = styled.p`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const SaveNewBlockButton = styled(StandardButton)`
    margin-top: 24px;
`;

export const ControlsRow = styled.div`
    display: flex;
    gap: 12px;
`;