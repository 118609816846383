import CheckIcon from '@mui/icons-material/Check';
import {
    Avatar, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select
} from '@mui/material';

import styles from './AvatarDropdown.module.css';

interface Props {
    inputLabel: string;
    dataList: any[];
    textProperty: string;
    valueProperty: string;
    avatarProperty: string;
    selectedData: string;
    error?: string;
    onChange: (selected: string[]) => void;
}

function AvatarDropdown(props: Props) {
    const { avatarProperty, dataList, inputLabel, error, selectedData, textProperty, valueProperty, onChange } = props;
    
    const handleChange = (event: any) => {
        const { target: { value } } = event;
        onChange(value);
    };

    
    const getSelectedName = () => {
        const filteredSelected = dataList.find((data) => data[valueProperty] === selectedData);
        return filteredSelected[textProperty];
    }

    return (
        <FormControl className={styles.control} error={!!error}>
            <InputLabel>{inputLabel}</InputLabel>
            <Select
                value={selectedData}
                onChange={handleChange}
                input={<OutlinedInput label={inputLabel} />}
                renderValue={() => getSelectedName()}
            >
                {dataList.map((data, i) => (
                    <MenuItem key={i} value={data[valueProperty]}>
                        <div className={styles.staffAvatar}>
                            <Avatar alt={data[textProperty]} src={data[avatarProperty] || ' '}/>
                            {selectedData === data[valueProperty] && (
                                <div className={styles.selectBox}>
                                    <CheckIcon className={styles.checkIcon}/>
                                </div>
                            )}
                        </div>
                        <ListItemText className={styles.text} primary={data[textProperty]} />
                    </MenuItem>
                ))}
            </Select>
            {!!error && (
                <FormHelperText>{error}</FormHelperText>
            )}
        </FormControl>
    );
}
  
export default AvatarDropdown;