import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import * as yup from 'yup';

import { TextField, Typography } from '@mui/material';

import DefaultLoadingButton from '../../../components/DefaultLoadingButton/DefaultLoadingButton';
import UploadPictureForm from '../../../components/UploadPictureForm/UploadPictureForm';
import styles from './CreateStaffPage.module.css';

function CreateStaffPage() {
    const validationSchema = yup.object().shape({
        name: yup
          .string()
          .required('Digite o nome do membro da equipe'),
        role: yup
          .string()
          .required('Digite o cargo do membro da equipe'),
        phone: yup
          .string()
          .matches(/^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/, 'Digite um telefone válido')
          .optional(),
        email: yup
          .string()
          .email('Digite um email válido')
          .optional()
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            role: '',
            phone: '',
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: () => {
        },
    });

    return (
        <div className={styles.main}>
            <Typography className={styles.header} variant="h5">
                Novo Membro
            </Typography>

            <div className={styles.mainContainer}>
                <div className={styles.profileCard}>
                    <UploadPictureForm/>
                    <Typography className={styles.profileFiles}>
                        Formatos *.jpeg, *.jpg, *.png <br/> Tamanho máximo 3.1 MB
                    </Typography>
                </div>
                <div className={styles.card}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={styles.inputsInner}>
                            <TextField
                                className={styles.input}
                                id="name"
                                label="Nome"
                                variant="outlined"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                className={styles.input}
                                id="role"
                                label="Cargo"
                                variant="outlined"
                                type="text"
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                error={formik.touched.role && Boolean(formik.errors.role)}
                                helperText={formik.touched.role && formik.errors.role}
                            />
                            <TextField
                                className={styles.input}
                                id="email"
                                label="Email"
                                variant="outlined"
                                type="text"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <InputMask
                                mask="(99) 99999-9999"
                                maskPlaceholder={' '}
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                            >
                                <TextField 
                                    className={styles.input}
                                    id="phone"
                                    type="tel"
                                    label="Telefone"
                                    variant="outlined"
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </InputMask>
                        </div>
                        <DefaultLoadingButton
                            className={styles.button}
                            text='Próximo'
                            loading={false}
                            type='submit'
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}
  
export default CreateStaffPage;