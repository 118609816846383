import styled from 'styled-components';
import { themes } from '../../../../themes/themes';
import { Tabs } from '@mui/material';
import StandardButton from '../../../../components/Button/Button';
import ImageUploading from 'react-images-uploading';
import { IoImagesOutline } from "react-icons/io5";

export const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Backdrop = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${themes.white};
    max-width: 700px;
    width: 100%;
    padding: 24px;
    border-radius: 24px;
    margin: 24px;
    height: calc(100vh - 48px);
    box-sizing: border-box;

    @media (max-width: ${themes.tabletDimension}){
        box-sizing: border-box;
        max-height: 100%;
        height: 100%;
        max-width: 100%;
        width: 100%;
        margin: 0;
        border-radius: 0;
    }
`;

export const InnerContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    position: relative;

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        border: 2px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }
`;

export const Image = styled.div<{ src: string, selected: boolean }>`
    width: 100%;
    height: 0;
    padding-top: calc(70%);
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    box-sizing: border-box;
    outline: ${props => props.selected ? `2px solid ${themes.darkPurple}` : 'none'};
    outline-offset: 3px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.3s;
    }
`;

export const TabsList = styled(Tabs)`
    button {
        text-transform: none !important;
    }
`;

export const Button = styled(StandardButton)`
    margin-top: auto;
`;

export const ImageWrapper = styled.div`
    position: relative;
`;

export const GoBackText = styled.h3`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem;
    margin-left: 6px;
    color: ${themes.darkGray};
`;

export const GoBackButton = styled.div`
    display: flex;
    align-items: center;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

export const PartnerText = styled.p`
    margin: 12px 0;
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    color: ${themes.darkGray};
`;

export const PartnerLink = styled.a`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    color: ${themes.darkPurple};
`;

export const Uploader = styled(ImageUploading)`
`;

export const UploaderInnerWrapper = styled.div<{ isDragging: boolean }>`
    position: relative;
    opacity: ${props => props.isDragging ? '0.5' : '1'};

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export const UploaderInner = styled.div`
    width: 100%;
    height: 0;
    padding-top: calc(70%);
    border-radius: 8px;
    outline: 1px solid ${themes.darkGray};
    box-sizing: border-box;
    outline-style: dashed;
    outline-width: 2px;
`;

export const UploaderContent = styled.div`
    top: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ImageIcon = styled(IoImagesOutline)`
    font-size: 25px;
    color: ${themes.darkGray};
    user-select: none;
`;

export const ImageUploaderTitle = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    color: ${themes.darkGray};
    margin-top: 6px;
    user-select: none;
`;

export const ImageUploaderDescription = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 0.75rem;
    color: ${themes.darkGray};
    margin-top: 6px;
    user-select: none;
`;