import styled from "styled-components";
import { MdMenu } from "react-icons/md";
import { themes } from "../../themes/themes";

export const Header = styled.header`
    min-width: 100%;
    background-color: ${themes.white};
    min-height: 80px;
    justify-content: space-between;
    display: none;
    align-items: center;
    position: fixed;
    z-index: 50;

    @media (max-width: 780px){
        display: flex;
    }
`

export const LogoHiveB = styled.img`
    width: 150px;
    height: 60px;
    margin-left: 24px;
`

export const MenuHamComponent = styled(MdMenu)`
    margin-right: 24px;
    height: 30px;
    width: 30px;
`