import ReactDOM from "react-dom";
import { Alert, AlertColor, Snackbar, SnackbarOrigin } from "@mui/material";
import { Container } from "./Toast.style";

const ToastComponent = (props: { onClose: () => void; text: string, dismissible?: boolean, severity?: AlertColor, snackPosition?: SnackbarOrigin, autoHideDuration?: number }) => {
    const { text, severity, dismissible, snackPosition, autoHideDuration, onClose } = props;

    const modalRoot = document.getElementById('modal-root')!;
    return ReactDOM.createPortal(
        <Container>
            <Snackbar
                open={true}
                autoHideDuration={autoHideDuration ?? 2500}
                onClose={onClose}
                style={{top: 40, left: 'auto', right: 'auto', bottom: 'auto'}}
                anchorOrigin={snackPosition}
            >
                <Alert
                    onClose={dismissible ? onClose : undefined}
                    severity={severity ?? 'info'}
                    sx={{ width: '100%', margin: '0 10px' }}
                >
                    {text}
                </Alert>
            </Snackbar>
        </Container>,
        modalRoot
    );
}


export class Toast {
    static showToast(text: string, severity?: AlertColor, dismissible?: boolean, snackPosition?: SnackbarOrigin, autoHideDuration?: number) {
        const modalRoot = document.getElementById('modal-root')!;
        const onClose = () => {
            ReactDOM.unmountComponentAtNode(modalRoot);
        };

        ReactDOM.render(
            <ToastComponent
                text={text}
                severity={severity}
                snackPosition={snackPosition}
                autoHideDuration={autoHideDuration}
                onClose={onClose}
            />,
            modalRoot
        );
    }
}