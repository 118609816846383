import { useEffect, useState } from "react";
import { BackDrop, Container, ModalInner, ModalInnerScrollableArea } from "./publishment.style";
import RegisterStep from "../registerStep/registerStep";
import DomainStep from "../domainStep/domainStep";
import PaymentStep from "../paymentStep/paymentStep";
import Domain from "../../../../interfaces/Domain";
import ResultStep from "../resultStep/resultStep";
import { useRecoilState } from "recoil";
import { authState } from "../../../../state/authState";

interface Props {
    siteId?: string;
    businessName: string;
    setCurrentSiteId: (siteId: string) => void;
    onClose: () => void;
}

export enum PublishmentStep {
    REGISTER = 'REGISTER',
    DOMAIN = 'DOMAIN',
    PAYMENT = 'PAYMENT',
    RESULT =  'RESULT'
}

export default function PublishmentModal(props: Props) {
    const { businessName, siteId, onClose, setCurrentSiteId } = props;
    const [domain, setDomain] = useState<Domain | undefined>(undefined);
    const [paymentId, setPaymentId] = useState('');
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [auth] = useRecoilState(authState);
   
    const getInitialStep = () => {
        if (!!auth){
            return PublishmentStep.DOMAIN;
        }

        return PublishmentStep.REGISTER;
    }
    const [step, setStep] = useState(getInitialStep());

    const goToStep = (step: PublishmentStep) => {
        setStep(step);
    }

    const setCurrentDomain = (domain: Domain) => {
        setDomain(domain);
    }

    const setCurrentPaymentId = (paymentId: string) => {
        setPaymentId(paymentId);
    }

    const handleOnClose = () => {
        if (step != PublishmentStep.RESULT || !isProcessingPayment)
            onClose();
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
          document.body.style.overflow = 'visible';
        };
    }, []);

    return (
        <Container>
            <ModalInner>
                <ModalInnerScrollableArea>
                    {step == PublishmentStep.REGISTER && (
                        <RegisterStep
                            goToStep={goToStep}
                            closeModal={handleOnClose}
                        />
                    )}
                    {step == PublishmentStep.DOMAIN && (
                        <DomainStep
                            siteId={siteId}
                            businessName={businessName}
                            goToStep={goToStep}
                            setCurrentDomain={setCurrentDomain}
                            closeModal={handleOnClose}
                        />
                    )}
                    {step == PublishmentStep.PAYMENT && (
                        <PaymentStep
                            domain={domain}
                            siteId={siteId}
                            goToStep={goToStep}
                            setCurrentPaymentId={setCurrentPaymentId}
                            setIsProcessingPayment={setIsProcessingPayment}
                        />
                    )}
                    {step == PublishmentStep.RESULT && (
                        <ResultStep
                            domain={domain}
                            siteId={siteId}
                            goToStep={goToStep}
                            onClose={handleOnClose}
                            setIsProcessingPayment={setIsProcessingPayment}
                        />
                    )}
                </ModalInnerScrollableArea>
            </ModalInner>
            <BackDrop onClick={handleOnClose}/>
        </Container>
    )
}