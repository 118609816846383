import { SetterOrUpdater } from "recoil";
import Auth from "../interfaces/authentication/Auth";
import { GetAuthFromToken } from "./SecurityHelper";

export const GetStoredToken = (setRecoilAuth: SetterOrUpdater<Auth | undefined>): Auth | undefined => {
    const recoilPersist = localStorage.getItem("recoil-persist");
    if(recoilPersist != null){
        const parsedData = JSON.parse(recoilPersist);
        if (Object.keys(parsedData).length != 0)
            return parsedData?.authState as Auth;
    }

    const ssoAccessToken = getCookie('accessToken');
    const ssoRefreshToken = getCookie('refreshToken');
    if (ssoAccessToken && ssoRefreshToken) {
        const token = GetAuthFromToken({
            accessToken: ssoAccessToken,
            refreshToken: ssoRefreshToken
        });
        setRecoilAuth(token);
        return token;
    }

    return undefined;
}

const getCookie = (cookieName: string) => {
    const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : undefined;
}