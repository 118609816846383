import styled from 'styled-components';
import { themes } from '../../../../themes/themes';

interface ContainerProps {
    sticky?: boolean;
}

export const Container = styled.div<ContainerProps>`
    position: ${props => props.sticky ? 'sticky' : 'relative'};
    top: 0;
    box-sizing: border-box;

    ${props => props.sticky ? `
        z-index: 99;
    ` : ''}
`;

interface InnerContainerProps {
    isHovering: boolean;
}

export const InnerContainer = styled.div<InnerContainerProps>`
    &:hover {
        background-color: #00000099;
        z-index: 999;
    }
`;

export const LoadingContainer = styled.div`
    margin: 5rem;
    display: flex;
    justify-content: center;
`;

export const Border = styled.div<{sticky?: boolean}>`
    border: 4px solid ${themes.darkPurple};
    position: absolute;
    top: -2px;
    bottom: -2px;
    right: 0;
    left: 0;
    ${props => props.sticky ? `z-index: 100;` : 'z-index: 1'};
`;