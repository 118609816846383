import { PublishmentStep } from "../../../publishmentModal/publishment";
import {
    Button,
    ContainerCircle,
    DomainText,
    Form,
    GoBackArrow,
    InnerContainerTitle,
    InstallmentsQuantity,
    MainContainer,
    CouponContainer,
    Row,
    Select,
    StepNumber,
    SubContainer,
    SubStepTitle,
    Title,
    CouponExistText
} from "./cardDetailsStep.styles";
import FireBaseService, { analyticsEvents } from "../../../../../../infrastructure/firebaseService";
import { useGetDiscountCoupon } from "../../../../../../hooks/paymentService/useGetDiscountCoupon";
import TextInput from "../../../../../../components/TextInput/TextInput";
import Domain from "../../../../../../interfaces/Domain";
import { GetDomainString } from "../../../../utils/siteUtils";
import { SubStep } from "../../paymentStep";
import { PaymentStepData } from "../../../../../../interfaces/payment/PaymentStepData";
import * as yup from 'yup';
import { useFormik } from "formik";
import { ValidationUtils } from "../../../../utils/validationUtils";

interface CardDetailsStepProps {
    paymentStepData: PaymentStepData;
    domain?: Domain;
    goToStep: (step: PublishmentStep) => void;
    goToSubStep: (step: SubStep) => void;
    updatePaymentStepData: (paymentStepData: PaymentStepData) => void;
}

export default function CardDetailsStep({
    paymentStepData,
    domain,
    goToStep,
    goToSubStep,
    updatePaymentStepData
}: CardDetailsStepProps) {
    const validationSchema = yup.object().shape({
        creditCardNumber: yup
            .string()
            .required('Número do cartão é obrigatório')
            .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Número do cartão deve conter 16 dígitos')
            .test('validate-credit-card-number', 'Insira um cartão válido', (value) => ValidationUtils.validateCreditCardNumber(value ?? '')),
        creditCardExpiration: yup
            .string()
            .required('Data de expiração é obrigatório')
            .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, 'Data de expiração inválida')
            .test('validate-credit-card-exp', 'Data de expiração inválida', (value) => ValidationUtils.verifyCreditCardExpString(value ?? '')),
        creditCardHolderName: yup
            .string()
            .required('Nome no cartão é obrigatório'),
        creditCardCvv: yup
            .string()
            .required('Código de segurança (CVV) é obrigatório')
            .matches(/^\d{3,4}$/, 'CVV deve conter 3 ou 4 dígitos'),
        document: yup
            .string()
            .required('CPF é obrigatório')
            .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF deve conter 11 dígitos')
            .test('validate-cpf', 'Insira um CPF válido', (value) => ValidationUtils.validateCPF(value ?? '')),
    });

    const formik = useFormik({
        initialValues: {
            creditCardNumber: paymentStepData.creditCardData.card.number,
            creditCardExpiration: paymentStepData.creditCardData.card.exp_month !=  '' ? `${paymentStepData.creditCardData.card.exp_month}/${paymentStepData.creditCardData.card.exp_year}` : '',
            creditCardHolderName: paymentStepData.creditCardData.card.holder_name,
            creditCardCvv: paymentStepData.creditCardData.card.cvv,
            document: paymentStepData.paymentData.customerInfo.document,
            installments: paymentStepData.paymentData.installments,
            discountCoupom: paymentStepData.paymentData.discountCoupon ?? ''
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            FireBaseService.logButtonClick(analyticsEvents.editorPaymentInfo);

            updatePaymentStepData({
                ...paymentStepData,
                creditCardData: {
                    ...paymentStepData.creditCardData,
                    card: {
                        number: values.creditCardNumber,
                        exp_month: values.creditCardExpiration.replace(/\D/g, "").slice(0, 2),
                        exp_year: values.creditCardExpiration.replace(/\D/g, "").slice(2, 5),
                        cvv: values.creditCardCvv,
                        holder_name: values.creditCardHolderName
                    }
                },
                paymentData: {
                    ...paymentStepData.paymentData,
                    installments: values.installments,
                    customerInfo: {
                        ...paymentStepData.paymentData.customerInfo,
                        document: values.document
                    }
                }
            });

            goToSubStep(SubStep.CARD_ADDRESS);
        },
    });

    const { data: discount, isLoading: isLoadingDiscountCoupon, refetch: checkCoupom, isSuccess: couponExists } = useGetDiscountCoupon(formik.values.discountCoupom);

    const createDropdownItems = (): { value: any, text: string }[] => {
        return Array.from({ length: 12 }, (_, i) => i + 1).map((value) => {
            if (!discount) {
                return {
                    value: value,
                    text: textWithoutDiscount(value)
                }
            }
            return {
                value: value,
                text: textWithDiscount(value, discount)
            }
        })
    }

    const textWithoutDiscount = (installmentsQuantity: number) => {
        return `${installmentsQuantity}x de R$ ${(358.8 / installmentsQuantity).toFixed(2).replace('.', ',')}`
    }

    const textWithDiscount = (installmentsQuantity: number, discount: number) => {
        return `${installmentsQuantity}x de R$ ${((358.8 - (358.8 * (discount / 100))) / installmentsQuantity).toFixed(2).replace('.', ',')}`
    }

    return (
        <MainContainer>
            <SubContainer>
                <GoBackArrow to={() => goToStep(PublishmentStep.DOMAIN)} />
                <InnerContainerTitle>
                    <ContainerCircle>
                        <StepNumber>1</StepNumber>
                    </ContainerCircle>
                    <SubStepTitle>Dados do cartão</SubStepTitle>
                </InnerContainerTitle>
            </SubContainer>

            <Form onSubmit={formik.handleSubmit}>
                <TextInput
                    type='text'
                    placeholder='Número do cartão'
                    label='Número do cartão'
                    inputId='creditCardNumber'
                    value={formik.values.creditCardNumber}
                    mask="9999 9999 9999 9999"
                    maskInput={true}
                    error={!!formik.errors.creditCardNumber && formik.touched.creditCardNumber}
                    errorText={formik.errors.creditCardNumber}
                    onChange={formik.handleChange}
                />

                <Row>
                    <TextInput
                        type='text'
                        mask="99/99"
                        maskInput={true}
                        placeholder='Expiração (MM/AA)'
                        label='Expiração (MM/AA)'
                        inputId='creditCardExpiration'
                        value={formik.values.creditCardExpiration}
                        onChange={formik.handleChange}
                        error={!!formik.errors.creditCardExpiration && formik.touched.creditCardExpiration}
                        errorText={formik.errors.creditCardExpiration}
                    />
                    <TextInput
                        type='text'
                        placeholder='CVV'
                        label='CVV'
                        mask="9999"
                        maskInput={true}
                        inputId='creditCardCvv'
                        value={formik.values.creditCardCvv}
                        onChange={formik.handleChange}
                        error={!!formik.errors.creditCardCvv && formik.touched.creditCardCvv}
                        errorText={formik.errors.creditCardCvv}
                    />
                </Row>

                <TextInput
                    type='text'
                    placeholder='Nome no cartão'
                    label='Nome no cartão'
                    inputId='creditCardHolderName'
                    value={formik.values.creditCardHolderName}
                    onChange={formik.handleChange}
                    error={!!formik.errors.creditCardHolderName && formik.touched.creditCardHolderName}
                    errorText={formik.errors.creditCardHolderName}
                />

                <Row>
                    <TextInput
                        type='text'
                        placeholder='CPF'
                        label='CPF'
                        mask="999.999.999-99"
                        maskInput={true}
                        inputId='document'
                        value={formik.values.document}
                        onChange={formik.handleChange}
                        error={!!formik.errors.document && formik.touched.document}
                        errorText={formik.errors.document}
                    />
                    <Select
                        id="installments"
                        value={formik.values.installments}
                        onChangeEvent={formik.handleChange}
                        options={createDropdownItems()}
                        label="Parcelas"
                    />
                </Row>
            </Form>

            <CouponContainer>
                <Title>Assinatura Anual</Title>
                <DomainText>{GetDomainString(domain)}</DomainText>

                <TextInput
                    type='text'
                    placeholder='Cupom de desconto (Opcional)'
                    label='Cupom de desconto (Opcional)'
                    inputId='discountCoupom'
                    value={formik.values.discountCoupom}
                    onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
                        formik.handleChange(e);
                    }}
                    loading={isLoadingDiscountCoupon}
                    onStopTyping={() => checkCoupom()}
                    rightIcon={couponExists ? 'IoMdCheckmark' : undefined}
                    error={!!formik.errors.discountCoupom && formik.touched.discountCoupom}
                    errorText={formik.errors.discountCoupom}
                />

                {couponExists && (
                    <CouponExistText>Desconto aplicado</CouponExistText>
                )}
                <InstallmentsQuantity>
                    {discount
                        ? textWithDiscount(formik.values.installments, discount)
                        : textWithoutDiscount(formik.values.installments)
                    }
                </InstallmentsQuantity>
            </CouponContainer>

            <Button
                text="Próximo"
                type="submit"
                onClick={formik.handleSubmit}
            />
        </MainContainer>
    )
}