import { useQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";

export const useTestimonialsCreationQueryKey = ['testimonialCreation'];
export const useTestimonialsCreation = (businessName: string, category: string, componentId: string) => {
    return useQuery({
        retry: true,
        enabled: false,
        refetchOnWindowFocus: false,
        queryKey: [...useTestimonialsCreationQueryKey, componentId],
        queryFn: () => {
            return AxiosConfig.getAxiosInstance()
                .get(
                    `/api/v1/site/section/generate/testimonial`,
                    { params: {context: `${businessName} que é um(a) ${category}` } }   
                )
                .then((res) => res.data)
                .catch((error) => {
                    console.log(error);
                })
        },
    })
}