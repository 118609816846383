import { Moment } from 'moment';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, ButtonBase, Typography } from '@mui/material';

import { DefaultIcon, IconNames } from '../../../../components/DefaultIcon/DefaultIcon';
import styles from './NotificationButton.module.css';

interface Props {
    title: string;
    subtitle: string;
    date: Moment;
}

function NotificationButton({ date, title, subtitle }: Props) {
    const GetDate = (date: Moment) => {
        return 'Há 30 minutos';
    }
    
    const GetIcon = (): IconNames => {
        return 'Today';
    }

    return (
        <ButtonBase className={styles.notificationCard}>
            <div className={styles.notificationDetails}>
                <Avatar className={styles.notificationIcon}>
                    <DefaultIcon iconName={GetIcon()}/>
                </Avatar>
                <div className={styles.notificationCaptions}>
                    <Typography variant='subtitle2' className={styles.eventTitle}>{title}</Typography>
                    <Typography variant='caption' className={styles.eventSubtitle}>{subtitle}</Typography>
                </div>
            </div>
            <div className={styles.viewMoreSection}>
                <Typography variant='subtitle2' className={styles.eventDate}>{GetDate(date)}</Typography>
                <ArrowForwardIosIcon/>
            </div>
        </ButtonBase>
    );
}
  
export default NotificationButton;