import styled, { keyframes } from "styled-components";
import { ImSpinner6 } from "react-icons/im";
import InputText from "../../../../../../components/TextInput/TextInput";
import Dropdown from "../../../../../../components/Dropdown/Dropdown";
import ReturnArrow from "../../../../../../components/ReturnArrow/ReturnArrow";
import { themes } from "../../../../../../themes/themes";
import StandardButton from "../../../../../../components/Button/Button";
import TextInput from "../../../../../../components/TextInput/TextInput";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ContainerTitle = styled.div`
    display: flex;
`;

export const ContainerCircle = styled.div`
    position: relative;
    height: 30px;
    width: 30px;
    min-width: 30px;
    background-color: ${themes.darkPurple};
    border-radius: 50%;
`;

export const StepNumber = styled.p`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${themes.white};
    font-size: 1.2rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    word-wrap: break-word;
`;

export const SubTitle = styled.p`
    margin-top: 12px;
`;

export const ContainerFlex = styled.div`
    display: flex;
    column-gap: 24px;
    align-items: center;
    margin-bottom: 12px;

    @media (max-width: ${themes.mobileDimension}){
        flex-direction: column;
        gap: 12px;
    }
`;

export const Select = styled(Dropdown)`
    flex: 1;

    @media (max-width: ${themes.mobileDimension}){
        width: 100%;
    }
`;

export const InnerContainerTitle = styled.div`
    display: flex;
    column-gap: 16px;
    align-items: center;
`;

export const GoBackArrow = styled(ReturnArrow)`
    padding: 0;
    max-width: 100%;
    flex: 1;
`;

export const Div = styled.form`
    flex: 1;
`;

export const ContainerInputZipCode = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 12px;
`;

export const LoadingSpinner = styled(ImSpinner6)`
    position: absolute;
    right: 14px;
    width: 20px;
    height: 20px;
    animation: ${rotate360} 1s linear infinite;
`;

export const DivButton = styled.div`
    @media(max-width: ${themes.tabletDimension}){
        margin-top: 35px;
    }
`;

export const SubStepTitle = styled.p`
    color: ${themes.darkGray};
    font-size: 1rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    word-wrap: break-word;

    @media (max-width: ${themes.tabletDimension}){
        margin-top: 10px;
        max-height: 45px;
        height: 100%;
    }
`;

export const BiggerLabel = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    font-weight: 700;
    color: ${themes.darkGray};
    margin: 12px 0;

    @media(max-width: ${themes.tabletDimension}){
        text-align: center;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const Button = styled(StandardButton)`
    margin-top: 12px;

    @media(max-width: ${themes.tabletDimension}){
        margin-top: auto:
    }
`;

export const CepInput = styled(TextInput)`
    flex: 0 1;
`;

export const Input = styled(TextInput)`
    @media (max-width: ${themes.mobileDimension}){
        width: 100%;
    }
`;