import { useMutation } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import EditSiteRequest from "../../interfaces/site/EditSiteRequest";

const unexpectedErrorText = "Erro inesperado, tente novamente";

const updateSite = async (data: EditSiteRequest) => {
    try{
        const response = await AxiosConfig
            .getAxiosInstance()
            .put(`/api/v1/site/${data.siteId}`, data);

        if (response.status === 200)
            return response.data;
        throw new Error(unexpectedErrorText).message
    }catch(err){
        throw new Error(unexpectedErrorText).message;
    }
}
        
export const useUpdateSite = (onSuccessCallback: () => void) =>
    useMutation<any, Error, EditSiteRequest>({
        mutationFn: updateSite,
        onSuccess: () => {
            onSuccessCallback();
        }
    });
