import { useEffect, useState } from 'react';
import BaseConfig from '../../../../configs/BaseConfig';
import Domain from '../../../../interfaces/Domain';
import { DivError, DivImg, InnerDiv, LoadingContainer, ErrorTitle, ErrorMessage, DivButton, SuccessContainer, FailedContainer, DivSuccess, SuccessTitle, AccessUrl, DivSuccessModalButton, DivAccess, CopyButton, DivUrl, LottieFail, LottieSuccess, LottieLoading } from './resultStep.style';
import { useSetRecoilState } from 'recoil';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import LogoHiveB from '../../../../assets/hiveb-logo.svg';
import { ModalButton } from '../../../../interfaces/ModalButton';
import LoadingCircle from '../../../../assets/animations/LoadingCircle.json'
import AnimationSuccess from '../../../../assets/animations/AnimationSuccess.json'
import AnimationFailed from '../../../../assets/animations/AnimationFailed.json'
import * as Clipboard from 'clipboard-polyfill';
import { PublishmentStep } from '../publishmentModal/publishment';
import { siteJustPublishedState } from '../../../../state/siteEditorState';
import { usePublishTrialSite } from '../../../../hooks/siteService/usePublishTrialSite';

interface Props {
    domain?: Domain;
    siteId?: string;
    goToStep: (step: PublishmentStep) => void;
    setIsProcessingPayment:  (paymentProcress: boolean) => void;
    onClose: () => void;
}

export enum ResultStepStatus {
    LOADING = 'LOADING',
    ANIMATIONSUCCESS = 'ANIMATIONSUCCESS',
    ANIMATIONFAILED = 'ANIMATIONFAILED',
    FAILED =  'FAILED',
    SUCCESS = 'SUCCESS'
}

export default function ResultStep(props: Props) {
    const { domain, siteId, goToStep, onClose, setIsProcessingPayment } = props;
    const setSiteJustPublished = useSetRecoilState(siteJustPublishedState);

    const onSitePublished = () => {
        setSiteJustPublished(true);
        setStep(ResultStepStatus.ANIMATIONSUCCESS);
    }
    const { mutate: publishTrialSite, error: errorPublishingTrialSite, isLoading } = usePublishTrialSite(onSitePublished);

    const getInitialStep = () => {
        return ResultStepStatus.LOADING;
    }
    const [step, setStep] = useState(getInitialStep())

    useEffect(() => {
        publishTrialSite({
            baseUrl: domain!.baseUrl,
            siteId: siteId!
        });
    }, [])

    useEffect(() => {
        if (errorPublishingTrialSite != null)
            setStep(ResultStepStatus.ANIMATIONFAILED)
    }, [errorPublishingTrialSite]);

    const getSiteUrl = () => {
        return `https://${domain?.baseUrl}.${BaseConfig.config.baseSiteUrl}`;    
    }

    const handleAccessSiteClick = () => {
        window.open(getSiteUrl());
    };

    const button: ModalButton[] = [
        { 
            type: 'button', 
            buttonType: 'primary',  
            buttonText: 'Tente novamente',
            buttonWidth: '100%', 
            loading: isLoading, 
            handlePressButton: () => goToStep(PublishmentStep.DOMAIN)
        }
    ]

    const successModalButton: ModalButton[] = [
       { 
            type: 'button', 
            buttonType: 'secondary', 
            buttonText: 'Continuar Editando',
            buttonWidth: '100%', 
            loading: isLoading, 
            handlePressButton: () => onClose()
        },       
        { 
            type: 'button', 
            buttonType: 'primary', 
            buttonText: 'Acessar Site',
            buttonWidth: '100%', 
            loading: isLoading, 
            handlePressButton: () => handleAccessSiteClick()
        }
    ]

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
        Clipboard.writeText(getSiteUrl())
            .then(() => {
                setIsCopied(true);
            })
            .catch((error) => {
                console.error('Erro ao copiar para a área de transferência:', error);
            });
    };

    useEffect(() => {
        if (step == ResultStepStatus.SUCCESS || step == ResultStepStatus.FAILED)
            setIsProcessingPayment(false);
    }, [step])

    return (
        <>
            <DivImg>
                <InnerDiv>
                    <ConfirmationModal.Img imgUrl={LogoHiveB}/>
                </InnerDiv>
            </DivImg>

            {isLoading && (
                <LoadingContainer>
                    <LottieLoading animationData={LoadingCircle}/>
                </LoadingContainer>
            )}

            {step === ResultStepStatus.ANIMATIONSUCCESS && (
                <SuccessContainer>
                    <LottieSuccess animationData={AnimationSuccess} onComplete={() => setStep(ResultStepStatus.SUCCESS)} loop={false}/>
                </SuccessContainer>
            )}

            {step === ResultStepStatus.ANIMATIONFAILED && (
                <>
                    <FailedContainer>
                        <LottieFail animationData={AnimationFailed} onComplete={() => setStep(ResultStepStatus.FAILED)} loop={false}/>
                    </FailedContainer>
                </>
            )}

            {step === ResultStepStatus.FAILED && (
                <>
                    <DivError>
                        <ErrorTitle>Ocorreu um erro inesperado ao publicar o seu domínio</ErrorTitle>
                        <ErrorMessage>
                            Por favor tente novamente ou entre em contato caso o erro persista
                        </ErrorMessage>
                    </DivError>
                    <DivButton>
                        {!isLoading && <ConfirmationModal.Actions buttons={button}/> }
                    </DivButton>
                </>
            )}

            {step === ResultStepStatus.SUCCESS && (
                <>
                    <DivSuccess>
                        <SuccessTitle>Parabéns, seu site esta no ar!</SuccessTitle>
                        <DivAccess>
                            <DivUrl>
                                <AccessUrl>{getSiteUrl()}</AccessUrl>
                            </DivUrl>
                            <CopyButton
                                type='button'
                                buttonType='primary'
                                text={isCopied? 'Copiado' : 'Copiar'}
                                onClick={handleCopyClick}
                            />
                        </DivAccess>
                    </DivSuccess>
                    <DivSuccessModalButton>
                        {!isLoading && (
                            <ConfirmationModal.Actions 
                                buttons={successModalButton} 
                            />
                        )}
                    </DivSuccessModalButton>
                </>
            )}
        </>
    )
}