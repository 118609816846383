import { useEffect, useImperativeHandle, useState } from 'react';

import EditBanner from './editBanner/editBanner';
import { BackgroundProps, Banner, BannerProps, Position, Size } from '@hivefuse/page-components';
import { useBannerCreation } from '../../../../hooks/siteGenerationService/useBannerCreation';
import { ImageHelper } from '../../utils/imageUtils';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { GetPreferredSocialNetwork, GetSocialMediaUrl } from '../../../../helpers/SocialNetworkHelper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteSocialNetworksState, siteStockImagesState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';
import { isDifferentStyle } from '../../utils/siteUtils';

interface Props {
    componentId: string;
    existingProps?: BannerProps;
}

export enum BannerEditScreen {
    GENERAL = 'GENERAL',
    BACKGROUND = 'BACKGROUND'
}

const BannerCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const socialNetworks = useRecoilValue(siteSocialNetworksState);
    const images = useRecoilValue(siteStockImagesState);
    const theme = useRecoilValue(siteThemeState)!;
    const { isLoading, isRefetching, refetch: getBannerText, data: bannerTexts } = useBannerCreation(creationOptions.name, creationOptions.category, componentId);
    const [bannerProps, setBannerProps] = useState<BannerProps>(existingProps ?? {});
    const [currentEditScreen, setCurrentEditScreen] = useState(BannerEditScreen.GENERAL);
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return bannerProps;
        },
        regenerateStyle() {
            handleRegenerateStyle();
        },
        regenerateContent() {
            ReGenerateContent();
        },
        regenerateEntireSection() {
            ReGenerateEntireSection();
        }
    })); 

    useEffect(() => {
        if (!existingProps) {
            getInitialProps();
            getBannerText();
        }
        
        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!theme) {
            setBannerProps(prevState => { return {
                ...prevState,
                fonts: theme?.themeFont
            } });
        }
    }, [theme]);

    useEffect(() => {
        if (!!bannerTexts) {
            const button = handleGenerateButton();
            setBannerProps(prevState => { return { ...prevState, ...bannerTexts, button }});
        }
    }, [bannerTexts]);

    useEffect(() => {
        setBannerProps(prevState => { return { ...prevState, isLoading: isLoading || isRefetching }});
    }, [isLoading, isRefetching]);

    const getInitialProps = () => {
        const newBannerStyleProps = GetNewStyle();
        setBannerProps(prevState => { return { ...prevState, ...newBannerStyleProps }});
    }

    const handleGenerateButton = () => {
        if (!socialNetworks)
            return undefined;

        const preferredSocialNetwork = GetPreferredSocialNetwork(socialNetworks);
        if (!preferredSocialNetwork)
            return undefined;

        return {
            label: bannerTexts.ctaButtonText,
            url: GetSocialMediaUrl(preferredSocialNetwork),
            borderEdge: theme.borderEdges,
            backgroundColor: theme.colorTheme.ctaColor,
            fontColor: getFontColor(theme.colorTheme.ctaColor)
        };
    }

    const handleRegenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = GetRegeneratedStyle();
        while (!isDifferentStyle(bannerProps, newStyle)) {
            newStyle = GetRegeneratedStyle();
        }

        setBannerProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleGenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = GetNewStyle();
        while (!isDifferentStyle(bannerProps, newStyle)) {
            newStyle = GetNewStyle();
        }

        setBannerProps(prevState => { return {...prevState, ...newStyle} });
    }

    const GetRegeneratedStyle = (): BannerProps => {
        return {
            alignment: GenerateAlignment(),
            verticalPadding: GeneratePadding(),
            fonts: theme?.themeFont
        };
    }

    const GetNewStyle = (): BannerProps => {
        return {
            alignment: GenerateAlignment(),
            verticalPadding: GeneratePadding(),
            background: GenerateBackground(),
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont
        };
    }

    const GenerateAlignment = (): Position => {
        const possibleValues = [Position.LEFT, Position.CENTER];
        return possibleValues[Math.floor(Math.random() * possibleValues.length)];
    }

    const GeneratePadding = (): Size => {
        const possibleValues = [Size.MEDIUM, Size.LARGE];
        return possibleValues[Math.floor(Math.random() * possibleValues.length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        if (!!theme.prioritizeImages) {
            return {
                backgroundImageUrl: ImageHelper.getRandomImages(images, 1)[0]
            }
        }

        if (!!theme.gradient) {
            return {
                gradient: theme.gradient
            }
        }

        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const GenerateFontColor = (): string => {
        if (!!theme.prioritizeImages) {
            return "#ffffff";
        }
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const ReGenerateContent = () => {
        setSiteEditedState(true);
        getBannerText();
    }

    const ReGenerateEntireSection = () => {
        handleGenerateStyle();
        ReGenerateContent();
    }

    const onEditing = (newProps: BannerProps) => {
        setSiteEditedState(true);
        setBannerProps(prevState => { return {...prevState, ...newProps} });
    }

    const getEditComponent = () => {
        return (
            <EditBanner
                onEdit={onEditing}
                currentProps={bannerProps}
                bannerEditScreen={currentEditScreen}
                images={images}
                goToScreen={goToScreen}
            />
        )
    }

    const goToScreen = (screen: BannerEditScreen) => {
        setCurrentEditScreen(screen);
    }

    const handleInnerNavigationGoBack = () => {
        setCurrentEditScreen(BannerEditScreen.GENERAL);
    }

    const handleOnEdit = () => {
        setCurrentEditScreen(BannerEditScreen.GENERAL);
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            editTitleHeader='Banner'
            onInnerNavigation={currentEditScreen != BannerEditScreen.GENERAL}
            onEdit={handleOnEdit}
            onInnerNavigationGoBack={handleInnerNavigationGoBack}
            editComponent={getEditComponent()}
            onRegenerateStyle={handleRegenerateStyle}
            onRegenerateContent={ReGenerateContent}
            onRegenerateEntireSection={ReGenerateEntireSection}
        >
            <Banner
                data={bannerProps}
            />
        </BackdropEditor>
    )
});

export default BannerCreation;