import styled from 'styled-components';

export const EditContainer = styled.div`
`;

export const Input = styled.input`
    margin-bottom: 1rem;
`;

export const Select = styled.select`
    margin-bottom: 1rem;
`;