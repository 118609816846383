import { ColorCategories } from "../interface/colorCategories";
import { brightColorCategories } from "./colorThemes/brightThemes";
import { coldColorCategories } from "./colorThemes/coldThemes";
import { darkColorCategories } from "./colorThemes/darkThemes";
import { monocrhomaticColorCategories } from "./colorThemes/monocromaticThemes";
import { pastelColorCategories } from "./colorThemes/pastelThemes";
import { vintageColorCategories } from "./colorThemes/vintageThemes";
import { warmColorCategories } from "./colorThemes/warmThemes";

const ColorCategoriesThemes: ColorCategories[] = [
    warmColorCategories,
    coldColorCategories,
    brightColorCategories,
    darkColorCategories,
    monocrhomaticColorCategories,
    pastelColorCategories,
    vintageColorCategories
];

export default ColorCategoriesThemes;