import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../siteEditor/components/spinner/spinner";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useGetSitesList } from "../../hooks/siteService/useGetSitesList";
import { 
    MainContainer, 
    InnerContainer, 
    YourSitesTitle, 
    LoadingContainer, 
    ErrorMessage, 
    WebsitePanel,
    Container,
    Title,
    SubTitle,
    Button,
    ErrorMessageContainer,
    RetryButton
} from "./YourSitesPage.styles";
import SitesList from "./components/sitesList/SitesList";
import SitesListDrawer from "./components/sitesListDrawer/SitesListDrawer";

export default function YourSitesPage() {
    const { isLoading, error, data, refetch } = useGetSitesList();
    const [sitesList, setSitesList] = useState(data?.sites);
    const navigate = useNavigate();

    const fetchSitesOnChange = (value: string) => {
        if(value !== "") {
            if (data?.sites) {
                const filteredSites = data.sites.filter(site => site.name.toLowerCase().includes(value.toLowerCase()));
                setSitesList(filteredSites);
            }
        } else {
            setSitesList(data?.sites)
        }
    }

    useEffect(() => {
        setSitesList(data?.sites)
    }, [data])

    return(
        <MainContainer>
            <InnerContainer>    
                <SearchInput
                    onChange={(e) => {
                        fetchSitesOnChange(e.target.value)
                    }}
                />
                <YourSitesTitle>Seus sites</YourSitesTitle>

                {isLoading && (
                    <LoadingContainer>
                        <Spinner/>
                    </LoadingContainer>
                )}

                {!!error && (
                    <WebsitePanel emptyList={true}>
                        <ErrorMessageContainer>
                            <ErrorMessage>
                                {error.message}
                            </ErrorMessage>
                            <RetryButton
                                text='Tentar Novamente'
                                onClick={() => refetch()}
                            />
                        </ErrorMessageContainer>
                        
                    </WebsitePanel>
                )}

                {!!data && sitesList && (
                    <WebsitePanel emptyList={sitesList?.length == 0}>
                        {sitesList?.length > 0 && (
                            <SitesList
                                sitesList={sitesList}
                            />
                        )}
                        {data?.sites.length === 0 && !isLoading &&
                            <Container>
                                <Title>Ops!</Title>
                                <SubTitle>Você ainda não possui nenhum site.</SubTitle>
                                <Button
                                    onClick={() => navigate("/business/create")}
                                    buttonType="primary"
                                    text="Criar site"
                                />
                            </Container>

                        }
                    </WebsitePanel>
                )}
                <SitesListDrawer sitesList={sitesList}/>
            </InnerContainer>
        </MainContainer>
    )
}