import styled from "styled-components";
import { themes } from "../../themes/themes";
import { Alert } from "@mui/material";
import LoginCard from "../../components/LoginCard";

const { purple, primaryFont, black } = themes;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DivInnerCard = styled.div`
    flex-direction: column;
    align-items: center;
`;

export const TitleForgotPassword = styled.h2`   
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding-bottom: 25px;
    padding-top: 40px;
`
export const DescriptionForgotPassword = styled.p`
    color: ${purple};
    font-size: 0.875rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding-bottom: 20px;
    max-width: 295px;
    flex-wrap: wrap;
`

export const DivButton = styled.div`
    padding-top: 20px;
`;

export const AlertInstructions = styled(Alert)`
    margin-bottom: 30px;
    text-align: center;
    max-width: 300px;
`;

export const Card = styled(LoginCard)`
    width: auto;
`;