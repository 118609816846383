import IConfig from "./IConfig";

class BaseConfig {
    config: IConfig;

    constructor() {
        this.config = {
            businessApiUrl: '',
            pagarmeApiUrl: '',
            pagarmePublicKey: '',
            baseSiteUrl: '',
            embededGoogleMapsApiKey: ''
        };
    }

    init = () => {
        this.config.businessApiUrl = process.env.REACT_APP_API_URL!;
        this.config.pagarmeApiUrl = process.env.REACT_APP_PAGARME_API_URL!;
        this.config.pagarmePublicKey = process.env.REACT_APP_PAGARME_PUBLIC_KEY!;
        this.config.baseSiteUrl = process.env.REACT_APP_BASE_SITE_URL!;
        this.config.embededGoogleMapsApiKey = process.env.REACT_APP_EMBEDED_GOOGLE_MAPS_API_KEY!;
    }
}

export default new BaseConfig();