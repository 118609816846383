import styled, { keyframes } from "styled-components";
import { themes } from '../../../../themes/themes';
import InputText from '../../../../components/TextInput/TextInput';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { ImSpinner6 } from "react-icons/im";
import { CiCircleCheck } from "react-icons/ci";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import Icon from "../../../../components/Icon/Icon";


export const DivButton = styled.div`
    display: flex; 
    margin-top: 24px;
`;

export const TitleSubContent = styled.p`
    color: ${themes.darkGray};
    font-size: 1rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    line-height: 1.25rem;
    word-wrap: break-word;
    margin-top: 12px;
`;

export const DivImg = styled.div`
    height: 82px;
    position: relative;
    display: flex;
    justify-content: center;
`;

export const InnerDiv = styled.div`
    position: absolute;
    top: -15px;
`;

export const Input = styled(InputText)<{isAvailable: boolean}>`
    border-radius: 10px; 
    border: ${(props) => !props.error && props.isAvailable ? `1px ${themes.darkGray} solid` : '1px #299755 solid'};
    width: auto;
    flex: 1;
    padding-right: 45px;
`;

export const PriceMessage = styled.p`
    color: ${themes.darkGray}; 
    font-size: 1.25rem; 
    font-family: DM Sans; 
    font-weight: 700; 
    line-height: 1.25rem; 
    word-wrap: break-word;
    margin: 24px 0;

    @media (max-width: ${themes.tabletDimension}){
        margin-top: auto;
        padding-top: 24px;
    }
`;

export const DropdownOptionDomain = styled(Dropdown)`
    @media (max-width: ${themes.mobileDimension}){
        display: flex;
        flex: 1;
    }
`;

export const Div = styled.div`
    display: flex;
    column-gap: 24px;
    margin-top: 12px;
    width: 100%;
    margin-bottom: auto;
    padding-bottom: 24px;

    @media (max-width: ${themes.mobileDimension}){
        flex-direction: column;
        gap: 12px;
    }
`;

export const CircleCheck = styled(CiCircleCheck)`
    color: ${themes.greenActive};
    width: 22px;
    height: 22px;
    position: absolute;
    right: 22px;
`;

export const ExclamationCircle = styled(HiOutlineExclamationCircle)`
    width: 18px;
    height: 18px;
    color: ${themes.redExclamation};  
    position: absolute;
    right: 16px; 
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
`;

export const DivInput = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const Span = styled.span`
    display: flex;
    align-items: center;
    margin-top: 5px;
`;

export const Title = styled.h2`
    color: ${themes.darkPurple}; 
    font-size: 1.563rem; 
    font-family: ${themes.dashBoardFont}; 
    font-weight: 700;
    word-wrap: break-word;
`

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CloseIcon = styled(Icon)`
    cursor: pointer;
    font-size: 24px;

    &:hover {
        opacity: 0.5;
    }
`;