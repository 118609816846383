import { useEffect, useImperativeHandle, useState } from 'react';

import EditText from './editText/editText';
import { BackgroundProps, Position, Size, Text, TextProps } from '@hivefuse/page-components';
import { useAboutCreation } from '../../../../hooks/siteGenerationService/useAboutCreation';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';

interface Props {
    componentId: string;
    existingProps?: TextProps;
}

const TextCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const theme = useRecoilValue(siteThemeState)!;
    const { isLoading, refetch: getAboutText, data: aboutText } = useAboutCreation(creationOptions.name, creationOptions.category, componentId);
    const [textProps, setTextProps] = useState<TextProps>(existingProps ?? {});
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return textProps;
        }
    })); 

    useEffect(() => {
        if (!existingProps) {
            getInitialProps();
            getAboutText();
        }

        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!aboutText)
            setTextProps(prevState => { return {
                ...prevState,
                ...GetNewStyle(),
                content: `# Sobre nós\n${aboutText.description}`
            }});
    }, [aboutText]);

    const getInitialProps = () => {
        const newTextStyleProps = GetNewStyle();
        setTextProps(prevState => { return { ...prevState, ...newTextStyleProps }});
    }

    const handleGenerateStyle = () => {
        const newStyle = GetNewStyle();
        setTextProps(prevState => { return {...prevState, ...newStyle} });
    }

    const GetNewStyle = (): TextProps => {
        return {
            textAlign: GenerateAlignment(),
            verticalPadding: GeneratePadding(),
            background: GenerateBackground(),
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont
        };
    }

    const GenerateAlignment = (): Position => {
        const allowedPositions = [Position.LEFT, Position.CENTER];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GeneratePadding = (): Size => {
        const allowedPositions = [Size.MEDIUM, Size.LARGE];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const ReGenerateContent = () => {
        getAboutText();
    }

    const ReGenerateEntireSection = () => {
        handleGenerateStyle();
        ReGenerateContent();
    }

    const onEditing = (newProps: TextProps) => {
        setSiteEditedState(true);
        setTextProps(prevState => { return {...prevState, ...newProps} });
    }

    const getEditComponent = () => {
        return (
            <EditText
                onEdit={onEditing}
                currentProps={textProps}
            />
        )
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            loading={isLoading}
            editComponent={getEditComponent()}
            onRegenerateStyle={handleGenerateStyle}
            onRegenerateContent={ReGenerateContent}
            onRegenerateEntireSection={ReGenerateEntireSection}
        >
            <Text
                data={textProps}
            />
        </BackdropEditor>
    )
});

export default TextCreation;