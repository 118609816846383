import { BackgroundProps, BaseContainer } from "@hivefuse/page-components";
import { useState } from "react";
import { BackgroundPreview, ChildrenInnerContainer, EditButton, EditContainer, InputLabel, InputRow, RowInputLabel, TabsList } from "./backgroundEditor.style";
import { Tab } from "@mui/material";
import ImageSelectionModal from "../imageSelectionModal/imageSelectionModal";
import ColorPicker from "../../../../components/ColorPicker/ColorPicker";
import { getFontColor } from "../../utils/colorUtils";
import { themes } from "../../../../themes/themes";
import { Ellipse8 } from "../../../../components/DefaultDesign/DefaultDesign.styles";

interface Props {
    images: string[];
    backgroundProps?: BackgroundProps;
    onBackgroundPropsChange: (newBackgroundProps: BackgroundProps, fontColor: string) => void;
}

enum BackgroundEditScreen {
    SOLID_COLOR = 'SOLID_COLOR',
    IMAGE = 'IMAGE'
}

export default function BackgroundEditor(props: Props) {
    const { images, backgroundProps, onBackgroundPropsChange } = props;
    const [imageSelectionModalOpened, setImageSelectionModalOpened] = useState(false);

    const getInitialBackgroundScreen = (): BackgroundEditScreen => {
        if (backgroundProps?.backgroundColor != undefined)
            return BackgroundEditScreen.SOLID_COLOR
        else
            return BackgroundEditScreen.IMAGE
    }
    const [backgroundScreen, setBackgroundStep] = useState(getInitialBackgroundScreen());

    const handleSolidBackgroundColorChange = (e: string) => {
        const fontColor = getFontColor(e);
        onBackgroundPropsChange({ backgroundColor: e }, fontColor);
    }

    const handleImageBackgroundChange = (e: string) => {
        onBackgroundPropsChange({ backgroundImageUrl: e }, themes.white);
    }

    return (
        <EditContainer>
            <TabsList value={backgroundScreen} variant="scrollable" scrollButtons={false} onChange={(_, value) => setBackgroundStep(value)}>
                <Tab label="Cor Sólida" value={BackgroundEditScreen.SOLID_COLOR}/>
                <Tab label="Imagem" value={BackgroundEditScreen.IMAGE}/>
            </TabsList>
            
            <ChildrenInnerContainer>
                {backgroundScreen == BackgroundEditScreen.SOLID_COLOR && (
                    <>
                        <InputLabel>Cor de fundo</InputLabel>
                        <ColorPicker
                            value={backgroundProps?.backgroundColor}
                            onChange={handleSolidBackgroundColorChange}
                        />
                    </>
                )}

                {backgroundScreen == BackgroundEditScreen.IMAGE && (
                    <>
                        <InputRow>
                            <RowInputLabel>Imagem</RowInputLabel>
                            <EditButton onClick={() => setImageSelectionModalOpened(true)}>Alterar</EditButton>
                        </InputRow>
                    </>
                )}

                <InputLabel>Pré-Visualização</InputLabel>
                <BaseContainer background={backgroundProps} style={{position: 'relative', width: '100%', paddingTop: '56.25%'}}>
                    <></>
                </BaseContainer>

                {imageSelectionModalOpened && (
                    <ImageSelectionModal
                        onClose={() => setImageSelectionModalOpened(false)}
                        selectedImage={backgroundProps?.backgroundImageUrl}
                        onImageSelected={(image) => handleImageBackgroundChange(image)}
                        images={images}
                    />
                )}
            </ChildrenInnerContainer>
        </EditContainer>
    );
}