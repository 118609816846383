import { DivSearch, SearchIco, SearchInputComponent } from "./SearchInput.styles";
import SearchIcoImg from "../../assets/SearchIco.svg";
import { ChangeEventHandler } from "react";

export default function SearchInput({onChange}: {onChange?: ChangeEventHandler<HTMLInputElement> | undefined}){
    return(
        <DivSearch>
            <SearchIco src={SearchIcoImg}/>
            <SearchInputComponent placeholder="Buscar..." type="text" onChange={onChange}/>
        </DivSearch>
    )
}