import { initializeApp, FirebaseOptions, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { getRemoteConfig, fetchAndActivate, getAll, RemoteConfig } from 'firebase/remote-config';
import { logEvent } from 'firebase/analytics';

export const analyticsEvents = {
    loginButton: 'login',
    forgotPasswordButton: 'forgot_password',
    loginCreateSiteButton: 'login_create_site',
    confirmForgotPasswordButton: 'confirm_forgot_password',
    forgotPasswordBackButton: 'forgot_password_back',
    recoverNewPasswordButton: 'recover_new_password',
    logoutButton: 'logout',
    dashboardCreateSiteButton: 'login_create_site',
    siteBusinessTypeForm: 'site_business_type_form',
    siteNameForm: 'site_name_form',
    siteLocationForm: 'site_location_form',
    siteSocialNetwork: 'site_social_form',
    editorRegisterButton: 'editor_register',
    editorPublishButton: 'editor_publish',
    editorSelectedDomainButton: 'editor_selected_domain',
    editorPaymentInfo: 'editor_payment_info',
    editorBillingAddress: 'editor_billing_address',
    googleAuthDuringCreation: 'google_auth_site_creation',
    googleAuthDashboard: 'google_auth_home'
}

class FireBaseService {
    private app: FirebaseApp | undefined;
    private analytics: Analytics | undefined;
    private remoteConfig: RemoteConfig | undefined;
    private configs: Record<string, string> = {};

    public init = () => {
        const firebaseConfig: FirebaseOptions = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
            appId: process.env.REACT_APP_FIREBASE_APP_ID!,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!
        };

        this.app = initializeApp(firebaseConfig);
        this.analytics = getAnalytics(this.app);
        this.remoteConfig = getRemoteConfig(this.app);
    }

    public getApp = () => {
        return this.app;
    }

    public logButtonClick = (buttonName: string) => {
        logEvent(this.analytics!, `btn_${buttonName}`);
    }

    public logPathChange = (path: string) => {
        logEvent(this.analytics!, 'page_view', { page_path: path });
    }

    public fetchConfigs = async (): Promise<void> => {
        try {
          await fetchAndActivate(this.remoteConfig!);
          const allConfigs = getAll(this.remoteConfig!);
    
          for (const key in allConfigs) {
            this.configs[key] = allConfigs[key].asString();
          }
        } catch (error) {
            console.error("Erro ao buscar Remote Config", error);
        }
    }
    
    public getConfig = (key: string): string => {
        return this.configs[key];
    }
}

export default new FireBaseService();