import { useRef, useState } from "react";
import { ColorPicked, Container, Input, MainContainer, PickerContainer } from "./ColorPicker.styles";
import { ChromePicker } from 'react-color';

interface Props {
    value?: string;
    onChange: (color: string) => void;
}

const ColorPicker = (props: Props) => {
    const { value, onChange } = props;
    const [pickerOpened, setPickerOpened] = useState(false);
    const [color, setColor] = useState(value ?? '#000000');
    const pickerContainerRef = useRef<any>(null);

    const handleChange = (color: any) => {
        const input = color.hex.toUpperCase();
        setColor(input);
        onChange(input);
    }

    const handlePickChange = () => {
        setPickerOpened(prev => !prev);
    }

    return(
        <Container>
            <MainContainer onClick={handlePickChange}>
                <ColorPicked background={color}/>
                <Input>{color}</Input>
            </MainContainer>
            <PickerContainer ref={pickerContainerRef}>
                {pickerOpened && (
                    <ChromePicker disableAlpha onChange={handleChange} color={color}/>
                )}
            </PickerContainer>
        </Container>
    )
}

export default ColorPicker;