import InputText from '../../components/TextInput/TextInput';
import styled, { css } from "styled-components"
import { themes } from '../../themes/themes';
import LoginCard from '../../components/LoginCard';
import { SiteCreationStep } from './SiteCreationPage';

const { primaryFont, black  } = themes;

interface Props {
    status?: SiteCreationStep;
    isEnable?: boolean;
}
  
export const BgBlur = styled.div`
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    min-width: 100%;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
`

export const PageCreateBusiness = styled.div`
    min-width: 100%;
    min-height: 100%;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
  
export const InputBusinessLocalization = styled(InputText)<Props>`
    ${({ status }) =>
    status !== 'LOCATION' &&
    css`
        display: none;
    `};
`;

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TitleBusinessType = styled.h2`
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding-top: 15px;
    padding-bottom: 20px;
`

export const InnerCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const DivNextButton = styled.div`
    padding-top: 30px;
    min-width: 300px;
`
export const Card = styled(LoginCard)`
    max-width: 310px;
    width: 100%;
`