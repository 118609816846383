import Dropdown from '../../../../../components/Dropdown/Dropdown';
import InputText from '../../../../../components/TextInput/TextInput';
import { BannerEditScreen } from '../bannerCreation';
import { BackgroundPreview, EditButton, EditContainer, InputLabel, InputRow, RowInputLabel } from './editBanner.style';
import { BannerProps, Position, Size } from '@hivefuse/page-components';
import BackgroundEditor from '../../backgroundEditor/backgroundEditor';

interface Props {
    images: string[];
    currentProps: BannerProps;
    onEdit: (newProps: BannerProps) => void;
    bannerEditScreen: BannerEditScreen;
    goToScreen: (screen: BannerEditScreen) => void;
}

export default function EditBanner(props: Props) {
    const { bannerEditScreen, currentProps, images, onEdit, goToScreen } = props;
  
    const handleTitleChange = (e: any) => {
        onEdit({
            ...currentProps,
            title: e.target.value
        });
    }

    const handleDescriptionChange = (e: any) => {
        onEdit({
            ...currentProps,
            description: e.target.value
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e
        });
    }

    const handleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            alignment: e
        });
    }

    if (bannerEditScreen == BannerEditScreen.BACKGROUND)
        return (
            <BackgroundEditor
                images={images}
                backgroundProps={currentProps.background}
                onBackgroundPropsChange={(background, fontColor) => onEdit({...currentProps, background, fontColor })}
            />
    );

    return (
        <EditContainer>
            <InputLabel noMarginTop={true}>Título</InputLabel>
            <InputText
                type='text'
                value={currentProps?.title}
                onChange={handleTitleChange}
            />
            <InputLabel>Descrição</InputLabel>
            <InputText
                type='text'
                value={currentProps?.description}
                textareaInput={true}
                onChange={handleDescriptionChange}
                rows={5}
            />
            <InputRow>
                <RowInputLabel>Fundo</RowInputLabel>
                <EditButton onClick={() => goToScreen(BannerEditScreen.BACKGROUND)}>Alterar</EditButton>
            </InputRow>
            <BackgroundPreview background={currentProps.background}>
                <></>
            </BackgroundPreview>
            <InputLabel>Alinhamento</InputLabel>
            <Dropdown
                value={currentProps?.alignment}
                options={[
                    {
                        text: 'Esquerda',
                        value: Position.LEFT 
                    },
                    {
                        text: 'Centro',
                        value: Position.CENTER 
                    },
                    {
                        text: 'Direita',
                        value: Position.RIGHT 
                    }
                ]}
                onValueChange={handleAlignmentChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.verticalPadding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL
                    },
                    {
                        text: 'Medio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
        </EditContainer>
    )
}