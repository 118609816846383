import styled from "styled-components";
import { themes } from '../../../../../themes/themes';

const { primaryFont, black } = themes;

export const BusinessName = styled.h2`
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    margin-top: 15px;
    margin-bottom: 15px;
`

export const DivButton = styled.div`
    padding-top: 28px;
`