import styled from 'styled-components';
import { themes } from '../../../../../../themes/themes';
import StandardButton from '../../../../../../components/Button/Button';

export const InnerContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: 12px;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    position: relative;

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        border: 2px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }

    @media (max-width: 600px){
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const PartnerText = styled.p`
    margin: 12px 0;
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    color: ${themes.darkGray};
`;

export const PartnerLink = styled.a`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    color: ${themes.darkPurple};
`;

export const ErrorMessageContainer = styled.div`
    flex-grow: 1;
    margin: 24px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const ErrorMessage = styled.h2`
   font-family: ${themes.dashBoardFont};
   text-align: center;
   font-size: 1.25rem;
   color: ${themes.onyx};
   margin-bottom: 24px;
`;

export const RetryButton = styled(StandardButton)`
    max-width: 500px;
`;
