import { ReactNode } from "react"
import styled from "styled-components";
import { themes } from "../../themes/themes";

interface PopUpTitleProps{
    children: ReactNode;
}

const Title = styled.h2`
    color: ${themes.darkPurple}; 
    font-size: 1.563rem; 
    font-family: ${themes.dashBoardFont}; 
    font-weight: 700; 
    line-height: 1.25rem; 
    word-wrap: break-word;
`

export default function ConfirmationModalTitle({children}: PopUpTitleProps){
    return(
        <Title>
            {children}
        </Title>
    )
}