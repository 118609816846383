import styled from 'styled-components';
import { themes } from '../../../../themes/themes';
import InputText from '../../../../components/TextInput/TextInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import StandardButton from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';

export const ModalBackground = styled.div`
    background-color: ${themes.white};
    padding: 24px;
    width: 100%;
    max-height: 720px;
    max-width: 720px;
    border-radius: 12px;
`;

export const Div = styled.div`
    display: flex; 
    justify-content: space-between;
    column-gap: 24px;
    margin-top: 12px;
    flex-wrap: wrap;
    flex: 1;
    width: 100%;
    
    @media (max-width: ${themes.tabletDimension}){
        flex-direction: column;
        margin-top: 3px;
    }
`;
export const DivButton = styled.div`
    display: flex; 
    margin-top: 24px;

    @media (max-width: 780px){
        margin-top: 12px;
    }
`;

export const TitleSubContent = styled.p`
    color: ${themes.darkGray};
    font-size: 1rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    word-wrap: break-word;
    margin-top: 12px;
    text-align: center;

    @media (max-width: ${themes.tabletDimension}){
        display: flex;
        justify-content: center;
        margin-top: 12px;
    }
`;

export const PasswordDescription = styled.p`
    color: ${themes.gray};
    font-size: 0.775rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
    word-wrap: break-word;
    margin-top: 12px;
`;

export const DivImg = styled.div`
    height: 82px;
    position: relative;
    display: flex;
    justify-content: center;
    @media (max-width: ${themes.tabletDimension}){
        height: 0;
    }
`;

export const InnerDiv = styled.div`
    position: absolute;
    top: -15px;
    @media (max-width: ${themes.tabletDimension}){
        display: none;
    }
`;

export const Input = styled(InputText)`
    border: 1px solid ${themes.darkGray};
    width: auto;
    flex: 1;
`;

export const DivTitle = styled.div`
    @media (max-width: ${themes.tabletDimension}){
    display: flex;
    justify-content: center;
    margin-top: -10px;
    }    
`;

export const DivImage = styled.div`
    display: none;
    @media (max-width: ${themes.tabletDimension}){
        position: absolute;
        display: flex;
        top: -14px;
        left: 47px;
    }
`;

export const ImgLogoHiveB = styled.img`
    width: 50px;
    height: 50px;
`;

export const VisibilityOffImg = styled(VisibilityOff)` 
`

export const VisibilityImg = styled(Visibility)`
`
export const IconButton = styled.div`
    background: none;
    border: none;
    cursor: pointer;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CloseIcon = styled(Icon)`
    cursor: pointer;
    font-size: 24px;

    &:hover {
        opacity: 0.5;
    }
`;

export const Title = styled.h2`
    color: ${themes.darkPurple}; 
    font-size: 1.563rem; 
    font-family: ${themes.dashBoardFont}; 
    font-weight: 700; 
    line-height: 1.25rem; 
    word-wrap: break-word;
`

export const Row = styled.div<{lastRow?: boolean}>`
    display: flex;
    gap: 24px;
    margin-top: 12px;

    @media (max-width: ${themes.tabletDimension}){
        flex-direction: column;
        gap: 12px;
        ${(props) => {
            if (props.lastRow) {
                return `
                    margin-bottom: 24px;
                `;
            }
        }}
    }
`

export const Button = styled(StandardButton)`
    margin-top: 24px;

    @media (max-width: ${themes.tabletDimension}){
        margin-top: auto;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    flex: 1;
`;