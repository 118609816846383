import { Moment } from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField, Typography } from '@mui/material';

import { GetHourStringFromDate } from '../../../helpers/DateHelper';
import styles from './RangeInput.module.css';
import { TimeSlot } from './WorkHourDay';

interface Props {
    restOfTimeSlots: TimeSlot[];
    timeSlot: TimeSlot;
    onStartChange: (start: Moment) => void;
    onEndChange: (end: Moment) => void;
}

const RangeInput = forwardRef<any, Props>((props, ref) => {
    const { timeSlot, restOfTimeSlots, onStartChange, onEndChange } = props;
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        const CheckForError = () => {
            if ((!!timeSlot.startTime && !!timeSlot.endTime) &&
                (timeSlot.startTime > timeSlot.endTime)) {
                setErrorMessage('O horário inicial não pode ser maior do que o final');
                return;
            }
    
            if (validateIfOverlaps()) {
                setErrorMessage('Horários já utilizados');
                return;
            }
    
            setErrorMessage(undefined);
        }

        CheckForError();
    }, [timeSlot, restOfTimeSlots]);

    useImperativeHandle(ref, () => ({
        getRange() {
            if (!!errorMessage || !timeSlot.startTime?.isValid() || !timeSlot.endTime?.isValid()) {
                return null;
            }

            return {
                startTime: GetHourStringFromDate(timeSlot.startTime),
                endTime: GetHourStringFromDate(timeSlot.endTime)
            };
        }
    }));

    const dateRangeOverlaps = (a_start: Moment, a_end: Moment, b_start: Moment, b_end: Moment): boolean => {
        if (a_start < b_start && b_start < a_end) return true;
        if (a_start < b_end   && b_end   < a_end) return true;
        if (b_start <  a_start && a_end   <  b_end) return true;
        return false;
    }

    const validateIfOverlaps = (): boolean => {
        let result = false;
        restOfTimeSlots.every((slot) => {
            if (dateRangeOverlaps(timeSlot.startTime!, timeSlot.endTime!, slot.startTime!, slot.endTime!)) {
                result = true;
                return false;
            }
            return true;
        });

        return result;
    }

    return (
        <div className={styles.outer}>
            <div className={styles.hourSectionInner}>
                <TimePicker
                    ampm={false}
                    value={timeSlot.startTime}
                    className={styles.timePicker}
                    onChange={(newValue: any) => { onStartChange(newValue) }}
                    renderInput={(params) => <TextField className={styles.inputText} {...params} />}
                />
                <div className={styles.separator}>
                    <Typography variant="body1">
                        -
                    </Typography>
                </div>
                <TimePicker
                    ampm={false}
                    value={timeSlot.endTime}
                    className={styles.timePicker}
                    onChange={(newValue: any) => { onEndChange(newValue) }}
                    renderInput={(params) => <TextField className={styles.inputText} {...params} />}
                />
            </div>
            {!!errorMessage && (
                <div className={styles.invalidInputContainer}>
                    <Typography variant="caption" gutterBottom className={styles.invalidInput}>
                        {errorMessage}
                    </Typography>
                </div>
            )}
        </div>
    );
});
  
export default RangeInput;