import { BackgroundGradient } from "@hivefuse/page-components";

const GradientPossibilities: BackgroundGradient[] = [
    {
        radial: false,
        linearDegree: 90,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 100
            }
        ]
    },
    {
        radial: false,
        linearDegree: 301,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 81
            }
        ]
    },
    {
        radial: false,
        linearDegree: 21,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 81
            }
        ]
    },
    {
        radial: false,
        linearDegree: 21,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 100
            }
        ]
    },
    {
        radial: false,
        linearDegree: 21,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 100
            }
        ]
    },
    {
        radial: false,
        linearDegree: 80,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 100
            }
        ]
    },
    {
        radial: false,
        linearDegree: 164,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 100
            }
        ]
    },
    {
        radial: false,
        linearDegree: 256,
        colors: [
            {
                color: '',
                stop: 0
            },
            {
                color: '',
                stop: 100
            }
        ]
    }
];

export default GradientPossibilities;