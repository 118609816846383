import { Tooltip } from "@mui/material";
import { themes } from "../../../../../themes/themes";
import { AddSectionDirection } from "../../../interface/addSectionDirectionEnum";
import { AddSectionsButton } from "./AddSectionButton.style";
import { FaPlus } from "react-icons/fa6";

interface Props {
    higherOverflow?: boolean;
    direction: AddSectionDirection;
    onAddSectionButtonClicked: (direction: AddSectionDirection) => void;
}

export default function AddSectionButton(props: Props) {
    const {
        higherOverflow: sticky,
        direction,
        onAddSectionButtonClicked
    } = props;

    return (
        <Tooltip title="Adicionar componente" placement="top" arrow>
            <AddSectionsButton
                higher={sticky}
                direction={direction}
                onClick={() => onAddSectionButtonClicked(direction)}
            >
                <FaPlus color={themes.darkPurple} size={25}/>
            </AddSectionsButton>
        </Tooltip>
    );
}