import { Button } from '@mui/material';

import styles from './DefaultButton.module.css';

interface Props {
    className?: string;
    variant?: "text" | "outlined" | "contained" | undefined;
    type?: 'submit' | 'reset' | 'button' | undefined;
    text: string;
    onClick?: () => void;
}

function DefaultButton(props: Props) {
    const { className, variant, type, text, onClick } = props;
    
    return (
        <Button
            className={[styles.button, className].join(' ')}
            variant={variant ?? "contained"}
            type={type}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}
  
export default DefaultButton;