import styled from 'styled-components';
import { themes } from '../../../../../../../themes/themes';
import ComponentSkeleton from '../../../../../../../components/Skeleton-Loading/steletonLoading';
import { FaRegTrashAlt } from 'react-icons/fa';
import { CiImageOff } from "react-icons/ci";

export const ImageContainer = styled.div<{ selected?: boolean }>`
    width: 100%;
    height: 0;
    padding-top: calc(70%);
    border-radius: 8px;
    box-sizing: border-box;
    outline: ${props => props.selected ? `2px solid ${themes.darkPurple}` : 'none'};
    outline-offset: 3px;
    position: relative

    &:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.3s;
    }
`;

export const Image = styled.img<{ src: string }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
`;

export const ImageWrapper = styled.div`
    position: relative;
`;

export const Skeleton = styled(ComponentSkeleton)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
`;

export const DeleteIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: ${themes.darkPurple};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    z-index: 9999;
`;

export const TrashIcon = styled(FaRegTrashAlt)`
    color: ${themes.white};
    font-size: 12px;
    line-height: 12px;
`;

export const NoImageContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid ${themes.onyx};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
`;

export const NoImageIcon = styled(CiImageOff)`
`;