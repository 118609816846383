import { BaseContainer } from '@hivefuse/page-components';
import { Tabs } from '@mui/material';
import styled from 'styled-components';
import { themes } from '../../../../themes/themes';

export const EditContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const TabsList = styled(Tabs)`
    width: 100%;

    button {
        flex: 1;
        text-transform: none !important;
    }
`;

export const ChildrenInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const BackgroundPreview = styled(BaseContainer)`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
`;

export const InputLabel = styled.p<{ noMarginTop?: boolean }>`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
    margin-top: ${props => !!props.noMarginTop ? '0' : '12px'};
    margin-bottom: 12px;
`;

export const RowInputLabel = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
`;

export const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
`;

export const EditButton = styled.p`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;