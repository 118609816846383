import styled from 'styled-components';
import { themes } from '../../../../../themes/themes';
import { BaseContainer } from '@hivefuse/page-components';
import { Tabs } from '@mui/material';

export const EditContainer = styled.div`
`;

export const InputLabel = styled.p<{ noMarginTop?: boolean }>`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
    margin-top: ${props => !!props.noMarginTop ? '0' : '12px'};
    margin-bottom: 12px;
`;

export const Input = styled.input`
    margin-bottom: 1rem;
`;

export const Select = styled.select`
    margin-bottom: 1rem;
`;

export const BackgroundPreview = styled(BaseContainer)`
    position: relative;
    width: 100%;
    min-height: 96px;
    border-radius: 8px;
    border: 1px solid ${themes.darkGray};
    box-shadow: ${themes.inputShadow};
`;

export const RowInputLabel = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
`;

export const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`;

export const EditButton = styled.p`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const LogoInnerContainer = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    padding: 1rem 0;
`;

export const TabsList = styled(Tabs)`
    width: 100%;

    button {
        flex: 1;
        text-transform: none !important;
    }
`;

export const EditInnerContainer = styled.div<{alignLeft?: boolean}>`
    padding: 12px 0;

    ${props => {
        if (!!props.alignLeft) {
            return `
                display: flex;
                justify-content: end;
            `;
        }
    }}
`;