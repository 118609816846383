import { useState } from 'react';
import ColorPicker from '../../../../../components/ColorPicker/ColorPicker';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import { getFontColor } from '../../../utils/colorUtils';
import { ControlsRow, EditButton, EditContainer, ImagePreview, InputLabel, InputRow, QuillEditor, RemoveButton } from './editTextAndImage.style';
import { AspectRatio, BorderEdge, Position, Size, TextAndImageProps } from '@hivefuse/page-components';
import { remark } from "remark";
import remarkHtml from "remark-html";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import { Alert } from '../../../../../components/Alert/Alert';
import ImageSelectionModal from '../../imageSelectionModal/imageSelectionModal';
import { useRecoilValue } from 'recoil';
import { siteStockImagesState } from '../../../../../state/siteEditorState';

interface Props {
    currentProps: TextAndImageProps;
    onEdit: (newProps: TextAndImageProps) => void;
}

export default function EditTextAndImage(props: Props) {
    const { currentProps, onEdit } = props;
    const [imageSelectionModalOpened, setImageSelectionModalOpened] = useState(false);
    const images = useRecoilValue(siteStockImagesState);
    
    const markdownToHtml = (markdownText: string) => {
        const file = remark().use(remarkHtml).processSync(markdownText);
        return String(file);
    }

    const [markdowValue, setMarkdownValue] = useState(markdownToHtml(currentProps?.textProps?.content ?? ''));

    const htmlToMarkdown = (htmlText: string) => {
        const file = remark()
          .use(rehypeParse, { emitParseErrors: true, duplicateAttribute: false })
          .use(rehypeRemark)
          .use(remarkStringify)
          .processSync(htmlText);
      
        return String(file);
    }

    const handleDescriptionChange = (e: any) => {
        setMarkdownValue(e);
        onEdit({
            ...currentProps,
            textProps: {
                ...currentProps.textProps,
                content: htmlToMarkdown(e)
            }
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                ...currentProps.background,
                backgroundColor: e,
            },
            fontColor: fontColor,
            textProps: {
                ...currentProps.textProps,
                fontColor: fontColor
            }
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e
        });
    }

    const handleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            textProps: {
                ...currentProps.textProps,
                textAlign: e
            }
        });
    }

    const handleRemoveImage = () => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover esta imagem?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => {
                        onEdit({
                            ...currentProps,
                            imageProps: {
                                imageUrl: undefined
                            }
                        });
                    }
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                },
            ],
            true
        );
    }

    const handleNewImageSelected = (image: string) => {
        onEdit({
            ...currentProps,
            imageProps: {
                imageUrl: image
            }
        });
    }

    const handleOrderChange = (order: boolean) => {
        onEdit({
            ...currentProps,
            invertedOrder: order
        });
    }

    const handleImageBorderEdgeChange = (border: BorderEdge) => {
        onEdit({
            ...currentProps,
            imageProps: {
                ...currentProps.imageProps,
                borderEdge: border
            }
        });
    }

    const handleAspectRatioChange = (aspectRatio: AspectRatio) => {
        onEdit({
            ...currentProps,
            imageProps: {
                ...currentProps.imageProps,
                aspectRatio: aspectRatio
            }
        });
    }

    return (
        <EditContainer>
            <InputLabel noMarginTop={true}>Descrição</InputLabel>
            <QuillEditor
                theme="snow"
                value={markdowValue}
                onChange={handleDescriptionChange}
                placeholder="Descrição..."
            />
            <InputLabel>Ordem</InputLabel>
            <Dropdown
                value={currentProps?.invertedOrder}
                options={[
                    {
                        text: 'Texto - Imagem',
                        value: false
                    },
                    {
                        text: 'Imagem - Texto',
                        value: true
                    }
                ]}
                onValueChange={handleOrderChange}
            />
            <InputLabel>Borda das Imagem</InputLabel>
            <Dropdown
                value={currentProps?.imageProps?.borderEdge}
                options={[
                    {
                        text: 'Sem borda',
                        value: BorderEdge.SHARP
                    },
                    {
                        text: 'Pequena',
                        value: BorderEdge.SMALL
                    },
                    {
                        text: 'Média',
                        value: BorderEdge.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: BorderEdge.LARGE
                    }
                ]}
                onValueChange={handleImageBorderEdgeChange}
            />
            <InputLabel>Formato da Imagem</InputLabel>
            <Dropdown
                value={currentProps?.imageProps?.aspectRatio}
                options={[
                    {
                        text: 'Paisagem',
                        value: AspectRatio.LANDSCAPE
                    },
                    {
                        text: 'Retrato',
                        value: AspectRatio.PORTRAIT
                    },
                    {
                        text: 'Quadrada',
                        value: AspectRatio.SQUARE
                    },
                    {
                        text: 'Widescreen',
                        value: AspectRatio.WIDESCREEN
                    }
                ]}
                onValueChange={handleAspectRatioChange}
            />
            <InputLabel>Alinhamento</InputLabel>
            <Dropdown
                value={currentProps?.textProps?.textAlign}
                options={[
                    {
                        text: 'Esquerda',
                        value: Position.LEFT 
                    },
                    {
                        text: 'Centro',
                        value: Position.CENTER 
                    },
                    {
                        text: 'Direita',
                        value: Position.RIGHT 
                    }
                ]}
                onValueChange={handleAlignmentChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.verticalPadding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL
                    },
                    {
                        text: 'Medio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
            <InputRow>
                <InputLabel>Imagem</InputLabel>
                <ControlsRow>
                    {currentProps?.imageProps?.imageUrl != undefined && (
                        <RemoveButton onClick={handleRemoveImage}>
                            Remover
                        </RemoveButton>
                    )}
                    <EditButton onClick={() => setImageSelectionModalOpened(true)}>
                        {currentProps?.imageProps?.imageUrl != undefined
                            ? 'Alterar'
                            : 'Adicionar'
                        }
                    </EditButton>
                </ControlsRow>
            </InputRow>
            {currentProps?.imageProps?.imageUrl != undefined && (
                <ImagePreview src={currentProps?.imageProps?.imageUrl ?? ''}/>
            )}

            {imageSelectionModalOpened && (
                <ImageSelectionModal
                    onClose={() => setImageSelectionModalOpened(false)}
                    selectedImage={currentProps?.imageProps?.imageUrl}
                    onImageSelected={(image) => handleNewImageSelected(image)}
                    images={images}
                />
            )}
        </EditContainer>
    )
}