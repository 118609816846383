import styled from 'styled-components';
import { themes } from '../../themes/themes';
import IconDropdown from '../../components/IconDropdown/IconDropdown';
import { MdModeEdit } from "react-icons/md";
import { MdEditOff } from "react-icons/md";
import { FaCog } from "react-icons/fa";
import { HiBolt } from 'react-icons/hi2';
import StandardButton from '../../components/Button/Button';
import { IoHelpCircleSharp } from "react-icons/io5";
import { IoHomeSharp } from "react-icons/io5";
import { IoColorPaletteSharp } from "react-icons/io5";

export const MainContainer = styled.div`
    @media (max-width: ${themes.tabletDimension}) {
        padding-bottom: 138px;
    }
`;

export const Loader = styled.div`
    flex: 1;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

export const PublishOutsideContainer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 24px;
    z-index: 999;
    width: fit-content;
    margin: 0 auto;

    @media (max-width: ${themes.tabletDimension}) {
        display: none;
    }
`;

export const PublishContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    width: fit-content;
    background-color: white;
    margin: 0 10px;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const PublishButton = styled.div`
    padding: 12px;
    background-color: ${themes.magentaBlue};
    border-radius: 8px;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const PublishText = styled.p`
    color: white;
    user-select: none;
    font-family: sans-serif;
`;

export const PublishDescription = styled.p`
    user-select: none;
    font-family: ${themes.dashBoardFont};
    font-size: 0.938rem;
    margin-right: 10px;
    text-align: center;
`;

export const Tool = styled.div`
    cursor: pointer;
    padding: 10px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    gap: 3px;

    &:hover {
        background-color: ${themes.veryLightShadeOfGray};
    }
`;

export const Regenerate = styled(IconDropdown)`
    padding: 10px;
    border-radius: 8px;

    &:hover {
        background-color: ${themes.veryLightShadeOfGray};
        opacity: 1;
    }
`;

export const Divider = styled.div`
    height: 40px;
    border: 1px solid ${themes.veryLightShadeOfGray};
    margin: 0 10px;

    @media(max-width: 550px){
        margin: 0 5px;
        border: none;
    }
`;

export const PublishMobileOutsideContainer = styled.div`
    display: none;

    @media (max-width: ${themes.tabletDimension}) {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        width: fit-content;
        margin: 0 auto;
        width: 100%;
    }
`;

export const PublishButtonMobileContainer = styled.div`
    display: flex;
    background-color: ${themes.white};
    height: 76px;
    align-items: center;
    padding: 0 12px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 0 -1px 5px rgba(57, 63, 72, 0.3);
`;

export const FullWidthButton = styled(StandardButton)`
    width: 100%;
`;

export const PublishMobileContainer = styled.div`
    height: 62px;
    width: 100%;
    background-color: ${themes.white};
    border-top: 1px solid ${themes.lightSilver};
`;

export const PublishMobileGrid = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    height: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(auto-fit,minmax(0,1fr));
    max-width: 480px;
`;


export const MobileSectionContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    gap: 4px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: ${themes.hover};
    }
`;

export const MobileSectionTitle = styled.p`
    font-size: 0.625rem;
    font-family: ${themes.primaryFont};
    white-space: pre-wrap;
    text-align: center;
`;


export const EditIcon = styled(MdModeEdit)`
    color: ${themes.darkPurple};
    font-size: 20px;

    @media (max-width: ${themes.tabletDimension}) {
        color: ${themes.onyx};
    }
`;

export const StopEditIcon = styled(MdEditOff)`
    color: ${themes.darkPurple};
    font-size: 20px;

    @media (max-width: ${themes.tabletDimension}) {
        color: ${themes.onyx};
    }
`;

export const StyleIcon = styled(IoColorPaletteSharp)`
    color: ${themes.darkPurple};
    font-size: 20px;

    @media (max-width: ${themes.tabletDimension}) {
        color: ${themes.onyx};
    }
`;

export const RegenerateIcon = styled(HiBolt)`
    color: ${themes.darkPurple};
    font-size: 20px;

    @media (max-width: ${themes.tabletDimension}) {
        color: ${themes.onyx};
    }
`;

export const HelpIcon = styled(IoHelpCircleSharp)`
    color: ${themes.darkPurple};
    font-size: 20px;

    @media (max-width: ${themes.tabletDimension}) {
        color: ${themes.onyx};
    }
`;

export const HomeIcon = styled(IoHomeSharp)`
    color: ${themes.darkPurple};
    font-size: 20px;

    @media (max-width: ${themes.tabletDimension}) {
        color: ${themes.onyx};
    }
`;