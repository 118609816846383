import { ReactNode } from "react"
import styled from "styled-components";
import { themes } from "../../themes/themes";

interface PopUpContentProps{
    children: ReactNode;
}

const DivContent = styled.div`
    color: ${themes.darkGray};
    font-size: 1rem;
    font-family: ${themes.dashBoardFont};
    font-weight: 700;
    line-height: 1.25rem;
    word-wrap: break-word;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`

export default function ConfirmationModalContent({children}: PopUpContentProps){
    return(
        <DivContent>
            {children}
        </DivContent>
    )
}