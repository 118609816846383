import { useMutation } from "react-query";
import ForgotPasswordRequest from "../../interfaces/ForgotPasswordRequest";
import AxiosConfig from "../../configs/AxiosConfig";

const unexpectedErrorText = "Erro inesperado, tente novamente";

const postForgotPassword = async (email: ForgotPasswordRequest) => {
    try {
        const response = await AxiosConfig
            .getAxiosInstance()
            .post('/api/v1/users/business/forgot-password', email);
        if (response.status === 200)
            return response.data;
        else
            throw new Error(unexpectedErrorText).message;
    } catch(err) {
        throw new Error(unexpectedErrorText).message;
    }
}
        
export const useForgotPassword = () => {
    const mutation = useMutation({
        mutationFn: postForgotPassword
    });

    return mutation;
}
