import { useEffect } from "react";
import { LoadingContainer, LoadingDiv, LottieLoading, MainContainer, Image, Text } from "./creatingSiteScreen.style";
import LoadingCircle from '../../../../assets/animations/LoadingCircle.json';
import AnimationSuccess from '../../../../assets/animations/AnimationSuccess.json';
import Logo from '../../../../assets/LogoHiveB_Header.svg';
import { FadeText } from "./components/animatedText";
import { useRecoilState } from "recoil";
import { SiteEditorState, siteEditorRecoilState } from "../../../../state/siteEditorState";

const CreatingSiteScreen = () => {
    const [siteEditorState, setSiteEditorState] = useRecoilState(siteEditorRecoilState);
    
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = 'visible'; };
    }, []);

    const loadingTexts = [
        'escolhendo o melhor layout para o seu negócio...',
        'gerando conteúdo para o seu site...',
        'criando galeria de imagens...',
        'escrevendo parágrafo sobre o seu negócio...',
        'adicionando alguns serviços...',
        'criando depoimentos positivos...'
    ];

    const onCreation = () => {
        setSiteEditorState(SiteEditorState.READY_TO_PUBLISH);
    }
    
    return (
        <MainContainer>
            <LoadingContainer>
                {siteEditorState != SiteEditorState.SITE_CREATED_ANIMATION && (
                    <>
                        <LoadingDiv>
                            <Image src={Logo}/>
                            <LottieLoading animationData={LoadingCircle}/>
                        </LoadingDiv>
                        <FadeText texts={loadingTexts}/>
                    </>
                )}
                {siteEditorState == SiteEditorState.SITE_CREATED_ANIMATION && (
                    <>
                        <LoadingDiv>
                            <LottieLoading
                                animationData={AnimationSuccess}
                                loop={false}
                                onComplete={onCreation}
                            />
                        </LoadingDiv>
                        <Text>Seu site está pronto!</Text>
                    </>
                )}
            </LoadingContainer>
        </MainContainer>
    )
};

export default CreatingSiteScreen;