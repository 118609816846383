import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import InteractionCard from '../../components/InteractionCard/InteractionCard';

import styles from './EventDetailsPage.module.css';

function EventDetailsPage() {
    const params = useParams();

    return (
        <div className={styles.main}>
            <Typography className={styles.header} variant="h5">
                Evento
            </Typography>

            <div className={styles.cardHorizontal}>
                <div className={styles.eventInfoContainer}>
                    <div>
                        <Typography className={styles.sectionTitle} variant="body1">
                            Horário de início
                        </Typography>
                        <Typography className={styles.sectionDescription} variant="body2">
                            25/11/2022 17:00
                        </Typography>
                    </div>
                    <div>
                        <Typography className={styles.sectionTitle} variant="body1">
                            Horário de término
                        </Typography>
                        <Typography className={styles.sectionDescription} variant="body2">
                            25/11/2022 18:00
                        </Typography>
                    </div>
                    <div>
                        <Typography className={styles.sectionTitle} variant="body1">
                            Tipo de Evento
                        </Typography>
                        <Typography className={styles.sectionDescription} variant="body2">
                            Online
                        </Typography>
                    </div>
                    <div>
                        <Typography className={styles.sectionTitle} variant="body1">
                            Identificação
                        </Typography>
                        <Typography className={styles.sectionDescription} variant="body2">
                            ABJ32KL
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={styles.cardHorizontal}>
                <Typography variant='h6' className={styles.cardTitle}>Cliente</Typography>
                <div className={styles.clientInfoContainer}>
                    <div>
                        <Typography className={styles.sectionTitle} variant="body1">
                            Nome
                        </Typography>
                        <Typography className={styles.sectionDescription} variant="body2">
                            Osvaldo Barros
                        </Typography>
                    </div>
                    <div>
                        <Typography className={styles.sectionTitle} variant="body1">
                            CPF
                        </Typography>
                        <Typography className={styles.sectionDescription} variant="body2">
                            012.345.678-90
                        </Typography>
                    </div>
                    <div>
                        <Typography className={styles.sectionTitle} variant="body1">
                            Telefone
                        </Typography>
                        <Typography className={styles.sectionDescription} variant="body2">
                            (81) 99759-7878
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={styles.cardHorizontal}>
                <Typography variant='h6' className={styles.cardTitle}>Pagamento</Typography>
                <div>
                    <InteractionCard icon='Pix' title={'PIX - R$ 85,00'} onClick={() => console.log('ae')}/>
                </div>
            </div>

            <div className={styles.cardHorizontal}>
                <Typography variant='h6' className={styles.cardTitle}>Equipe</Typography>
                <div>
                    <InteractionCard avatarUrl=' ' title={'Osvaldo Barros'} onClick={() => console.log('ae')}/>
                </div>
            </div>

            <div className={styles.cardHorizontal}>
                <Typography variant='h6' className={styles.cardTitle}>Serviços</Typography>
                <div>
                    <InteractionCard icon='Handyman' title={'Corte de Cabelo'} onClick={() => console.log('ae')}/>
                </div>
            </div>

        </div>
    );
}

export default EventDetailsPage;