import styled from "styled-components";
import { themes } from '../../../../../themes/themes';

const { primaryFont, black, darkPurple } = themes;

export const Title = styled.h2`
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding-top: 15px;
    padding-bottom: 20px;
    width: 290px;
`
export const Description = styled.p`
    color: rgba(97.75, 85.94, 85.94, 0.60);
    font-size: 0.75rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
`

export const ListSuggestions = styled.ul`
    max-width: 300px;
    min-height: 120px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
`

export const SuggestionsCategoryItem = styled.li`
    font-size: 0.938rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    background: rgba(217, 217, 217, 0.30);
    background-size: auto;
    color: rgba(97.75, 85.94, 85.94, 0.60);
    border-radius: 10px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 150px;
    text-align: center;
    margin: 5px;
    &:hover{
        cursor: pointer;
        opacity: 0.7;
    }
`
export const SuggestionsCategorySkeleton = styled.li`
    border-radius: 10px;
    max-width: 150px;
    margin: 5px;
`
export const GenerateAgain = styled.a`
    color: ${darkPurple};
    font-size: 0.875rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    margin-top: 1rem;
    position: relative;
    &:hover{
        cursor: pointer;
        opacity: 0.7;
    }
`