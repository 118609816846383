import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { StyleIcon, Divider, EditIcon, FullWidthButton, HelpIcon, HomeIcon, MainContainer, MobileSectionContainer, MobileSectionTitle, PublishButtonMobileContainer, PublishContainer, PublishMobileContainer, PublishMobileGrid, PublishMobileOutsideContainer, PublishOutsideContainer, Regenerate, RegenerateIcon, StopEditIcon, Tool } from "./SiteEditor.style";
import { useGetImages } from '../../hooks/imageService/useGetImages';
import { useStyleCreation } from '../../hooks/siteGenerationService/useStyleCreation';
import { ThemeUtils, createCustomTheme } from './utils/themeUtils';
import SiteSteps from './resources/SiteSteps';
import { ThemeFont } from '@hivefuse/page-components';
import PublishmentModal from './modals/publishmentModal/publishment';
import { CompanySite } from './interface/CompanySiteComponent';
import CreationOptions from './interface/creationOptions';
import { useGetSiteById } from '../../hooks/siteService/useGetSiteById';
import { useUpdateSite } from '../../hooks/siteGenerationService/useUpdateSite';
import StandardButton from '../../components/Button/Button';
import { Tooltip } from '@mui/material';
import { SiteComponentState, SiteEditorState, creationOptionsState, editingSiteIdState, editingSiteState, siteComponentsState, siteEditedState, siteEditorRecoilState, siteJustPublishedState, siteStockImagesState, siteThemeState, useSiteRecoilStates } from '../../state/siteEditorState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRefGetter, useRegisterRef } from './utils/siteRefManager';
import { createComponent } from './utils/siteUtils';
import FireBaseService, { analyticsEvents } from '../../infrastructure/firebaseService';
import { useIsCreatingSiteComponent } from '../../hooks/useIsCreatingSiteComponent';
import { authState } from '../../state/authState';
import { Alert } from '../../components/Alert/Alert';
import CreateSiteResponse from '../../interfaces/site/CreateSiteResponse';
import { useCreateSite } from '../../hooks/siteGenerationService/useCreateSite';
import CreatingSiteScreen from './components/creatingSiteScreen/creatingSiteScreen';
import LoadingSiteScreen from './components/loadingSiteScreen/loadingSiteScreen';

const SiteEditor = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [siteId, setSiteId] = useRecoilState(editingSiteIdState);
    const [stockImages, setStockImages] = useRecoilState(siteStockImagesState);
    const [modalOpened, setModalOpened] = useState(false);
    const [siteComponents, setSiteComponents] = useRecoilState(siteComponentsState);
    const [theme, setTheme] = useRecoilState(siteThemeState);
    const [creationOptions, setCreationOptions] = useRecoilState(creationOptionsState);
    const { mutate: getImages, data: imageData, isLoading: isLoadingImages } = useGetImages();
    const { mutate: getStyleInfo, data: styleInfo } = useStyleCreation();
    const { mutate: getSiteById, data: existingSite } = useGetSiteById();
    const registerRef = useRegisterRef();
    const getRef = useRefGetter();
    const [editingSite, setEditingSite] = useRecoilState(editingSiteState);
    const isCreatingSiteComponent = useIsCreatingSiteComponent();
    const [auth] = useRecoilState(authState);
    const [siteEditorState, setSiteEditorState] = useRecoilState(siteEditorRecoilState);
    const [siteEdited, setSiteEdited] = useRecoilState(siteEditedState);
    const siteJustPublished = useRecoilValue(siteJustPublishedState);
    const [refreshedSiteState, setRefreshedSiteState] = useState(false);

    const onResetedRecoilStates = () => {
        setRefreshedSiteState(true);
        const siteParamId = searchParams.get('siteId');
        if (location.state == null && siteParamId == null)
            navigate('/business/create')
        else {
            if (!!siteParamId) {
                getSiteById(siteParamId);
                setCurrentSiteId(siteParamId);
                setSiteEditorState(SiteEditorState.LOADING_SITE);
            }
            else
                handleSiteCreation(location.state as CreationOptions);
        }
    }
    const resetAllRecoilStates = useSiteRecoilStates(onResetedRecoilStates);
    
    useEffect(() => {
        resetAllRecoilStates();
    }, []);

    useEffect(() => {
        if (siteEditorState == SiteEditorState.READY_TO_PUBLISH && searchParams.get('flow') == 'POST_GOOGLE_AUTH')
            setModalOpened(true);
    }, [siteEditorState]);

    const mountExistingSite = () => {
        const site = JSON.parse(existingSite!.site.content.structuredContent) as CompanySite;
        loadFonts(site.theme.themeFont);
        setCreationOptions(site.creationOptions);
        setTheme(site.theme);
        getImages(site.creationOptions.category);
        const components: SiteComponentState[] = [];
        site.components.map((component) => {
            components.push(
                createComponent(component.type, registerRef, component.data)
            )
        });
        setSiteComponents(components);
        setSiteEditorState(SiteEditorState.READY_TO_PUBLISH);
    }

    useEffect(() => {
        if (!!existingSite && siteEditorState == SiteEditorState.LOADING_SITE && !isLoadingImages)
            mountExistingSite();
    }, [existingSite, siteEditorState, isLoadingImages]);

    useEffect(() => {
        if (!!styleInfo && !isLoadingImages)
            setTheme(ThemeUtils.createSiteTheme(styleInfo));
    }, [styleInfo, isLoadingImages]);

    const createSiteFromScratch = () => {
        setSiteEditorState(SiteEditorState.CREATING_SITE_COMPONENTS)
        loadFonts(theme!.themeFont);
        const components: SiteComponentState[] = [];
        let steps = [...SiteSteps];
        if (!creationOptions?.location || creationOptions?.location?.length == 0)
            steps = steps.filter(s => s.type !== 'Location');
        steps.map((step) => {
            components.push(
                createComponent(step.type, registerRef)
            )
        });
        setSiteComponents(components);
    }
    useEffect(() => {
        if (!!theme && !!stockImages && siteEditorState == SiteEditorState.CREATING_SITE_STYLES)
            createSiteFromScratch();
    }, [theme, imageData, siteEditorState, stockImages]);

    useEffect(() => {
        if (!!theme && !!siteEdited)
            loadFonts(theme.themeFont)
    }, [theme]);

    const loadFonts = async (fonts: ThemeFont) => {
        addstyle(fonts.title.url);
        addstyle(fonts.description.url);
    }

    const addstyle = (url: string) => {
        if (!document.querySelector(`link[href="${url}"]`)) {
            const style = document.createElement("link");
            style.href = url;
            style.rel = "stylesheet";
            document.head.appendChild(style);
        }
    };

    const handleSiteCreation = (creationOptions: CreationOptions) => {
        setSiteEditorState(SiteEditorState.CREATING_SITE_STYLES);
        setCreationOptions(creationOptions);
        getImages(creationOptions.category);
        getStyleInfo({
            businessName: creationOptions.name,
            category: creationOptions.category
        });
    }

    const setCurrentSiteId = (siteId: string) => {
        setSiteId(siteId);
        searchParams.set('siteId', siteId);
        setSearchParams(searchParams);
    }

    const getExportableSiteComponents = (): string => {
        return JSON.stringify({
            creationOptions: creationOptions!,
            theme: theme!,
            components: siteComponents.map((component, index) => {
                return {
                    type: component.type,
                    index: index,
                    data: getRef(component.componentId)?.current.getProps()
                }
            })
        });
    }

    const onSuccessfulUpdate = () => {
        setSiteEdited(false);
    }
    const { mutate: updateSite, isLoading: isUpdatingSite } = useUpdateSite(onSuccessfulUpdate);
    
    const handlePublishEditButtonClicked = () => {
        if (isEditing) {
            handleSiteUpdate();
        } else {
            publishButtonClick();
        }
    }

    const handleSiteUpdate = () => {
        const content = getExportableSiteComponents();
        updateSite({
            siteId: siteId!,
            content
        });
    }

    const publishButtonClick = () => {
        setModalOpened(true);
        FireBaseService.logButtonClick(analyticsEvents.editorPublishButton);
    }

    const onHelpClicked = () => {
        var defaultMessage = "Olá, gostaria de ajuda para criar o meu site";
        window.open(`https://wa.me/5511978250756?text=${defaultMessage}`);
    }

    const onHomeClicked = () => {
        Alert.alert(
            'Ir para home',
            siteEdited ? 'Você tem alterações não salvas, tem certeza que deseja sair antes de salva-las?' :
            'Tem certeza que deseja parar de editar o seu site?',
            [
                {
                    text: 'Sim',
                    buttonType: 'secondary',
                    onPress: () => navigate('/sites')
                },
                {
                    text: 'Não'
                }
            ]
        );
    }
    const onSiteCreation = (createSiteResponse: CreateSiteResponse) => {
        setSiteEditorState(SiteEditorState.SITE_CREATED_ANIMATION);
        setCurrentSiteId(createSiteResponse.siteId);
    }

    const { mutate: createSite } = useCreateSite(onSiteCreation);
    
    const createSiteAsync = async () => {
        // Sleep needed to prevent strange behaviours
        await new Promise(resolve => setTimeout(resolve, 100));

        const content = getExportableSiteComponents();
        createSite({
            name: location.state.name,
            content: content
        });
    }

    const regenerateSiteStyle = () => {
        setSiteEdited(true);
        setTheme(ThemeUtils.createSiteTheme(createCustomTheme()));
        siteComponents.map((component) => {
            getRef(component.componentId)?.current.regenerateStyle();
        });
    }

    const regenerateSiteContent = () => {
        setSiteEdited(true);
        siteComponents.map((component) => {
            getRef(component.componentId)?.current.regenerateContent();
        });
    }

    const regenerateSite = () => {
        setSiteEdited(true);
        setTheme(ThemeUtils.createSiteTheme(createCustomTheme()));
        siteComponents.map((component) => {
            getRef(component.componentId)?.current.regenerateContent();
            getRef(component.componentId)?.current.regenerateStyle();
        });
    }

    useEffect(() => {
        if(!siteId && !isCreatingSiteComponent && siteEditorState == SiteEditorState.CREATING_SITE_COMPONENTS)
            createSiteAsync();
        
    }, [isCreatingSiteComponent, siteId])

    const isEditing = siteEdited || (existingSite?.site.publishmentDetails.status != undefined &&
            existingSite?.site.publishmentDetails.status != "UNPUBLISHED") || siteJustPublished;

    return(
        <MainContainer>
            {(siteEditorState == SiteEditorState.CREATING_SITE_STYLES ||
            siteEditorState == SiteEditorState.CREATING_SITE_COMPONENTS ||
            siteEditorState == SiteEditorState.SITE_CREATED_ANIMATION) && (
                <CreatingSiteScreen/>
            )}
            {(siteEditorState == SiteEditorState.LOADING_SITE) && (
                <LoadingSiteScreen/>
            )}
            {!!theme &&
                <>
                    {refreshedSiteState && siteComponents.map((component) => (component.component))}
                    <PublishOutsideContainer>
                        <PublishContainer>
                            <StandardButton
                                fitContent
                                text={isEditing ? 'Salvar Alterações': 'Publicar'}
                                onClick={handlePublishEditButtonClicked}
                                loading={isCreatingSiteComponent || isUpdatingSite}
                            />
                            <Divider/>
                            <StandardButton
                                fitContent
                                buttonType='secondary'
                                text={editingSite ? 'Esconder edição' : 'Editar'}
                                onClick={() => setEditingSite(prev => !prev)}
                            />
                            <Regenerate
                                tooltip='Regerar'
                                size='medium'
                                Icon={RegenerateIcon}
                                fixed={true}
                                buttons={[
                                    {
                                        text: 'Regerar Estilo',
                                        onPress: () => regenerateSiteStyle()
                                    },
                                    {
                                        text: 'Regerar Conteúdo',
                                        onPress: () => regenerateSiteContent()
                                    },
                                    {
                                        text: 'Regerar Site',
                                        onPress: () => regenerateSite()
                                    }
                                ]}
                            />
                            {/* <Tooltip title="Configurar" placement="top" arrow>
                                <Tool>
                                    <StyleIcon/>
                                </Tool>
                            </Tooltip> */}
                            <Tooltip title="Ajuda" placement="top" arrow>
                                <Tool onClick={onHelpClicked}>
                                    <HelpIcon/>
                                </Tool>
                            </Tooltip>
                            {!!auth && (
                                <Tooltip title="Dashboard" placement="top" arrow>
                                    <Tool onClick={onHomeClicked}>
                                        <HomeIcon/>
                                    </Tool>
                                </Tooltip>
                            )}
                        </PublishContainer>
                    </PublishOutsideContainer>
                    <PublishMobileOutsideContainer>
                        <PublishButtonMobileContainer>
                            <FullWidthButton
                                fitContent
                                text={isEditing ? 'Salvar Alterações': 'Publicar'}
                                onClick={handlePublishEditButtonClicked}
                                loading={isCreatingSiteComponent || isUpdatingSite}
                            />
                        </PublishButtonMobileContainer>
                        <PublishMobileContainer>
                            <PublishMobileGrid>
                                {/* <MobileSectionContainer>
                                    <StyleIcon/>
                                    <MobileSectionTitle>Estilo</MobileSectionTitle>
                                </MobileSectionContainer> */}
                                <MobileSectionContainer onClick={() => setEditingSite(prev => !prev)}>
                                    {editingSite ? (
                                        <>
                                            <StopEditIcon/>
                                            <MobileSectionTitle>{'Esconder\nEdição'}</MobileSectionTitle>
                                        </>
                                    ) : (
                                        <>
                                            <EditIcon/>
                                            <MobileSectionTitle>Editar</MobileSectionTitle>
                                        </>
                                    )}
                                </MobileSectionContainer>
                                {/* <MobileSectionContainer>
                                    <RegenerateIcon/>
                                    <MobileSectionTitle>Reconstruir com IA</MobileSectionTitle>
                                </MobileSectionContainer> */}
                                <MobileSectionContainer>
                                        <Regenerate
                                            tooltip='Regerar'
                                            size='medium'
                                            noIconPadding={true}
                                            Icon={RegenerateIcon}
                                            fixed={true}
                                            buttons={[
                                                {
                                                    text: 'Regerar Estilo',
                                                    onPress: () => regenerateSiteStyle()
                                                },
                                                {
                                                    text: 'Regerar Conteúdo',
                                                    onPress: () => regenerateSiteContent()
                                                },
                                                {
                                                    text: 'Regerar Site',
                                                    onPress: () => regenerateSite()
                                                }
                                            ]}
                                        />
                                        <MobileSectionTitle>Reconstruir com IA</MobileSectionTitle>
                                </MobileSectionContainer>
                                <MobileSectionContainer onClick={onHelpClicked}>
                                    <HelpIcon/>
                                    <MobileSectionTitle>Ajuda</MobileSectionTitle>
                                </MobileSectionContainer>
                                {!!auth && (
                                    <MobileSectionContainer onClick={onHomeClicked}>
                                        <HomeIcon/>
                                        <MobileSectionTitle>Home</MobileSectionTitle>
                                    </MobileSectionContainer>
                                )}
                            </PublishMobileGrid>
                        </PublishMobileContainer>
                    </PublishMobileOutsideContainer>
                </>
            }
            {!!modalOpened && (
                <PublishmentModal
                    siteId={siteId}
                    setCurrentSiteId={setCurrentSiteId}
                    businessName={creationOptions!.name}
                    onClose={() => {
                        setModalOpened(false);
                    }}
                />
            )}
        </MainContainer>
    )
}

export default SiteEditor;