import { Moment } from 'moment';
import moment from 'moment-timezone';

import { DaysEnum } from '../interfaces/DaysEnum';
import { WorkHours } from '../interfaces/WorkHours';
import { WorkTimeSlot } from '../interfaces/WorkTimeSlot';

export const GetDateFromHourString = (hour: string): Moment => {
    return moment(hour, 'HH:mm').tz('America/Sao_Paulo');
}

export const GetHourStringFromDate = (date: Moment): string => {
    return moment(date, "HH:mm").format("HH:mm");
}

export const GetHourPeriodString = (start: Moment, end: Moment): string => {
    return `${GetHourStringFromDate(start)} - ${GetHourStringFromDate(end)}`;
}

export const AddHourToDate = (current: Moment, hours: number): Moment => {
    let date = moment(current.toISOString());
    date = date.add(hours, 'hours');
    return GetDateFromHourString(GetHourStringFromDate(date));
}

export const GetOuterRangeHours = (timeSlots: WorkTimeSlot[]): WorkTimeSlot[] => {
    if (timeSlots.length === 0) {
        return [{
            startTime: '00:00',
            endTime: '23:59'
        }]
    }

    const result: WorkTimeSlot[] = [];
    timeSlots.forEach((slot, i) => {
        if (i === 0 && slot.startTime !== '00:00') {
            result.push({
            startTime: '00:00',
            endTime: slot.startTime
            });
        }

        if (i !== 0 && timeSlots[i - 1].endTime !== slot.startTime) {
            result.push({
                startTime: timeSlots[i - 1].endTime,
                endTime: slot.startTime
            });
        }
    });

    if (timeSlots[timeSlots.length - 1].endTime !== '23:59') {
        result.push({
            startTime: timeSlots[timeSlots.length - 1].endTime,
            endTime: '23:59'
        });
    }

    return result;
}

export const GetCurrentDayEnumFromdate = (date: Moment): DaysEnum => {
    const days = [
        DaysEnum.SUNDAY,
        DaysEnum.MONDAY,
        DaysEnum.TUESDAY,
        DaysEnum.WEDNESDAY,
        DaysEnum.THURSDAY,
        DaysEnum.FRIDAY,
        DaysEnum.SATURDAY
    ];
    return days[date.day()];
}

export const GetCurrentDateWorkingHours = (date: Moment, workHours: WorkHours[]): WorkTimeSlot[] => {
    const day = GetCurrentDayEnumFromdate(date);
    const workHour = workHours.filter((workHour) => workHour.day === day);
    return workHour[0].timeSlots!;
}

export const GetWeeksMonthCalendar = (date: Moment): Moment[][] => {
    const year = date.year();
    const firstDayOfMonth = moment(new Date(year, date.month(), 1)).day();
    let counter = 0 - firstDayOfMonth;
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            counter++;
            return moment(new Date(year, date.month(), counter));
        });
    });
    return daysMatrix;
}

export const GetDaysOfWeek = (date: Moment): Moment[] => {
    const currentDate = date.clone().toDate();
    const week = new Array(); 
    currentDate.setDate((currentDate.getDate() - currentDate.getDay() +1));
    for (var i = 0; i < 7; i++) {
        week.push(
            moment(currentDate)
        ); 
        currentDate.setDate(currentDate.getDate() +1);
    }
    return week; 
}

export const GetMonthString = (date: Moment): string => {
    const month = date.month();
    switch(month) {
        case 0:
            return 'Jan';
        case 1:
            return 'Fev';
        case 2:
            return 'Mar';
        case 3:
            return 'Abr';
        case 4:
            return 'Mai';
        case 5:
            return 'Jun';
        case 6:
            return 'Jul';
        case 7:
            return 'Ago';
        case 8:
            return 'Set';
        case 9:
            return 'Out';
        case 10:
            return 'Nov';
        case 11:
            return 'Dez';
        default:
            return '';
    }
}

export const GetMonthStringComplete = (date: Moment): string => {
    const month = date.month();
    switch(month) {
        case 0:
            return 'Janeiro';
        case 1:
            return 'Fevereiro';
        case 2:
            return 'Março';
        case 3:
            return 'Abril';
        case 4:
            return 'Maio';
        case 5:
            return 'Junho';
        case 6:
            return 'Julho';
        case 7:
            return 'Agosto';
        case 8:
            return 'Setembro';
        case 9:
            return 'Outubro';
        case 10:
            return 'Novembro';
        case 11:
            return 'Dezembro';
        default:
            return '';
    }
}

export const GetDayString = (date: Moment): string => {
    const day = date.day();
    switch(day) {
        case 0:
            return 'Dom';
        case 1:
            return 'Seg';
        case 2:
            return 'Ter';
        case 3:
            return 'Qua';
        case 4:
            return 'Qui';
        case 5:
            return 'Sex';
        case 6:
            return 'Sab';
        default:
            return '';
    }
}

export const IsFirstDayOfMonth = (date: Moment): boolean => {
    if (date.date() === 1)
        return true;

    return false;
}

export const IsLastDayOfMonth = (date: Moment): boolean => {
    if (date.date() === date.clone().endOf('month').date())
        return true;

    return false;
}

export const IsTheSameDay = (date: Moment, otherDate: Moment): boolean => {
    return date.year() === otherDate.year() &&
        date.dayOfYear() === otherDate.dayOfYear();
        
}

export const DefaultMoment = moment.locale("pt-br");