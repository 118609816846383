import { useMutation } from 'react-query';
import AxiosConfig from '../../configs/AxiosConfig';
import { useSetRecoilState } from 'recoil';
import { siteStockImagesState } from '../../state/siteEditorState';

export const useGetImagesQueryKey = ['getImages'];
export const useGetImages = () => {
    const  setStockImages = useSetRecoilState(siteStockImagesState);

    return useMutation({
        retry: false,
        mutationKey: useGetImagesQueryKey,
        mutationFn: async (category: string) => {
            return AxiosConfig.getAxiosInstance()
                .get(
                    '/api/v1/site/images/generate',
                    {
                        params: {
                            context: category,
                            quantity: 60
                        }
                    }
                )
                .then((res) => res.data)
                .catch((error) => {
                    console.log(error);
                })
        },
        onSuccess: (data) => {
            setStockImages(data?.images ?? [])
        }
    })
}