import { useRef } from 'react';

import DefaultLoadingButton from '../../../../../components/DefaultLoadingButton/DefaultLoadingButton';
import WorkHourForm from '../../../../../components/WorkHourForm/WorkHourForm';
import styles from './StaffWorkingHours.module.css';

function StaffWorkingHours() {
    const workHourForm = useRef<any>(null);

    return (
        <div className={styles.main}>
            <div className={styles.card}>
                <WorkHourForm ref={workHourForm}/>
                <div className={styles.buttonContainer}>
                    <DefaultLoadingButton
                        className={styles.button}
                        text='Salvar Alterações'
                        loading={false}
                        type='submit'
                        onClick={() => console.log(workHourForm?.current?.getWorkHours())}
                    />
                </div>
            </div>
        </div>
    );
}
  
export default StaffWorkingHours;