import { Drawer } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedSiteListIndexState } from "../../../../state/siteListState";
import { SiteCardSection, SiteCardSectionIcon, SiteCardSectionText } from "./SitesListDrawer.style";
import { SiteListItem } from "../../../../interfaces/payment/GetSitesListResponse";
import Domain from "../../../../interfaces/Domain";
import BaseConfig from "../../../../configs/BaseConfig";
import PubStatus from "../../../../interfaces/PublishmentStatusEnum";
import { Toast } from "../../../../components/Toast/Toast";
import * as Clipboard from 'clipboard-polyfill';

interface Props {
    sitesList?: SiteListItem[];
}

export default function SitesListDrawer(props: Props) {
    const { sitesList } = props;
    const [selectedSiteIndex, setSelectedSiteIndex] = useRecoilState(selectedSiteListIndexState);

    const getSiteUrl = (publishmentStatus: PubStatus, siteId: string, domains: Domain[]) => {
        if (publishmentStatus === 'PUBLISHED')
            return `https://${domains[0].baseUrl}.${BaseConfig.config.baseSiteUrl}`;
        return getSitePreviewUrl(siteId);
    }

    const getSitePreviewUrl = (siteId: string) => {
        return `https://${BaseConfig.config.baseSiteUrl}/temporary/${siteId}?ref=${Date.now()}`; 
    }

    const handleCopySite = () => {
        const site = sitesList![selectedSiteIndex!];
        Clipboard.writeText(getSiteUrl(site.publishmentStatus, site.id, site.domains))
            .then(() => {
                Toast.showToast(
                    'Link copiado para a área de transferência',
                    'info',
                    false
                );
                setSelectedSiteIndex(undefined)
            })
            .catch((error) => {
                setSelectedSiteIndex(undefined)
            });
    };

    return(
        <Drawer
            anchor='bottom'
            open={selectedSiteIndex != undefined}
            onClose={() => setSelectedSiteIndex(undefined)}
        >
            <>
                <SiteCardSection onClick={() => setSelectedSiteIndex(undefined)}>
                    <SiteCardSectionIcon name='IoMdCloseCircle'/>
                    <SiteCardSectionText>Fechar</SiteCardSectionText>
                </SiteCardSection>
                <SiteCardSection onClick={handleCopySite}>
                    <SiteCardSectionIcon name='FaCopy'/>
                    <SiteCardSectionText>Copiar Link</SiteCardSectionText>
                </SiteCardSection>
                {/* <SiteCardSection>
                    <SiteCardSectionIcon name='FaCog'/>
                    <SiteCardSectionText>Configurar</SiteCardSectionText>
                </SiteCardSection> */}
            </>
        </Drawer>
    )
}