import moment from 'moment';

import { Typography } from '@mui/material';

import NotificationButton from './components/NotificationButton/NotificationButton';
import styles from './NotificationsPage.module.css';

function NotificationsPage() {
    return (
      <div className={styles.main}>
        <Typography className={styles.header} variant="h5">
            Notificações
        </Typography>
        <div>
            <NotificationButton date={moment()} title='Novo agendamento' subtitle='Corte de cabelo'/>
            <NotificationButton date={moment()} title='Novo agendamento' subtitle='Corte de cabelo'/>
            <NotificationButton date={moment()} title='Novo agendamento' subtitle='Corte de cabelo'/>
        </div>
      </div>
    );
}

export default NotificationsPage;