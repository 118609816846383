import styled from "styled-components";
import { themes } from "../../themes/themes";
import StandardButton from "../../components/Button/Button";

interface Props{
    isEmpty?: boolean;
}

const { bgSitesDashBoard, purple, dashBoardFont, primaryFont } = themes;

export const MainContainer = styled.div`
    background-color: ${bgSitesDashBoard};
    min-height: 100vh;
    width: 100%;
`;

export const InnerContainer = styled.div`
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-top: 6rem;

    @media (max-width: 780px){
        margin-left: 24px;
        margin-right: 24px;
    }
`;

export const Title = styled.h2`
    color: ${purple};
    font-size: 1.563rem;
    font-family: ${dashBoardFont};
    font-weight: 700;
    line-height: 1.25rem;
    word-wrap: break-word;
    margin-top: 50px;
`;

export const Content = styled.div`
    margin-top: 60px;
`

export const PersonalDataContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const PersonalDataTitle = styled.h3`
    color: ${purple};
    font-size: 1rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    margin-bottom: 20px;
`

export const SkeletonDiv = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`


export const ErrorMessageContainer = styled.div`
    margin: 24px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const ErrorMessage = styled.h2`
   font-family: ${themes.dashBoardFont};
   text-align: center;
   font-size: 1.25rem;
   color: ${themes.onyx};
   margin-bottom: 24px;
`;

export const RetryButton = styled(StandardButton)`
    max-width: 500px;
`;
