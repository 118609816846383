import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { ProtectedRoute } from './helpers/ProtectedRoutesHelper';
import BusinessesListPage from './pages/businessesListPage/BusinessesListPage';
import CreateBusinessPage from './pages/siteCreationPage/SiteCreationPage';
import CreateEventPage from './pages/createEventPage/CreateEventPage';
import EventDetailsPage from './pages/eventDetailsPage/EventDetailsPage';
import ForgotPasswordPage from './pages/forgotPasswordPage/ForgotPasswordPage';
import LoginPage from './pages/loginPage/LoginPage';
import NotificationsPage from './pages/notificationsPage/NotificationsPage';
import PaymentsDetails from './pages/paymentDetailsPage/PaymentsDetails';
import ProfilePage from './pages/profilePage/ProfilePage';
import RegisterPage from './pages/registerPage/RegisterPage';
import ResetPasswordPage from './pages/resetPasswordPage/ResetPasswordPage';
import CreateServicePage from './pages/service/create/CreateServicePage';
import EditServicePage from './pages/service/edit/EditServicePage';
import CreateStaffPage from './pages/staff/create/CreateStaffPage';
import EditStaffPage from './pages/staff/edit/EditStaffPage';
import DefaultDesign from './components/DefaultDesign/DefaultDesign';
import YourSitesPage from './pages/yourSitesPage/YourSitesPage';
import SettingsPage from './pages/settingsPage/SettingsPage';
import DashboardBaseV2 from './pages/DashboardBaseV2/DashboardBase';
import SiteEditor from './pages/siteEditor/SiteEditor';
import AxiosConfig from './configs/AxiosConfig';
import { useRecoilState } from 'recoil';
import { authState } from './state/authState';
import HomePage from './pages/homePage/HomePage';
import './extensions/StringExtensions';
import { useEffect } from 'react';
import FireBaseService from './infrastructure/firebaseService';

const queryClient = new QueryClient()
  
export default function App() {
  const navigate = useNavigate();
  const [_auth, setAuth] = useRecoilState(authState);
  AxiosConfig.initializeHooks(navigate, setAuth);
  AxiosConfig.init();
  const location = useLocation();

  useEffect(() => {
    const logPageView = (path: string) => {
      FireBaseService.logPathChange(path);
    };

    logPageView(location.pathname);
  }, [location.pathname]);
  
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="site/editor" element={<SiteEditor/>}/>
          <Route element={<DefaultDesign/>}>
            <Route path="auth" element={<LoginPage/>}/>
            <Route path="auth/forgot" element={<ForgotPasswordPage/>}/>
            <Route path="auth/recover" element={<ResetPasswordPage/>}/>
            <Route path="auth/register" element={<RegisterPage/>}/>
            <Route path="sites/create" element={<CreateBusinessPage/>}/>
            <Route path="business/create" element={<CreateBusinessPage/>}/> {/*TODO: Remover esse antigo endpoint*/}
          </Route>
          <Route element={<ProtectedRoute/>}>
            <Route element={<DashboardBaseV2/>}>
              <Route path="sites" element={<YourSitesPage/>}/>
              <Route path="settings" element={<SettingsPage/>}/>
              <Route path="/profile" element={<ProfilePage/>}/>
              <Route path="/businesses" element={<BusinessesListPage/>}/>
              <Route path="/payments/:id" element={<PaymentsDetails/>}/>
              <Route path="/notifications" element={<NotificationsPage/>}/>
              <Route path="/events/:id" element={<EventDetailsPage/>}/>
              <Route path="/events/create" element={<CreateEventPage/>}/>
              <Route path="/staff/create" element={<CreateStaffPage/>}/>
              <Route path="/staff/:id" element={<EditStaffPage/>}/>
              <Route path="/services/create" element={<CreateServicePage/>}/>
              <Route path="/services/:id" element={<EditServicePage/>}/>
            </Route>
          </Route>
        </Routes>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}
