import Steps from "./Steps";

const SiteSteps: Steps[] = [
    {
        index: 0,
        type: 'Header'
    },
    {
        index: 1,
        type: 'Banner'
    },
    {
        index: 2,
        type: 'TextAndImage'
    },
    {
        index: 3,
        type: 'List'
    },
    {
        index: 4,
        type: 'Testimonials'
    },
    {
        index: 5,
        type: 'Gallery'
    },
    {
        index: 6,
        type: 'Location'
    },
    {
        index: 7,
        type: 'Footer'
    }
];

export default SiteSteps;