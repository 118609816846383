import { Moment } from 'moment';
import { ChangeEvent, createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ButtonBase, FormControlLabel, FormGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { AddHourToDate, GetDateFromHourString } from '../../../helpers/DateHelper';
import { DaysEnum } from '../../../interfaces/DaysEnum';
import { WorkHours } from '../../../interfaces/WorkHours';
import { WorkTimeSlot } from '../../../interfaces/WorkTimeSlot';
import RangeInput from './RangeInput';
import styles from './WorkHourDay.module.css';

interface Props {
    workHours: WorkHours;
}

export interface TimeSlot {
    errorMessage?: string;
    inputError?: boolean;
    endTime: Moment | null;
    startTime: Moment | null;
}

const getDefaultHourSlot = () => {
    return [
        { startTime: GetDateFromHourString('08:00'), endTime: GetDateFromHourString('12:00') },
        { startTime: GetDateFromHourString('13:00'), endTime: GetDateFromHourString('18:00') },
    ];
}

const GetInitialTimeSlots = (workHours: WorkHours): TimeSlot[] => {
    if (workHours.timeSlots === undefined) {
        if (!IsCheckDisabled(workHours)) {
            return [...getDefaultHourSlot()];
        } else {
            return [];
        }
    }
    
    const initialTimeSlots = workHours.timeSlots.map((timeSlot) => {
        return {
            endTime: GetDateFromHourString(timeSlot.endTime),
            startTime: GetDateFromHourString(timeSlot.startTime)
        }
    });
    return initialTimeSlots;
}

const IsCheckDisabled = (workHours: WorkHours): boolean => {
    if (!!workHours.timeSlots && workHours.timeSlots.length === 0) {
        return true;
    }

    switch (workHours.day) {
        case DaysEnum.SATURDAY:
            return true;
        case DaysEnum.SUNDAY:
            return true;
        default:
            return false;
    }
}

const WorkHourDay = forwardRef<any, Props>((props, ref) => {
    const { workHours } = props;
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>(GetInitialTimeSlots(workHours));
    const [disabled, setDisabled] = useState<boolean>(IsCheckDisabled(workHours));
    const refs = useRef<any>([]);
    refs.current = timeSlots.map((_e, i) => refs.current[i] ?? createRef());

    const GetDayString = (day: DaysEnum): string => {
        switch (day) {
            case DaysEnum.MONDAY:
                return "SEGUNDA";
            case DaysEnum.TUESDAY:
                return "TERÇA";
            case DaysEnum.WEDNESDAY:
                return "QUARTA";
            case DaysEnum.THURSDAY:
                return "QUINTA";
            case DaysEnum.FRIDAY:
                return "SEXTA";
            case DaysEnum.SATURDAY:
                return "SÁBADO";
            case DaysEnum.SUNDAY:
                return "DOMINGO";
            default:
                return "";
        }
    }

    const updateIntervalStartTime = (index: number, startTime: Moment) => {
        const newArray = [...timeSlots];
        newArray[index].startTime = startTime;
        setTimeSlots(newArray);
    }

    const updateIntervalEndTime = (index: number, endTime: Moment) => {
        const newArray = [...timeSlots];
        newArray[index].endTime = endTime;
        setTimeSlots(newArray);
    }

    const handleIntervalErase = (index: number) => {
        if (timeSlots.length == 1)
            setDisabled(true);
        setTimeSlots(timeSlots.filter((_slot, i) => i !== index));
    }

    const handleAddInterval = () => {
        if (disabled) 
            setDisabled(false);

        if (timeSlots.length == 0) {
            setTimeSlots([...timeSlots, ...getDefaultHourSlot()]);
        } else {
            const lastDate = timeSlots[timeSlots.length - 1];
            const start = AddHourToDate(lastDate.endTime!, 1);
            const end = AddHourToDate(lastDate.endTime!, 2);
            setTimeSlots([...timeSlots, {
                startTime: start,
                endTime: end
            }]);
        }
    }

    const handleCheckboxButtonPressed = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked && timeSlots.length == 0) {
            setTimeSlots([...timeSlots, ...getDefaultHourSlot()]);
        }

        setDisabled(!e.target.checked)
    }

    useImperativeHandle(ref, () => ({
        getWorkHours(): WorkHours | null {
            if (disabled) {
                return {
                    day: workHours.day,
                    timeSlots: []
                };
            }

            let slots: WorkTimeSlot[] | null = [];
            refs.current.every((ref: any) => {
                const current = ref.current.getRange();
                if (current == null) {
                    slots = null;
                    return false;
                }
                slots?.push(ref.current.getRange());
                return true;
            });

            if (slots == null) {
                return null;
            }

            return {
                day: workHours.day,
                timeSlots: slots
            };
        }
    }));

    return (
        <div className={styles.mainContainer}>
            <FormGroup className={styles.checkBoxSection}>
                <FormControlLabel
                    control={<Checkbox
                        checked={!disabled}
                        onChange={handleCheckboxButtonPressed}
                    />}
                    label={GetDayString(workHours.day)}
                />
            </FormGroup>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={styles.hourColumnContainer}>
                    {!disabled && timeSlots.map((slot, index) => (
                        <div key={index} className={styles.hourSection}>
                            <RangeInput
                                restOfTimeSlots={timeSlots.filter((_: any, i: number) => i !== index)}
                                timeSlot={slot}
                                onStartChange={(newValue: any) => updateIntervalStartTime(index, newValue)}
                                onEndChange={(newValue: any) => updateIntervalEndTime(index, newValue)}
                                ref={refs.current[index]}
                            />
                            <ButtonBase className={styles.eraseButtonContainer} onClick={() => handleIntervalErase(index)}>
                                <DeleteForeverIcon/>
                            </ButtonBase>
                        </div>
                    ))}
                </div>
            </LocalizationProvider>
            <div className={styles.addButtonContainer}>
                <ButtonBase className={styles.addButton} onClick={() => handleAddInterval()}>
                    <AddIcon/>
                </ButtonBase>
            </div>
        </div>
    );
});
  
export default WorkHourDay;