import { useState } from "react";
import {
    DeleteIconContainer,
    Image,
    ImageContainer,
    ImageWrapper,
    NoImageContainer,
    NoImageIcon,
    Skeleton,
    TrashIcon
} from "./SquaredImage.style";

interface Props {
    src: string;
    selected?: boolean;
    className?: string;
    onImageSelected?: (imageUrl: string) => void;
    onRemoveImage?: (imageUrl: string) => void;
}

const SquaredImage = (props: Props) => {
    const { className, src, selected, onImageSelected, onRemoveImage } = props;
    const [loading, setLoading] = useState(src.isNullOrEmpty() ? false : true);

    return (
        <ImageWrapper className={className}>
            <ImageContainer selected={selected}>
                {loading && (
                    <Skeleton/>
                )}
                {!!src ? (
                    <Image
                        src={src}
                        onLoad={() => setLoading(false)}
                        onClick={() => !!onImageSelected && onImageSelected(src)}
                    />
                ) : (
                    <NoImageContainer>
                        <NoImageIcon/>
                    </NoImageContainer>
                )}

                {selected && !!onRemoveImage && (
                    <DeleteIconContainer onClick={() => onRemoveImage(src)}>
                        <TrashIcon/>
                    </DeleteIconContainer>
                )}
            </ImageContainer>
        </ImageWrapper>
    )
};

export default SquaredImage;