import { useEffect } from "react";
import { BackButton, Backdrop, ComponentContainer, ComponentDescription, ComponentTile, MainContainer, Modal, ModalInner, ModalTitle, ModalTopContainer } from "./AddSectionModal.style";
import { CompanySiteComponentType } from "../../../interface/CompanySiteComponent";
import { useRegisterRef } from "../../../utils/siteRefManager";
import { useRecoilState, useSetRecoilState } from "recoil";
import { siteComponentsState, siteEditedState } from "../../../../../state/siteEditorState";
import { createComponent } from "../../../utils/siteUtils";

interface Props {
    insertIndex: number;
    onModalClose: () => void;
}

export default function AddSectionModal(props: Props) {
    const { insertIndex, onModalClose } = props;
    const registerRef = useRegisterRef();
    const [siteComponents, setSiteComponents] = useRecoilState(siteComponentsState);
    const setSiteEditedState = useSetRecoilState(siteEditedState);
    
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
          document.body.style.overflow = 'visible';
        };
    }, []);

    const handleAddNewSection = (type: CompanySiteComponentType) => {
        const newComponent = createComponent(type, registerRef);
        setSiteEditedState(true);
        setSiteComponents(prev => ([
            ...prev.slice(0, insertIndex),
            newComponent,
            ...prev.slice(insertIndex)
        ]));
        onModalClose();
    }

    return (
        <MainContainer>
            <Backdrop onClick={onModalClose}/>
            <Modal>
                <ModalTopContainer>
                    <ModalTitle>Novo Componente</ModalTitle>
                    <BackButton onClick={onModalClose}>Fechar</BackButton>
                </ModalTopContainer>
                <ModalInner>
                    <ComponentContainer firstItem={true} onClick={() => handleAddNewSection('TextAndImage')}>
                        <ComponentTile>Texto e Imagem</ComponentTile>
                        <ComponentDescription>Seção que é divida entre um texto e uma imagem</ComponentDescription>
                    </ComponentContainer>
                    <ComponentContainer onClick={() => handleAddNewSection('Banner')}>
                        <ComponentTile>Banner</ComponentTile>
                        <ComponentDescription>Seção que é composta por título e descrição opcionalmente uma imagem de fundo</ComponentDescription>
                    </ComponentContainer>
                    <ComponentContainer onClick={() => handleAddNewSection('List')}>
                        <ComponentTile>Lista</ComponentTile>
                        <ComponentDescription>Seção que é uma lista de imagens, títulos e descrição</ComponentDescription>
                    </ComponentContainer>
                    <ComponentContainer onClick={() => handleAddNewSection('Testimonials')}>
                        <ComponentTile>Testemunhos</ComponentTile>
                        <ComponentDescription>Seção de testemunhos de clientes</ComponentDescription>
                    </ComponentContainer>
                    <ComponentContainer onClick={() => handleAddNewSection('Instagram')}>
                        <ComponentTile>Instagram</ComponentTile>
                        <ComponentDescription>Seção que contém um feed de um perfil do Instagram</ComponentDescription>
                    </ComponentContainer>
                    <ComponentContainer onClick={() => handleAddNewSection('Video')}>
                        <ComponentTile>Video</ComponentTile>
                        <ComponentDescription>Seção que contém um video do Youtube</ComponentDescription>
                    </ComponentContainer>
                    <ComponentContainer onClick={() => handleAddNewSection('Gallery')}>
                        <ComponentTile>Galeria</ComponentTile>
                        <ComponentDescription>Seção que é uma galeria de imagens</ComponentDescription>
                    </ComponentContainer>
                    <ComponentContainer onClick={() => handleAddNewSection('Location')}>
                        <ComponentTile>Mapa</ComponentTile>
                        <ComponentDescription>Seção que contém um mapa</ComponentDescription>
                    </ComponentContainer>
                </ModalInner>
            </Modal>
        </MainContainer>
    );
}