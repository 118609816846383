import { useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { FormControl, FormHelperText, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import AvatarDropdown from '../../../../components/AvatarDropdown/AvatarDropdown';
import DefaultButton from '../../../../components/DefaultButton/DefaultButton';
import { DaysEnum } from '../../../../interfaces/DaysEnum';
import { StaffMember } from '../../../../interfaces/staff/StaffMember';
import styles from './NewTimeOff.module.css';

function NewTimeOff() {
    const staffList: StaffMember[] = [
        {
            id: 'staffId',
            businessId: 'string',
            name: 'João Santos',
            email: 'string',
            phone: 'string',
            role: 'Barbeiro',
            workingHours: [
                {
                    day: DaysEnum.MONDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.TUESDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.THURSDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.FRIDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.WEDNESDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.SATURDAY,
                    timeSlots: []
                },
                {
                    day: DaysEnum.SUNDAY,
                    timeSlots: []
                }
            ]
        },
        {
            id: 'staffId2',
            businessId: 'string',
            name: 'Osvaldo Barros',
            email: 'string',
            phone: 'string',
            role: 'Cabelereiro',
            workingHours: [
                {
                    day: DaysEnum.MONDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.TUESDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.THURSDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.FRIDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.WEDNESDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        },
                        {
                            startTime: '13:00',
                            endTime: '18:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.SATURDAY,
                    timeSlots: []
                },
                {
                    day: DaysEnum.SUNDAY,
                    timeSlots: []
                }
            ]
        },
        {
            id: 'staffId3',
            businessId: 'string',
            name: 'Rodrigo Alves',
            email: 'string',
            phone: 'string',
            role: 'Cabelereiro',
            workingHours: [
                {
                    day: DaysEnum.MONDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.TUESDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.THURSDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.FRIDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.WEDNESDAY,
                    timeSlots: [
                        {
                            startTime: '08:00',
                            endTime: '12:00'
                        }
                    ]
                },
                {
                    day: DaysEnum.SATURDAY,
                    timeSlots: []
                },
                {
                    day: DaysEnum.SUNDAY,
                    timeSlots: []
                }
            ]
        }
    ];

    const validationSchema = yup.object().shape({
        description: yup
          .string()
          .optional(),
        
        startDate: yup
            .date()
            .nullable()
            .required("Informe o horário de início"),
        
        endDate: yup
            .date()
            .nullable()
            .required("Informe o horário de fim")
            .min(
                yup.ref('startDate'),
                "Anterior ao horário de início"
            ),
        
        staffId: yup
            .string()
            .required("Escolha um membro de equipe")
    });

    const formik = useFormik({
        initialValues: {
            description: '',
            startDate: moment(),
            endDate: moment().add(1, 'hour'),
            staffId: ''
        },
        validationSchema: validationSchema,
        onSubmit: () => {

        },
    });

    return (
        <div className={styles.main}>
            <div className={styles.card}>
                <form className={styles.form} onSubmit={formik.handleSubmit}>
                    <AvatarDropdown
                        inputLabel={'Membro da Equipe *'}
                        dataList={staffList}
                        textProperty={'name'}
                        valueProperty={'id'}
                        avatarProperty={'imageUrl'}
                        selectedData={formik.values.staffId}
                        onChange={(newValue) => {
                            formik.setFieldValue(
                                "staffId",
                                newValue
                            );
                        }}
                        error={formik.errors.staffId}
                    />  
                    <div className={styles.inputDateContainer}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormControl error={!!formik.errors.startDate}>
                                <DateTimePicker
                                    renderInput={(props) => (
                                        <TextField
                                            name="startDate"
                                            required
                                            {...props}
                                            error={!!formik.errors.startDate}
                                        />
                                    )}
                                    ampm={false}
                                    label="Horário de início"
                                    value={formik.values?.startDate}
                                    onChange={(newValue) => {
                                        formik.setFieldValue(
                                            "startDate",
                                            newValue
                                        );
                                    }}
                                />
                                {!!formik.errors.startDate && (
                                    <FormHelperText>{`${formik.errors.startDate}`}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl error={!!formik.errors.endDate}>
                                <DateTimePicker
                                    renderInput={(props) => (
                                        <TextField
                                            name="endDate"
                                            required
                                            {...props}
                                            error={!!formik.errors.endDate}
                                        />
                                    )}
                                    ampm={false}
                                    label="Horário de fim"
                                    value={formik.values?.endDate}
                                    onChange={(newValue) => {
                                        formik.setFieldValue(
                                            "endDate",
                                            newValue
                                        );
                                    }}
                                />
                                {!!formik.errors.endDate && (
                                    <FormHelperText>{`${formik.errors.endDate}`}</FormHelperText>
                                )}
                            </FormControl>
                        </LocalizationProvider>
                    </div>
                    <TextField
                        className={styles.input}
                        label="Descrição"
                        multiline
                        maxRows={4}
                        id="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />
                    <div className={styles.content}>
                        <DefaultButton
                            text='Criar'
                            type='submit'
                            variant='contained'
                            className={styles.button}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewTimeOff;