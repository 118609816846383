import { useQuery } from "react-query"
import AxiosConfig from "../../configs/AxiosConfig"
import { AxiosError } from "axios";

const getDiscountCoupon = async (couponCode: string): Promise<number> => {
    const result = await AxiosConfig
    .getAxiosInstance()
    .get(`/api/v1/coupon/${couponCode}`)
    return result.data.discountPercentual;
}

export const useGetDiscountCoupon = (couponCode: string) => {
    return useQuery<number>({
        enabled: false,
        queryKey: ['useGetDiscountCoupon', couponCode],
        queryFn: () => getDiscountCoupon(couponCode),
        refetchOnWindowFocus: false,
        retry: (_failureCount, error) => {
            if (error instanceof AxiosError && (error.response?.status == 404 || error.response?.status == 400))
                return false;
            
            return true;
        },
    })
}