import { useEffect, useImperativeHandle, useState } from 'react';

import { BackgroundProps, Position, Size, Video, VideoProps } from '@hivefuse/page-components';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { siteComponentIndexState, siteEditedState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';
import EditVideo from './editVideo/editVideo';

interface Props {
    componentId: string;
    existingProps?: VideoProps;
}

const VideoCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const theme = useRecoilValue(siteThemeState)!;
    const [videoProps, setVideoProps] = useState<VideoProps>(existingProps ?? {});
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return videoProps;
        },
        regenerateStyle() {
            handleGenerateStyle()
        },
        regenerateContent() {

        },
        regenerateEntireSection() {
            ReGenerateEntireSection()
        }
    })); 

    useEffect(() => {
        if (!existingProps) {
            getInitialProps();
        }

        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!theme) {
            setVideoProps(prevState => { return {
                ...prevState,
                fonts: theme?.themeFont
            } });
        }
    }, [theme]);

    const getInitialProps = () => {
        const newTextStyleProps = GetNewStyle();
        setVideoProps(prevState => { return { ...prevState, title: 'Video', autoplay: true, url: 'https://www.youtube.com/shorts/Rxze5UC9qPA', ...newTextStyleProps }});
    }

    const handleGenerateStyle = () => {
        const newStyle = GetNewStyle();
        setVideoProps(prevState => { return {...prevState, ...newStyle} });
    }

    const GetNewStyle = (): VideoProps => {
        return {
            textAlign: GenerateAlignment(),
            verticalPadding: GeneratePadding(),
            background: GenerateBackground(),
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont
        };
    }

    const GenerateAlignment = (): Position => {
        const allowedPositions = [Position.LEFT, Position.CENTER];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GeneratePadding = (): Size => {
        const allowedPositions = [Size.MEDIUM, Size.LARGE];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const ReGenerateEntireSection = () => {
        handleGenerateStyle();
    }

    const onEditing = (newProps: VideoProps) => {
        setSiteEditedState(true);
        setVideoProps(prevState => { return {...prevState, ...newProps} });
    }

    const getEditComponent = () => {
        return (
            <EditVideo
                onEdit={onEditing}
                currentProps={videoProps}
            />
        )
    }

    return (
        <BackdropEditor
            index={index}
            editTitleHeader='Video'
            componentId={componentId}
            editComponent={getEditComponent()}
            onRegenerateStyle={handleGenerateStyle}
        >
            <Video
                data={videoProps}
            />
        </BackdropEditor>
    )
});

export default VideoCreation;