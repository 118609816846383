import * as icons from "@mui/icons-material";

export type IconNames = keyof typeof icons

interface Props {
  iconName: IconNames;
    color?: 'inherit'
        | 'action'
        | 'disabled'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    fontSize?: 'inherit'
        | 'large'
        | 'medium'
        | 'small';
}

export const DefaultIcon = ({color, iconName, fontSize}: Props): JSX.Element => {
    const Icon = icons[iconName];
    return <Icon color={color} fontSize={fontSize}/>;
};