import { useQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import { AxiosError } from "axios";
import { Toast } from "../../components/Toast/Toast";

const fetchDomainStatus = async (domain: string) => {
    const response = await AxiosConfig
        .getAxiosInstance()
        .get(`/api/v1/sites/domain/get-status?baseUrl=${domain}&domainType=HIVEB`);

    if (!response.data.isAvailable)
        Toast.showToast(
            'Esse endereço já esta sendo utilizado, por favor escolha outro',
            'error'
        );

    return response.data;
}

export function useGetDomainStatus(domain: string){
    const query = useQuery ({
        queryFn: () => fetchDomainStatus(domain),
        queryKey:['fetchDomainStatus', domain],
        cacheTime:0,
        refetchOnWindowFocus: false,
        enabled: false,
        retry: (_failureCount, error: any) => {
            if (error instanceof AxiosError && (error.response?.status == 400)) {
                Toast.showToast(
                    'Por favor selecione outro endereço que tenha entre 3 e 60 caracteres',
                    'error'
                );
                return false;
            }
            
            if (_failureCount == 3) {
                Toast.showToast(
                    'Ocorreu um erro inesperado, por favor tente digitar o endereço novamente',
                    'error'
                );
            }
            return false;
        }
    });

    return query;
}