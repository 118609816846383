import styled from "styled-components"

const Img = styled.img`
    width: 160px;
    height: 70px;
    display: flex;
    align-self: center;
`

interface PopUpImgProps{
    imgUrl: string;
    alt?: string;
}

export default function ConfirmationModalImg({imgUrl, alt}: PopUpImgProps){
    return(
        <Img src={imgUrl} alt={alt}></Img>
    )
}