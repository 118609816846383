import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { themes } from '../../../../../themes/themes';

const fadeInOutAnimation = keyframes`
    0% { opacity: 0; }
    25% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
`;

const AnimatedText = styled.div`
    opacity: 0;
    animation: ${fadeInOutAnimation} 5s ease-in-out infinite;
    width: 100%;
`;

const Text = styled.p`
    color: ${themes.white};
    font-family: ${themes.secundaryFont};
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin: 0 20px;
`;

interface Props {
    texts: string[];
    interval?: number;
}

export const FadeText = ({ texts, interval = 5000 }: Props) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timerId = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, interval);

        return () => clearInterval(timerId);
    }, [texts.length, interval]);

    return (
        <AnimatedText>
            <Text>{texts[index]}</Text>
        </AnimatedText>
    );
};
