import * as yup from 'yup';
import { useFormik } from "formik";
import { useRegister } from "../../../../hooks/userService/useRegister";
import { PublishmentStep } from "../publishmentModal/publishment";
import { Button, CloseIcon, Form, PasswordDescription, Row, Title, TitleContainer, TitleSubContent } from './registerStep.style';
import FireBaseService, { analyticsEvents } from '../../../../infrastructure/firebaseService';
import { useRecoilValue } from 'recoil';
import { editingSiteIdState } from '../../../../state/siteEditorState';
import TextInput from '../../../../components/TextInput/TextInput';
import GoogleAuthButton from '../../../../components/GoogleButton/GoogleButton';

interface Props {
    goToStep: (step: PublishmentStep) => void;
    closeModal: () => void;
}

export default function RegisterStep(props: Props) {
    const { goToStep, closeModal } = props;
    const siteId = useRecoilValue(editingSiteIdState);

    const onRegister = () => {
        goToStep(PublishmentStep.DOMAIN);
    }

    const { mutate: register, isLoading } = useRegister(onRegister);

    const validationSchema = yup.object({
        email: yup
          .string()
          .email('Digite um email válido')
          .required('Digite seu email'),
        password: yup
          .string()
          .min(8, 'Sua senha deve possuir pelo menos 8 caracteres')
          .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula')
          .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
          .required('Digite sua senha'),
        name: yup
          .string()
          .required('Digite seu nome'),
        surname: yup
          .string()
          .required('Digite seu sobrenome')
    });
 
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            name: '',
            surname: '',
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            FireBaseService.logButtonClick(analyticsEvents.editorRegisterButton);
            register({
                email: formik.values.email,
                password: formik.values.password,
                name: `${formik.values.name} ${formik.values.surname}`,
                siteId: siteId!
            });
        }
    });

    const handleNameChange = (e: any) => {
        e.target.value = e.target.value.replace(/\s/g, '');
        formik.handleChange(e);
    }

    const handleGoogleAuthClicked = () => {
        FireBaseService.logButtonClick(analyticsEvents.googleAuthDuringCreation);
        window.location.href = `${process.env.REACT_APP_API_URL!}/api/v1/users/business/login/google?flow=SITE_CREATION&referenceId=${encodeURIComponent(siteId!)}`;
    }
    
    return (
        <>
            <TitleContainer>
                <Title>Cadastro</Title>
                <CloseIcon name='IoMdClose' onClick={closeModal}/>
            </TitleContainer>
            <Form onSubmit={formik.handleSubmit}>
                <div>
                    <GoogleAuthButton
                        onClick={handleGoogleAuthClicked}
                    />
                </div>
                <TitleSubContent>Ou preencha seus dados</TitleSubContent>
                <Row>
                    <TextInput
                        type='text'
                        placeholder='Nome'
                        label='Nome'
                        inputId='name'
                        value={formik.values.name} 
                        onChange={handleNameChange} 
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        errorText={formik.errors.name || ''}
                    />
                    <TextInput
                        type='text'
                        label='Sobrenome'
                        placeholder='Sobrenome'
                        inputId='surname'
                        value={formik.values.surname} 
                        onChange={handleNameChange} 
                        error={formik.touched.surname && Boolean(formik.errors.surname)}
                        errorText={formik.errors.surname || ''}
                    />
                </Row>
                <Row>
                    <TextInput
                        inputId='email'
                        type='email' 
                        label='Email'
                        placeholder='Email'
                        autocomplete='email'
                        value={formik.values.email} 
                        onChange={formik.handleChange} 
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        errorText={formik.errors.email || ''}
                    />
                </Row>
                <PasswordDescription>A senha precisa conter letra maiúscula, minúscula e no mínimo 8 caracteres</PasswordDescription>
                <Row lastRow>
                    <TextInput
                        inputId='password'
                        type='password'
                        autocomplete='new-password'
                        label='Senha'
                        placeholder='Senha'
                        value={formik.values.password} 
                        onChange={formik.handleChange} 
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        errorText={formik.errors.password || ''}
                    />
                </Row>
                <Button
                    text='Cadastrar'
                    type='submit'
                    loading={isLoading}
                    onClick={formik.handleSubmit}
                />
            </Form>
        </>
    )
}
