import { Size, Testimonial, TestimonialsProps } from "@hivefuse/page-components";
import { AddListItemText, DeleteIcon, EditContainer, EditIcon, InputLabel, ListBlock, ListItemDescription, ListItemDescriptionContainer, ListItemTitle, ListItems, SaveNewBlockButton } from "./editTestimonials.style";
import { Alert } from "../../../../../components/Alert/Alert";
import { TestimonialEditScreen } from "../testimonialsCreation";
import { useState } from "react";
import InputText from "../../../../../components/TextInput/TextInput";
import ColorPicker from "../../../../../components/ColorPicker/ColorPicker";
import { getFontColor } from "../../../utils/colorUtils";
import Dropdown from "../../../../../components/Dropdown/Dropdown";

interface Props {
    currentProps: TestimonialsProps;
    onEdit: (newProps: TestimonialsProps) => void;
    currentEditScreen: TestimonialEditScreen;
    goToScreen: (screen: TestimonialEditScreen) => void;
}

export default function EditTestimonials(props: Props) {
    const { currentEditScreen, currentProps, onEdit, goToScreen } = props;
    const [editingItemIndex, setEditingItemIndex] = useState<number | undefined>(undefined);
    const [newBlock, setNewBlock] = useState<Testimonial>({});

    const handleRemoveListItem = (index: number) => {
        if (!currentProps.testimonialList) 
            return;

        const newList = [...currentProps.testimonialList];
        newList.splice(index, 1);
        onEdit({
            ...currentProps,
            testimonialList: newList
        });
    }

    const handleOnListItemRemoveClick = (index: number) => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover este item?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => handleRemoveListItem(index)
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                }
            ],
            true
        );
    }

    const handleEditListClick = (index: number) => {
        setEditingItemIndex(index);
        goToScreen(TestimonialEditScreen.LIST_ITEM);
    }

    const handleNewBlockClick = () => {
        setNewBlock({});
        goToScreen(TestimonialEditScreen.NEW_ITEM);
    }

    const handleNameChange = (e: any, index: number) => {
        if (!currentProps.testimonialList) 
            return;

        const newArray = [...currentProps.testimonialList];
        newArray[index].name = e.target.value;
        onEdit({
            ...currentProps,
            testimonialList: newArray
        });
    }

    const handleDescriptionChange = (e: any, index: number) => {
        if (!currentProps.testimonialList) 
            return;

        const newArray = [...currentProps.testimonialList];
        newArray[index].description = e.target.value;
        onEdit({
            ...currentProps,
            testimonialList: newArray
        });
    }

    const handleAddNewBlock = () => {
        if (!currentProps.testimonialList) 
            return;

        const newArray = [...currentProps.testimonialList, {...newBlock}];
        onEdit({
            ...currentProps,
            testimonialList: newArray
        });
        setNewBlock({});
        goToScreen(TestimonialEditScreen.GENERAL);
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                backgroundColor: e
            },
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e
        });
    }

    if (currentEditScreen == TestimonialEditScreen.LIST_ITEM) {
        const itemIndex = editingItemIndex ?? 0;
        return (
            <EditContainer>
                <InputLabel noMarginTop={true}>Nome</InputLabel>
                <InputText
                    type='text'
                    value={currentProps!.testimonialList?.at(itemIndex)?.name}
                    onChange={(e) => handleNameChange(e, itemIndex)}
                />
                <InputLabel>Testemunho</InputLabel>
                <InputText
                    type='text'
                    textareaInput={true}
                    rows={5}
                    value={currentProps!.testimonialList?.at(itemIndex)?.description}
                    onChange={(e) => handleDescriptionChange(e, itemIndex)}
                />
            </EditContainer>
        );
    }

    if (currentEditScreen == TestimonialEditScreen.NEW_ITEM) {
        return (
            <EditContainer>
                <InputLabel noMarginTop={true}>Nome</InputLabel>
                <InputText
                    type='text'
                    value={newBlock.name ?? ''}
                    onChange={(e) => setNewBlock(prev => ({...prev, name: e.target.value}))}
                />
                <InputLabel>Testemunho</InputLabel>
                <InputText
                    type='text'
                    value={newBlock.description ?? ''}
                    textareaInput={true}
                    rows={5}
                    onChange={(e) => setNewBlock(prev => ({...prev, description: e.target.value}))}
                />

                <SaveNewBlockButton
                    text='Salvar'
                    onClick={handleAddNewBlock}
                />
            </EditContainer>
        );
    }

    return (
        <EditContainer>
            <InputLabel noMarginTop={true}>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.verticalPadding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL 
                    },
                    {
                        text: 'Médio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
            <InputLabel>Lista de testemunhos</InputLabel>
            <ListItems>
                {currentProps?.testimonialList?.map((testimonial, i) => (
                    <ListBlock key={i} firstItem={i == 0}>
                        <ListItemDescriptionContainer>
                            <ListItemTitle>{testimonial.name}</ListItemTitle>
                            <ListItemDescription>{testimonial.description}</ListItemDescription>
                        </ListItemDescriptionContainer>
                        <EditIcon onClick={() => handleEditListClick(i)}/>
                        <DeleteIcon onClick={() => handleOnListItemRemoveClick(i)}/>
                    </ListBlock>
                ))}
                <AddListItemText onClick={handleNewBlockClick}>Adicionar novo</AddListItemText>
            </ListItems>
        </EditContainer>
    )
}