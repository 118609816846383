import { useEffect, useState } from "react";
import { Backdrop, Button, Container, ContentContainer, GoBackButton, GoBackText, HeaderContainer, Image, ImageIcon, ImageUploaderDescription, ImageUploaderTitle, ImageWrapper, InnerContainer, PartnerLink, PartnerText, TabsList, Uploader, UploaderContent, UploaderInner, UploaderInnerWrapper } from "./imageSelectionModal.style";
import { Tab } from "@mui/material";
import MyImages from "./screens/myImages/myImages";
import StockImages from "./screens/stockImages/stockImages";

enum ImageSelectionScreen {
    STOCK_IMAGES = 'STOCK_IMAGES',
    YOUR_IMAGES = 'YOUR_IMAGES'
}

interface Props {
    images: string[];
    selectedImage?: string;
    onImageSelected: (imageUrl: string) => void;
    onClose: () => void;
}

const ImageSelectionModal = (props: Props) => {
    const { images, selectedImage: initialImage, onClose, onImageSelected } = props;
    const [selectedImage, setSelectedImage] = useState<string | undefined>(initialImage);
    const [screen, setScreen] = useState(ImageSelectionScreen.STOCK_IMAGES);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
          document.body.style.overflow = 'visible';
        };
    }, []);

    const handleSelect = () => {
        if (selectedImage != undefined)
            onImageSelected(selectedImage);
        onClose();
    };

    return (
        <Container>
            <Backdrop onClick={onClose}/>
            <ContentContainer>
                <HeaderContainer>
                    <GoBackButton onClick={onClose}>
                        <GoBackText>Fechar</GoBackText>
                    </GoBackButton>
                </HeaderContainer>
                <TabsList value={screen} variant="scrollable" scrollButtons={false} onChange={(_, value) => setScreen(value)}>
                    <Tab label="Imagens de exemplo" value={ImageSelectionScreen.STOCK_IMAGES}/>
                    <Tab label="Suas Imagens" value={ImageSelectionScreen.YOUR_IMAGES}/>
                </TabsList>

                {screen == ImageSelectionScreen.STOCK_IMAGES && (
                    <StockImages
                        onImageSelected={(image) => setSelectedImage(image)}
                        selectedImage={selectedImage}
                        images={images}
                    />
                )}

                {screen == ImageSelectionScreen.YOUR_IMAGES && (
                    <MyImages
                        onImageSelected={(image) => setSelectedImage(image)}
                        selectedImage={selectedImage}
                    />
                )}

                <Button
                    text='Selecionar'
                    onClick={handleSelect}
                />
            </ContentContainer>
        </Container>
    )
};

export default ImageSelectionModal;