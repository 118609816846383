import { BusinessCategoryList } from "../../resources/BusinessResource";
import { useQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";

const fetchBusinessCategories = async () => {
    try {
        const response = await AxiosConfig
            .getAxiosInstance()
            .get('/api/v1/site/categories');

        if (response.status == 200)
            return  response.data;
        return { categories: BusinessCategoryList }
    } catch (error) {
        return { categories: BusinessCategoryList }
    }
};

export function useGetSuggestedCategories(){
    const query = useQuery({
        queryFn: fetchBusinessCategories,
        queryKey: ['GetSuggestedCategories'],
        cacheTime: 0,
        refetchOnWindowFocus: false
    })
    return query;
};