import * as yup from 'yup';
import InputText from "../../../../../components/TextInput/TextInput";
import { BusinessName, DivButton } from "./NameStep.styles";
import ReturnArrow from "../../../../../components/ReturnArrow/ReturnArrow";
import StandardButton from "../../../../../components/Button/Button";
import FireBaseService, { analyticsEvents } from "../../../../../infrastructure/firebaseService";
import { SiteCreationStep } from "../../../SiteCreationPage";
import { useFormik } from 'formik';

interface Props{
    initialValue: string;
    goToStep: (step: SiteCreationStep) => void;
    setNameValue: (value: string) => void;
}

export default function NameStep({initialValue, goToStep, setNameValue}: Props){
    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Digite o nome da sua empresa'),
    });

    const formik = useFormik({
        initialValues: { name: initialValue },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: () => {
            FireBaseService.logButtonClick(analyticsEvents.siteNameForm);
            setNameValue(formik.values.name);
            goToStep(SiteCreationStep.LOCATION);
        },
    });

    const handleGoBack = () => {
        setNameValue(formik.values.name);
        goToStep(SiteCreationStep.CATEGORY);
    }
    
    return(
        <>
            <ReturnArrow to={handleGoBack}/>
            <BusinessName>
                Qual o nome do seu negócio?
            </BusinessName>
            <form onSubmit={formik.handleSubmit}>
                <InputText
                    inputId="name"
                    placeholder="Ex: Maria Cabalereira, Casa das Carnes e etc"
                    value={formik.values.name}
                    error={!!formik.errors.name && formik.touched.name}
                    errorText={formik.errors.name}
                    type="text"
                    onChange={formik.handleChange}
                />
            </form>
            <DivButton>
                <StandardButton
                    type="button"
                    text="Próximo"
                    disabled={formik.values.name == "" || !!formik.errors.name}
                    onClick={formik.handleSubmit}
                />
            </DivButton>
        </>
    )
}