import { useEffect, useRef } from "react";
import FileUploaded from "../../../../../../interfaces/FileUploaded";
import {
    ErrorMessage,
    ErrorMessageContainer,
    ImageIcon,
    ImageUploaderDescription,
    ImageUploaderTitle,
    InnerContainer,
    LoadingContainer,
    RetryButton,
    Uploader,
    UploaderContent,
    UploaderInner
} from "./myImages.style";
import { CircularProgress } from "@mui/material";
import SnackBarFeedback from "../../../../../../components/SnackBar/SnackBar";
import { Alert } from "../../../../../../components/Alert/Alert";
import SquaredImage from "../components/squaredImage/SquaredImage";
import { useRecoilValue } from "recoil";
import { editingSiteIdState } from "../../../../../../state/siteEditorState";
import { useGetSiteImages } from "../../../../../../hooks/imageService/upload/useGetSiteImages";
import { useUploadSiteImage } from "../../../../../../hooks/imageService/upload/useUploadSiteImage";
import { useDeleteSiteImage } from "../../../../../../hooks/imageService/upload/useDeleteSiteImage";

interface Props {
    selectedImage?: string;
    onImageSelected: (imageUrl: string) => void;
}

const MyImages = (props: Props) => {
    const { selectedImage, onImageSelected } = props;
    const snackBarRef = useRef<any>(null);
    const siteId = useRecoilValue(editingSiteIdState);
    const { isLoading: isLoadingSiteImages, refetch: refetchSiteImages, isError: loadingSiteImagesError, data: siteImages } = useGetSiteImages(siteId);
    const { isLoading: isUploadingSiteImage, data: uploadedSiteImage, mutate: uploadSiteImage, error: errorUploadingSiteImage } = useUploadSiteImage(siteId);
    const { isLoading: isDeletingSiteImage, mutate: deleteSiteImage, error: errorDeletingSiteImage } = useDeleteSiteImage(siteId, refetchSiteImages);

    useEffect(() => {
        if (!!uploadedSiteImage)
            refetchSiteImages();
    }, [uploadedSiteImage]);

    useEffect(() => {
        if (!!errorUploadingSiteImage)
            snackBarRef.current.open(errorUploadingSiteImage.message, 'error');
        if (!!errorDeletingSiteImage)
            snackBarRef.current.open(errorDeletingSiteImage.message, 'error');
    }, [errorUploadingSiteImage, errorDeletingSiteImage]);

    const onChange = (imageList: any) => {
        const images = imageList as FileUploaded[];
        
        if (!!siteId)
            uploadSiteImage(images);
    };

    const removeImage = (image: string) => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover esta imagem?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => !!siteId ? deleteSiteImage(image) : null
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                }
            ],
            true
        );
    }

    if (isLoadingSiteImages || isDeletingSiteImage)
        return (
            <LoadingContainer>
                <CircularProgress/>
            </LoadingContainer>
        )

    if (loadingSiteImagesError) {
        return (
            <ErrorMessageContainer>
                <ErrorMessage>
                    Erro inesperado ao carregar as imagens, por favor tente novamente
                </ErrorMessage>
                <RetryButton
                    text='Tentar Novamente'
                    onClick={() => refetchSiteImages()}
                />
            </ErrorMessageContainer>
        );
    }

    return (
        <InnerContainer>
            {!!siteId && siteImages?.images?.map((image: string, index: number) => (
                <SquaredImage
                    selected={image === selectedImage}
                    key={index}
                    src={image}
                    onImageSelected={onImageSelected}
                    onRemoveImage={removeImage}
                />
            ))}

            <Uploader
                multiple={true}
                maxNumber={20}
                value={[]}
                acceptType={['jpg', 'jpeg', 'png']}
                onChange={onChange}
                dataURLKey="data_url"
            >
                {({
                    onImageUpload,
                    isDragging,
                    dragProps,
                }) => (
                    <UploaderInner 
                        onClick={() => (!isUploadingSiteImage) && onImageUpload()}
                        isDragging={isDragging}
                        {...dragProps}
                    >
                        <UploaderContent>
                            {isUploadingSiteImage ? (
                                <CircularProgress/>
                            ) : (
                                <>
                                    <ImageIcon/>
                                    <ImageUploaderTitle>Enviar</ImageUploaderTitle>
                                </>
                            )}
                        </UploaderContent>
                    </UploaderInner>
                )}
            </Uploader>

            <SnackBarFeedback ref={snackBarRef}/>
        </InnerContainer>
    )
};

export default MyImages;