import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authState } from '../../state/authState';

function HomePage() {
    const [auth] = useRecoilState(authState);

    if (!auth) {
        return <Navigate to="/auth" replace />;
    }
    
    return <Navigate to="/sites" replace />;
}
  
export default HomePage;