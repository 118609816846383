import { themes } from "../../../../../themes/themes";
import { Container, Regenerate, Tool } from "./ToolBar.style";
import { FaArrowDown, FaArrowUp, FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { HiBolt } from "react-icons/hi2";
import { Tooltip } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { siteComponentsState } from "../../../../../state/siteEditorState";
import { Alert } from "../../../../../components/Alert/Alert";
import { moveComponentDown, moveComponentUp } from "../../../utils/siteUtils";

interface Props {
    index: number;
    isLastComponent: boolean;
    componentId: string;
    siteComponentsSize: number;
    sticky?: boolean;
    toolbarOverTop?: boolean;
    onRegenerateStyle?: () => void;
    onRegenerateContent?: () => void;
    onRegenerateEntireSection?: () => void;
    onEditComponent?: () => void;
}

export default function ToolBar(props: Props) {
    const {
        index,
        isLastComponent,
        sticky,
        componentId,
        siteComponentsSize,
        toolbarOverTop,
        onRegenerateStyle,
        onRegenerateContent,
        onRegenerateEntireSection,
        onEditComponent
    } = props;
    const setSiteComponents = useSetRecoilState(siteComponentsState);
    const firstOrLast = index === 0 || isLastComponent;

    const onDeleteButtonPressed = () => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover este item?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => setSiteComponents(components => (components.filter(c => c.componentId !== componentId)))
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                }
                
            ],
            true
        );
    }

    const onMoveUp = () => {
        setSiteComponents(components => moveComponentUp(components, index))
    }

    const onMoveDown = () => {
        setSiteComponents(components => moveComponentDown(components, index))
    }

    return (
        <Container sticky={sticky} toolbarOverTop={toolbarOverTop}>
            {(!!onRegenerateStyle || !!onRegenerateContent || !!onRegenerateEntireSection) && (
                <Regenerate
                    tooltip='Regerar'
                    size='small'
                    Icon={HiBolt}
                    buttons={[
                        ... !!onRegenerateStyle ? [{
                            text: 'Regerar Estilo',
                            onPress: () => onRegenerateStyle()
                        }] : [],
                        ... !!onRegenerateContent ? [{
                            text: 'Regerar Conteúdo',
                            onPress: () => onRegenerateContent()
                        }] : [],
                        ... !!onRegenerateEntireSection ? [{
                            text: 'Regerar Tudo',
                            onPress: () => onRegenerateEntireSection()
                        }] : []
                    ]}
                />
            )}
            {!!onEditComponent && (
                <Tooltip title="Editar" placement="top" arrow>
                    <Tool onClick={onEditComponent}>
                        <FaEdit color={themes.darkPurple} size={16}/>
                    </Tool>
                </Tooltip>
            )}
            {index > 1 && !firstOrLast && (
                <Tooltip title="Mover para cima" placement="top" arrow>
                    <Tool onClick={onMoveUp}>
                        <FaArrowUp color={themes.darkPurple} size={16}/>
                    </Tool>
                </Tooltip>
            )}
            {siteComponentsSize - index > 2 && !firstOrLast && (
                <Tooltip title="Mover para baixo" placement="top" arrow>
                    <Tool onClick={onMoveDown}>
                        <FaArrowDown color={themes.darkPurple} size={16}/>
                    </Tool>
                </Tooltip>
            )}
            {!firstOrLast && (
                <Tooltip title="Remover" placement="top" arrow>
                    <Tool onClick={onDeleteButtonPressed}>
                        <MdDeleteForever color={themes.darkPurple} size={16}/>
                    </Tool>
                </Tooltip>
            )}
        </Container>
    );
}