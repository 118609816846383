import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StoreIcon from '@mui/icons-material/Store';
import { Avatar, ButtonBase, Typography } from '@mui/material';

import DefaultButton from '../../components/DefaultButton/DefaultButton';
import styles from './BusinessesListPage.module.css';

function BusinessesListPage() {
    return (
      <div className={styles.main}>
        <Typography className={styles.header} variant="h5">
            Meus Negócios
        </Typography>
        <div className={styles.buttonContainer}>
            <DefaultButton
                text='+ Novo Negócio'
            />
        </div>
        <div className={styles.list}>
            <ButtonBase className={styles.card}>
                <div className={styles.cardInner}>
                    <Avatar className={styles.icon}>
                        <StoreIcon/>
                    </Avatar>
                    <div className={styles.cardText}>
                        <Typography variant='subtitle2' className={styles.title}>
                            Lojão do Cabelereiro - Boa Viagem
                        </Typography>
                        <Typography variant='caption' className={styles.position}>
                            Gerente
                        </Typography>
                    </div>
                </div>
                <ArrowForwardIosIcon/>
            </ButtonBase>
            <ButtonBase className={styles.card}>
                <div className={styles.cardInner}>
                    <Avatar className={styles.icon}>
                        <StoreIcon/>
                    </Avatar>
                    <div className={styles.cardText}>
                        <Typography variant='subtitle2' className={styles.title}>
                            Lojão do Cabelereiro - Caxanga
                        </Typography>
                        <Typography variant='caption' className={styles.position}>
                            Gerente
                        </Typography>
                    </div>
                </div>
                <ArrowForwardIosIcon/>
            </ButtonBase>
        </div>
      </div>
    );
}
  
export default BusinessesListPage;