import { forwardRef, useImperativeHandle, useState } from 'react';
import ImageUploading from 'react-images-uploading';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ButtonBase, Typography } from '@mui/material';

import avatar from '../../assets/noavatar.png';
import styles from './UploadPictureForm.module.css';

interface Props {
    imageUrl?: string;
}

const UploadPictureForm = forwardRef<any, Props>((props, ref) => {
    const getInitialImage = () => {
        const { imageUrl } = props;
        if (!!imageUrl) {
            return [{
                data_url: imageUrl
            }];
        }

        return [];
    }

    const [images, setImages] = useState<any>(getInitialImage());

    useImperativeHandle(ref, () => ({
        getImage(): string | null | undefined {
            if (images.length > 0) {
                if(images[0]['data_url'].startsWith("http")) {
                    return null;
                } else {
                    return images[0]['data_url'];
                }
            } else {
                return null;
            }
        }
    }));

    const onChange = (image: any) => {
        setImages(image);
    };

    const getImageUrl = () => {
        return images[0]['data_url'];
    }

    return (
        <div className={styles.mainContainer}>
            
            <div className={styles.inner}>
                <ImageUploading
                    multiple={false}
                    value={images}
                    onChange={onChange}
                    dataURLKey="data_url"
                    maxFileSize={3000000}
                >
                    {({
                        onImageUpload,
                        onImageRemoveAll,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <div {...dragProps} className={[styles.dragArea, isDragging ? styles.onDrag : undefined].join(' ')}>
                            <div className={styles.avatar}>
                                <img className={styles.image} src={images.length > 0 ? getImageUrl() : avatar} alt="Staff"/>
                                {images.length > 0 && (
                                    <ButtonBase className={styles.eraseIcon} onClick={onImageRemoveAll}>
                                        <DeleteForeverIcon fontSize='small' htmlColor='white'/>
                                    </ButtonBase>
                                )}
                            </div>
                            {errors && <div>
                                {errors.maxNumber && <Typography>Você selecionou mais de uma imagem</Typography>}
                                {errors.acceptType && <Typography>Tipo de arquivo não permitido</Typography>}
                                {errors.maxFileSize && <Typography>Você selecionou uma imagem que excede o limite de tamanho</Typography>}
                                {errors.resolution && <Typography>Sua imagem não encaixa nas nossas dimensões</Typography>}
                            </div>}
                            <div className={styles.overlay}>
                                <div className={styles.overlayInner} onClick={onImageUpload}>
                                    <AddAPhotoIcon className={styles.uploadImage}/>
                                    <Typography className={styles.uploadText}>
                                        Enviar foto
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                </ImageUploading>
            </div>
        </div>
    );
});
  
export default UploadPictureForm;