import { useMutation } from "react-query";
import AxiosConfig from "../../../configs/AxiosConfig";

const unexpectedErrorText = 'Um erro inesperado ocorreu';
const deleteSiteImage = async (siteId?: string, url?: string, onSuccess?: () => void) => {
    try {
        const response = await AxiosConfig.getAxiosInstance()
            .delete(
                `api/v1/site/${siteId}/images`,
                { params: { url } }
            );

        if (response.status === 200) {
            !!onSuccess && onSuccess();
            return;
        }
        throw new Error(unexpectedErrorText);
    } catch(err) {
        throw new Error(unexpectedErrorText);
    }
}

export const useDeleteSiteImage = (siteId?: string, onSuccess?: () => void) => {
    return useMutation<void, Error, string>({
        retry: false,
        mutationKey: ['DeleteSiteImage'],
        mutationFn: async (url: string) => deleteSiteImage(siteId, url, onSuccess),
    })
}