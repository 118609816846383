import { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Tab, Tabs, Typography } from '@mui/material';

import ServiceInfo from './components/ServiceInfo/ServiceInfo';
import ServiceWorkingHours from './components/ServiceWorkingHours/ServiceWorkingHours';
import styles from './EditServicePage.module.css';

enum EditServiceTabs {
    INFO = 'INFO',
    WORKING_HOURS = 'WORKING_HOURS',
}

function EditServicePage() {
    const [tab, setTab] = useState(EditServiceTabs.INFO);

    const handleTabChange = (_e: any, newValue: EditServiceTabs) => {
        setTab(newValue);
    };

    return (
        <div className={styles.main}>
            <Typography className={styles.header} variant="h5">
                Serviço
            </Typography>
            <Tabs value={tab} onChange={handleTabChange} className={styles.tab}>
                <Tab
                    className={styles.tab}
                    icon={<InfoIcon/>}
                    iconPosition="start"
                    value={EditServiceTabs.INFO}
                    label="Informações"
                />
                <Tab
                    className={styles.tab}
                    icon={<QueryBuilderIcon/>}
                    iconPosition="start"
                    value={EditServiceTabs.WORKING_HOURS}
                    label="Horário do Serviço"
                />
            </Tabs>
            {tab === EditServiceTabs.INFO && (<ServiceInfo/>)}
            {tab === EditServiceTabs.WORKING_HOURS && (<ServiceWorkingHours/>)}
        </div>
    );
}
  
export default EditServicePage;