import ColorPicker from '../../../../../components/ColorPicker/ColorPicker';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import InputText from '../../../../../components/TextInput/TextInput';
import { getFontColor } from '../../../utils/colorUtils';
import { EditContainer, Input, InputLabel, Select } from './editFooter.style';
import { FooterProps, Size } from '@hivefuse/page-components';

interface Props {
    currentProps: FooterProps;
    onEdit: (newProps: FooterProps) => void;
}

export default function EditFooter(props: Props) {
    const { currentProps, onEdit } = props;

    const handleTitleChange = (e: any) => {
        onEdit({
            ...currentProps,
            title: e.target.value
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                ...currentProps.background,
                backgroundColor: e,
            },
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            padding: e
        });
    }

    return (
        <EditContainer>
            <InputLabel noMarginTop={true}>Título</InputLabel>
            <InputText
                type='text'
                value={currentProps?.title}
                onChange={handleTitleChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.padding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL
                    },
                    {
                        text: 'Medio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
        </EditContainer>
    )
}