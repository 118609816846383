import { getFontColor } from '../../../utils/colorUtils';
import { EditContainer, Input, InputLabel, Select } from './editVideo.style';
import { Position, Size, VideoProps } from '@hivefuse/page-components';
import InputText from '../../../../../components/TextInput/TextInput';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import ColorPicker from '../../../../../components/ColorPicker/ColorPicker';
import Checkbox from '../../../../../components/Checkbox/Checkbox';

interface Props {
    currentProps: VideoProps;
    onEdit: (newProps: VideoProps) => void;
}

export default function EditVideo(props: Props) {
    const { currentProps, onEdit } = props;

    const handleTitleChange = (e: any) => {
        onEdit({
            ...currentProps,
            title: e.target.value
        });
    }

    const handlVideoUrlChange = (e: any) => {
        onEdit({
            ...currentProps,
            url: e.target.value
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                backgroundColor: e
            },
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e
        });
    }

    const handleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            textAlign: e
        });
    }

    const handleChangedControls = () => {
        onEdit({
            ...currentProps,
            showControls: !currentProps?.showControls
        });
    }

    const handleAutoPlayChange = () => {
        onEdit({
            ...currentProps,
            autoplay: !currentProps?.autoplay
        });
    }

    return (
        <EditContainer>
            <InputLabel>Título</InputLabel>
            <InputText type='text' value={currentProps?.title} onChange={handleTitleChange}/>
            <InputLabel>Alinhamento</InputLabel>
            <Dropdown
                value={currentProps?.textAlign}
                options={[
                    {
                        text: 'Esquerda',
                        value: Position.LEFT 
                    },
                    {
                        text: 'Centro',
                        value: Position.CENTER 
                    },
                    {
                        text: 'Direita',
                        value: Position.RIGHT 
                    }
                ]}
                onValueChange={handleAlignmentChange}
            />
            <InputLabel>Link do Vídeo do Youtube</InputLabel>
            <InputText type='text' value={currentProps?.url} onChange={handlVideoUrlChange}/>
            <Checkbox
                text='Mostrar barra de ferramentas'
                value={currentProps?.showControls}
                onChange={handleChangedControls}
            />
            <Checkbox
                text='Dar play automaticamente'
                value={currentProps?.autoplay}
                onChange={handleAutoPlayChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.verticalPadding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL 
                    },
                    {
                        text: 'Médio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
        </EditContainer>
    )
}