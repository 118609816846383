import { Moment } from 'moment';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Avatar, ButtonBase, Tooltip, Typography } from '@mui/material';

import { GetCurrency } from '../../helpers/TransactionsHelper';
import { DefaultIcon, IconNames } from '../DefaultIcon/DefaultIcon';
import styles from './InteractionCard.module.css';

interface Props {
    icon?: IconNames;
    title: string;
    date?: Moment;
    value?: number;
    description?: string;
    avatarUrl?: string;
    onClick?: () => void;
}

function InteractionCard(props: Props) {
    const { avatarUrl, description, date, icon, title, value, onClick } = props;

    const GetDotToolTip = () => {
        const mock = Math.random();
        switch(mock) {
            case 1:
                return 'TODO';
            case 2:
                return 'TODO';
            case 3:
                return 'TODO';
            default:
                return 'Pagamento';
        }
    }

    const GetDate = (date: Moment) => {
        return date.format("DD/MM/YYYY HH:mm");
    }

    return (
        <ButtonBase className={styles.transactionCard} onClick={() => !!onClick && onClick()}>
            <div className={styles.transactionDetails}>
                <Tooltip title={GetDotToolTip()} placement="top" className={styles.transactionIcon}>
                    {!!avatarUrl ? (
                        <Avatar alt={title} src={avatarUrl}/>
                    ) : (
                        <Avatar>
                            {!!icon ? (
                                <DefaultIcon iconName={icon}/>
                            ) : (
                                <SwapHorizIcon/>
                            )}
                        </Avatar>
                    )}
                </Tooltip>
                <div className={styles.transactionCaptions}>
                    <Typography variant='subtitle2' className={styles.eventTitle}>{title}</Typography>
                    {!!date && (
                        <Typography variant='caption' className={styles.eventDate}>{GetDate(date)}</Typography>
                    )}
                    {!!description && (
                        <Typography variant='caption' className={styles.description}>{description}</Typography>
                    )}
                </div>
            </div>
            <div className={styles.viewMoreSection}>
                {!!value && (
                    <Typography variant='subtitle2' className={styles.value}>R$ {GetCurrency(value)}</Typography>
                )}
                {!!onClick && (
                    <ArrowForwardIosIcon/>
                )}
            </div>
        </ButtonBase>
    );
}
  
export default InteractionCard;