function getRGB(c: any) {
    return parseInt(c, 16) || c;
}
  
function getsRGB(c: any) {
    return getRGB(c) / 255 <= 0.03928
      ? getRGB(c) / 255 / 12.92
      : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}
  
function getLuminance(hexColor: any) {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    );
}
  
function getContrast(f: string, b: string) {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

export const getFontColor = (hexColor: string) => {
    const whiteContrast = getContrast(hexColor, '#ffffff')
    const blackContrast = getContrast(hexColor, '#000000')
    return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}
