import styled from "styled-components";
import { themes } from "../../themes/themes";

export const DefaultHeader = styled.header`
    height: 95px;
    width: 100%;
    background-color: transparent;
    display: flex;
    position: relative;
    top: 0;
`;

export const ImgHeader = styled.img`
    padding-left: 70px;
    padding-top: 35px;
    width: 49px;
    height: 49px;
    z-index: 20;
    
    @media(max-width: 565px){
        padding-left: 25px;
        width: 40px;
        height: 40px;
    }

    @media (max-width: ${themes.mobileDimension}){
        display: none;
    }
`;