import { useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronUp, Container, DropdownItemContainer, DropdownItemValue, DropdownItemsContainer, DropdownValueContainer, DropdownWrapper, MainContainer, maxHeight } from "./IconDropdown.style";
import { IconType } from "react-icons";
import { Tooltip } from "@mui/material";

type Size = 'small' | 'medium';

interface Button {
    text: string;
    onPress: () => void;
}

interface Props {
    tooltip?: string;
    Icon: IconType;
    size?: Size;
    fixed?: boolean;
    noIconPadding?: boolean;
    className?: string;
    buttons: Button[];
}

const IconDropdown = (props: Props) => {
    const { className, buttons, tooltip, Icon, size, fixed, noIconPadding } = props;
    const [opened, setOpened] = useState(false);
    const [tooltipOpened, setTooltipOpened] = useState(false);
    const [dropdownUp, setDropdownUp] = useState(false);
    const mainContainerRef = useRef<any>(null);
    const dropdownItemsContainerRef = useRef<any>(null);
    const iconSize = size ?? 'medium';
    
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!dropdownItemsContainerRef.current.contains(event.target) && !mainContainerRef.current.contains(event.target))
                setOpened(false);
        }

        if (opened)
            document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [opened, mainContainerRef, dropdownItemsContainerRef]);

    useEffect(() => {
        if (mainContainerRef.current) {
            if (fixed) {
                const element: HTMLElement = mainContainerRef.current;
                const rect = element.getBoundingClientRect();
                const spaceBelow = window.innerHeight - rect.bottom;

                if (spaceBelow < maxHeight) {
                    setDropdownUp(true);
                } else {
                    setDropdownUp(false);
                }
            } else {
                const element: HTMLElement = mainContainerRef.current;
                const elementDistanceToTop = element.getBoundingClientRect().top + window.scrollY;
                const bodyHeight = document['body'].clientHeight;
                const hasSpaceBelow = bodyHeight > (elementDistanceToTop + maxHeight);
                if (hasSpaceBelow) {
                    setDropdownUp(false);
                } else {
                    setDropdownUp(true);
                } 
            }
        }
    }, [mainContainerRef]);
    
    const handleOptionSelection = (onPress: () => void) => {
        setOpened(false);
        onPress();
    }

    const renderChevron = () => {
        if (opened)
            return <ChevronUp iconsize={iconSize}/>;
        else
            return <ChevronDown iconsize={iconSize}/>
    }

    const handleIconClicked = () => {
        setOpened(prev => !prev);
        setTooltipOpened(false);
    }

    return(
        <Container>
            <DropdownWrapper opened={opened} dropdownUp={dropdownUp} size={iconSize}>
                <Tooltip
                    open={tooltipOpened}
                    title={tooltip}
                    onPointerEnter={() => !opened && setTooltipOpened(true)}
                    onPointerLeave={() => setTooltipOpened(false)}
                    placement="top"
                    arrow
                >
                    <MainContainer className={className} ref={mainContainerRef} onClick={handleIconClicked} nopadding={noIconPadding}>
                        <DropdownValueContainer size={iconSize}>
                            <Icon/>
                        </DropdownValueContainer>
                        {renderChevron()}
                    </MainContainer>
                </Tooltip>
                {opened && (
                    <DropdownItemsContainer
                        size={iconSize}
                        ref={dropdownItemsContainerRef}
                        style={{
                            top: dropdownUp ? 'auto' : '100%',
                            bottom: dropdownUp ? '100%' : 'auto',
                        }}
                    >
                        {buttons.map((button, index) => (
                            <DropdownItemContainer key={index} onClick={() => handleOptionSelection(button.onPress)}>
                                <DropdownItemValue>{button.text}</DropdownItemValue>
                            </DropdownItemContainer>
                        ))}
                    </DropdownItemsContainer>
                )}
            </DropdownWrapper>
        </Container>
    )
}

export default IconDropdown;