import styled from 'styled-components';
import { themes } from '../../themes/themes';

export const Container = styled.div`
    position: relative;
`

export const MainContainer = styled.div`
    box-sizing: border-box;
    min-height: 54px;
    background-color: ${themes.white};
    border-radius: 10px;
    border: 1px solid ${themes.darkGray};
    box-shadow: ${themes.inputShadow};
    display: flex;
    align-items: center;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`

export const ColorPicked = styled.div<{ background: string}>`
    background-color: ${props => props.background};
    box-shadow: ${themes.inputShadow};
    border-radius: 5px;
    width: 36px;
    height: 36px;
    margin: 0 9px;
`

export const Input = styled.p`
    display: flex;
    align-items: center;
    border: none;
    flex: 1;
    font-family: ${themes.primaryFont};
    user-select: none;
    color: ${themes.onyx};

    &:focus {
        outline: none;
    }    
`

export const PickerContainer = styled.div`
    margin: 12px 0;
    
    > div:first-child {
        width: auto !important;
    }

    div > div > div > div > div {
        justify-content: center;
    }
`