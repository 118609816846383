import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import { CleanIcon, Container, IconLeftContainer, IconRightContainer, Input, Label, LeftIcon, LoadingSpinner, PhoneBrazilianComponent, RightIcon, TextAreaInput, VisibilityIcon, VisibilityOffIcon } from "./TextInput.styles"
import InputTooltip from "./InputTooltip/InputTooltip";
import InputMask from 'react-input-mask';

interface Props{
    children?: React.ReactNode;
    placeholder?: string;
    label?: string;
    type: React.HTMLInputTypeAttribute;
    inputId?: string;
    error?: boolean;
    value?: string;
    errorText?: string;
    autocomplete?: string;
    disable?: boolean;
    loading?: boolean;
    min?: number;
    max?: number;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    className?: string; 
    leftIcon?: string;
    rightIcon?: string;
    maxLength?: number;
    phoneInput?: boolean;
    textareaInput?: boolean;
    onCleanButtonClicked?: () => void;
    handleClickInRightIcon?: (handleRightIconIsClicked: boolean) => void;
    ref?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement>;
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    autoCapitalize?: string;
    inputmode?: string;
    maskInput?: boolean;
    mask?: string;
    rows?: number;
    onStopTyping?: (value: string) => void;
    onStopTypingDelayInMs?: number; 
}

export default function TextInput({ 
    placeholder, 
    autocomplete,
    type,
    inputId, 
    error, 
    value, 
    onChange, 
    errorText, 
    disable, 
    onFocus, 
    className, 
    leftIcon, 
    rightIcon, 
    maxLength, 
    handleClickInRightIcon,
    inputmode,
    ref,
    onKeyUp,
    onKeyDown,
    autoCapitalize,
    min,
    max,
    label,
    onCleanButtonClicked,
    phoneInput,
    loading,
    textareaInput,
    maskInput,
    mask,
    rows,
    onStopTyping,
    onStopTypingDelayInMs
}: Props){
    const [inputType, setInputType] = useState(type ?? 'text');
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

    const handleTypingStopped = (value: string) => {
        if (onStopTyping)
            onStopTyping(value);
    }

    const handleShowHidePasswordPressed = () => {
        if (inputType == 'text')
            setInputType('password');
        else
            setInputType('text');
    }

    const isPassword = type == 'password';
    const showingRightIcon = isPassword || !!rightIcon || !!error;

    const renderRightIcon = () => {
        if (!!loading) {
            return (
                <IconRightContainer>
                    <LoadingSpinner/>
                </IconRightContainer>
            );
        }

        if (!!error) {
            return (
                <IconRightContainer>
                    <InputTooltip error={errorText}/>
                </IconRightContainer>
            );
        }

        if (!!isPassword) {
            return (
                <IconRightContainer onClick={handleShowHidePasswordPressed}>
                    {inputType == 'password' && (
                        <VisibilityOffIcon/>
                    )}
                    {inputType == 'text' && (
                        <VisibilityIcon/>
                    )}
                </IconRightContainer>
            );
        }

        if (!!value && !!onCleanButtonClicked) {
            return (
                <IconRightContainer onClick={onCleanButtonClicked}>
                    <CleanIcon/>
                </IconRightContainer>
            );
        }

        if (!!rightIcon) {
            return (
                <IconRightContainer>
                    <RightIcon name={rightIcon}/>
                </IconRightContainer>
            );
        }
    }

    const renderInput = () => {
        if (!!phoneInput) {
            return (
                <PhoneBrazilianComponent
                    label={label}
                    error={error}
                    id={inputId}
                    disabled={disable}
                    type={inputType}
                    value={value} 
                    inputMode={inputmode}
                    onChange={handleOnInputChange}
                    onFocus={onFocus}
                    maxLength={maxLength}
                    autoComplete={autocomplete}
                    ref={ref}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    autoCapitalize={autoCapitalize}
                    min={min}
                    max={max}
                    showingLeftIcon={!!leftIcon}
                    showingRightIcon={showingRightIcon}
                    temddd={true}
                    separaddd={true}
                    placeholder="(DDD) 33333-3333"
                />
            )
        } else if (!!textareaInput) {
            return (
                <TextAreaInput
                    label={label}
                    error={error}
                    id={inputId}
                    inputMode={inputmode}
                    disabled={disable}
                    placeholder={placeholder}
                    type={inputType}
                    value={value} 
                    onChange={handleOnInputChange}
                    onFocus={onFocus}
                    maxLength={maxLength}
                    autoComplete={autocomplete}
                    ref={ref}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    autoCapitalize={autoCapitalize}
                    min={min}
                    max={max}
                    showingLeftIcon={!!leftIcon}
                    showingRightIcon={showingRightIcon}
                    rows={`${rows}`}
                />
            );
        } else if (maskInput) {
            return (
                <>
                    <InputMask
                        mask={mask ?? ''}
                        maskPlaceholder={null}
                        type="text"
                        id={inputId}
                        value={value}
                        onChange={handleOnInputChange}
                    >
                        <Input
                            label={label}
                            error={error}
                            id={inputId}
                            inputMode={inputmode}
                            disabled={disable}
                            placeholder={placeholder}
                            type={inputType}
                            value={value} 
                            onChange={handleOnInputChange}
                            onFocus={onFocus}
                            maxLength={maxLength}
                            autoComplete={autocomplete}
                            ref={ref}
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                            autoCapitalize={autoCapitalize}
                            min={min}
                            max={max}
                            showingLeftIcon={!!leftIcon}
                            showingRightIcon={showingRightIcon}
                        />
                    </InputMask>
                </>
            );
        }
        else {
            return (
                <Input
                    label={label}
                    error={error}
                    id={inputId}
                    inputMode={inputmode}
                    disabled={disable}
                    placeholder={placeholder}
                    type={inputType}
                    value={value} 
                    onChange={handleOnInputChange}
                    onFocus={onFocus}
                    maxLength={maxLength}
                    autoComplete={autocomplete}
                    ref={ref}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    autoCapitalize={autoCapitalize}
                    min={min}
                    max={max}
                    showingLeftIcon={!!leftIcon}
                    showingRightIcon={showingRightIcon}
                />
            );
        }
    }

    const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!!phoneInput) {
            e.target.value = e.target.value.replace(/\D+/g, '');
            onChange(e);
        }
        else
            onChange(e);

        if (onStopTyping) {
            if (typingTimeout)
                clearTimeout(typingTimeout);
                setTypingTimeout(setTimeout(() => handleTypingStopped(e.target.value), onStopTypingDelayInMs ?? 500));
        }
    }

    return (
        <Container className={className}>
            {!!label && (
                <Label
                    showingLeftIcon={!!leftIcon}
                >
                    {label}
                </Label>
            )}
            {renderInput()}
            {!!leftIcon && (
                <IconLeftContainer>
                    <LeftIcon name={leftIcon}/>
                </IconLeftContainer>
            )}
            {renderRightIcon()}
        </Container>
    );
}