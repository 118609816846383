import styled from "styled-components";
import { themes } from '../../../../../themes/themes';

const { primaryFont, black, gray, purple } = themes;

export const DivButton = styled.div`
    padding-top: 28px;
`

export const TitleBusinessType = styled.h2`
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding-top: 15px;
    padding-bottom: 20px;
`

export const OptionCategory = styled.option`
    height: 20px;
    font-family: ${primaryFont};
`

export const TextSuggestions = styled.div`
    display: flex;
    column-gap: 3.5px;
    margin-top: 12px;
`

export const PrimaryText = styled.p`
    color: ${gray};
    font-size: 0.813rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
`

export const SecundaryText = styled.a`
    color: ${purple};
    font-size: 0.813rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;

    &:hover{
        cursor:pointer;
        opacity: 0.5;
    }
`