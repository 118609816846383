import { getFontColor } from '../../../utils/colorUtils';
import { EditContainer, InputLabel } from './editInstagram.style';
import { InstagramProps, Position, Size } from '@hivefuse/page-components';
import InputText from '../../../../../components/TextInput/TextInput';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import ColorPicker from '../../../../../components/ColorPicker/ColorPicker';

interface Props {
    currentProps: InstagramProps;
    onEdit: (newProps: InstagramProps) => void;
}

export default function EditVideo(props: Props) {
    const { currentProps, onEdit } = props;

    const handleTitleChange = (e: any) => {
        onEdit({
            ...currentProps,
            title: e.target.value
        });
    }

    const handlAccountChange = (e: any) => {
        onEdit({
            ...currentProps,
            account: e.target.value
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                backgroundColor: e
            },
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e
        });
    }

    const handleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            textAlign: e
        });
    }

    return (
        <EditContainer>
            <InputLabel>Título</InputLabel>
            <InputText type='text' value={currentProps?.title} onChange={handleTitleChange}/>
            <InputLabel>Alinhamento</InputLabel>
            <Dropdown
                value={currentProps?.textAlign}
                options={[
                    {
                        text: 'Esquerda',
                        value: Position.LEFT 
                    },
                    {
                        text: 'Centro',
                        value: Position.CENTER 
                    },
                    {
                        text: 'Direita',
                        value: Position.RIGHT 
                    }
                ]}
                onValueChange={handleAlignmentChange}
            />
            <InputLabel>Perfil do Instagram</InputLabel>
            <InputText
                type='text'
                leftIcon='FaInstagram'
                placeholder="@ (seu usuário aqui)"
                value={currentProps?.account}
                onChange={handlAccountChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.verticalPadding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL 
                    },
                    {
                        text: 'Médio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
        </EditContainer>
    )
}