import jwt_decode from 'jwt-decode';

import Auth from '../interfaces/authentication/Auth';
import AuthToken from '../interfaces/authentication/AuthToken';

export const GetAuthFromToken = (token: AuthToken): Auth => {
    const decodedAccessToken: any = jwt_decode(token.accessToken);
    const decodedRefreshToken: any = jwt_decode(token.refreshToken);

    return {
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
        userId: decodedAccessToken.userId,
        sessionId: decodedAccessToken.sessionId,
        accessTokenExp: decodedAccessToken.exp,
        refreshTokenExp: decodedRefreshToken.exp
    };
}