import { useFormik } from 'formik';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';

import { Alert, Snackbar, TextField } from '@mui/material';

import DefaultLoadingButton from '../../../../components/DefaultLoadingButton/DefaultLoadingButton';
import SnackBarOptions from '../../../../interfaces/UI/SnackBarOptions';
import { AuthenticationApi } from '../../../../rpc/AuthenticationApi';
import { BaseApi } from '../../../../rpc/BaseApi';
import { authState } from '../../../../state/authState';
import styles from './ChangePassword.module.css';

function ChangePassword() {
    const [snackbar, setSnackbar] = useState<SnackBarOptions>({opened: false});
    const [form, setForm] = useState<any>();
    const [auth] = useRecoilState(authState);
    
    const { isLoading } = useQuery<any, Error>(['resetPassword', form], () =>
        AuthenticationApi.changePassword(form.oldPassword, form.password, auth?.accessToken),
        {
            ...BaseApi.queryWhenExists(form),
            onError: (error) => {
                setSnackbar({ opened: true, severity: 'error', text: error?.message});
            },
            onSuccess: () => {
                setSnackbar({ opened: true, severity: 'success', text: 'Senha alterada com sucesso!'});
            }
        }
    )

    const validationSchema = yup.object().shape({
        oldPassword: yup
          .string()
          .min(8, 'Sua senha deve possuir mais do que 8 caracteres')
          .required('Digite sua senha atual'),
        password: yup
          .string()
          .min(8, 'Sua senha deve possuir mais do que 8 caracteres')
          .required('Digite sua nova senha'),
        changepassword: yup.string().required('Confirme sua nova senha').when("password", {
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: yup.string().oneOf(
            [yup.ref("password")],
            "As senhas devem ser iguais"
          )
        })
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            changepassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setForm(values)
        },
    });

    const handleCloseSnackbar = (_e?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbar({...snackbar, opened: false});
    };
    
    return (
        <div className={styles.main}>
            <div className={styles.card}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.inputsInner}>
                        <TextField
                            className={styles.input}
                            id="oldPassword"
                            label="Senha Atual"
                            variant="outlined"
                            type="password"
                            value={formik.values.oldPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                        />
                        <TextField
                            className={styles.input}
                            id="password"
                            label="Nova senha"
                            variant="outlined"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <TextField
                            className={styles.input}
                            id="changepassword"
                            label="Confirme sua senha"
                            variant="outlined"
                            type="password"
                            value={formik.values.changepassword}
                            onChange={formik.handleChange}
                            error={formik.touched.changepassword && Boolean(formik.errors.changepassword)}
                            helperText={formik.touched.changepassword && formik.errors.changepassword}
                        />
                    </div>
                    <DefaultLoadingButton
                        className={styles.button}
                        text='Salvar Alterações'
                        loading={isLoading}
                        type='submit'
                    />
                </form>
                <Snackbar style={{marginTop: 70}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbar.opened} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.text}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}
  
export default ChangePassword;