import styled, { keyframes } from "styled-components";
import { themes } from "../../themes/themes";
import { ImSpinner8 } from "react-icons/im";

const { primaryFont, lightBlue, darkPurple, white, whiteOpacity, magentaBlue, mediumGray } = themes;

type ButtonType = 'primary' | 'secondary' | 'disabled';
interface Props{
  buttonType: ButtonType;
  disabled: boolean;
  isLoading: boolean;
  fitContent?: boolean;
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
  function getButtonColor(buttonType: ButtonType, disabled: boolean) {
    if(buttonType === 'primary' && disabled === true) {
      return lightBlue
    } else if (buttonType === 'primary' && disabled === false){
      return darkPurple
    }  else if (buttonType === 'secondary' && disabled === true){
      return lightBlue 
    } else if (buttonType === 'secondary' && disabled === false){
      return white
    }
}

 function getColorText (buttonType: ButtonType, disabled: boolean){
  if(buttonType === 'primary' && disabled === true){
    return whiteOpacity
  } else if (buttonType === 'primary' && disabled === false){
    return white
  }else if (buttonType === 'secondary' && disabled === true){
    return whiteOpacity
  }else if (buttonType === 'secondary' && disabled === false){
    return darkPurple
  }
}

export const Button = styled.button<Props>`
    background: ${(props) => {
      if (props.isLoading === true && props.buttonType === 'primary'){
        return darkPurple
      }else if (props.isLoading === true && props.buttonType === 'secondary'){
        return white
      }else{
        return getButtonColor(props.buttonType, props.disabled)
      }
    }};
    color: ${props => getColorText (props.buttonType, props.disabled)};
    width: ${props => props.fitContent ? 'auto' : '100%'};
    font-size: 1rem;
    font-family: ${primaryFont};
    font-weight: 700;
    line-height: 1rem;
    word-wrap: break-word;
    border-radius: 0.625rem;
    border: ${(props) => props.buttonType === 'secondary' ? `1px solid ${darkPurple}` : '0rem'};
    padding: 18px;
    user-select: none;
    
    &:hover{
        cursor: pointer;
        background-color: ${(props) => {
          if (props.buttonType === 'primary' && props.disabled === false){
            return magentaBlue
          }else if (props.buttonType === 'secondary' && props.disabled === false){
            return mediumGray
          }
        }}
    }
`;

export const Icon = styled(ImSpinner8)<{buttonType?: ButtonType}>`
  animation: ${rotate360} 1s linear infinite;
  font-size: 13px;
  ${(props) => {
    if (props.buttonType == 'secondary') {
      return `color: ${themes.darkPurple};`
    }
    else {
      return `color: ${themes.white};`
    }
  }}
`;