import { atom, selector, selectorFamily, useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { SiteTheme } from "../pages/siteEditor/interface/siteTheme";
import CreationOptions from "../pages/siteEditor/interface/creationOptions";
import { getSocialNetworks } from "../helpers/SocialNetworkHelper";
import { SocialNetwork } from "@hivefuse/page-components";
import { CompanySiteComponentType } from "../pages/siteEditor/interface/CompanySiteComponent";
import { useEffect, useState } from "react";

export const editingSiteIdState = atom<string | undefined>({
    key: 'EditingSiteId',
    default: undefined
});

export const siteEditedState = atom<boolean>({
    key: 'SiteEditedState',
    default: false
});

export const siteJustPublishedState = atom<boolean>({
    key: 'SiteJustPublished',
    default: false
});

export const siteStockImagesState = atom<string[]>({
    key: 'SiteStockImages',
    default: []
});

export enum SiteEditorState {
    // Existing site
    LOADING_SITE = 'LOADING_SITE',
    
    // Creating site
    CREATING_SITE_STYLES = 'CREATING_SITE_STYLES',
    CREATING_SITE_COMPONENTS = 'CREATING_SITE_COMPONENTS',
    SITE_CREATED_ANIMATION = 'SITE_CREATED_ANIMATION',

    EDITING_SITE = 'EDITING_SITE',
    UPDATING_SITE = 'UPDATING_SITE',
    READY_TO_PUBLISH = 'READY_TO_PUBLISH'
}

export const siteEditorRecoilState = atom<SiteEditorState>({
    key: 'SiteEditorState',
    default: SiteEditorState.LOADING_SITE
});

export const siteThemeState = atom<SiteTheme | undefined>({
    key: 'SiteTheme',
    default: undefined
});

export const creationOptionsState = atom<CreationOptions | undefined>({
    key: 'CreationOptions',
    default: undefined
});

export interface SiteComponentState {
    componentId: string;
    component: React.ReactElement;
    type: CompanySiteComponentType;
}

export const siteComponentsState = atom<SiteComponentState[]>({
    key: 'SiteComponents',
    default: []
});

export const siteRefMapState = atom<Map<string, React.MutableRefObject<any>>>({
    key: 'SiteRefMapState',
    default: new Map(),
});

export const siteSocialNetworksState = selector<SocialNetwork[]>({
    key: 'SiteSocialNetworks',
    get: ({get}) => {
      const options = get(creationOptionsState);
      return getSocialNetworks(options);
    },
});

export const siteComponentsSizeState = selector<number>({
    key: 'SiteComponentsSize',
    get: ({get}) => {
      const components = get(siteComponentsState);
      return components.length;
    },
});

export const siteComponentIndexState = selectorFamily<number, string>({
    key: 'SiteComponentIndex',
    get: (componentId) => ({get}) => {
      const components = get(siteComponentsState);
      return components.findIndex(a => a.componentId == componentId);
    },
});

export const editingSiteState = atom<boolean>({
    key: 'EditingSite',
    default: false
});

enum ResetingSteps {
    INITIAL,
    PREPARING,
    RESETING,
    RESETED
}
export const resetingRecoilState = atom<ResetingSteps>({
    key: 'ResetingRecoilState',
    default: ResetingSteps.INITIAL
});
export const useSiteRecoilStates = (callback: () => void) => {
    const [resetStatus, setResetStatus] = useRecoilState(resetingRecoilState);
    useEffect(() => {
        if (resetStatus == ResetingSteps.PREPARING) {
            setResetStatus(ResetingSteps.RESETED);
        } else if (resetStatus == ResetingSteps.RESETED) {
            callback();
            setResetStatus(ResetingSteps.INITIAL);
        }
    }, [resetStatus, callback]);
    
    const editingSiteIdReset = useSetRecoilState(editingSiteIdState);
    const siteEditorRecoilStateReset = useSetRecoilState(siteEditorRecoilState);
    const siteStockImagesReset = useSetRecoilState(siteStockImagesState);
    const siteThemeStateReset = useSetRecoilState(siteThemeState);
    const creationOptionsReset = useSetRecoilState(creationOptionsState);
    const siteComponentsReset = useSetRecoilState(siteComponentsState);
    const siteRefMapReset = useSetRecoilState(siteRefMapState);
    const editingSiteReset = useSetRecoilState(editingSiteState);
    const siteEditedReset = useSetRecoilState(siteEditedState);
    const siteJustPublishedReset = useSetRecoilState(siteJustPublishedState);

    const resetAll = () => {
        editingSiteIdReset(undefined);
        siteEditedReset(false);
        siteEditorRecoilStateReset(SiteEditorState.LOADING_SITE);
        siteStockImagesReset([]);
        siteThemeStateReset(undefined);
        creationOptionsReset(undefined);
        siteComponentsReset([]);
        siteRefMapReset(new Map());
        editingSiteReset(false);
        siteJustPublishedReset(false);
        setResetStatus(ResetingSteps.PREPARING);
    };
    return resetAll;
};