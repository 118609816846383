import styled, { keyframes } from "styled-components";
import { lighten } from 'polished';

interface SkeletonProps {
    width?: number;
    height?: number;
    maxWidth?: number;
    minWidth?: number;
}

const animation = keyframes`
    from {
        background-position: 0% 0%;
    }
    to {
        background-position: 135% 0%;
    }
`;

export const Skeleton = styled.div<SkeletonProps>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    max-width: ${(props) => props.maxWidth ? `${props.maxWidth}px` : '100%'};
    min-width: ${(props) => props.minWidth ? `${props.minWidth}px` : '100%'};

    background: linear-gradient(
        -90deg, 
        #91aab4 0%,
        ${lighten(0.9, '#91aab4')} 50%,
        #91aab4 100%);
    background-size: 400% 400%;
    opacity: 0.3;
    animation: ${animation} 1.2s ease-in-out infinite;
    border-radius: 10px;
`;
