import { useMutation } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";

interface StyleCreationPayload {
    businessName: string;
    category: string;
}

export const useStyleCreationQueryKey = ['styleCreation'];
export const useStyleCreation = () => {
    return useMutation({
        retry: false,
        mutationKey: useStyleCreationQueryKey,
        mutationFn: (payload: StyleCreationPayload) => {
            return AxiosConfig.getAxiosInstance()
                .post(
                    '/api/v1/site/style/generate',
                    {
                        context: `${payload.businessName} que é um(a) ${payload.category}`
                    }
                )
                .then((res) => res.data)
                .catch((error) => {
                    console.log(error);
                })
        },
    })
}