import * as yup from 'yup';
import { useFormik } from "formik";
import StandardButton from "../../../../components/Button/Button";
import { PublishmentStep } from "../publishmentModal/publishment";
import Domain from '../../../../interfaces/Domain';
import { DomainType } from '../../../../interfaces/DomainType';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editingSiteIdState } from '../../../../state/siteEditorState';
import { Div, DropdownOptionDomain, PriceMessage, Form, Title, TitleContainer, CloseIcon } from './domainStep.style';
import { useGetDomainStatus } from '../../../../hooks/domainService/useGetDomain';
import { useState } from 'react';
import FireBaseService, { analyticsEvents } from '../../../../infrastructure/firebaseService';
import { authState } from '../../../../state/authState';
import { Alert } from '../../../../components/Alert/Alert';
import { FindUserOnWhiteList } from '../../../../helpers/FindUserOnWhiteList';
import TextInput from '../../../../components/TextInput/TextInput';

interface Props {
    siteId?: string;
    businessName: string;
    goToStep: (step: PublishmentStep) => void;
    setCurrentDomain: (domain: Domain) => void;
    closeModal: () => void;
}

export default function DomainStep(props: Props) {
    const { goToStep, setCurrentDomain, closeModal } = props;
    const siteId = useRecoilValue(editingSiteIdState);
    const [auth] = useRecoilState(authState);
    const userWhiteList = FindUserOnWhiteList();
    const [alertConfirmationSiteWithoutPayment, setAlertConfirmationSiteWithoutPayment] = useState(false);

    const validationSchema = yup.object({
        siteAddress: yup
          .string()
          .required('Digite o endereço do seu site')
          .max(63, 'O seu endereço deve possuir no máximo 63 caracteres'),
    });
 
    const formik = useFormik({
        initialValues: {
            siteAddress: ''
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: async () => {
            FireBaseService.logButtonClick(analyticsEvents.editorSelectedDomainButton);
            setCurrentDomain({
                baseUrl: formik.values.siteAddress,
                type: DomainType.HIVEB
            });
            if(!userWhiteList) { goToStep(PublishmentStep.RESULT); }
            setAlertConfirmationSiteWithoutPayment(true);
        }
    });

    const handleOnConfirmationPressAlert = () => {
        if(userWhiteList && siteId && auth){
            setAlertConfirmationSiteWithoutPayment(false);
            goToStep(PublishmentStep.RESULT);
        }
    }

    const handleOnCancelPressAlert = () => {
        setAlertConfirmationSiteWithoutPayment(false);
    }

    const domainStatus = useGetDomainStatus(formik.values.siteAddress);

    return (
        <>
        <TitleContainer>
            <Title>Endereço do seu site</Title>
            <CloseIcon name='IoMdClose' onClick={closeModal}/>
        </TitleContainer>
        <Form onSubmit={formik.handleSubmit}>
            <Div>
                <TextInput
                    type='text'
                    placeholder='Endereço'
                    inputId='siteAddress'
                    value={formik.values.siteAddress} 
                    onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toLowerCase();
                        formik.handleChange(e);
                    }}
                    error={(!domainStatus.isLoading && domainStatus.data?.isAvailable === false)}
                    errorText={"Endereço já existe"}
                    autoCapitalize="none"
                    loading={domainStatus.isLoading}
                    rightIcon={!!domainStatus?.data?.isAvailable ? 'IoMdCheckmark' : undefined}
                    onStopTyping={() => domainStatus.refetch()}
                />
                <DropdownOptionDomain  
                    options={[
                        { value: '.hiveb.com.br', text: '.hiveb.com.br' },
                    ]}
                    value='.hiveb.com.br'  
                    onValueChange={(selectedValue) => {}}
                />
            </Div>
            <StandardButton
                text='Publicar'
                type='submit'
                buttonType='primary'
                onClick={formik.handleSubmit}
                disabled={
                    !formik.values.siteAddress ||
                    !domainStatus?.data?.isAvailable
                }
            />
        </Form>
        {alertConfirmationSiteWithoutPayment && 
            Alert.alert("Confirmar processamento do site", "", 
            [
                {text: 'Confirmar', onPress: handleOnConfirmationPressAlert, buttonType: 'primary'}, 
                {text: 'Cancelar', onPress: handleOnCancelPressAlert, buttonType: 'secondary'}
            ])
        }
        </>
    )
}