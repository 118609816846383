import styled from "styled-components";
import { themes } from "../../../../themes/themes";
import Icon from "../../../../components/Icon/Icon";

export const SiteCardSectionText = styled.p`
    font-size: 1rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkCharcoal};
    font-weight: 500;
    padding: 12px;
`;

export const SiteCardSectionIcon = styled(Icon)`
    font-size: 1rem;
    color: ${themes.darkCharcoal};
`;


export const SiteCardSection = styled.a`
    border-bottom: 1px solid ${themes.veryLightShadeOfGray};
    margin: 0 24px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }

    &:last-child{
        border-bottom: none;
        margin-bottom: 12px;
    }
`;