import styled, { keyframes } from "styled-components";
import { themes } from "../../themes/themes";
import { ImSpinner6 } from "react-icons/im";

const { primaryFont, white, pinkLace } = themes;

interface Props{
    OnMouseOver: boolean;
}

export const DivDropDown = styled.div`
    position: absolute;
    top: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${white};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 6px;
    padding: 10px 0px;
`;

export const BackGroundSuggestion = styled.div<Props>`
  background-color: ${({ OnMouseOver }) => OnMouseOver ? pinkLace : white};
  cursor: pointer;
  padding: 16px;
  
  &:focus {
    outline: none;
}
`;

export const Suggestion = styled.p`
    font-family: ${primaryFont};
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled(ImSpinner6)`
    position: absolute;
    right: 14px;
    width: 20px;
    height: 20px;
    animation: ${rotate360} 1s linear infinite;
`;