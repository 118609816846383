import { DivButton, PrimaryText, SecundaryText, TextSuggestions, TitleBusinessType } from "./CategoryStep.styles";
import InputText from "../../../../../components/TextInput/TextInput";
import StandardButton from "../../../../../components/Button/Button";
import ReturnArrow from "../../../../../components/ReturnArrow/ReturnArrow";
import { useNavigate } from "react-router-dom";
import FireBaseService, { analyticsEvents } from "../../../../../infrastructure/firebaseService";
import { useFormik } from "formik";
import * as yup from 'yup';
import { SiteCreationStep } from "../../../SiteCreationPage";

interface Props{
    initialValue: string;
    goToStep: (step: SiteCreationStep) => void;
    setCategoryValue: (value: string) => void;
}

export default function CategoryStepInput ({ initialValue, goToStep, setCategoryValue }: Props){
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        category: yup
            .string()
            .required('Digite o tipo da sua empresa'),
    });

    const formik = useFormik({
        initialValues: { category: initialValue },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: () => {
            FireBaseService.logButtonClick(analyticsEvents.siteBusinessTypeForm);
            setCategoryValue(formik.values.category);
            goToStep(SiteCreationStep.NAME);
        },
    });

    const handleGoBack = () => {
        navigate(-1);
    }

    return(
        <>
            <ReturnArrow to={handleGoBack}/>
            <TitleBusinessType>
                Qual o tipo do seu negócio?
            </TitleBusinessType>
            <form onSubmit={formik.handleSubmit}>
                <InputText
                    inputId="category"
                    placeholder="Ex: Maquiadora, Restaurante e etc"
                    value={formik.values.category}
                    error={!!formik.errors.category && formik.touched.category}
                    errorText={formik.errors.category}
                    type="text"
                    onChange={formik.handleChange}
                />
            </form>
            <DivButton>
                <StandardButton
                    type="button"
                    text="Próximo"
                    disabled={formik.values.category == "" || !!formik.errors.category}
                    onClick={formik.handleSubmit}
                />
            </DivButton>
            <TextSuggestions>
                <PrimaryText>
                    Não tem certeza?
                </PrimaryText>
                <SecundaryText onClick={() => goToStep(SiteCreationStep.CATEGORY_SUGGESTIONS)}>
                    Veja algumas sugestões
                </SecundaryText>
            </TextSuggestions>
        </>
    )
}