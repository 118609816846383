import LazyLoad from "react-lazyload";
import { SiteCard, SiteNameUrl, SiteName, PublishmentContainer, StatusSite, IconCircle, PublishmentStatus, SiteCardSection, SiteCardSectionIcon, SiteCardSectionText } from "./SitesList.style";
import IFramePreview from "../iframePreview/IFramePreview";
import { themes } from "../../../../themes/themes";
import { SiteListItem } from "../../../../interfaces/payment/GetSitesListResponse";
import PubStatus from "../../../../interfaces/PublishmentStatusEnum";
import Domain from "../../../../interfaces/Domain";
import BaseConfig from "../../../../configs/BaseConfig";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { selectedSiteListIndexState } from "../../../../state/siteListState";

interface Props {
    sitesList: SiteListItem[];
}

export default function SitesList(props: Props) {
    const { sitesList } = props;
    const setSiteListIndexState = useSetRecoilState(selectedSiteListIndexState);

    const navigate = useNavigate();

    const handleSiteEditClick = (siteId: string) => {
        navigate(`/site/editor?siteId=${siteId}`);
    }

    const isPublished = (publishmentStatus: PubStatus): boolean => {
        return publishmentStatus === 'PUBLISHED';
    }

    const getSiteUrl = (publishmentStatus: PubStatus, siteId: string, domains: Domain[]) => {
        if (publishmentStatus === 'PUBLISHED' && domains && domains.length > 0)
            return `https://${domains[0].baseUrl}.${BaseConfig.config.baseSiteUrl}`;
        return getSitePreviewUrl(siteId);
    }

    const getSitePreviewUrl = (siteId: string) => {
        return `https://${BaseConfig.config.baseSiteUrl}/temporary/${siteId}?ref=${Date.now()}`; 
    }

    const onMoreClicked = (index: number) => {
        setSiteListIndexState(index);
    }
    
    return (
        <>
            {sitesList.map((site: SiteListItem, index: number) => (
                <SiteCard key={site.id}>
                    <LazyLoad height={225} once style={{height: '225px', width: '100%'}}>
                        <IFramePreview src={getSitePreviewUrl(site.id)}/>
                    </LazyLoad>
                    <SiteNameUrl>
                        <SiteName>{site.name}</SiteName>
                    </SiteNameUrl>
                    <PublishmentContainer>
                        <StatusSite>
                            <IconCircle color={isPublished(site.publishmentStatus) ? themes.greenActive : themes.redDeactivate} />
                            <PublishmentStatus> 
                                {isPublished(site.publishmentStatus) ? 'Publicado' : 'Não Publicado'} 
                            </PublishmentStatus>
                        </StatusSite>
                        <SiteCardSection onClick={() => handleSiteEditClick(site.id)}>
                            <SiteCardSectionIcon name='MdEdit'/>
                            <SiteCardSectionText>Editar Site</SiteCardSectionText>
                        </SiteCardSection>
                        <SiteCardSection href={getSiteUrl(site.publishmentStatus, site.id, site.domains)} target="_blank">
                            <SiteCardSectionIcon name='FaExternalLinkAlt'/>
                            <SiteCardSectionText>Acessar Site</SiteCardSectionText>
                        </SiteCardSection>
                        <SiteCardSection onClick={() => onMoreClicked(index)}>
                            <SiteCardSectionIcon name='IoMdMore'/>
                            <SiteCardSectionText>Mais</SiteCardSectionText>
                        </SiteCardSection>
                    </PublishmentContainer>
                </SiteCard>
            ))}
        </>
    );
}
