import { useIsFetching } from 'react-query';
import { useAboutCreationQueryKey } from './siteGenerationService/useAboutCreation';
import { useBannerCreationQueryKey } from './siteGenerationService/useBannerCreation';
import { useServicesProvidedCreationQueryKey } from './siteGenerationService/useServicesProvidedCreation';
import { useStyleCreationQueryKey } from './siteGenerationService/useStyleCreation';
import { useTestimonialsCreationQueryKey } from './siteGenerationService/useTestimonialsCreation';
import { useGetImagesQueryKey } from './imageService/useGetImages';

export function useIsCreatingSiteComponent() {
    const isFetchingAbout = useIsFetching({ queryKey: useAboutCreationQueryKey});
    const isFetchingBanner = useIsFetching({ queryKey: useBannerCreationQueryKey});
    const isFetchingServices = useIsFetching({ queryKey: useServicesProvidedCreationQueryKey});
    const isFetchingStyle = useIsFetching({ queryKey: useStyleCreationQueryKey});
    const isFetchingTestimonials = useIsFetching({ queryKey: useTestimonialsCreationQueryKey});
    const isFetchingImages = useIsFetching({ queryKey: useGetImagesQueryKey});

    return !!isFetchingAbout
        || !!isFetchingBanner
        || !!isFetchingServices
        || !!isFetchingStyle
        || !!isFetchingTestimonials
        || !!isFetchingImages
    ;
}