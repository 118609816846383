import { Alert } from "@mui/material";
import styled from "styled-components";
import { themes } from "../../themes/themes";

const { primaryFont, secundaryFont, white } = themes;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TitleRegister = styled.div`
    display: flex;
    column-gap: 10px;
`;

export const Title = styled.h2`
    font-family: ${secundaryFont};
    color: ${white};
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 2.813rem;
    word-wrap: break-word;
`;

export const TitleContinuation = styled.h2`
    color: #F1A7DC;
    font-size: 2.5rem;
    font-family: ${secundaryFont};
    font-weight: 700;
    line-height: 2.813rem;
    word-wrap: break-word;
`;

export const SubTitle = styled.h3`
    color: ${white};
    font-size: 1.563rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 2.125rem;
    word-wrap: break-word;
    padding-bottom: 40px;
`;

export const InnerLoginCard = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const AlertInstructions = styled(Alert)`
    margin-bottom: 30px;
    text-align: center;
    background: none;
`;