import { ReactNode } from "react";
import styled from "styled-components";
import { themes } from "../../themes/themes";

const { white } = themes;

const Container = styled.div<PopUpProps>`   
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => props.width ? props.width : '100%'};
    max-width: ${(props) => props.maxWidth ? props.maxWidth : '720px'};
    height: ${(props) => props.height ? props.height : '100%'};
    max-height: ${(props) => props.maxHeight ? props.maxHeight : '525px'};

    @media (max-width: ${themes.tabletDimension}) {
        max-height: ${(props) => props.maxHeightTabletDimension ? '555px' : '525px'};
    }
`;

const InnerContainer = styled.div<PopUpProps>`    
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    height: 100%;
    width: 100%;
    padding: 24px;
    border-radius: 1rem;
    border: 1px solid ${white};
    background-color: ${white};
    margin: 20px;
`;

interface PopUpProps{
    children?: ReactNode;
    width?: string;
    maxWidth?: string;
    height?: string;
    maxHeight?: string;
    className?: string;
    maxHeightTabletDimension?:boolean;
}

export default function ConfirmationModalRoot({ children, width, maxWidth, height, maxHeight, className, maxHeightTabletDimension }: PopUpProps){
    return(
        <Container width={width} maxWidth={maxWidth} height={height} maxHeight={maxHeight} className={className} maxHeightTabletDimension={maxHeightTabletDimension}>
            <InnerContainer>
                {children}
            </InnerContainer>
        </Container>
    )
}