import { useState } from 'react';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Tab, Tabs, Typography } from '@mui/material';

import StaffGeneralInfo from './components/StaffGeneralInfo/StaffGeneralInfo';
import StaffWorkingHours from './components/StaffWorkingHours/StaffWorkingHours';
import styles from './EditStaffPage.module.css';

enum EditStaffTabs {
    PROFILE = 'PROFILE',
    WORKING_HOURS = 'WORKING_HOURS',
}

function EditStaffPage() {
    const [tab, setTab] = useState(EditStaffTabs.PROFILE);

    const handleTabChange = (_e: any, newValue: EditStaffTabs) => {
        setTab(newValue);
    };

    return (
        <div className={styles.main}>
            <Typography className={styles.header} variant="h5">
                Equipe
            </Typography>
            <Tabs value={tab} onChange={handleTabChange} className={styles.tab}>
                <Tab
                    className={styles.tab}
                    icon={<AccountBoxIcon/>}
                    iconPosition="start"
                    value={EditStaffTabs.PROFILE}
                    label="Informações Gerais"
                />
                <Tab
                    className={styles.tab}
                    icon={<QueryBuilderIcon/>}
                    iconPosition="start"
                    value={EditStaffTabs.WORKING_HOURS}
                    label="Horário de Trabalho"
                />
            </Tabs>
            {tab === EditStaffTabs.PROFILE && (<StaffGeneralInfo/>)}
            {tab === EditStaffTabs.WORKING_HOURS && (<StaffWorkingHours/>)}
        </div>
    );
}
  
export default EditStaffPage;