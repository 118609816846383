import styled from "styled-components";
import ComponentSkeleton from "../../../../components/Skeleton-Loading/steletonLoading";
import { themes } from "../../../../themes/themes";

export const MainContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const Skelleton = styled(ComponentSkeleton)`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0px;
`;

export const IFrame = styled.iframe`
    width: 100%;
    height: 100%;
`;

export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    background-color: ${themes.transparentOverlay};
`;