import styled from "styled-components";
import { themes } from "../../themes/themes";

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${themes.darkGray};
    border-radius: 10px;
    box-shadow: ${themes.inputShadow};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`

export const Logo = styled.img`
    width: 20px;
    height: 20px;
    margin: 16px 0;
    margin-right: 10px;
    user-select: none;
`


export const Text = styled.p`
    font-size: 14px;
    font-family: ${themes.primaryFont};
    font-weight: 500;
    color: ${themes.googleFont};
    user-select: none;
    text-align: center;
`
