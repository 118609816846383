import styled from 'styled-components';
import { AddSectionDirection } from '../../../interface/addSectionDirectionEnum';
import { themes } from '../../../../../themes/themes';

interface AddsSectionButtonProps {
    higher?: boolean;
    direction: AddSectionDirection;
}

export const AddSectionsButton = styled.div<AddsSectionButtonProps>`
    position: absolute;
    background-color: ${themes.white};
    border-radius: 50%;
    display: table;
    height: 50px;
    width: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    z-index: ${props => props.higher ? '100' : '10'};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${themes.veryLightShadeOfGray};
    }

    ${props => props.direction == AddSectionDirection.ABOVE && (`
        top: -25px;
    `)}

    ${props => props.direction == AddSectionDirection.BELLOW && (`
        bottom: -25px;
    `)}
`;