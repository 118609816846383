import { useState } from 'react';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Tab, Tabs, Typography } from '@mui/material';

import styles from './ProfilePage.module.css';
import GeneralInfo from './components/generalInfo/GeneralInfo';
import ChangePassword from './components/changePassword/ChangePassword';

enum ProfileTabs {
    PROFILE = 'PROFILE',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

function ProfilePage() {
    const [tab, setTab] = useState(ProfileTabs.PROFILE);

    const handleTabChange = (_e: any, newValue: ProfileTabs) => {
        setTab(newValue);
    };

    return (
        <div className={styles.main}>
            <Typography className={styles.header} variant="h5">
                Perfil
            </Typography>
            <Tabs value={tab} onChange={handleTabChange} className={styles.tab}>
                <Tab
                    className={styles.tab}
                    icon={<AccountBoxIcon/>}
                    iconPosition="start"
                    value={ProfileTabs.PROFILE}
                    label="Informações Gerais"
                />
                <Tab
                    className={styles.tab}
                    icon={<VpnKeyIcon/>}
                    iconPosition="start"
                    value={ProfileTabs.CHANGE_PASSWORD}
                    label="Alterar Senha"
                />
            </Tabs>
            {tab === ProfileTabs.PROFILE && (<GeneralInfo/>)}
            {tab === ProfileTabs.CHANGE_PASSWORD && (<ChangePassword/>)}
        </div>
    );
}
  
export default ProfilePage;