import { ColorCategories } from "../../interface/colorCategories";
import { ColorCategory } from "../../interface/colorCategory";

export const coldColorCategories: ColorCategories = {
    category: ColorCategory.Cold,
    colorThemes: [
        {
            ctaColor: '#313D41',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E0E2ED',
            gradientColors: []
        },
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#000000',
            colorfulBackgroundColor: '#B3C3CF',
            gradientColors: []
        },
        {
            ctaColor: '#484955',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E0E2ED',
            gradientColors: []
        },
        {
            ctaColor: '#1470AF',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#EAEAEE',
            gradientColors: []
        },
    ]
};