import { useQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";

export const useAboutCreationQueryKey = ['aboutCreation'];
export const useAboutCreation = (businessName: string, category: string, componentId: string) => {
    return useQuery<any>({
        retry: true,
        enabled: false,
        refetchOnWindowFocus: false,
        queryKey: [...useAboutCreationQueryKey, componentId],
        queryFn: () => {
            return AxiosConfig.getAxiosInstance()
                .get(
                    '/api/v1/site/section/generate/about',
                    { params: {context: `${businessName} que é um(a) ${category}`} }
                )
                .then((res) => res.data)
                .catch((error) => {
                    console.log(error);
                })
        },
    })
}