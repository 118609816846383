import CheckIcon from '@mui/icons-material/Check';
import {
    Avatar, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography
} from '@mui/material';

import styles from './MultiselectDropdown.module.css';

interface Props {
    inputLabel: string;
    dataList: any[];
    textProperty: string;
    valueProperty: string;
    avatarProperty?: string;
    selectedData: string[];
    renderAvatar?: boolean;
    subTextProperty?: string;
    subTextType?: SubtextType;
    showAllButton?: boolean;
    onChange: (selected: string[]) => void;
}

export enum SubtextType {
    MONEY = 'MONEY',
    TEXT = 'TEXT'
}

function MultiselectDropdown(props: Props) {
    const { avatarProperty, dataList, inputLabel, renderAvatar, selectedData, subTextProperty, subTextType, textProperty, showAllButton, valueProperty, onChange } = props;

    const handleChange = (event: any) => {
        const { target: { value } } = event;

        if (value[value.length - 1] === "all") {
            onChange(selectedData.length === dataList.length ? [] : dataList.map((data) => data[valueProperty]));
            return;
        }

        onChange(typeof value === "string" ? value.split(",") : value);
    };

    const getSelectedNames = () => {
        const filteredSelected = dataList.filter((data) => selectedData.includes(data[valueProperty]));
        return filteredSelected.map((data) => data[textProperty]).join(", ");
    }

    const getSubtextProperty = (data: any) => {
        if (subTextProperty === undefined) {
            return '';
        }

        if (subTextType === SubtextType.MONEY) {
            return `R$ ${data[subTextProperty]}`
        }

        return data[subTextProperty];
    }

    const isAllSelected = dataList.length > 0 && selectedData.length === dataList.length;
    const shouldRenderAvatar = renderAvatar !== false && !!avatarProperty;

    return (
        <FormControl className={styles.control}>
            <InputLabel>{inputLabel}</InputLabel>
            <Select
                multiple
                value={selectedData}
                onChange={handleChange}
                input={<OutlinedInput label={inputLabel} />}
                renderValue={() => {
                    if (isAllSelected)
                        return 'Todos';
                    return getSelectedNames();
                }}
            >
                {showAllButton && (
                    <MenuItem value={'all'}>
                        <Checkbox checked={isAllSelected} />
                        <div>
                            <ListItemText primary={'Todos'} />
                        </div>
                    </MenuItem>
                )}
                {dataList.map((data, i) => (
                    <MenuItem key={i} value={data[valueProperty]}>
                        <div className={shouldRenderAvatar ? styles.staffAvatar : undefined}>
                            {shouldRenderAvatar && (
                                <>
                                    <Avatar alt={data[textProperty]} src={data[avatarProperty] || ' '}/>
                                    {selectedData.indexOf(data[valueProperty]) > -1 && (
                                        <div className={styles.selectBox}>
                                            <CheckIcon className={styles.checkIcon}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {!shouldRenderAvatar && (
                            <Checkbox checked={selectedData.indexOf(data[valueProperty]) > -1} />
                        )}
                        <div>
                            <ListItemText primary={data[textProperty]} />
                            {!!subTextProperty && (
                                <Typography variant='caption'>{getSubtextProperty(data)}</Typography>
                            )}
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
  
export default MultiselectDropdown;