import { useMutation } from "react-query";
import BaseConfig from "../../configs/BaseConfig";
import CreateCreditCardTokenResponse from "../../interfaces/payment/CreateCreditCardTokenResponse";
import CreateCreditCardTokenRequest from "../../interfaces/payment/CreateCreditCardTokenRequest";
import axios from "axios";

const unexpectedErrorText = "Erro inesperado, tente novamente";

const createCreditCardToken = async (data: CreateCreditCardTokenRequest) => {
    try{
        const response = await axios
            .post(
                `${BaseConfig.config.pagarmeApiUrl}/core/v5/tokens?appId=${BaseConfig.config.pagarmePublicKey}`,
                data
            );

        if (response.status === 200)
            return response.data;
        throw new Error(unexpectedErrorText).message
    } catch(err) {
        throw new Error(unexpectedErrorText).message;
    }
}
        
export const useCreateCreditCardToken = (onSuccessCallback: (response: CreateCreditCardTokenResponse) => void) =>
    useMutation<CreateCreditCardTokenResponse, Error, CreateCreditCardTokenRequest>({
        mutationFn: createCreditCardToken,
        onSuccess: (response) => {
            onSuccessCallback(response);
        }
    });
