import { useQuery } from "react-query"
import axios, { AxiosError } from "axios";
import { ZipCodeAddressResponse } from "../../interfaces/payment/ZipCodeAddressResponse";

const expectedError = 'Não foi possível encontrar o CEP';
const getZipCodeAddress = async (zipCode: string): Promise<ZipCodeAddressResponse> => {
    const result = await axios
    .get(`https://viacep.com.br/ws/${zipCode}/json`)

    if(result.status === 200 && result.data.erro == undefined)
        return result.data;

    throw new Error(expectedError);
}

export const useGetZipCodeAddress = (zipCode: string) => {
    return useQuery<ZipCodeAddressResponse>({
        enabled: false,
        queryKey: ['useGetZipCodeAddress', zipCode],
        queryFn: () => getZipCodeAddress(zipCode),
        refetchOnWindowFocus: false,
        retry: (_failureCount, error) => {
            if (error instanceof AxiosError && error.response?.status == 404)
                return false;

            if (error instanceof Error && error.message == expectedError)
                return false;
            
            return true;
        },
    })
}