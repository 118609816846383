import { useParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import styles from './PaymentsDetails.module.css';
import InteractionCard from '../../components/InteractionCard/InteractionCard';
import moment from 'moment';

function PaymentsDetails() {
    const params = useParams();

    return (
      <div className={styles.main}>
        <Typography className={styles.header} variant="h5">
            Pagamento
        </Typography>
        <div className={styles.card}>
            <div className={styles.leftContainer}>
                <Typography className={styles.sectionTitle} variant="body1">
                    Valor
                </Typography>
                <Typography className={styles.value} variant="h4">
                    R$ 10,00
                </Typography>
            </div>
            <div className={styles.rightContainer}>
                <div>
                    <Typography className={styles.sectionTitle} variant="body1">
                        Data
                    </Typography>
                    <Typography className={styles.sectionDescription} variant="body2">
                        11/11/2022 12:23
                    </Typography>
                </div>
                <div>
                    <Typography className={styles.sectionTitle} variant="body1">
                        Forma de Pagamento
                    </Typography>
                    <Typography className={styles.sectionDescription} variant="body2">
                        PIX
                    </Typography>
                </div>
                <div>
                    <Typography className={styles.sectionTitle} variant="body1">
                        Status
                    </Typography>
                    <Typography className={styles.sectionDescription} variant="body2">
                        Pago
                    </Typography>
                </div>
                <div>
                    <Typography className={styles.sectionTitle} variant="body1">
                        Código
                    </Typography>
                    <Typography className={styles.sectionDescription} variant="body2">
                        DS6V7CX8FD8SA9
                    </Typography>
                </div>
            </div>
        </div>
        <div className={styles.cardHorizontal}>
            <Typography variant='h6' className={styles.cardTitle}>Serviços</Typography>
            <div>
                <InteractionCard icon='Handyman' title={'Corte de Cabelo'} date={moment()} onClick={() => console.log('ae')}/>
            </div>
        </div>
        <div className={styles.cardHorizontal}>
            <Typography variant='h6' className={styles.cardTitle}>Cliente</Typography>
            <div className={styles.clientInfoContainer}>
                <div>
                    <Typography className={styles.sectionTitle} variant="body1">
                        Nome
                    </Typography>
                    <Typography className={styles.sectionDescription} variant="body2">
                        Osvaldo Barros
                    </Typography>
                </div>
                <div>
                    <Typography className={styles.sectionTitle} variant="body1">
                        CPF
                    </Typography>
                    <Typography className={styles.sectionDescription} variant="body2">
                        012.345.678-90
                    </Typography>
                </div>
                <div>
                    <Typography className={styles.sectionTitle} variant="body1">
                        Telefone
                    </Typography>
                    <Typography className={styles.sectionDescription} variant="body2">
                        (81) 99759-7878
                    </Typography>
                </div>
            </div>
        </div>
      </div>
    );
}

export default PaymentsDetails;