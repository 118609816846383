import { ColorCategories } from "../../interface/colorCategories";
import { ColorCategory } from "../../interface/colorCategory";

export const monocrhomaticColorCategories: ColorCategories = {
    category: ColorCategory.Monocrhomatic,
    colorThemes: [
        {
            ctaColor: '#2C2C2C',
            neutralBackgroundColor: '#EAE6E1',
            colorfulBackgroundColor: '#C4C4C4',
            gradientColors: []
        },
        {
            ctaColor: '#2F2D2D',
            neutralBackgroundColor: '#D7CFC9',
            colorfulBackgroundColor: '#92939C',
            gradientColors: []
        },
        {
            ctaColor: '#A8A6A1',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#DFE0E1',
            gradientColors: []
        },
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#EEEDEB',
            gradientColors: []
        },
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#F1F1EF',
            gradientColors: []
        },
    ]
};