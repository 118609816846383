export class ImageHelper {
    private static usedIndexes: Set<number> = new Set<number>();
  
    static getRandomImages(images: string[], count: number): string[] {
      const randomImages: string[] = [];
  
      if (count <= 0 || images.length === 0) {
        return randomImages;
      }
  
      while (randomImages.length < count) {
        const randomIndex = ImageHelper.getRandomIndex(images);
  
        if (randomIndex === -1) {
          break;
        }
  
        randomImages.push(images[randomIndex]);
        ImageHelper.usedIndexes.add(randomIndex);
      }
  
      return randomImages;
    }
  
    private static getRandomIndex(images: string[]): number {
      const unusedIndexes: number[] = [];
      images.forEach((_, index) => {
        if (!ImageHelper.usedIndexes.has(index)) {
          unusedIndexes.push(index);
        }
      });
  
      if (unusedIndexes.length === 0) {
        ImageHelper.usedIndexes.clear();
        return ImageHelper.getRandomIndex(images);
      }
  
      const randomIndex = Math.floor(Math.random() * unusedIndexes.length);
      return unusedIndexes[randomIndex];
    }
}