import { Font, FontTag, ThemeFont } from "@hivefuse/page-components";

const themeFonts: ThemeFont[] = [
    {
        title: {
            font: Font.BREE_SERIF,
            url: "https://fonts.googleapis.com/css2?family=Bree+Serif:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.NUNITO_SANS,
            url: "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Bold
    },
    {
        title: {
            font: Font.CABIN,
            url: "https://fonts.googleapis.com/css2?family=Cabin:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.RALEWAY,
            url: "https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Modern
    },
    {
        title: {
            font: Font.DANCING_SCRIPT,
            url: "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.QUICKSAND,
            url: "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Elegant
    },
    {
        title: {
            font: Font.LORA,
            url: "https://fonts.googleapis.com/css2?family=Lora:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.INTER,
            url: "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Elegant
    },
    {
        title: {
            font: Font.EXO_2,
            url: "https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.INTER,
            url: "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Classic
    },
    {
        title: {
            font: Font.JOSEFIN_SANS,
            url: "https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.LIBRE_BASKERVILLE,
            url: "https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Modern
    },
    {
        title: {
            font: Font.QUATTROCENTO,
            url: "https://fonts.googleapis.com/css2?family=Quattrocento:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.QUATTROCENTO_SANS,
            url: "https://fonts.googleapis.com/css2?family=Quattrocento+Sans:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Classic
    },
    {
        title: {
            font: Font.PACIFICO,
            url: "https://fonts.googleapis.com/css2?family=Pacifico:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.OPEN_SANS,
            url: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Elegant
    },
    {
        title: {
            font: Font.ROBOTO,
            url: "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.POPPINS,
            url: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Modern
    },
    {
        title: {
            font: Font.UBUNTU,
            url: "https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.OPEN_SANS,
            url: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Bold
    },
    {
        title: {
            font: Font.VARELA_ROUND,
            url: "https://fonts.googleapis.com/css2?family=Varela+Round:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.PT_SANS,
            url: "https://fonts.googleapis.com/css2?family=PT+Sans:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Classic
    },
    {
        title: {
            font: Font.WORK_SANS,
            url: "https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap"
        },
        description: {
            font: Font.OPEN_SANS,
            url: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"
        },
        tag: FontTag.Modern
    }
];

export default themeFonts;