import { ColorCategories } from "../../interface/colorCategories";
import { ColorCategory } from "../../interface/colorCategory";

export const darkColorCategories: ColorCategories = {
    category: ColorCategory.Dark,
    colorThemes: [
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#FAFAFA',
            colorfulBackgroundColor: '#DCDCDC',
            gradientColors: []
        },
        {
            ctaColor: '#9C8B7E',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#D5D2CD',
            gradientColors: []
        },
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#EEEDEB',
            gradientColors: []
        },
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#F1F1EF',
            gradientColors: []
        },
    ]
};