import styled from 'styled-components';
import { themes } from '../../../../themes/themes';
import { PiAlignLeftSimple, PiAlignRightSimple } from "react-icons/pi";
import { IoIosArrowBack } from "react-icons/io";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 24px;
    max-height: calc(100vh - 48px);
    z-index: 9999;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: ${themes.white};
    padding-top: 24px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    right: 12px;

    @media (max-width: ${themes.tabletDimension}){
        width: 100%;
        height: 100%;
        max-height: none;
        max-width: none;
        border-radius: 0px;
        top: 0;
        right: 0;
    }
`;

export const ChildrenContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    padding: 12px 24px;
    padding-bottom: 24px;

    ::-webkit-scrollbar {
        width: 16px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        border: 4px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }
`;

export const TopRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${themes.gray};
`;

export const Title = styled.h3`
    font-family: ${themes.dashBoardFont};
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.25rem;
    color: ${themes.darkPurple};
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

export const CloseButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 0.875rem;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const CloseButton = styled.p`
    display: inline-block;
    font-family: ${themes.dashBoardFont};
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: ${themes.darkPurple};
`;

export const ChangeDirectionButton = styled.div`
    display: flex;
    align-items: center;
    padding: 6px;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const LeftIcon = styled(PiAlignLeftSimple)`
    font-size: 1.25rem;
`;

export const RightIcon = styled(PiAlignRightSimple)`
    font-size: 1.25rem;
`;

export const GoBackText = styled.h3`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem;
    margin-left: 6px;
    color: ${themes.darkGray};
`;

export const GoBackIcon = styled(IoIosArrowBack)`
    font-size: 1.25rem;
    color: ${themes.darkGray};
`;

export const GoBackButton = styled.div`
    display: flex;
    align-items: center;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;