import styled from 'styled-components';
import { themes } from '../../../../../themes/themes';

export const EditContainer = styled.div`
`;
export const Input = styled.input`
    margin-bottom: 1rem;
`;

export const Select = styled.select`
    margin-bottom: 1rem;
`;

export const InputLabel = styled.p<{ noMarginTop?: boolean }>`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
    margin-top: ${props => !!props.noMarginTop ? '0' : '12px'};
    margin-bottom: 12px;
`;