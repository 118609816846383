import { forwardRef, useImperativeHandle, useState } from 'react';

import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';

export interface SnackBarOptions {
    opened?: boolean;
    severity?: AlertColor;
    text?: string;
    snackPosition?: SnackbarOrigin;
    autoHideDuration?: number;
}

const SnackBarFeedback = forwardRef<any, SnackBarOptions>((props, ref) => {
    const getInitialProps = (): SnackBarOptions => {
        return {
            ...props,
            opened: props.opened ?? false,
            snackPosition: props.snackPosition ?? {
                vertical: 'top',
                horizontal: 'center'
            },
            autoHideDuration: props.autoHideDuration ?? 2500,
            severity: props.severity ?? 'info'
        }
    }
    const [snackbar, setSnackbar] = useState<SnackBarOptions>(getInitialProps());

    useImperativeHandle(ref, () => ({
        open(text?: string, severity?: AlertColor) {
            if (!!text && !!severity)
                setSnackbar(prevProps => ({ ...prevProps, text: text, severity: severity, opened: true })); 
            else if (!!text)
                setSnackbar(prevProps => ({ ...prevProps, text: text, opened: true })); 
            else if (!!severity)
                setSnackbar(prevProps => ({ ...prevProps, severity: severity, opened: true })); 
            else
                setSnackbar(prevProps => ({ ...prevProps, opened: true })); 
        }
    }));

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
          return;

        setSnackbar(prevProps => ({ ...prevProps, opened: false }));
    };

    return (
        <Snackbar
            style={{top: 40}}
            anchorOrigin={snackbar.snackPosition}
            open={snackbar.opened}
            autoHideDuration={snackbar.autoHideDuration}
            onClose={handleCloseSnackbar}
        >
            <Alert
                onClose={handleCloseSnackbar}
                severity={snackbar.severity}
                sx={{ width: '100%' }}
            >
                {snackbar.text}
            </Alert>
        </Snackbar>
    );
});
  
export default SnackBarFeedback;