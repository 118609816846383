import styled from 'styled-components';
import ImageUploading from 'react-images-uploading';
import { themes } from '../../../../../../themes/themes';
import StandardButton from '../../../../../../components/Button/Button';
import { MdOutlineUploadFile } from 'react-icons/md';

export const LoadingContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InnerContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: 12px;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    position: relative;

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        border: 2px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }

    @media (max-width: 600px){
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const Uploader = styled(ImageUploading)`
`;

export const UploaderInner = styled.div<{ isDragging: boolean }>`
    width: 100%;
    height: 0;
    padding-top: calc(70%);
    border-radius: 8px;
    outline: 1px solid ${themes.darkGray};
    box-sizing: border-box;
    outline-style: dashed;
    outline-width: 2px;
    position: relative;
    opacity: ${props => props.isDragging ? '0.5' : '1'};

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export const UploaderContent = styled.div`
    top: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ImageIcon = styled(MdOutlineUploadFile)`
    font-size: 25px;
    color: ${themes.darkGray};
    user-select: none;
`;

export const ImageUploaderTitle = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    color: ${themes.darkGray};
    margin-top: 6px;
    user-select: none;
`;

export const ImageUploaderDescription = styled.p`
    font-family: ${themes.dashBoardFont};
    text-align: center;
    font-size: 0.75rem;
    color: ${themes.darkGray};
    margin-top: 6px;
    user-select: none;
`;

export const ErrorMessageContainer = styled.div`
    flex-grow: 1;
    margin: 24px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const ErrorMessage = styled.h2`
   font-family: ${themes.dashBoardFont};
   text-align: center;
   font-size: 1.25rem;
   color: ${themes.onyx};
   margin-bottom: 24px;
`;

export const RetryButton = styled(StandardButton)`
    max-width: 500px;
`;
