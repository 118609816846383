import { Outlet } from "react-router-dom";
import FooterDefault from "../DefaultFooter/FooterDefault";
import HeaderDefault from "../DefaultHeader/HeaderDefault";
import { BackGround, Container, DivImgsFooter, DivInnerContainer, Ellipse2, Ellipse6, Ellipse7, Ellipse8 } from "./DefaultDesign.styles";
import Ellipse_2 from '../../assets/Ellipse2.svg';
import Ellipse_6 from '../../assets/Ellipse6.svg';
import Ellipse_7 from '../../assets/Ellipse7.svg';
import Ellipse_8 from '../../assets/Ellipse8.svg';

export default function DefaultDesign() {
    return(
        <BackGround>
            <Container>
                <HeaderDefault/>
                <DivInnerContainer>
                    <Outlet/>
                </DivInnerContainer>
                <FooterDefault/>
                <DivImgsFooter>
                    <Ellipse2 src={Ellipse_2} alt='Ellipse'/>
                    <Ellipse6 src={Ellipse_6} alt='Ellipse'/>
                    <Ellipse7 src={Ellipse_7} alt='Ellipse'/>
                    <Ellipse8 src={Ellipse_8} alt='Ellipse'/>
                </DivImgsFooter>
            </Container>
        </BackGround>
    );
}