import { useEffect, useImperativeHandle, useState } from 'react';

import EditList from './editList/editList';
import { BackgroundProps, List, ListProps, Position, Size } from '@hivefuse/page-components';
import { useServicesProvidedCreation } from '../../../../hooks/siteGenerationService/useServicesProvidedCreation';
import { ImageHelper } from '../../utils/imageUtils';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { GetPreferredSocialNetwork, GetSocialMediaUrl } from '../../../../helpers/SocialNetworkHelper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteSocialNetworksState, siteStockImagesState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';

interface Props {
    componentId: string;
    existingProps?: ListProps;
}

export enum ListEditScreen {
    GENERAL = 'GENERAL',
    BACKGROUND = 'BACKGROUND',
    LIST_ITEM = 'LIST_ITEM',
    NEW_ITEM = 'NEW_ITEM'
}

const ListCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const socialNetworks = useRecoilValue(siteSocialNetworksState);
    const theme = useRecoilValue(siteThemeState)!;
    const images = useRecoilValue(siteStockImagesState);
    const { isLoading: isLoadingServices, isRefetching, refetch: getServices, data: servicesGenerated } = useServicesProvidedCreation(creationOptions.name, creationOptions.category, componentId);
    const [listProps, setListProps] = useState<ListProps>(existingProps ?? {});
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const [currentEditScreen, setCurrentEditScreen] = useState(ListEditScreen.GENERAL);
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return listProps;
        },
        regenerateStyle() {
            handleGenerateStyle()
        },
        regenerateContent() {
            ReGenerateContent()
        },
        regenerateEntireSection() {
            ReGenerateEntireSection()
        }
    })); 

    useEffect(() => {
        if (!existingProps) {
            getServices();
            getInitialProps();
        }

        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!servicesGenerated && !listProps.listBlocks?.length) {
            const images = getImages();
            const listBlocks = servicesGenerated?.listBlocks?.map((service: any, i: number) => {
                const button = getButton(service.ctaButtonText);
                return {
                    title: service.title,
                    description: service.description,
                    imageUrl: !!images ? images[i] : "",
                    button
                }
            });
            setListProps(prevState => { return { ...prevState, listBlocks }});
        } else if (!!servicesGenerated && !!listProps.listBlocks?.length) {
            const images = getImages();
            const listBlocks = servicesGenerated?.listBlocks?.map((service: any, i: number) => {
                const button = getButton(service.ctaButtonText);
                return {
                    title: service.title,
                    description: service.description,
                    imageUrl: listProps?.listBlocks?.at(i)?.imageUrl == undefined ? images[i] : listProps?.listBlocks?.at(i)?.imageUrl,
                    button
                }
            });
            setListProps(prevState => { return { ...prevState, listBlocks }});
        }
    }, [servicesGenerated]);

    useEffect(() => {
        if (!!theme) {
            setListProps(prevState => { return {
                ...prevState,
                fonts: theme?.themeFont
            } });
        }
    }, [theme]);

    useEffect(() => {
        setListProps(prevState => { return { ...prevState, isLoading: isLoadingServices || isRefetching }});
    }, [isLoadingServices, isRefetching]);

    const getButton = (ctaText: string) => {
        if (!socialNetworks)
            return undefined;

        const preferredSocialNetwork = GetPreferredSocialNetwork(socialNetworks);
        if (!preferredSocialNetwork)
            return undefined;

        return {
            label: ctaText,
            url: GetSocialMediaUrl(preferredSocialNetwork),
            borderEdge: theme.borderEdges,
            backgroundColor: theme.colorTheme.ctaColor,
            fontColor: getFontColor(theme.colorTheme.ctaColor)
        };
    }

    const getImages = () => {
        return ImageHelper.getRandomImages(images, 6);
    }

    const getInitialProps = () => {
        const newBannerStyleProps = GetNewStyle();
        setListProps(prevState => { return { ...prevState, title: 'Serviços', ...newBannerStyleProps }});
    }

    const handleGenerateAndImagesStyle = () => {
        setSiteEditedState(true);
        const newStyle = GetNewStyle();
        const newProps = {...listProps, ...newStyle};
        if (newProps.listBlocks) {
            for (let listBlock of newProps.listBlocks) {
                listBlock.imageUrl = ImageHelper.getRandomImages(images, 1)[0];
            }
        }

        setListProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleGenerateImages = () => {
        setSiteEditedState(true);
        const newProps = {...listProps };
        if (newProps.listBlocks) {
            for (let listBlock of newProps.listBlocks) {
                listBlock.imageUrl = ImageHelper.getRandomImages(images, 1)[0];
            }
        }

        setListProps(prevState => { return {...prevState, ...newProps} });
    }

    const handleGenerateStyle = () => {
        setSiteEditedState(true);
        const newStyle = GetNewStyle();
        setListProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleRegenerateStyle = () => {
        setSiteEditedState(true);
        const newStyle = GetRegeneratedStyle();
        setListProps(prevState => { return {...prevState, ...newStyle} });
    }

    const GetNewStyle = (): ListProps => {
        return {
            imagesBorderEdge: theme.borderEdges,
            titlePosition: GenerateAlignment(),
            verticalPadding: GeneratePadding(),
            background: GenerateBackground(),
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont,
            imageShadows: GenerateShadow()
        };
    }

    const GetRegeneratedStyle = (): ListProps => {
        return {
            imagesBorderEdge: theme.borderEdges,
            titlePosition: GenerateAlignment(),
            verticalPadding: GeneratePadding(),
            fonts: theme?.themeFont,
            imageShadows: GenerateShadow()
        };
    }

    const GenerateShadow = (): string | undefined => {
        if (!theme.useShadows)
            return undefined;

        return "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px";
    }

    const GenerateAlignment = (): Position => {
        const allowedPositions = [Position.LEFT, Position.CENTER];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GeneratePadding = (): Size => {
        return Object.values(Size)[Math.floor(Math.random() * Object.values(Size).length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const ReGenerateContent = () => {
        setSiteEditedState(true);
        getServices();
    }

    const ReGenerateEntireSection = () => {
        handleGenerateAndImagesStyle();
        ReGenerateContent();
    }

    const onEditing = (newProps: ListProps) => {
        setSiteEditedState(true);
        setListProps(prevState => { return {...prevState, ...newProps} });
    }

    const getEditComponent = () => {
        return (
            <EditList
                onEdit={onEditing}
                currentProps={listProps}
                bannerEditScreen={currentEditScreen}
                images={images}
                goToScreen={goToScreen}
            />
        )
    }

    const goToScreen = (screen: ListEditScreen) => {
        setCurrentEditScreen(screen);
    }

    const handleInnerNavigationGoBack = () => {
        setCurrentEditScreen(ListEditScreen.GENERAL);
    }

    const handleOnEdit = () => {
        setCurrentEditScreen(ListEditScreen.GENERAL);
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            editTitleHeader='Lista'
            onInnerNavigation={currentEditScreen != ListEditScreen.GENERAL}
            onEdit={handleOnEdit}
            editComponent={getEditComponent()}
            onRegenerateStyle={handleRegenerateStyle}
            onRegenerateContent={ReGenerateContent}
            onRegenerateEntireSection={ReGenerateEntireSection}
            onInnerNavigationGoBack={handleInnerNavigationGoBack}
        >
            <List
                data={listProps}
            />
        </BackdropEditor>
    )
});

export default ListCreation;