import { AspectRatio, BorderEdge, GalleryProps, Position, Size } from "@hivefuse/page-components";
import { AddListItemText, DeleteIcon, EditContainer, EditIcon, InputLabel, ListBlock, ListItems, Image, ButtonsContainer, ImagePreview, InputRow, ControlsRow, EditButton, SaveNewBlockButton } from "./editGallery.style";
import { GalleryEditScreen } from "../galleryCreation";
import { useState } from "react";
import { Alert } from "../../../../../components/Alert/Alert";
import ImageSelectionModal from "../../imageSelectionModal/imageSelectionModal";
import InputText from "../../../../../components/TextInput/TextInput";
import ColorPicker from "../../../../../components/ColorPicker/ColorPicker";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import { getFontColor } from "../../../utils/colorUtils";

interface Props {
    currentProps: GalleryProps;
    onEdit: (newProps: GalleryProps) => void;
    images: string[];
    editScreen: GalleryEditScreen;
    goToScreen: (screen: GalleryEditScreen) => void;
}

export default function EditGallery(props: Props) {
    const { currentProps, onEdit, images, editScreen, goToScreen } = props;
    const [editingItemIndex, setEditingItemIndex] = useState<number | undefined>(undefined);
    const [imageSelectionModalOpened, setImageSelectionModalOpened] = useState(false);
    const [newImage, setNewImage] = useState<string | undefined>(undefined);

    const handleOnListItemRemoveClick = (index: number) => {
        Alert.alert(
            '',
            'Tem certeza que deseja remover esta imagem?',
            [
                {
                    text: 'Remover',
                    buttonType: 'secondary',
                    onPress: () => handleRemoveListItem(index)
                },
                {
                    text: 'Cancelar',
                    buttonType: 'primary'
                }
            ],
            true
        );
    }

    const handleRemoveListItem = (index: number) => {
        if (!currentProps.imageUrls) 
            return;

        const newArray = [...currentProps.imageUrls];
        newArray.splice(index, 1);
        onEdit({
            ...currentProps,
            imageUrls: newArray
        });
    }

    const handleEditListClick = (index: number) => {
        setEditingItemIndex(index);
        goToScreen(GalleryEditScreen.LIST_ITEM);
    }

    const handleNewBlockClick = () => {
        setNewImage(undefined);
        goToScreen(GalleryEditScreen.NEW_ITEM);
    }

    const handleImageChange = (image: string, index: number) => {
        if (!currentProps.imageUrls) 
            return;

        const newArray = [...currentProps.imageUrls];
        newArray[index] = image;
        onEdit({
            ...currentProps,
            imageUrls: newArray
        });
    }

    const handleAddNewBlock = () => {
        if (!currentProps.imageUrls) 
            return;

        const newArray = [...currentProps.imageUrls, newImage!];
        onEdit({
            ...currentProps,
            imageUrls: newArray
        });
        setNewImage(undefined);
        goToScreen(GalleryEditScreen.GENERAL);
    }

    const handleEditNewBlockImage = (value: string | undefined) => {
        setNewImage(value);
    }

    const handleTitleChange = (e: any) => {
        onEdit({
            ...currentProps,
            title: e.target.value
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e);
        onEdit({
            ...currentProps,
            background: {
                backgroundColor: e
            },
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e
        });
    }

    const handleTitleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            titlePosition: e
        });
    }

    const handleImagesBorderEdgeChange = (e: any) => {
        onEdit({
            ...currentProps,
            borderEdge: e
        });
    }

    const handleAspectRatioChange = (aspectRatio: AspectRatio) => {
        onEdit({
            ...currentProps,
            aspectRatio: aspectRatio
        });
    }

    if (editScreen == GalleryEditScreen.LIST_ITEM) {
        const itemIndex = editingItemIndex ?? 0;
        return (
            <EditContainer>
                <InputRow>
                    <InputLabel>Imagem</InputLabel>
                    <ControlsRow>
                        <EditButton onClick={() => setImageSelectionModalOpened(true)}>
                            Alterar
                        </EditButton>
                    </ControlsRow>
                </InputRow>
                <ImagePreview src={currentProps!.imageUrls?.at(itemIndex) ?? ''}/>
                
                {imageSelectionModalOpened && (
                    <ImageSelectionModal
                        onClose={() => setImageSelectionModalOpened(false)}
                        selectedImage={currentProps!.imageUrls?.at(itemIndex)}
                        onImageSelected={(image) => handleImageChange(image, itemIndex)}
                        images={images}
                    />
                )}
            </EditContainer>
        );
    }

    if (editScreen == GalleryEditScreen.NEW_ITEM) {
        return (
            <EditContainer>
                <InputRow>
                    <InputLabel>Imagem</InputLabel>
                    <ControlsRow>
                        <EditButton onClick={() => setImageSelectionModalOpened(true)}>
                            {newImage != undefined
                                ? 'Alterar'
                                : 'Adicionar'
                            }
                        </EditButton>
                    </ControlsRow>
                </InputRow>
                {newImage != undefined && (
                    <ImagePreview src={newImage ?? ''}/>
                )}

                <SaveNewBlockButton
                    text='Salvar'
                    disabled={newImage == undefined}
                    onClick={handleAddNewBlock}
                />

                {imageSelectionModalOpened && (
                    <ImageSelectionModal
                        onClose={() => setImageSelectionModalOpened(false)}
                        selectedImage={newImage}
                        onImageSelected={(image) => handleEditNewBlockImage(image)}
                        images={images}
                    />
                )}
            </EditContainer>
        );
    }

    return (
        <EditContainer>
            <InputLabel noMarginTop={true}>Título</InputLabel>
            <InputText
                type='text'
                value={currentProps?.title}
                onChange={handleTitleChange}
            />
            <InputLabel>Alinhamento do Titulo</InputLabel>
            <Dropdown
                value={currentProps?.titlePosition}
                options={[
                    {
                        text: 'Esquerda',
                        value: Position.LEFT 
                    },
                    {
                        text: 'Centro',
                        value: Position.CENTER 
                    },
                    {
                        text: 'Direita',
                        value: Position.RIGHT 
                    }
                ]}
                onValueChange={handleTitleAlignmentChange}
            />
            <InputLabel>Espaçamento</InputLabel>
            <Dropdown
                value={currentProps?.verticalPadding}
                options={[
                    {
                        text: 'Pequeno',
                        value: Size.SMALL 
                    },
                    {
                        text: 'Médio',
                        value: Size.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: Size.LARGE
                    }
                ]}
                onValueChange={handlePaddingChange}
            />
            <InputLabel>Tamanho das bordas</InputLabel>
            <Dropdown
                value={currentProps?.borderEdge}
                options={[
                    {
                        text: 'Sem borda',
                        value: BorderEdge.SHARP
                    },
                    {
                        text: 'Pequena',
                        value: BorderEdge.SMALL
                    },
                    {
                        text: 'Média',
                        value: BorderEdge.MEDIUM
                    },
                    {
                        text: 'Grande',
                        value: BorderEdge.LARGE
                    }
                ]}
                onValueChange={handleImagesBorderEdgeChange}
            />
            <InputLabel>Tamanho das Imagens</InputLabel>
            <Dropdown
                value={currentProps?.aspectRatio}
                options={[
                    {
                        text: 'Paisagem',
                        value: AspectRatio.LANDSCAPE
                    },
                    {
                        text: 'Retrato',
                        value: AspectRatio.PORTRAIT
                    },
                    {
                        text: 'Quadrada',
                        value: AspectRatio.SQUARE
                    },
                    {
                        text: 'Widescreen',
                        value: AspectRatio.WIDESCREEN
                    }
                ]}
                onValueChange={handleAspectRatioChange}
            />
            <InputLabel>Cor de fundo</InputLabel>
            <ColorPicker
                value={currentProps?.background?.backgroundColor}
                onChange={handleBackgroundColorChange}
            />
            <InputLabel>Imagens</InputLabel>
            <ListItems>
                {currentProps?.imageUrls?.map((image, i) => (
                    <ListBlock key={i} firstItem={i == 0}>
                        <Image src={image}/>
                        <ButtonsContainer>
                            <EditIcon onClick={() => handleEditListClick(i)}/>
                            <DeleteIcon onClick={() => handleOnListItemRemoveClick(i)}/>
                        </ButtonsContainer>
                    </ListBlock>
                ))}
                <AddListItemText onClick={handleNewBlockClick}>Adicionar novo item</AddListItemText>
            </ListItems>
        </EditContainer>
    )
}