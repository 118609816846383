import { useMutation } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import GetSiteByIdResponse from "../../interfaces/site/GetSiteByIdResponse";

const unexpectedErrorText = "Erro inesperado, tente novamente";
const getSiteById = async (siteId: string): Promise<GetSiteByIdResponse> => {
    try{
        const response = await AxiosConfig
            .getAxiosInstance()
            .get<GetSiteByIdResponse>(`/api/v1/sites/${siteId}`);

        if (response.status === 200)
            return response.data;

        throw new Error(unexpectedErrorText);
    } catch(err){
        throw new Error(unexpectedErrorText);
    }
}

export const useGetSiteById = () =>
    useMutation({
        mutationFn: getSiteById
    });