import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';
import { AuthenticationApi } from '../../rpc/AuthenticationApi';
import { BaseApi } from '../../rpc/BaseApi';
import { authState } from '../../state/authState';
import Auth from '../../interfaces/authentication/Auth';
import InputText from '../../components/TextInput/TextInput';
import { InnerLoginCard, MainContainer, SubTitle, Title, TitleContinuation, TitleRegister, AlertInstructions } from './RegisterPage.styles';
import StandardButton from '../../components/Button/Button';
import { useState } from 'react';
import LoginCard from '../../components/LoginCard';

function RegisterPage() {
    const [_auth, setAuth] = useRecoilState(authState);
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const { isLoading, error, refetch } = useQuery<Auth, Error>(['registerUser'], () =>
        AuthenticationApi.registerUser(formik.values, params.get('token')!),
        {
            ...BaseApi.onButtonClickQueryOptions,
            onSuccess: () => {
                navigate('/');
            }
        }
    )

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Digite o seu nome'),
        email: yup
            .string()
            .email('Digite um email válido')
            .required('Digite o seu email'),
        password: yup
          .string()
          .min(8, 'Sua senha deve possuir mais do que 8 caracteres')
          .required('Digite sua senha'),
        changepassword: yup.string().when("password", {
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: yup.string().oneOf(
            [yup.ref("password")],
            "As senhas devem ser iguais"
          )
        })
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            changepassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            refetch()
        },
    });

    const [loading, setLoading] = useState(false);

    const handleButtonClick = () => {
        setLoading(true); 
        setTimeout(() => {
            setLoading(false); 
            console.log("check it out") 
        }, 5000);
    }

    return (
      <MainContainer>
        <TitleRegister>
            <Title>
                Crie seu site 
            </Title>
            <TitleContinuation>
                em poucos segundos
            </TitleContinuation>
        </TitleRegister>
        <SubTitle>
            Simples, rápido e totalmente personalizado.
        </SubTitle>
            <LoginCard>
                <form onSubmit={formik.handleSubmit}>
                    {!isLoading && !!error && (
                        <AlertInstructions severity="error">{error.message}</AlertInstructions>
                    )}
                    <InnerLoginCard>
                        <InputText
                            inputId="name"
                            placeholder="Seu nome"
                            type="text"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            errorText={formik.errors.name || ""}
                        />
                        <InputText
                            inputId="email"
                            placeholder="Email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            errorText={formik.errors.email || ""}
                        />
                    
                        <InputText
                            inputId="password"
                            placeholder="Senha"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            errorText={formik.errors.password || ""}
                        />
                        <InputText
                            inputId="changepassword"
                            placeholder="Confirme sua senha"
                            type="password"
                            value={formik.values.changepassword}
                            onChange={formik.handleChange}
                            error={formik.touched.changepassword && Boolean(formik.errors.changepassword)}
                            errorText={formik.errors.changepassword || ""}
                        />
                    </InnerLoginCard>
                    <StandardButton
                        type="submit"
                        // loading={loading}
                        text="CADASTRAR"
                        onClick={handleButtonClick}
                    />
                </form>
            </LoginCard>
      </MainContainer>
    );
}

export default RegisterPage;