import StandardButton from "../../../../../components/Button/Button";
import { SiteCreationStep } from "../../../SiteCreationPage";
import FireBaseService, { analyticsEvents } from "../../../../../infrastructure/firebaseService";
import { AdvanceArrow, DivArrows, DivTitle, Form, OptionalText, TitleSocialMedia } from "./SocialMediaStepInput.styles";
import ReturnArrow from "../../../../../components/ReturnArrow/ReturnArrow";
import { ChangeEvent, useState } from "react";
import TextInput from "../../../../../components/TextInput/TextInput";

interface Props{
    initialWhatsappValue?: string;
    initialInstagramValue?: string;
    setWhatsappValue: (value?: string) => void;
    setInstagramValue: (value?: string) => void;
    goToStep: (step: SiteCreationStep) => void;
    onSubmit: (whatsapp?: string, instagram?: string) => void;
}

export default function SocialMediaStepInput({ 
    initialInstagramValue,
    initialWhatsappValue,
    setWhatsappValue,
    setInstagramValue,
    goToStep,
    onSubmit
}: Props){
    const [instagram, setInstagram] = useState(initialInstagramValue ?? '');
    const [whatsapp, setWhatsapp] = useState(initialWhatsappValue ?? '');
    const [whatsappError, setWhatsappError] = useState(false);

    const handleSubmitClicked = () => {
        FireBaseService.logButtonClick(analyticsEvents.siteSocialNetwork);

        if (whatsapp.length > 0 && whatsapp.length < 10) {
            setWhatsappError(true);
            return;
        }

        onSubmit(
            whatsapp.length > 0 ? `55${whatsapp.replace(/\D/g, "")}` : undefined,
            instagram.length > 1 ? instagram.replace('@', '') : undefined
        );
    }

    const handleGoBack = () => {
        setInstagramValue(instagram);
        setWhatsapp(whatsapp);
        goToStep(SiteCreationStep.LOCATION);
    }

    const handleSkip = () => {
        FireBaseService.logButtonClick(analyticsEvents.siteSocialNetwork);
        onSubmit(
            (!whatsappError && whatsapp.length >= 10) ? `55${whatsapp.replace(/\D/g, "")}` : undefined,
            instagram.length > 1 ? instagram.replace('@', '') : undefined
        );
    }

    const onInstagramValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^A-Za-z0-9_.]/g, '').toLowerCase();
        const formattedValue = sanitizedValue.startsWith('@') ? sanitizedValue : `@${sanitizedValue}`;
        setInstagram(formattedValue);

        if (inputValue.length === 0 || inputValue === '@') {
          setInstagram('');
        }
    }

    const onWhatsappValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (value.length == 0 || value.length > 9)
            setWhatsappError(false)
    
        setWhatsapp(value);
    }

    const handleCleanWhatsappValue = () => {
        setWhatsapp('');
        setWhatsappError(false);
    }

    return (
        <>
            <DivArrows>
                <ReturnArrow to={handleGoBack}/>
                <AdvanceArrow to={handleSkip} positionArrow="right">
                    Pular
                </AdvanceArrow>
            </DivArrows>
            <DivTitle>
                <TitleSocialMedia>
                    Redes sociais
                </TitleSocialMedia>
                <OptionalText>(Opcional)</OptionalText>
            </DivTitle>
            <Form>
                <TextInput
                    type="text"
                    inputId="instagram"
                    leftIcon='FaInstagram'
                    placeholder="@ (seu usuário aqui)"
                    value={instagram}
                    onChange={onInstagramValueChange}
                    onCleanButtonClicked={() => setInstagram('')}
                />
                <TextInput
                    type="text"
                    inputId="whatsapp"
                    leftIcon='FaWhatsapp'
                    inputmode="tel"
                    phoneInput={true}
                    value={whatsapp}
                    error={whatsappError}
                    errorText="Insira o número completo"
                    onChange={onWhatsappValueChange}
                    onCleanButtonClicked={() => handleCleanWhatsappValue()}
                />
            </Form>
            <StandardButton
                text='Próximo'
                disabled={whatsappError}
                onClick={handleSubmitClicked}
            />
        </>
    );
}