import ComponentSkeleton from "../../components/Skeleton-Loading/steletonLoading";
import { useGetUserDetails } from "../../hooks/userService/useGetUserDetails";
import { Content, ErrorMessage, ErrorMessageContainer, InnerContainer, MainContainer, PersonalDataContent, PersonalDataTitle, RetryButton, SkeletonDiv, Title } from "./SettingsPage.styles";
import InputData from "./components/InputData";

export default function SettingsPage(){
    const { data: personalData, isLoading, isError, refetch } = useGetUserDetails();
    
    return(
        <MainContainer>
            <InnerContainer>
                <Title>Configurações</Title>
                <Content>
                    <PersonalDataContent>
                        <PersonalDataTitle>Dados Pessoais</PersonalDataTitle>
                        {isLoading && (
                            <SkeletonDiv>
                                <ComponentSkeleton maxWidth={1500} minWidth={300} height={56}/>
                                <ComponentSkeleton maxWidth={1500} minWidth={300} height={56}/>
                            </SkeletonDiv>
                        )}
                        {isError && !isLoading && (
                            <ErrorMessageContainer>
                                <ErrorMessage>
                                    Erro inesperado ao carregar as informações, por favor tente novamente
                                </ErrorMessage>
                                <RetryButton
                                    text='Tentar Novamente'
                                    onClick={() => refetch()}
                                />
                            </ErrorMessageContainer>
                        )}
                        {!!personalData && (
                            <>
                                <InputData placeholder="Seu nome" value={personalData?.data.name} disabled={true}/>
                                <InputData placeholder="E-mail" value={personalData?.data.email} disabled={true}/>
                            </>
                        )}
                    </PersonalDataContent>
                </Content>
            </InnerContainer>
        </MainContainer>
    )
}