import { ColorCategories } from "../../interface/colorCategories";
import { ColorCategory } from "../../interface/colorCategory";

export const pastelColorCategories: ColorCategories = {
    category: ColorCategory.Pastel,
    colorThemes: [
        {
            ctaColor: '#2E3105',
            neutralBackgroundColor: '#FEFFE9',
            colorfulBackgroundColor: '#FFFDC1',
            gradientColors: []
        },
        {
            ctaColor: '#583B1F',
            neutralBackgroundColor: '#EAE6E1',
            colorfulBackgroundColor: '#E5E0DA',
            gradientColors: []
        },
        {
            ctaColor: '#284F3F',
            neutralBackgroundColor: '#FEFAF6',
            colorfulBackgroundColor: '#F8EDE0',
            gradientColors: []
        },
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E7D1B5',
            gradientColors: []
        },
    ]
};