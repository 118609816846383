import BaseConfig from '../configs/BaseConfig';
import { BaseApi } from './BaseApi';

export class AuthenticationApi {
    static login(email: string, password: string): Promise<any> {
        const body = { email, password };
        
        return BaseApi.fetchUnauthenticatedPostMethod(BaseConfig.config.businessApiUrl, '/api/v1/authentication/login', body)
            .then(async (response) => {
                if (response.status === 400)
                    throw new Error('Verifique seu email e/ou senha');

                if (response.ok) {
                    const data = await response.json();
                    return data;
                } else {
                    throw new Error('Erro inesperado, por favor tente novamente');
                }
            });
    }

    static forgotPassword(email: string): Promise<any> {
        const body = { email };
        return BaseApi.fetchUnauthenticatedPostMethod(BaseConfig.config.businessApiUrl, '/api/v1/authentication/forgot-password', body)
            .then(async (response) => {
                if (response.ok) {
                    return true;
                } else {
                    throw new Error('Erro inesperado, por favor tente novamente');
                }
            });
    }

    static resetPassword(newPassword: string, resetToken: string): Promise<any> {
        const body = { newPassword, resetToken };
        return BaseApi.fetchUnauthenticatedPostMethod(BaseConfig.config.businessApiUrl, '/api/v1/authentication/reset', body)
            .then(async (response) => {
                if (response.ok) {
                    return true;
                } else {
                    throw new Error('Erro inesperado, por favor tente novamente');
                }
            });
    }

    static changePassword = (oldPassword: string, newPassword: string, accessToken?: string): Promise<any> => {
        const body = { oldPassword, newPassword };
        return BaseApi.fetchPutMethod(BaseConfig.config.businessApiUrl, '/api/v1/authentication/change-password', accessToken!, body)
            .then(async (response) => {
                if (response.ok) {
                    return true;
                } else {
                    throw new Error('Erro inesperado, por favor tente novamente');
                }
            });
    }

    static registerUser(registerInfo: any, registerToken: string): Promise<any> {
        const body = {
            name: registerInfo.name,
            email: registerInfo.email,
            password: registerInfo.password,
            registerToken
        };
        return BaseApi.fetchUnauthenticatedPostMethod(BaseConfig.config.businessApiUrl, '/api/v1/authentication/register', body)
            .then(async (response) => {
                if (response.ok) {
                    const data = await response.json();
                    return data;
                } else {
                    throw new Error('Erro inesperado, por favor tente novamente');
                }
            });
    }
}