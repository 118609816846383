import { getFontColor } from '../../../utils/colorUtils';
import { EditContainer, Input, Select } from './editText.style';
import { Position, Size, TextProps } from '@hivefuse/page-components';

interface Props {
    currentProps: TextProps;
    onEdit: (newProps: TextProps) => void;
}

export default function EditText(props: Props) {
    const { currentProps, onEdit } = props;

    const handleDescriptionChange = (e: any) => {
        onEdit({
            ...currentProps,
            content: e.target.value
        });
    }

    const handleBackgroundColorChange = (e: any) => {
        const fontColor = getFontColor(e.target.value);
        onEdit({
            ...currentProps,
            background: {
                ...currentProps.background,
                backgroundColor: e.target.value,
            },
            fontColor: fontColor
        });
    }

    const handlePaddingChange = (e: any) => {
        onEdit({
            ...currentProps,
            verticalPadding: e.target.value
        });
    }

    const handleAlignmentChange = (e: any) => {
        onEdit({
            ...currentProps,
            textAlign: e.target.value
        });
    }

    return (
        <EditContainer>
            <p>Descrição</p>
            <Input value={currentProps?.content} onChange={handleDescriptionChange}/>
            <p>Alinhamento</p>
            <Select value={currentProps?.textAlign} onChange={handleAlignmentChange}>
                <option value={Position.LEFT}>Esquerda</option>
                <option value={Position.CENTER}>Centro</option>
                <option value={Position.RIGHT}>Direita</option>
            </Select>
            <p>Espaçamento</p>
            <Select value={currentProps?.verticalPadding} onChange={handlePaddingChange}>
                <option value={Size.SMALL}>Pequeno</option>
                <option value={Size.MEDIUM}>Medio</option>
                <option value={Size.LARGE}>Grande</option>
            </Select>
            <p>Cor de fundo</p>
            <Input type='color' value={currentProps?.background?.backgroundColor} onChange={handleBackgroundColorChange}/>
        </EditContainer>
    )
}