import { useRecoilValue, useSetRecoilState } from 'recoil';
import { siteRefMapState } from '../../../state/siteEditorState';

export const useRegisterRef = () => {
    const setRefMap = useSetRecoilState(siteRefMapState);
    const registerRef = (id: string, ref?: React.MutableRefObject<any>) => {
        setRefMap(prevRefMap => {
            const newRefMap = new Map(prevRefMap);
            if (ref)
                newRefMap.set(id, ref);
            else
                newRefMap.delete(id);
            
            return newRefMap;
        });
    };

    return registerRef;
};

export const useRefGetter = () => {
    const refMap = useRecoilValue(siteRefMapState);
    const getRef = (id: string) => {
        return refMap.get(id);
    };

    return getRef;
};