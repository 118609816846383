import { useEffect } from "react";
import { LoadingContainer, LoadingDiv, LottieLoading, MainContainer, Text, Image } from "./loadingSiteScreen.style";
import LoadingCircle from '../../../../assets/animations/LoadingCircle.json';
import Logo from '../../../../assets/LogoHiveB_Header.svg';

const LoadingSiteScreen = () => {    
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = 'visible'; };
    }, []);

    return (
        <MainContainer>
            <LoadingContainer>
                <LoadingDiv>
                    <Image src={Logo}/>
                    <LottieLoading animationData={LoadingCircle}/>
                </LoadingDiv>
                <Text>Carregando seu site...</Text>
            </LoadingContainer>
        </MainContainer>
    )
};

export default LoadingSiteScreen;