import styled from 'styled-components';
import { themes } from '../../../../../themes/themes';
import IconDropdown from '../../../../../components/IconDropdown/IconDropdown';

export const Container = styled.div<{sticky?: boolean, toolbarOverTop?: boolean}>`
    position: absolute;
    right: 1rem;
    top: 1rem;
    bottom: 0;
    height: fit-content;
    background-color: ${themes.white};
    border-radius: 8px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: ${props => props.sticky ? '100' : '10'};

    ${props => {
        if (props.toolbarOverTop) {
            return 'z-index: 999'
        }
    }}
`;

export const Tool = styled.div`
    cursor: pointer;
    padding: 10px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    gap: 3px;

    &:hover {
        background-color: ${themes.veryLightShadeOfGray};
    }
`;

export const ToolText = styled.p`
`;

export const Regenerate = styled(IconDropdown)`
    padding: 10px;
    border-radius: 8px;

    &:hover {
        background-color: ${themes.veryLightShadeOfGray};
        opacity: 1;
    }
`;