import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonsContainer, ChangeDirectionButton, ChildrenContainer, CloseButton, CloseButtonContainer, Container, GoBackButton, GoBackIcon, GoBackText, LeftIcon, RightIcon, Title, TopRow } from "./ComponentEditor.style";
import { themes } from '../../../../themes/themes';

interface Props {
    title?: string;
    children: React.ReactNode;
    onInnerNavigation?: boolean;
    onInnerNavigationGoBack?: () => void;
    onClose: () => void;
}

export default function ComponentEditor(props: Props) {
    const { children, title, onInnerNavigation, onClose, onInnerNavigationGoBack } = props;
    const [isMobile, setIsMobile] = useState(false);
    const mainContainerRef = useRef<any>(null);
    const modalRoot = useMemo(() => document.getElementById('modal-root')!, []);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (modalRoot.childNodes.length == 0 && !mainContainerRef.current.contains(event.target))
                onClose();
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [mainContainerRef]);

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth <= 780)
                document.body.style.overflow = 'hidden';
            else
                document.body.style.overflow = 'visible';
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            document.body.style.overflow = 'visible';
            window.removeEventListener('resize', handleResize)
        };
    }, []);

    return (
        <Container ref={mainContainerRef}>
            <TopRow>
                <ButtonsContainer>
                    <CloseButtonContainer onClick={onClose}>
                        <CloseButton>Fechar</CloseButton>
                    </CloseButtonContainer>
                </ButtonsContainer>
                {!!onInnerNavigation ? (
                    <GoBackButton onClick={onInnerNavigationGoBack}>
                        <GoBackIcon/>
                        <GoBackText>Voltar</GoBackText>
                    </GoBackButton>
                ) : (
                    <>
                        {!!title && (
                            <Title>{title}</Title>
                        )}
                    </>
                )}
            </TopRow>
            <ChildrenContainer>
                {children}
            </ChildrenContainer>
        </Container>
    )
}