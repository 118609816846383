import { ImgLogoHiveB, WrapperCard } from "./style";
import LogoHiveB from '../../assets/LogoHiveB.svg';

interface Props{
    children?: React.ReactNode;
    className?: string;
}

export default function LoginCard({ children, className }: Props){
    return(
        <WrapperCard className={className}>
                <ImgLogoHiveB src={LogoHiveB} alt="Logo HiveB em roxo e rosa na parte superior do card"/>
                {children}
        </WrapperCard>
    )
}