export class ValidationUtils {
    public static validateCreditCardNumber = (cardNumber: string): boolean => {
        cardNumber = cardNumber.replace(/\D/g, '');

        if (cardNumber.length !== 16 || isNaN(Number(cardNumber))) {
            return false;
        }

        let sum = 0;
        let isSecondDigit = false;

        for (let i = cardNumber.length - 1; i >= 0; i--) {
            let digit = parseInt(cardNumber.charAt(i));

            if (isSecondDigit) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            isSecondDigit = !isSecondDigit;
        }

        const numbers = cardNumber.split('').map(Number);

        if (numbers.every(number => number === numbers[0])) {
            return false;
        }

        if (sum % 10 != 0) {
            return false;
        }

        return true;
    }

    public static verifyCreditCardExpString = (e: string): boolean => {
        if (e.length === 5) {
            const expCardRegex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
            const currentDate = new Date();

            if (Number(e.slice(3, 5)) < (currentDate.getFullYear() % 100)) {
                return false;
            } else if (Number(e.slice(0, 2)) > 12) {
                return false;
            } else if (expCardRegex.test(e)) {
                return true;
            }
        }
        
        return false;
    }

    public static validateCPF = (value: string): boolean => {
        const calculateFirstDigitVerifier = (total: number) => {
            if(total % 11 < 2)
                return 0;
            else if(total % 11 !== (0 ||1))
                return  11 - (total % 11);
        }
        
        const calculateSecundaryDigitVerifier = (total: number) => {
            if((total % 11) < 2)
                return 0;
            else if(total % 11 !== (0 ||1))
                return 11 - (total % 11);
        }

        const cpf = value.replace(/[^\d]/g, '')
        if (cpf.length !== 11)
            return false;
    
        const cpfDigits = cpf.split('').map(Number);
        if (cpfDigits.every(digit => digit === cpfDigits[0]))
            return false; 
    
        let count = 0;
        let total = 0;
        for(let i=0; i < 9; i++){
            total += cpfDigits[count] * (10 - i);
            count++;
        }
        
        let firstDigitVerifier = calculateFirstDigitVerifier(total);
        if(cpfDigits[9] !== firstDigitVerifier)
            return false; 
    
        count = 0;
        total = 0;
        for(let i=0; i <= 9; i++){
            total += cpfDigits[count] * (11 - i);
            count++;
        }
    
        let secundaryDigitVerifier = calculateSecundaryDigitVerifier(total);
        if(cpfDigits[10] !== secundaryDigitVerifier)
            return false; 
    
        return true;
    }
}