import { useEffect, useImperativeHandle, useState } from 'react';

import { BackgroundProps, Size, Testimonial, TestimonialsProps } from '@hivefuse/page-components';
import { useTestimonialsCreation } from '../../../../hooks/siteGenerationService/useTestimonialsCreation';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';
import EditTestimonials from './editTestimonials/editTestimonials';
import { isDifferentStyle } from '../../utils/siteUtils';

interface Props {
    componentId: string;
    existingProps?: TestimonialsProps;
}

export enum TestimonialEditScreen {
    GENERAL = 'GENERAL',
    LIST_ITEM = 'LIST_ITEM',
    NEW_ITEM = 'NEW_ITEM'
}

const TestimonialsCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const [currentEditScreen, setCurrentEditScreen] = useState(TestimonialEditScreen.GENERAL);
    const theme = useRecoilValue(siteThemeState)!;
    const { isLoading, isRefetching, refetch: getTestimonialsText, data: testimonials, isError } = useTestimonialsCreation(creationOptions.name, creationOptions.category, componentId);
    const [testimonialProps, setTestimonialProps] = useState<TestimonialsProps>(existingProps ?? {});
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return testimonialProps;
        },
        regenerateStyle() {
            handleGenerateStyle();
        },
        regenerateContent() {
            ReGenerateContent()
        },
        regenerateEntireSection() {
            ReGenerateEntireSection()
        }
    })); 
    
    useEffect(() => {
        if (!existingProps) {
            getInitialProps();
            getTestimonialsText();
        }

        return () => registerRef(componentId, undefined);
    }, [])

    useEffect(() => {
        if (!!testimonials)
            setTestimonialProps(prevState => { return { ...prevState, ...testimonials }});
    }, [testimonials]);

    useEffect(() => {
        if (!!theme) {
            setTestimonialProps(prevState => { return {
                ...prevState,
                fonts: theme?.themeFont
            }});
        }
    }, [theme]);

    useEffect(() => {
        setTestimonialProps(prevState => { return { ...prevState, isLoading: isLoading || isRefetching }});
    }, [isLoading, isRefetching]);

    const getInitialProps = () => {
        const newBannerStyleProps = GetNewStyle();
        setTestimonialProps(prevState => { return { ...prevState, ...newBannerStyleProps }});
    }

    const GetNewStyle = (): TestimonialsProps => {
        return {
            verticalPadding: GeneratePadding(),
            background: GenerateBackground(),
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont
        };
    }

    const getRegeneratedStyle = (): TestimonialsProps => {
        return {
            verticalPadding: GeneratePadding(),
            fonts: theme?.themeFont
        };
    }

    const GeneratePadding = (): Size => {
        const possibleValues = [Size.MEDIUM, Size.LARGE];
        return possibleValues[Math.floor(Math.random() * possibleValues.length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const handleGenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = GetNewStyle();
        while (!isDifferentStyle(testimonialProps, newStyle)) {
            newStyle = GetNewStyle();
        }

        setTestimonialProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleRegenerateStyle = () => {
        setSiteEditedState(true);
        let newStyle = getRegeneratedStyle();
        while (!isDifferentStyle(testimonialProps, newStyle)) {
            newStyle = getRegeneratedStyle();
        }

        setTestimonialProps(prevState => { return {...prevState, ...newStyle} });
    }

    const ReGenerateContent = () => {
        setSiteEditedState(true);
        getTestimonialsText();
    }

    const ReGenerateEntireSection = () => {
        handleGenerateStyle();
        ReGenerateContent();
    }

    const onEditing = (newProps: TestimonialsProps) => {
        setSiteEditedState(true);
        setTestimonialProps(prevState => { return {...prevState, ...newProps} });
    }

    const handleOnEdit = () => {
        setCurrentEditScreen(TestimonialEditScreen.GENERAL);
    }

    const getEditComponent = () => {
        return (
            <EditTestimonials
                onEdit={onEditing}
                currentProps={testimonialProps}
                currentEditScreen={currentEditScreen}
                goToScreen={(screen) => setCurrentEditScreen(screen)}
            />
        )
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            editTitleHeader='Testemunhos'
            editComponent={getEditComponent()}
            onEdit={handleOnEdit}
            onInnerNavigation={currentEditScreen != TestimonialEditScreen.GENERAL}
            onInnerNavigationGoBack={() => setCurrentEditScreen(TestimonialEditScreen.GENERAL)}
            onRegenerateStyle={handleRegenerateStyle}
            onRegenerateContent={ReGenerateContent}
            onRegenerateEntireSection={ReGenerateEntireSection}
        >
            <Testimonial
                data={{
                    ...testimonialProps
                }}
            />
        </BackdropEditor>
    )
});

export default TestimonialsCreation;