import { createRef, forwardRef, useImperativeHandle, useRef } from 'react';

import { DaysEnum } from '../../interfaces/DaysEnum';
import { WorkHours } from '../../interfaces/WorkHours';
import WorkHourDay from './components/WorkHourDay';
import styles from './WorkHourForm.module.css';

interface Props {
    workHours?: WorkHours[];
}

const WorkHourForm = forwardRef<any, Props>((props, ref) => {
    const { workHours } = props;
    const refs = useRef<any>([]);
    refs.current = (Object.keys(DaysEnum) as Array<keyof typeof DaysEnum>).map((_e, i) => refs.current[i] ?? createRef());

    useImperativeHandle(ref, () => ({
        getWorkHours() {
            let result: any = [];
            refs.current.every((ref: any) => {
                const current = ref.current.getWorkHours();
                if (current == null) {
                    result = null;
                    return false;
                }
                result.push(current);
                return true;
            });
            return result;
        }
    }));

    return (
        <div className={styles.mainContainer} >
            {!!workHours ? 
                (workHours.map((workHour, i) => (
                    <WorkHourDay key={workHour.day} workHours={{ day: workHour.day, timeSlots: workHour.timeSlots }} ref={refs.current[i]}/>
                ))) :
                (Object.keys(DaysEnum) as Array<keyof typeof DaysEnum>).map((day, i) => (
                    <WorkHourDay key={day} workHours={{ day: DaysEnum[day], timeSlots: undefined }} ref={refs.current[i]}/>
                ))
            }
        </div>
    );
});
  
export default WorkHourForm;