import styled from 'styled-components';
import { themes } from '../../../../themes/themes';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #00000099;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`;

export const ModalInner = styled.div`
    background-color: ${themes.white};
    width: 100%;
    max-height: 720px;
    max-width: 720px;
    border-radius: 12px;
    margin: 12px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    
    @media (max-width: ${themes.tabletDimension}){
        box-sizing: border-box;
        max-height: 100%;
        height: 100%;
        max-width: 100%;
        width: 100%;
        margin: 0;
        border-radius: 0;
    }
`;

export const ModalInnerScrollableArea = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    flex: 1;
`;

export const BackDrop = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: -1;
`;
