import styled from 'styled-components';
import Icon from '../Icon/Icon';
import { themes } from '../../themes/themes';

export const Container = styled.div`
    margin: 16px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const Circle = styled.div<{checked: boolean}>`
    border-radius: 50%;
    border: 1px solid ${themes.darkGray};
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;

    ${props => {
        if (props.checked) {
            return `
                background-color: ${themes.darkPurple};
            `;
        }

        return;
    }}
`;

export const Checked = styled(Icon)`
    width: 13px;
    height: 13px;
    color: ${themes.white};
`;

export const Text = styled.p`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
`;