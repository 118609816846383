import { useEffect, useImperativeHandle, useState } from 'react';
import EditFooter from './editFooter/editFooter';
import { BackgroundProps, Footer, FooterProps, Size } from '@hivefuse/page-components';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteSocialNetworksState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';

interface Props {
    componentId: string;
    existingProps?: FooterProps;
}

const FooterCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const socialNetworks = useRecoilValue(siteSocialNetworksState);
    const theme = useRecoilValue(siteThemeState)!;
    const [footerProps, setFooterProps] = useState<FooterProps>(existingProps ?? {});
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return footerProps;
        },
        regenerateStyle() {
            handleGenerateStyle()
        },
        regenerateContent() {

        },
        regenerateEntireSection() {
            handleGenerateStyle()
        }
    })); 
    
    useEffect(() => {
        if (!existingProps)
            getInitialProps();

        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!theme) {
            setFooterProps(prevState => { return {
                ...prevState,
                fonts: theme?.themeFont
            } });
        }
    }, [theme]);

    const getInitialProps = () => {
        const newStyleProps = GetNewStyle();
        setFooterProps(prevState => { return {
            ...prevState,
            ...newStyleProps,
            title: creationOptions.name,
            socialNetworks: socialNetworks
        }});
    }

    const handleGenerateStyle = () => {
        const newStyle = GetNewStyle();
        setSiteEditedState(true);
        setFooterProps(prevState => { return {...prevState, ...newStyle} });
    }

    const handleRenerateStyle = () => {
        const newStyle = GetRegeneratedStyle();
        setSiteEditedState(true);
        setFooterProps(prevState => { return {...prevState, ...newStyle} });
    }

    const GetNewStyle = (): FooterProps => {
        return {
            padding: GeneratePadding(),
            background: GenerateBackground(),
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont
        };
    }

    const GetRegeneratedStyle = (): FooterProps => {
        return {
            padding: GeneratePadding(),
            fonts: theme?.themeFont
        };
    }

    const GeneratePadding = (): Size => {
        return Object.values(Size)[Math.floor(Math.random() * Object.values(Size).length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme, true)
        };
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme, true));
    }

    const onEditing = (newProps: FooterProps) => {
        setSiteEditedState(true);
        setFooterProps(prevState => { return {...prevState, ...newProps} });
    }

    const getEditComponent = () => {
        return (
            <EditFooter
                onEdit={onEditing}
                currentProps={footerProps}
            />
        )
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            editTitleHeader='Rodapé'
            editComponent={getEditComponent()}
            onRegenerateStyle={handleRenerateStyle}
        >
            <Footer
                data={footerProps}
            />
        </BackdropEditor>
    )
});

export default FooterCreation;