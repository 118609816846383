import { BackgroundGradient, BorderEdge, FontTag, ThemeFont } from "@hivefuse/page-components";
import { GeneratedTheme } from "../interface/generatedTheme";
import { SiteTheme } from "../interface/siteTheme";
import { possibleColorDistributions } from "../interface/colorDistribution";
import { ColorTheme } from "../interface/colorTheme";
import GradientPossibilities from "../resources/GradientPossibilities";
import ColorCategoriesThemes from "../resources/ColorCategoriesThemes";
import { warmColorCategories } from "../resources/colorThemes/warmThemes";
import themeFonts from "../resources/ThemeFonts";
import { ColorCategory } from "../interface/colorCategory";

export class ThemeUtils {
    public static createSiteTheme(generatedTheme: GeneratedTheme): SiteTheme {
        const shouldUseCtaOnTitles = Math.random() < 0.5;
        const randomColorDistribution = possibleColorDistributions[Math.floor(Math.random() * possibleColorDistributions.length)];
        const shouldStickHeader = Math.random() < 0.5;
        const selectedTheme = ThemeUtils.getColorTheme(generatedTheme);
        const headerAndFootColor = Math.random() < 0.5 ? selectedTheme.colorfulBackgroundColor : '#FFFFFF';

        return {
            colorTheme: selectedTheme,
            colorDistribution: randomColorDistribution,
            themeFont: ThemeUtils.getFont(generatedTheme.fontTags),
            borderEdges: generatedTheme.borderEdges,
            gradient: undefined,
            useAnimations: generatedTheme.useAnimations,
            useShadows: generatedTheme.useShadows,
            useCtaColorOnTitles: shouldUseCtaOnTitles,
            headerSticked: shouldStickHeader,
            prioritizeImages: true,
            headerColor: headerAndFootColor,
            footerColor: headerAndFootColor
        }
    }

    public static getComponentBackgroundColor(index: number, theme: SiteTheme, lastElement: boolean = false): string {
        if (index == 0)
            return theme.headerColor
        if (lastElement)
            return theme.footerColor

        const distribution = theme.colorDistribution;
        const totalProportion = distribution.colorfulBackgroundColorProportion + distribution.neutralBackgroundColorProportion + distribution.whiteProportion;
        const normalizedIndex = (index - 1) % totalProportion;

        if (normalizedIndex < distribution.colorfulBackgroundColorProportion) {
            return theme.colorTheme.colorfulBackgroundColor;
        } else if (normalizedIndex < distribution.colorfulBackgroundColorProportion + distribution.neutralBackgroundColorProportion) {
            return theme.colorTheme.neutralBackgroundColor;
        } else {
            return '#FFFFFF';
        }
    }

    private static getGradient(colorTheme: ColorTheme): BackgroundGradient {
        const randomGradient = GradientPossibilities[Math.floor(Math.random() * GradientPossibilities.length)];
        const randomThemeGradients = colorTheme.gradientColors.sort((a, b) => 0.5 - Math.random());
        return {
            radial: randomGradient.radial,
            linearDegree: randomGradient.linearDegree,
            colors: randomGradient.colors.map((color, index) => {
                return {
                    color: randomThemeGradients[index],
                    stop: color.stop
                }
            })
        }
    }

    private static getFont(fontTags: FontTag[]): ThemeFont {
        const matchedFonts = themeFonts.filter(f => fontTags.includes(f.tag));
        return matchedFonts[Math.floor(Math.random() * matchedFonts.length)];
    }

    private static getColorTheme(generatedTheme: GeneratedTheme): ColorTheme {
        const getSelectedCategory = generatedTheme.colorCategories[Math.floor(Math.random() * generatedTheme.colorCategories.length)];
        const colorCategory = ColorCategoriesThemes.find(c => c.category === getSelectedCategory);
        return colorCategory?.colorThemes[Math.floor(Math.random() * colorCategory?.colorThemes?.length)] ?? warmColorCategories.colorThemes[0];
    }
}

export const createCustomTheme = (): GeneratedTheme => {
    return {
        colorCategories: Object.values(ColorCategory),
        fontTags: Object.values(FontTag),
        borderEdges: Object.values(BorderEdge)[Math.floor(Math.random() * Object.keys(BorderEdge).length)],
        useGradients: false,
        useAnimations: true,
        useShadows: true
    };
}