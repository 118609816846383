import { useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronUp, Container, DropdownItemContainer, DropdownItemValue, DropdownItemsContainer, DropdownLabel, DropdownValue, DropdownValueContainer, MainContainer, maxHeight } from "./Dropdown.style";

export interface DropdownOption {
    value: any;
    text: string;
}

interface Props {
    className?: string;
    label?: string;
    value?: any;
    options: DropdownOption[];
    id?: string | undefined;
    minHeight?: string;
    onValueChange?: (value: any) => void;
    onChangeEvent?: (event: any) => void;
}

const Dropdown = (props: Props) => {
    const { className, label, options, value, id, onValueChange, onChangeEvent, minHeight} = props;
    const [opened, setOpened] = useState(false);
    const [dropdownUp, setDropdownUp] = useState(false);
    const mainContainerRef = useRef<any>(null);
    const dropdownItemsContainerRef = useRef<any>(null);
    const getValueOption = (value?: any) => options.find(o => o.value === value);
    const [selectedValue, setSelectedValue] = useState<DropdownOption>(getValueOption(value) ?? options[0]);

   
    useEffect(() => {
        setSelectedValue(getValueOption(value) ?? options[0])
    }, [options])
    
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!dropdownItemsContainerRef.current?.contains(event.target) && !mainContainerRef.current?.contains(event.target))
                setOpened(false);
        }

        if (opened)
            document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [opened, mainContainerRef, dropdownItemsContainerRef]);

    useEffect(() => {
        if (mainContainerRef.current) {
            const element: HTMLElement = mainContainerRef.current;
            const rect = element.getBoundingClientRect();
            const spaceBelow = window.innerHeight - rect.bottom;

            if (spaceBelow < maxHeight) {
                setDropdownUp(true);
            } else {
                setDropdownUp(false);
            }
        }
    }, [mainContainerRef]);
    
    const handleOptionSelection = (option: DropdownOption) => {
        setSelectedValue(option);
        setOpened(false);
        if (!!onValueChange)
            onValueChange(option.value);
        if (!!onChangeEvent)
            onChangeEvent({
                target: {
                    value: option.value,
                    id: id,
                    name: ""
                }
            });
    }

    const renderChevron = () => {
        if (opened)
            return <ChevronUp/>;
        else
            return <ChevronDown/>;
    }

    return(
        <Container className={className}>
            <MainContainer id={id} ref={mainContainerRef} minHeight={minHeight} onClick={() => setOpened(prev => !prev)}>
                <DropdownValueContainer>
                    {!!label && (
                        <DropdownLabel>{label}</DropdownLabel>
                    )}
                    <DropdownValue>{selectedValue.text}</DropdownValue>
                </DropdownValueContainer>
                {renderChevron()}
            </MainContainer>
            {opened && (
                <DropdownItemsContainer
                    ref={dropdownItemsContainerRef}
                    style={{
                        top: dropdownUp ? 'auto' : '100%',
                        bottom: dropdownUp ? '100%' : 'auto',
                    }}
                >
                    {options.map((option, index) => (
                        <DropdownItemContainer key={index} onClick={() => handleOptionSelection(option)}>
                            <DropdownItemValue>{option.text}</DropdownItemValue>
                        </DropdownItemContainer>
                    ))}
                </DropdownItemsContainer>
            )}
        </Container>
    )
}

export default Dropdown;