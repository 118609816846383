import styled from 'styled-components';
import { themes } from '../../../../../themes/themes';
import ReactQuill from 'react-quill';
import { BaseContainer } from '@hivefuse/page-components';

export const EditContainer = styled.div`
`;

export const InputLabel = styled.p<{ noMarginTop?: boolean }>`
    font-family: ${themes.dashBoardFont};
    font-size: 1rem;
    line-height: 1rem;
    color: ${themes.darkGray};
    margin-top: ${props => !!props.noMarginTop ? '0' : '12px'};
    margin-bottom: 12px;
`;

export const QuillEditor = styled(ReactQuill)`
    box-shadow: ${themes.inputShadow};
    border: 1px solid ${themes.darkGray};
    border-radius: 10px;

    .ql-container.ql-snow {
        border: none;
    }
    
    .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1px solid ${themes.darkGray};
        border-radius: 10px 10px 0 0;
    }

    .ql-container {
        border-radius: 0 0 10px 10px;
    }
`;

export const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
`;

export const BackgroundPreview = styled(BaseContainer)`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
`;

export const ImagePreview = styled.div<{ src: string}>`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const EditButton = styled.p`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const RemoveButton = styled.p`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkPurple};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const ControlsRow = styled.div`
    display: flex;
    gap: 12px;
`;