import { useQuery } from 'react-query';
import AxiosConfig from '../../../configs/AxiosConfig';

const unexpectedErrorText = 'Um erro inesperado ocorreu';
const getSiteImages = async (siteId?: string) => {
    try{
        const response = await AxiosConfig.getAxiosInstance()
            .get(`api/v1/site/${siteId}/images`);

        if (response.status === 200)
            return response.data;
        throw new Error(unexpectedErrorText);
    } catch(err) {
        throw new Error(unexpectedErrorText);
    }
}

export const useGetSiteImages = (siteId?: string) => {
    return useQuery({
        queryFn: async () => getSiteImages(siteId),
        queryKey: ['GetSiteImages', siteId],
        refetchOnWindowFocus: false,
        enabled: !!siteId && siteId.length > 0
    });
}