import { Skeleton } from './style';

interface Props {
    className?: string;
    height?: number;
    width?: number;
    maxWidth?: number;
    minWidth?: number;
}

export default function ComponentSkeleton({className, width, height, maxWidth, minWidth} : Props) {
    return(
        <Skeleton 
            className={className}
            width={width} 
            height={height}
            maxWidth={maxWidth}
            minWidth={minWidth}
        />
    );
};