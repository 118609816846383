import { ColorCategories } from "../../interface/colorCategories";
import { ColorCategory } from "../../interface/colorCategory";

export const warmColorCategories: ColorCategories = {
    category: ColorCategory.Warm,
    colorThemes: [
        {
            ctaColor: '#655A3F',
            neutralBackgroundColor: '#FFF5D9',
            colorfulBackgroundColor: '#FBE19F',
            gradientColors: []
        },
        {
            ctaColor: '#171D3A',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E5E9FD',
            gradientColors: []
        },
        {
            ctaColor: '#D4967D',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#DCD9D0',
            gradientColors: []
        },
        {
            ctaColor: '#209D50',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E1DFD9',
            gradientColors: []
        },
    ]
};