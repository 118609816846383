import { useEffect, useImperativeHandle, useState } from 'react';

import EditTextAndImage from './editTextAndImage/editTextAndImage';
import { AspectRatio, BackgroundProps, ImageProps, Position, Size, TextAndImage, TextAndImageProps, TextProps, VerticalPosition } from '@hivefuse/page-components';
import { useAboutCreation } from '../../../../hooks/siteGenerationService/useAboutCreation';
import { ImageHelper } from '../../utils/imageUtils';
import { ThemeUtils } from '../../utils/themeUtils';
import { getFontColor } from '../../utils/colorUtils';
import BackdropEditor from '../backdropEditor/BackdropEditor';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { creationOptionsState, siteComponentIndexState, siteEditedState, siteStockImagesState, siteThemeState } from '../../../../state/siteEditorState';
import { useRegisterRef } from '../../utils/siteRefManager';

interface Props {
    componentId: string;
    existingProps?: TextAndImageProps;
}

const TextAndImageCreation = React.forwardRef((props: Props, ref: any) => {
    const { existingProps, componentId } = props;
    const creationOptions = useRecoilValue(creationOptionsState)!;
    const theme = useRecoilValue(siteThemeState)!;
    const images = useRecoilValue(siteStockImagesState);
    const { isLoading, isRefetching, refetch: getAboutText, data: aboutText } = useAboutCreation(creationOptions.name, creationOptions.category, componentId);
    const [textAndImageProps, setTextAndImageProps] = useState<TextAndImageProps>(existingProps ?? {});
    const registerRef = useRegisterRef();
    const index = useRecoilValue(siteComponentIndexState(componentId));
    const setSiteEditedState = useSetRecoilState(siteEditedState);

    useImperativeHandle(ref, () => ({
        getProps() {
          return textAndImageProps;
        },
        regenerateStyle() {
            handleGenerateStyle()
        },
        regenerateContent() {
            ReGenerateContent()
        },
        regenerateEntireSection() {
            ReGenerateEntireSection()
        }
    })); 

    useEffect(() => {
        if (!existingProps) {
            getInitialProps();
            getAboutText();
        }

        return () => registerRef(componentId, undefined);
    }, []);

    useEffect(() => {
        if (!!aboutText)
            setTextAndImageProps(prevState => { return {
                ...prevState,
                textProps: {
                    ...prevState.textProps,
                    content: `# Sobre nós\n${aboutText?.description}`
                }
            }});
    }, [aboutText]);

    useEffect(() => {
        if (!!theme) {
            setTextAndImageProps(prevState => {
                return {
                    ...prevState,
                    textProps: { ...prevState.textProps, fonts: theme?.themeFont },
                    fonts: theme?.themeFont
                }
            });
        }
    }, [theme]);

    useEffect(() => {
        setTextAndImageProps(prevState => { return { ...prevState, isLoading: isLoading || isRefetching }});
    }, [isLoading, isRefetching]);

    const getInitialProps = () => {
        const newStyle = GetNewStyle();
        const textStyle = GetNewTextStyle();
        const imageStyle = GetNewImageStyle();
        setTextAndImageProps(prevState => { return {
            ...prevState,
            ...newStyle,
            imageProps: { ...prevState.imageProps, ...imageStyle},
            textProps: { ...prevState.textProps, ...textStyle }
        }});
    }

    const handleGenerateStyle = () => {
        const newStyle = GetNewStyle();
        const textStyle = GetNewTextStyle();
        const imageStyle = GetNewImageStyle();
        setSiteEditedState(true);
        setTextAndImageProps(prevState => { return {
            ...prevState,
            ...newStyle,
            imageProps: { ...prevState.imageProps, ...imageStyle},
            textProps: { ...prevState.textProps, ...textStyle }
        }});
    }

    const handleRegenerateStyle = () => {
        const newStyle = GetRegeneratedStyle();
        const textStyle = GetNewTextStyle();
        const imageStyle = GetRegeneratedImageStyle();
        setSiteEditedState(true);
        setTextAndImageProps(prevState => { return {
            ...prevState,
            ...newStyle,
            imageProps: { ...prevState.imageProps, ...imageStyle},
            textProps: { ...prevState.textProps, ...textStyle }
        }});
    }

    const GenerateShadow = (): string | undefined => {
        if (!theme.useShadows)
            return undefined;

        return "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px";
    }

    const GetRegeneratedStyle = (): TextAndImageProps => {
        return {
            verticalPadding: GeneratePadding(),
            verticalAlign: GenerateVerticalAlign(),
            invertedOrder: GenerateOrder()
        };
    }

    const GetNewStyle = (): TextAndImageProps => {
        return {
            background: GenerateBackground(),
            verticalPadding: GeneratePadding(),
            verticalAlign: GenerateVerticalAlign(),
            invertedOrder: GenerateOrder()
        };
    }

    const GetRegeneratedImageStyle = (): ImageProps => {
        return {
            borderEdge: theme.borderEdges,
            imageShadow: GenerateShadow(),
            noVerticalPadding: true,
            noHorizontalPadding: true,
            aspectRatio: GenerateImageAspectRatio()
        };
    }

    const GetNewImageStyle = (): ImageProps => {
        return {
            borderEdge: theme.borderEdges,
            imageShadow: GenerateShadow(),
            noVerticalPadding: true,
            noHorizontalPadding: true,
            aspectRatio: GenerateImageAspectRatio(),
            imageUrl: ImageHelper.getRandomImages(images, 1)[0]
        };
    }

    const GetNewTextStyle = (): TextProps => {
        return {
            textAlign: GenerateAlignment(),
            fontColor: GenerateFontColor(),
            fonts: theme?.themeFont,
            noHorizontalPadding: true,
            noVerticalPadding: true
        };
    }

    const GenerateOrder = (): boolean => {
        return Math.random() < 0.3;
    }

    const GenerateImageAspectRatio = (): AspectRatio => {
        const allowedPositions = [AspectRatio.LANDSCAPE, AspectRatio.WIDESCREEN, AspectRatio.SQUARE];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GenerateAlignment = (): Position => {
        const allowedPositions = [Position.LEFT, Position.CENTER];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GeneratePadding = (): Size => {
        const allowedPositions = [Size.MEDIUM, Size.LARGE];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GenerateVerticalAlign = (): VerticalPosition => {
        const allowedPositions = [VerticalPosition.TOP, VerticalPosition.CENTER];
        return allowedPositions[Math.floor(Math.random() * allowedPositions.length)];
    }

    const GenerateBackground = (): BackgroundProps => {
        return {
            backgroundColor: ThemeUtils.getComponentBackgroundColor(index, theme)
        };
    }

    const GenerateFontColor = (): string => {
        return getFontColor(ThemeUtils.getComponentBackgroundColor(index, theme));
    }

    const ReGenerateContent = () => {
        setSiteEditedState(true);
        getAboutText();
    }

    const ReGenerateEntireSection = () => {
        handleGenerateStyle();
        ReGenerateContent();
    }

    const onEditing = (newProps: TextProps) => {
        setSiteEditedState(true);
        setTextAndImageProps(prevState => { return {...prevState, ...newProps} });
    }

    const getEditComponent = () => {
        return (
            <EditTextAndImage
                onEdit={onEditing}
                currentProps={textAndImageProps}
            />
        )
    }

    return (
        <BackdropEditor
            index={index}
            componentId={componentId}
            editTitleHeader='Texto e Imagem'
            editComponent={getEditComponent()}
            onRegenerateStyle={handleRegenerateStyle}
            onRegenerateContent={ReGenerateContent}
            onRegenerateEntireSection={ReGenerateEntireSection}
        >
            <TextAndImage
                data={textAndImageProps}
            />
        </BackdropEditor>
    )
});

export default TextAndImageCreation;