import styled from "styled-components";
import { themes } from "../../../themes/themes";

const { primaryFont, white } = themes;

const InputDataComponent = styled.input`
    border-radius: 10px; 
    height: 56px;
    width: 100%;
    border: none;
    margin-left: 30px;
    padding-top: 5px;
    font-family: ${primaryFont};
    text-align: left;
    background-color: ${white};
`;

const Label = styled.label`
    position: absolute;
    margin-left: 30px;
    font-weight: 500;
    line-height: 1.5rem;
    color: rgba(97.75, 85.94, 85.94, 0.60);
    font-family: ${primaryFont};
    word-wrap: wrap;
    pointer-events: none;
    transition: 0.2s ease-out;
    font-size: 0.688rem;
    top: 0;
`;

const DivInput = styled.div`
    position: relative;
    margin-bottom: 10px;
    display: flex;
    background-color: ${white};
    border-radius: 10px; 
`;

export default function InputData({ placeholder, disabled, value }: { placeholder: string; disabled: boolean; value?: string }){
    return (
        <DivInput>
            <Label>{placeholder}</Label>
            <InputDataComponent
                disabled={disabled}
                value={value}
            />
        </DivInput>
    )
}