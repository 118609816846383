import ConfirmationModalActions from "./ConfirmationModalActions";
import ConfirmationModalContent from "./ConfirmationModalContent";
import ConfirmationModalImg from "./ConfirmationModalImg";
import ConfirmationModalRoot from "./ConfirmationModalRoot";
import ConfirmationModalTitle from "./ConfirmationModalTitle";

export const ConfirmationModal = {
    Root: ConfirmationModalRoot,
    Img: ConfirmationModalImg,
    Content: ConfirmationModalContent,
    Title: ConfirmationModalTitle,
    Actions: ConfirmationModalActions
}