import { ColorCategories } from "../../interface/colorCategories";
import { ColorCategory } from "../../interface/colorCategory";

export const brightColorCategories: ColorCategories = {
    category: ColorCategory.Bright,
    colorThemes: [
        {
            ctaColor: '#2D2D2A',
            neutralBackgroundColor: '#EAE6DD',
            colorfulBackgroundColor: '#EDD286',
            gradientColors: []
        },
        {
            ctaColor: '#000000',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#FFCF5A',
            gradientColors: []
        },
        {
            ctaColor: '#A74D4A',
            neutralBackgroundColor: '#F5F5F5',
            colorfulBackgroundColor: '#EAE6E1',
            gradientColors: []
        },
        {
            ctaColor: '#C79288',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#E6E4E8',
            gradientColors: []
        },
        {
            ctaColor: '#F08E80',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#FDF0E6',
            gradientColors: []
        },
        {
            ctaColor: '#7877E6',
            neutralBackgroundColor: '#FFFFFF',
            colorfulBackgroundColor: '#F4F4F3',
            gradientColors: []
        },
    ]
};