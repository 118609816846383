import { useRecoilState } from 'recoil';

import { TextField } from '@mui/material';

import { capitalizeEveryWord } from '../../../../helpers/TextHelper';
import { authState } from '../../../../state/authState';
import styles from './GeneralInfo.module.css';

function GeneralInfo() {
    const [auth, _setAuth] = useRecoilState(authState);
    
    return (
        <div className={styles.main}>
            <div className={styles.card}>
                <div className={styles.inputsInner}>
                    <TextField
                        className={styles.input}
                        id="name"
                        label="Nome"
                        variant="outlined"
                        type="text"
                        disabled={true}
                        value={capitalizeEveryWord(auth?.userId)}
                    />
                    <TextField
                        className={styles.input}
                        label="Email"
                        variant="outlined"
                        type="text"
                        disabled={true}
                        value={auth?.userId}
                    />
                </div>
            </div>
        </div>
    );
}
  
export default GeneralInfo;