import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

import {
    Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField,
    Typography
} from '@mui/material';

import DefaultLoadingButton from '../../../components/DefaultLoadingButton/DefaultLoadingButton';
import MultiselectDropdown from '../../../components/MultiselectDropdow/MultiselectDropdown';
import { ServiceAppointmentTypeEnum } from '../../../interfaces/service/ServiceAppointmentTypeEnum';
import styles from './CreateServicePage.module.css';

function CreateServicePage() {
    const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
    
    const validationSchema = yup.object().shape({
        name: yup
          .string()
          .required('Digite o nome do serviço'),
        description: yup
          .string()
          .optional(),
        durationInMinutes: yup
          .number()
          .integer('Utilize números inteiros')
          .min(15, 'O tempo mínimo de um serviço é de 15 minutos')
          .positive('A duração não pode ser negativa')
          .required('Digite a duração do serviço'),
        price: yup
          .number()
          .positive('O preço não pode ser negativo')
          .min(50, 'Os serviços devem custar no mínimo 50 Reais')
          .required('Digite o preço do serviço'),
        staffId: yup
          .string()
          .required("Escolha um membro de equipe")
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            durationInMinutes: 60,
            price: 100,
            allowStaffSelection: false,
            appointmentType: ServiceAppointmentTypeEnum.PHYSICAL,
            staffId: ''
        },
        validationSchema: validationSchema,
        onSubmit: () => {
        },
    });

    return (
        <div className={styles.main}>
            <Typography className={styles.header} variant="h5">
                Novo Serviço
            </Typography>

            <div className={styles.mainContainer}>
                <div className={styles.card}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={styles.inputsInner}>
                            <TextField
                                className={styles.input}
                                id="name"
                                label="Nome"
                                variant="outlined"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                className={styles.input}
                                id="description"
                                label="Descrição"
                                variant="outlined"
                                type="text"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                            <TextField
                                className={styles.input}
                                id="durationInMinutes"
                                label="Duração em minutos"
                                variant="outlined"
                                type="number"
                                value={formik.values.durationInMinutes}
                                onChange={formik.handleChange}
                                error={formik.touched.durationInMinutes && Boolean(formik.errors.durationInMinutes)}
                                helperText={formik.touched.durationInMinutes && formik.errors.durationInMinutes}
                            />
                            <TextField
                                className={styles.input}
                                id="price"
                                label="Preço"
                                variant="outlined"
                                type="number"
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                helperText={formik.touched.price && formik.errors.price}
                            />
                            <div className={styles.row}>
                                <FormControl className={styles.rowItem}>
                                    <InputLabel id='appointmentTypeLabel'>Tipo de serviço</InputLabel>
                                    <Select
                                        
                                        id="appointmentType"
                                        labelId='appointmentTypeLabel'
                                        label='Tipo de serviço'
                                        name="appointmentType"
                                        variant="outlined"
                                        value={formik.values.appointmentType}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value={ServiceAppointmentTypeEnum.ONLINE}>ONLINE</MenuItem>
                                        <MenuItem value={ServiceAppointmentTypeEnum.PHYSICAL}>FÍSICO</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormGroup className={styles.rowItemCheckbox}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="allowStaffSelection"
                                                name="allowStaffSelection"
                                                value={formik.values.allowStaffSelection}
                                                onChange={formik.handleChange}
                                            />
                                        }
                                        label="Permite seleção de equipe"
                                    />
                                </FormGroup>
                            </div>
                            <MultiselectDropdown
                                showAllButton={true}
                                inputLabel={'Equipe'}
                                dataList={[]}
                                textProperty={'name'}
                                valueProperty={'id'}
                                avatarProperty={'imageUrl'}
                                selectedData={[]}
                                onChange={(selected: string[]) => setSelectedStaff(selected)}
                            />
                        </div>
                        <DefaultLoadingButton
                            className={styles.button}
                            text='Próximo'
                            loading={false}
                            type='submit'
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}
  
export default CreateServicePage;