import { useState } from "react";
import { IFrame, MainContainer, Overlay, Skelleton } from "./IFramePreview.style";

interface Props {
    src: string;
}

const IFramePreview = (props: Props) => {
    const { src } = props;
    const [loaded, setLoaded] = useState(true);

    const handleLoad = () => {
        setLoaded(false);
    }

    return(
        <MainContainer>
            {loaded && (
                <Skelleton/>
            )}
            <IFrame
                src={src}
                scrolling="no"
                seamless
                onLoad={handleLoad}
            />
            <Overlay/>
        </MainContainer>
    )
}

export default IFramePreview;