import styled from "styled-components";
import { themes } from "../../themes/themes";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

interface Props{
    disable?: boolean;
}

export const ReturnArrowComponent = styled.div<Props>`
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${(props) => props.disable ? 'rgba(47, 42, 149, 0.5)' : themes.purple};
    font-family: ${themes.primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding-top: 20px;
    max-width: 70px;
    pointer-events:  ${(props) => props.disable ? 'none' : 'auto'};
    cursor: pointer;
    &:hover{
        opacity: 0.5;
    }
`;

export const ArrowLeft = styled(FaArrowLeftLong)<Props>`
    opacity: ${(props) => props.disable ? '0.4' : '1'};
    margin-right: 6px;
    width: 16px;
    height: 16px;
`;

export const ArrowRight = styled(FaArrowRightLong)<Props>`
    opacity: ${(props) => props.disable ? '0.4' : '1'};
    margin-left: 6px;
    width: 16px;
    height: 16px;
`;