import Compressor from 'compressorjs';

export function base64ToBlob(base64: string, mimeType: string) {
    const bytes = atob(base64.split(',')[1]);
    const array = new Uint8Array(bytes.length);
  
    for (let i = 0; i < bytes.length; i++) {
      array[i] = bytes.charCodeAt(i);
    }
  
    return new Blob([array], { type: mimeType });
}

export async function compressImage(image: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 0.90,
      maxHeight: 2160,
      maxWidth: 3840,
      success: resolve,
      error: reject,
    });
  });
}

export async function compressImageFromBase64(base64: string, mimeType: string): Promise<Blob> {
  const blob = base64ToBlob(base64, mimeType);
  return await compressImage(blob);
}
