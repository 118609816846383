export interface ColorDistribution {
    neutralBackgroundColorProportion: number;
    colorfulBackgroundColorProportion: number;
    whiteProportion: number;
}

export const possibleColorDistributions: ColorDistribution[] = [
    {
        whiteProportion: 1,
        neutralBackgroundColorProportion: 0,
        colorfulBackgroundColorProportion: 1
    },
    {
        whiteProportion: 0,
        neutralBackgroundColorProportion: 1,
        colorfulBackgroundColorProportion: 1
    }
]