import { useState } from 'react';

import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HandymanIcon from '@mui/icons-material/Handyman';
import { Tab, Tabs, Typography } from '@mui/material';

import NewService from './components/NewService/NewService';
import NewTimeOff from './components/NewTimeOff/NewTimeOff';
import styles from './CreateEventPage.module.css';

enum NewEventTabs {
    TIME_OFF = 'TIME_OFF',
    SERVICE = 'SERVICE',
}

function CreateEventPage() {
    const [tab, setTab] = useState(NewEventTabs.TIME_OFF);

    const handleTabChange = (_e: any, newValue: NewEventTabs) => {
        setTab(newValue);
    };

    return (
        <div className={styles.main}>
            <Typography className={styles.header} variant="h5">
                Novo Evento
            </Typography>
            <Tabs value={tab} onChange={handleTabChange} className={styles.tab}>
                <Tab
                    className={styles.tab}
                    icon={<BeachAccessIcon/>}
                    iconPosition="start"
                    value={NewEventTabs.TIME_OFF}
                    label="Folga"
                />
                <Tab
                    className={styles.tab}
                    icon={<HandymanIcon/>}
                    iconPosition="start"
                    value={NewEventTabs.SERVICE}
                    label="Serviço"
                />
            </Tabs>
            {tab === NewEventTabs.TIME_OFF && (<NewTimeOff/>)}
            {tab === NewEventTabs.SERVICE && (<NewService/>)}
        </div>
    );
}

export default CreateEventPage;