import { Alert } from "@mui/material";
import styled from "styled-components"
import { themes } from "../../themes/themes";

const { primaryFont, secundaryFont, white, black } = themes;

export const TitleCreateNewPassword = styled.h2`
    color: ${white};
    font-size: 2.5rem;
    font-family: ${secundaryFont};
    font-weight: 700;
    line-height: 2.813rem;
    word-wrap: break-word;
    padding-bottom: 80px;
`

export const InnerCard = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TextCreateNewPassword = styled.h3`
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    max-width: 295px;
    padding-bottom: 25px;
`;

export const DivInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const Buttons = styled.div`
    padding-top: 30px;
`

export const AlertInstructions = styled(Alert)`
    margin-bottom: 30px;
    text-align: center;
    max-width: 300px;
`