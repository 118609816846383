import { useQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import GetSitesListResponse from "../../interfaces/payment/GetSitesListResponse";

const unexpectedErrorText = "Não foi possível obter a lista de sites, tente novamente";
const getSitesList = async (): Promise<GetSitesListResponse> => {
    try{
        const response = await AxiosConfig
            .getAxiosInstance()
            .get<GetSitesListResponse>('/api/v1/sites/get-by-user');

        if (response.status === 200)
            return response.data;

        throw new Error(unexpectedErrorText);
    } catch(err){
        throw new Error(unexpectedErrorText);
    }
}


export const useGetSitesList = () =>
    useQuery<GetSitesListResponse, Error>({
        queryFn: getSitesList,
        queryKey: ['useGetSitesList'],
        refetchOnWindowFocus: false
    });