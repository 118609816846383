import { useEffect, useState } from 'react';
import { PublishmentStep } from '../publishmentModal/publishment';
import { useSitePayment } from '../../../../hooks/paymentService/useSitePayment';
import { useCreateCreditCardToken } from '../../../../hooks/paymentService/useCreateCreditCardToken';
import CreateCreditCardTokenResponse from '../../../../interfaces/payment/CreateCreditCardTokenResponse';
import PaySiteResponse from '../../../../interfaces/payment/PaySiteResponse';
import PersonNature from '../../../../interfaces/PersonNature.enum';
import Domain from '../../../../interfaces/Domain';
import CardDetailsStep from './subSteps/cardDetailsStep/cardDetailsStep';
import CardAddressStep from './subSteps/cardAddressStep/cardAddressStep';
import { PaymentStepData } from '../../../../interfaces/payment/PaymentStepData';
import { Toast } from '../../../../components/Toast/Toast';

export enum SubStep {
    CARD_DETAILS,
    CARD_ADDRESS,
    PROCESSING_PAYMENT
}

interface Props {
    domain?: Domain;
    siteId?: string;
    goToStep: (step: PublishmentStep) => void;
    setCurrentPaymentId: (paymentId: string) => void;
    setIsProcessingPayment:  (paymentProcress: boolean) => void;
}

export default function PaymentStep(props: Props) {
    const { domain, siteId, goToStep, setCurrentPaymentId, setIsProcessingPayment } = props;
    const [subStepState, setSubStepState] = useState(SubStep.CARD_DETAILS);
    const [paymentStepData, setPaymentStepData] = useState<PaymentStepData>({
        creditCardData: {
            type: 'card',
            card: {
                number: '',
                exp_month: '',
                exp_year: '',
                holder_name: '',
                cvv: ''
            }
        },
        paymentData: {
            siteId: siteId!,
            domain: domain!,
            discountCoupon: '',
            installments: 1,
            cardToken: '',
            customerInfo: {
                personNature: PersonNature.NATURAL,
                document: ''
            },
            billingAddress: {
                street: '',
                neighborhood: '',
                zipCode: '',
                complement: '',
                number: '',
                city: '',
                state: 'AC',
                country: 'BR'
            }
        }
    });
    
    const onCreateCreditCardToken = (response: CreateCreditCardTokenResponse) => {
        paySite({
            ...paymentStepData.paymentData,
            customerInfo: {
                ...paymentStepData.paymentData.customerInfo,
                document: paymentStepData.paymentData.customerInfo.document.replace(/\D/g, "")
            },
            billingAddress: {
                ...paymentStepData.paymentData.billingAddress,
                zipCode: paymentStepData.paymentData.billingAddress.zipCode.replace(/\D/g, ""),
                complement: paymentStepData.paymentData.billingAddress.complement?.length != 0 ? paymentStepData.paymentData.billingAddress.complement : undefined,
                number: paymentStepData.paymentData.billingAddress.number?.length != 0 ? paymentStepData.paymentData.billingAddress.number : undefined
            },
            cardToken: response.id
        });
    };
    const { mutate: createCreditCardToken, isLoading: isLoadingCardToken, isError: failedToCreateCcToken } = useCreateCreditCardToken(onCreateCreditCardToken);

    const onSitePayment = (response: PaySiteResponse) => {
        setCurrentPaymentId(response.paymentUUID);
        goToStep(PublishmentStep.RESULT);
    };
    const { mutate: paySite, isLoading: isLoadingSitePayment, isError: failedToMakePayment } = useSitePayment(onSitePayment);

    useEffect(() => {
        if (isLoadingSitePayment || isLoadingCardToken)
            setIsProcessingPayment(true);
    }, [isLoadingSitePayment, isLoadingCardToken]);

    const goToSubStep = (step: SubStep) => {
        if (subStepState != SubStep.PROCESSING_PAYMENT)
            setSubStepState(step)
    };

    const updatePaymentStepData = (paymentStepData: PaymentStepData) => {
        setPaymentStepData(prev => ({...prev, ...paymentStepData}));
    }

    useEffect(() => {
        if (failedToCreateCcToken || failedToMakePayment) {
            Toast.showToast(
                'Ocorreu um erro inesperado durante o pagamento, por favor tente novamente',
                'error'
            );
            setSubStepState(SubStep.CARD_ADDRESS);
            setIsProcessingPayment(false);
        }
    }, [failedToMakePayment, failedToCreateCcToken]);

    useEffect(() => {
        if (subStepState == SubStep.PROCESSING_PAYMENT)
            createCreditCardToken({
                ...paymentStepData.creditCardData,
                card: {
                    ...paymentStepData.creditCardData.card,
                    number: paymentStepData.creditCardData.card.number.replace(/\D/g, "")
                }
            });
    }, [subStepState]);

    return (
        <>
            {subStepState === SubStep.CARD_DETAILS &&
                <CardDetailsStep
                    paymentStepData={paymentStepData}
                    updatePaymentStepData={updatePaymentStepData}
                    domain={domain}
                    goToStep={goToStep}
                    goToSubStep={goToSubStep}
                />
            }
            {(subStepState === SubStep.CARD_ADDRESS || subStepState == SubStep.PROCESSING_PAYMENT) &&
                <CardAddressStep
                    paymentStepData={paymentStepData}
                    updatePaymentStepData={updatePaymentStepData}
                    step={subStepState}
                    goToStep={goToStep}
                    goToSubStep={goToSubStep}
                />
            }
        </>
    );
}
