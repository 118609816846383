import ReturnArrow from "../../../../../components/ReturnArrow/ReturnArrow";
import { Title, Description, ListSuggestions, SuggestionsCategoryItem, GenerateAgain, SuggestionsCategorySkeleton } from "./CategorySuggestionsStep.styles";
import ComponentSkeleton from "../../../../../components/Skeleton-Loading/steletonLoading";
import { useGetSuggestedCategories } from "../../../../../hooks/siteGenerationService/useGetSuggestedCategories";
import { SiteCreationStep } from "../../../SiteCreationPage";

interface Props{
    goToStep: (step: SiteCreationStep) => void;
    setCategoryValue: (value: string) => void;
}

export default function CategorySuggestionsStep({ goToStep, setCategoryValue }: Props){
  const { isLoading, refetch, data, isRefetching } = useGetSuggestedCategories();

    const onCategoryClick = (category: string) => {
        setCategoryValue(category);
        goToStep(SiteCreationStep.CATEGORY);
    }

    return(
        <>
            <ReturnArrow to={() => goToStep(SiteCreationStep.CATEGORY)}/>
            <Title>
                Qual o tipo de negócio você gostaria de começar?
            </Title>
            <Description>
                Aqui estão algumas sugestões:
            </Description>
            <ListSuggestions>
                {(isLoading || isRefetching) ? (
                    Array.from({ length: 8}).map((_, index) => (
                    <SuggestionsCategorySkeleton key={index}>
                        <ComponentSkeleton 
                            width={55 + (Math.random() * 30)} 
                            height={25}
                        />
                    </SuggestionsCategorySkeleton>
                    ))
                ) : (
                    data.categories.map((category: string) => (
                        <SuggestionsCategoryItem
                            key={category}
                            value={category}
                            onClick={() => onCategoryClick(category)}
                        >
                            {category}
                        </SuggestionsCategoryItem>
                    )))
                }
            </ListSuggestions>
            <GenerateAgain onClick={() => refetch()}>Gerar novamente</GenerateAgain>
        </>
    )
}