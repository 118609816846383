import { useState } from 'react';
import CategoryStep from './components/Steps/CategoryStep/CategoryStep';
import LocationStep from './components/Steps/LocationStep/LocationStep';
import NameStep from './components/Steps/NameStep/NameStep';
import { 
    InnerCard, 
    PageCreateBusiness, 
    MainContainer,
    BgBlur,
    Card
} from './SiteCreationPage.styles';
import SocialMediaStepInput from './components/Steps/SocialMediaStepInput/SocialMediaStepInput';
import { useNavigate } from 'react-router-dom';
import CreationOptions from '../siteEditor/interface/creationOptions';
import CategorySuggestionsStep from './components/Steps/CategorySuggestionsStep/CategorySuggestionsStep';

export enum SiteCreationStep {
    CATEGORY = 'CATEGORY',
    SOCIAL_MEDIA = 'SOCIAL_MEDIA',
    CATEGORY_SUGGESTIONS = 'CATEGORY_SUGGESTIONS',
    NAME = 'NAME',
    LOCATION = 'LOCATION'
}

export default function CreateBusinessPage() {
    const [creationOptions, setCreationOptions] = useState<CreationOptions>({
        name: '',
        category: '',
        location: '',
        instagram: '',
        whatsapp: ''
    });
    const navigate = useNavigate();
    const [siteCreationStep, setSiteCreationStep] = useState(SiteCreationStep.CATEGORY);

    // ao entrar nessa página
        // validar se o usuário possui um token de studio armazenado nos cookies 

    const goToStep = (step: SiteCreationStep) => {
        setSiteCreationStep(step);
    }

    const setCategoryValue = (value: string) => {
        setCreationOptions(prev => ({...prev, category: value}));
    }

    const setNameValue = (value: string) => {
        setCreationOptions(prev => ({...prev, name: value}));
    }

    const setLocationValue = (value?: string) => {
        setCreationOptions(prev => ({...prev, location: value}));
    }

    const setWhatsappValue = (value?: string) => {
        setCreationOptions(prev => ({...prev, whatsapp: value}));
    }

    const setInstagramValue = (value?: string) => {
        setCreationOptions(prev => ({...prev, instagram: value}));
    }

    const handleSubmit = (whatsapp?: string, instagram?: string) => {
        navigate(
            '/site/editor',
            {
                state: {
                    ...creationOptions,
                    whatsapp,
                    instagram
                },
            }
        );
    }

    return (
        <>
            <BgBlur/>
            <PageCreateBusiness>
                <MainContainer>
                    <Card>
                        <InnerCard>
                            {siteCreationStep == SiteCreationStep.CATEGORY && (
                                <CategoryStep
                                    initialValue={creationOptions.category}
                                    goToStep={goToStep}
                                    setCategoryValue={setCategoryValue}
                                />
                            )}
                            {siteCreationStep == SiteCreationStep.CATEGORY_SUGGESTIONS && (
                                <CategorySuggestionsStep
                                    goToStep={goToStep}
                                    setCategoryValue={setCategoryValue}
                                />
                            )}
                            {siteCreationStep == SiteCreationStep.NAME && (
                                <NameStep
                                    initialValue={creationOptions.name}
                                    goToStep={goToStep}
                                    setNameValue={setNameValue}
                                />
                            )}
                            {siteCreationStep == SiteCreationStep.LOCATION && (
                                <LocationStep
                                    initialValue={creationOptions.location}
                                    goToStep={goToStep}
                                    setLocation={setLocationValue}
                                />
                            )}
                            {siteCreationStep == SiteCreationStep.SOCIAL_MEDIA && (
                                <SocialMediaStepInput
                                    initialInstagramValue={creationOptions.instagram}
                                    initialWhatsappValue={creationOptions.whatsapp}
                                    setWhatsappValue={setWhatsappValue}
                                    setInstagramValue={setInstagramValue}
                                    goToStep={goToStep}
                                    onSubmit={handleSubmit}
                                />
                            )}
                        </InnerCard>
                    </Card>
                </MainContainer>
            </PageCreateBusiness>
        </>
    );
}